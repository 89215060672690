import * as form from '../create/formConstructor';
import * as messages from '../../../constants/messages';

export default (values) => {
  const errors = {};

  if (!values[form.NAME]) {
    errors[form.NAME] = messages.NOT_EMPTY;
  }
  if (!values[form.FULL_NAME]) {
    errors[form.FULL_NAME] = messages.NOT_EMPTY;
  }
  if (!values[form.INN]) {
    errors[form.INN] = messages.NOT_EMPTY;
  }
  else if (values[form.INN].includes('_')) {
    errors[form.INN] = messages.INVALID_DATA;
  }
  if (!values[form.KPP]) {
    errors[form.KPP] = messages.NOT_EMPTY;
  }
  else if (values[form.KPP].includes('_')) {
    errors[form.KPP] = messages.INVALID_DATA;
  }
  if (!values[form.OGRN]) {
    errors[form.OGRN] = messages.NOT_EMPTY;
  }
  else if (values[form.OGRN].includes('_')) {
    errors[form.OGRN] = messages.INVALID_DATA;
  }
  if (values[form.PHONE] && values[form.PHONE].includes('_')) {
    errors[form.PHONE] = messages.WRONG_PHONE;
  }
  if (values[form.EMAIL] && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values[form.EMAIL])) {
    errors[form.EMAIL] = messages.WRONG_EMAIL;
  }
  if (values[form.WEBSITE] &&
    !/(ftp|http|https):(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(|([\w#!:.?+=&%@!]))?/.test(values[form.WEBSITE])) {
    errors[form.WEBSITE] = messages.WRONG_WEBSITE;
  }
  if (values[form.OWNERSHIP_TYPE] && !values[form.OWNERSHIP_TYPE].value) {
    errors[form.OWNERSHIP_TYPE] = messages.NOT_EMPTY;
  }

  return errors;
}