import React from 'react';
import {connect} from 'react-redux';
import {Clay, Button} from '@n3/kit';
import {Link} from 'react-router-dom';

import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import RecruitmentTable from './RecruitmentTable';
import StaffSearch from '../../layouts/StaffSearch';
import {VACANCY_READ_STORE} from '../read/VacancyRead';
import StaffPaginator from '../../layouts/StaffPaginator';
import RecruitmentListFilter from './RecruitmentListFilter';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {VACANCIES_LIST, RECRUITMENT} from '../../../constants/pagesName';
import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';

const RECRUITMENT_STORE = 'recruitmentStore';

class RecruitmentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breadCrumbs: [
        {
          url: `/vacancy`,
          title: VACANCIES_LIST
        },
        {
          url: null,
          title: RECRUITMENT
        }
      ]
    };
  }

  componentDidMount() {
    const {dataSource, user} = this.props;
    this.vacancyId = this.props.match.params.id;

    if (!dataSource && user.id) {
      this.createVacancyStore();
      this.createRecruitmentStore();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user.id !== nextProps.user.id) {
      if (nextProps.user.id) {
        this.createVacancyStore();
        this.createRecruitmentStore();
      }
    }
  }

  componentWillUnmount() {
    this.props.appStoreDelete(RECRUITMENT_STORE);
    this.props.appStoreDelete(VACANCY_READ_STORE);
  }

  createRecruitmentStore = () => {
    const dataSource = `/specialists/recruitments_by_vacancy/${this.vacancyId}/`;
    this.props.appStoreCreate(RECRUITMENT_STORE, dataSource);
  };

  createVacancyStore = () => {
    const dataSource = `/vacancy/${this.vacancyId}`;
    this.props.appStoreCreate(VACANCY_READ_STORE, dataSource);
    this.props.appStoreGetDisplayData(VACANCY_READ_STORE, dataSource);
  };

  handleDownloadDocuments = (event) => {
    event.preventDefault();
    const token = getCookie('token');
    const API = process.env.REACT_APP_STAFF_SERVER;
    const request = {
      file: `${API}/specialists/recruitments_by_vacancy/${this.vacancyId}/excel/?token=${token}`
    };

    window.open(request.file);
  };

  renderDownload() {
    if (this.props.specialistList && this.props.specialistList.length > 0) {
      return <Button onlyIcon onClick={(event) => this.handleDownloadDocuments(event)}>
        <i className="fas fa-file-download" />
      </Button>;
    }
    else {
      return null;
    }
  }

  renderTitle = () => {
    const {vacancy} = this.props;

    if (!vacancy) {
      return null;
    }
    else {
      return (
        <Clay>
          {RECRUITMENT}
          <Link to={`/vacancy/read/${this.props.match.params.id}`} target="_blank">
            {` "${vacancy.name}"`}
          </Link>
        </Clay>
      );
    }
  };

  render() {
    const {breadCrumbs} = this.state;
    const {user, vacancy} = this.props;

    if (!user.id || !vacancy) {
      return null;
    }

    const permissionCheck = user.organization.id === vacancy.organization;

    return (
      <AccessControl permission={permission.VACANCY_SEARCH_SPECIALISTS} accessCheck={permissionCheck} redirect={'/hasNoAccess'}>
        <Clay className="app-container app-container__list-area">
          <PageHeader breadCrumbs={breadCrumbs} title={this.renderTitle()} />

          <Clay padding={[3, 5]}>
            <RecruitmentListFilter storeName={RECRUITMENT_STORE} />

            <Clay padding={[0, 0, 2]} />

            <Clay padding={[2, 0, 3, 0]}>
              <StaffSearch storeName={RECRUITMENT_STORE} />
            </Clay>

            <Clay padding={[0, 0, 6]} />

            <RecruitmentTable storeName={RECRUITMENT_STORE} />

            <Clay padding={[0, 0, 2]} />

            <Clay padding={[2, 0, 0, 0]} className="app-container app-container__row">
              <StaffPaginator storeName={RECRUITMENT_STORE} />
              {this.renderDownload()}
            </Clay>
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

const mapStateToProps = (state) => {
  const recruitmentStore = state.appStore[RECRUITMENT_STORE];
  const vacancyStore = state.appStore[VACANCY_READ_STORE];

  return {
    user: state.user,
    filters: recruitmentStore ? recruitmentStore.storeFilters : null,
    dataSource: recruitmentStore ? recruitmentStore.storeDataSource : null,
    vacancy: vacancyStore ? vacancyStore.storeDisplayData : null,
    specialistList: recruitmentStore ? recruitmentStore.storeListData : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentList);