export const FORM_NAME = 'specialistUpdateForm';

export const ID = 'id';
export const FAX = 'fax';
export const OKS = 'oks';
export const RANK = 'rank';
export const SNILS = 'snils';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const GRADE = 'grade';
export const PHOTO = 'photo';
export const WORKS = 'works';
export const ACTIVE = 'active';
export const REGION = 'region';
export const GENDER = 'gender';
export const DEGREE = 'degree';
export const COURSE = 'course';
export const WEBSITE = 'website';
export const BRIGATE = 'brigate';
export const INVALID = 'invalid';
export const DATE_END = 'date_end';
export const APARTMENT = 'apartment';
export const BIRTH_DAY = 'birth_day';
export const LAST_NAME = 'last_name';
export const MAIN_WORK = 'main_work';
export const FIRST_NAME = 'first_name';
export const PROFESSION = 'profession';
export const TECHNOLOGY = 'technology';
export const DISABILITY = 'disability';
export const DATE_START = 'date_start';
export const EDUCATIONS = 'educations';
export const SPECIALITY = 'speciality';
export const STUDY_MODE = 'study_mode';
export const SECOND_NAME = 'second_name';
export const NATIONALITY = 'nationality';
export const ADDRESS_UAS = 'address_uas';
export const ORGANIZATION = 'organization';
export const DATE_START_WORK = 'date_start';
export const QUALIFICATION = 'qualification';
export const PHONE_ADDITORY = 'phone_additory';
export const EDUCATION_TYPE = 'education_type';
export const SPECIALIZATION = 'specialization';
export const YEAR_EDUCATION = 'year_education';
export const REGISTRATION_SPB = 'registration_spb';
export const ACCESS_WORK_TYPE = 'access_work_type';
export const COMPLEX_PROJECTS = 'complex_projects';
export const PLANNED_DATE_END = 'planned_date_end';
export const RESIDENCE_ADDRESS = 'residence_address';
export const EDUCATION_PROGRAM = 'education_program';
export const EMPLOYMENT_STATUS = 'employment_status';
export const REGISTRATION_ADDRESS = 'registration_address';
export const REG_RES_ADDRESS_MATCH = 'reg_res_address_match';

export const SELECTOR_DEFAULT_VALUE = {value: null, label: 'Выберите...'};

export const SPECIALIST_UPDATE_FORM = {
  [FAX]: null,
  [SNILS]: null,
  [PHONE]: null,
  [EMAIL]: null,
  [PHOTO]: null,
  [WORKS]: null,
  [WEBSITE]: null,
  [ACTIVE]: false,
  [APARTMENT]: '',
  [TECHNOLOGY]: [],
  [BIRTH_DAY]: null,
  [LAST_NAME]: null,
  [FIRST_NAME]: null,
  [EDUCATIONS]: null,
  [SECOND_NAME]: null,
  [DISABILITY]: false,
  [PHONE_ADDITORY]: null,
  [ACCESS_WORK_TYPE]: [],
  [RESIDENCE_ADDRESS]: '',
  [DATE_START_WORK]: null,
  [REGISTRATION_SPB]: true,
  [REGISTRATION_ADDRESS]: '',
  [REG_RES_ADDRESS_MATCH]: true,
  [RANK]: SELECTOR_DEFAULT_VALUE,
  [GRADE]: SELECTOR_DEFAULT_VALUE,
  [GENDER]: SELECTOR_DEFAULT_VALUE,
  [REGION]: SELECTOR_DEFAULT_VALUE,
  [PROFESSION]: SELECTOR_DEFAULT_VALUE,
  [NATIONALITY]: SELECTOR_DEFAULT_VALUE,
  [EMPLOYMENT_STATUS]: SELECTOR_DEFAULT_VALUE,
  [ADDRESS_UAS]: {value: '', label: 'Начните вводить адрес...'}
};

export const SPECIALIST_WORK_FORM = {
  [OKS]: '',
  [ID]: null,
  [BRIGATE]: '',
  [INVALID]: true,
  [DATE_END]: null,
  [MAIN_WORK]: true,
  [DATE_START]: null,
  [COMPLEX_PROJECTS]: '',
  [RANK]: SELECTOR_DEFAULT_VALUE,
  [ORGANIZATION]: SELECTOR_DEFAULT_VALUE
};

export const SPECIALIST_EDUCATION_FORM = {
  [ID]: null,
  [INVALID]: true,
  [DATE_END]: null,
  [DATE_START]: '',
  [SPECIALIZATION]: '',
  [YEAR_EDUCATION]: '',
  [PLANNED_DATE_END]: null,
  [COURSE]: SELECTOR_DEFAULT_VALUE,
  [DEGREE]: SELECTOR_DEFAULT_VALUE,
  [STUDY_MODE]: SELECTOR_DEFAULT_VALUE,
  [SPECIALITY]: SELECTOR_DEFAULT_VALUE,
  [ORGANIZATION]: SELECTOR_DEFAULT_VALUE,
  [QUALIFICATION]: SELECTOR_DEFAULT_VALUE,
  [EDUCATION_TYPE]: SELECTOR_DEFAULT_VALUE,
  [EDUCATION_PROGRAM]: SELECTOR_DEFAULT_VALUE
};

export const SNILS_MASK = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
export const PHONE_MASK = [
  '+',
  '7',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];