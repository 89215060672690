import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Clay, createDropdownMenu, EllipsisDropdown, Tabs, log} from '@n3/kit';

import API from '../../../API';
import StaffTab from '../../layouts/StaffTab';
import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import * as messages from '../../../constants/messages';
import {mapErrorMessage} from '../../../services/mapper';
import OrganizationReadData from './OrganizationReadData';
import * as permission from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';
import OrganizationReadContacts from './OrganizationReadContacts';
import OrganizationReadDataHeader from './OrganizationReadDataHeader';
import DocumentsListContainer from '../../documents/list/DocumentsListContainer';
import {ORGANIZATION_READ, ORGANISATIONS_LIST} from '../../../constants/pagesName';
import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';

const ORGANIZATION_READ_STORE = 'organizationReadStore';

const PAGE_TABS = [
  {id: 1, title: 'Основная информация'},
  {id: 2, title: 'Контакты'},
  {id: 3, title: 'Документы'}
];

class OrganizationRead extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1,
      breadCrumbs: [
        {
          url: `/organizations`,
          title: ORGANISATIONS_LIST
        },
        {
          url: null,
          title: ORGANIZATION_READ
        }
      ]
    };
  }

  componentDidMount() {
    const {tab} = this.props.match.params;
    const orgId = this.props.match.params.id;
    const dataSource = `/organizations/${orgId}/`;

    this.setState({currentTab: tab * 1});

    this.props.appStoreCreate(ORGANIZATION_READ_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(ORGANIZATION_READ_STORE);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {dataSource, displayData} = this.props;

    if (dataSource && !displayData) {
      this.props.appStoreGetDisplayData(ORGANIZATION_READ_STORE, dataSource);
    }
  }

  setCurrentTab = (tabId) => {
    this.setState({currentTab: tabId});

    const {id} = this.props.match.params;
    this.props.history.push(`/organizations/read/${id}/${tabId}`);
  };

  redirectToUpdate = () => {
    const {id, tab} = this.props.match.params;
    const path = `/organizations/update/${id}/${tab}`;
    this.props.history.push(path);
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  onDelete = () => {
    const orgId = this.props.match.params.id;
    const request = `/organizations/${orgId}/`;

    return API.request(request, {method: 'delete'})
      .then(() => {
        this.props.history.push('/organizations');
      })
      .catch((error) => {
        const errorMessage = error.response.status === 400
          ? mapErrorMessage(error)
          : messages.DELETE_ERROR;
        this.renderErrorLog(errorMessage);
      });
  };

  renderDropdown = () => {
    const controlArr = [];

    const userRole = getCookie('role');

    if (checkPermission(userRole, permission.ORGANIZATION_UPDATE)) {
      controlArr.push({
        type: 'ellipsis',
        onClick: this.redirectToUpdate,
        label: 'Редактировать'
      });
    }

    if (checkPermission(userRole, permission.ORGANIZATION_DELETE)) {
      controlArr.push({
        type: 'ellipsis',
        onClick: this.onDelete,
        label: 'Удалить'
      });
    }

    if (controlArr.length === 0) {
      return null;
    }

    return (
      <EllipsisDropdown {...createDropdownMenu(controlArr)} />
    );
  };

  getBreadCrumbs = () => {
    const {breadCrumbs} = this.state;
    const role = getCookie('role');

    return checkPermission(role, permission.ORGANIZATION_READ_LIST) ? breadCrumbs : null;
  };

  render() {
    const {currentTab} = this.state;

    return (
      <Clay className="app-container">
        <PageHeader title={ORGANIZATION_READ}
                    breadCrumbs={this.getBreadCrumbs()}
                    controlButton={this.renderDropdown()} />

        <OrganizationReadDataHeader storeName={ORGANIZATION_READ_STORE} />

        <Tabs tabs={PAGE_TABS}
              current={currentTab}
              setCurrentTab={this.setCurrentTab} />

        <Clay margin={[6, 5]}>
          <StaffTab tabId={1} currentTabId={currentTab}>
            <OrganizationReadData storeName={ORGANIZATION_READ_STORE} />
          </StaffTab>

          <StaffTab tabId={2} currentTabId={currentTab}>
            <OrganizationReadContacts storeName={ORGANIZATION_READ_STORE} />
          </StaffTab>

          <StaffTab tabId={3} currentTabId={currentTab}>
            <DocumentsListContainer docsType="by_organization" />
          </StaffTab>
        </Clay>
      </Clay>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[ORGANIZATION_READ_STORE];

  return {
    dataSource: store ? store.storeDataSource : null,
    displayData: store ? store.storeDisplayData : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrganizationRead));