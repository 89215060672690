import * as form from './formConstructor';
import * as messages from '../../../constants/messages';
import {getCookie} from '../../../utils/Cookie';
import {checkPermission} from '../../../services/checkPermission';
import * as permission from '../../../constants/permissionsList';
import * as roles from '../../../constants/rolesList';

export default (values) => {
  const errors = {};
  const userRole = getCookie('role');

  if (!values[form.DATE_START]) {
    errors[form.DATE_START] = messages.NOT_EMPTY;
  }
  if (userRole === roles.STAFFING && !values[form.DATE_END]) {
    errors[form.DATE_END] = messages.NOT_EMPTY;
  }
  if (!values[form.EDUCATION_TYPE] || !values[form.EDUCATION_TYPE].value) {
    errors[form.EDUCATION_TYPE] = messages.NOT_EMPTY;
  }
  if (!values[form.STUDY_MODE] || !values[form.STUDY_MODE].value) {
    errors[form.STUDY_MODE] = messages.NOT_EMPTY;
  }
  if (!values[form.PLANNED_DATE_END]) {
    errors[form.PLANNED_DATE_END] = messages.NOT_EMPTY;
  }
  if (!values[form.COURSE] || !values[form.COURSE].value) {
    errors[form.COURSE] = messages.NOT_EMPTY;
  }
  if (checkPermission(userRole, permission.EDUCATION_UPDATE_ORGANISATION) &&
    (!values[form.ORGANIZATION] || !values[form.ORGANIZATION].value)) {
    errors[form.ORGANIZATION] = messages.NOT_EMPTY;
  }
  if (checkPermission(userRole, permission.EDUCATION_CREATE) &&
    (!values[form.ORGANIZATION] || !values[form.ORGANIZATION].value)) {
    errors[form.ORGANIZATION] = messages.NOT_EMPTY;
  }

  return errors;
}