import React from 'react';
import {connect} from 'react-redux';
import {Clay, Alert, Button, Col, Row} from '@n3/kit';

import API from '../../API';
import {appStoreCreate, appStoreDelete, appStoreUpdateListData} from '../../store/appStore/actions';
import AccessControl from '../accessControl/AccessControl';
import {NOTIFY_READ_LIST} from '../../constants/permissionsList';

export const NOTIFICATIONS_LIST_STORE = 'NOTIFICATIONS_LIST_STORE';

class Notifications extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.server = process.env.REACT_APP_SOCKET_STAFF_SERVER;
  }

  componentDidMount() {
    this.props.appStoreCreate(NOTIFICATIONS_LIST_STORE);
    this.props.appStoreUpdateListData(NOTIFICATIONS_LIST_STORE, []);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(NOTIFICATIONS_LIST_STORE);
  }

  componentDidUpdate(prevProps) {
    const {user} = this.props;

    if (user.id && user !== prevProps.user) {
      this.ws_connect(user.id);
    }
  }

  ws_connect = (id) => {
    this.socket = new WebSocket(`${this.server}?user_id=${id}`);

    this.socket.onopen = () => console.log('WS connection open');

    this.socket.onmessage = (event) => {
      const {notificationsList} = this.props;
      const message = JSON.parse(event.data).message;

      if (message.event) {
        this.props.appStoreUpdateListData(NOTIFICATIONS_LIST_STORE,
          [...notificationsList.filter(notify => notify.event.id !== message.event.id), message]);
      }
    };

    this.socket.onclose = (event) => {
      if (event.wasClean) {
        console.log('WS clean connection end');
      }
      else {
        console.error('WS connection broken');
      }
      // Try to reconnect in 2 seconds
      setTimeout(() => this.ws_connect(id), 2000);
    };

    this.socket.onerror = function(error) {
      console.error('WS connection error !!!', error);
    };
  };

  handleClick = (id) => {
    const {notificationsList} = this.props;
    const request = `/notifications/edit/${id}/`;
    const data = {was_readed: true};

    API.request(request, {method: 'patch', data})
      .then(resp => {
        this.props.appStoreUpdateListData(NOTIFICATIONS_LIST_STORE,
          [...notificationsList.filter(notify => notify.id !== resp.id * 1)]);
      });
  };

  renderNotifications = () => {
    const {notificationsList} = this.props;

    return notificationsList.map((notify, index) => {
      return (
        <Alert key={index} color="primary1">
          <Row>
            <Col xs={10}>
              {notify.event && notify.event.text_to_interface}
            </Col>
            <Col xs={2}>
              <Clay className="app-container__row_flex-end">
                <Button onClick={() => this.handleClick(notify.id)}>
                  Я Прочитал
                </Button>
              </Clay>
            </Col>
          </Row>
        </Alert>
      );
    });
  };

  render() {
    const {userRole, userToken, notificationsList} = this.props;

    if (!userRole && !userToken && !notificationsList) {
      return null;
    }

    return notificationsList.length
      ? (
        <AccessControl permission={NOTIFY_READ_LIST} accessCheck={!!userRole}>
          <Clay padding={[3, 5]}>
            {this.renderNotifications()}
          </Clay>
        </AccessControl>
      )
      : null;
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[NOTIFICATIONS_LIST_STORE];

  return {
    user: state.user,
    userRole: state.app.userRole,
    userToken: state.app.userToken,
    notificationsList: store ? store.storeListData : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);