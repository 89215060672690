import * as form from './formConstructor';
import * as messages from '../../../constants/messages';

export default (values) => {
  const errors = {};

  if (!values[form.FIRST_NAME]) {
    errors[form.FIRST_NAME] = messages.NOT_EMPTY;
  }
  if (!values[form.LAST_NAME]) {
    errors[form.LAST_NAME] = messages.NOT_EMPTY;
  }

  return errors;
}