export const ID = 'id';
export const OKS = 'oks';
export const RANK = 'rank';
export const BRIGATE = 'brigate';
export const DATE_END = 'date_end';
export const MAIN_WORK = 'main_work';
export const DATE_START = 'date_start';
export const ORGANIZATION = 'organization';
export const COMPLEX_PROJECTS = 'complex_projects';

export const WORK_UPDATE_FORM = [
  ID,
  OKS,
  RANK,
  BRIGATE,
  DATE_END,
  MAIN_WORK,
  DATE_START,
  ORGANIZATION,
  COMPLEX_PROJECTS,
];
