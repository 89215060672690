import {connect} from 'react-redux';

import { appStoreCreate, appStoreDelete, appStoreGetListData } from '../../../store/appStore/actions';
import {appFilterDelete} from '../../../store/app/actions';
import DemandsList, {DEMAND_STORE} from './DemandsList';

const mapStateToProps = (state) => {
  const store = state.appStore[DEMAND_STORE];

  return {
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetListData,
  appFilterDelete
};

export default connect(mapStateToProps, mapDispatchToProps)(DemandsList);