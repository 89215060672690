import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';
import SpecialistRead, {SPECIALIST_READ_STORE} from './SpecialistRead';

const mapStateToProps = (state) => {
  const store = state.appStore[SPECIALIST_READ_STORE];

  return {
    userRole: state.app.userRole,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpecialistRead));