import React, {Component} from 'react';
import {Clay, LoadingArea, Row, Col} from '@n3/kit';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';
import {EDUCATION_TYPE_VALUES, STUDY_MODES_VALUES} from '../../../constants/selectorsValues';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import StaffCollapse from '../../layouts/StaffCollapse';
import DisplayData from '../../layouts/DisplayData';

const EDUCATION_LIST_STORE = 'educationListStore';

class EducationList extends Component {

  componentDidMount() {
    const specId = this.props.match.params.id;
    const dataSource = `/specialists/${specId}/educations/`;

    this.props.appStoreCreate(EDUCATION_LIST_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(EDUCATION_LIST_STORE);
  }

  componentDidUpdate() {
    const {dataSource, eduList} = this.props;

    if (dataSource && !eduList) {
      this.props.appStoreGetDisplayData(EDUCATION_LIST_STORE, dataSource);
    }
  }

  renderCollapseHeader = (edu) => {

    const education = `${edu.date_start} - ${edu.date_end ? edu.date_end : 'настоящее время'}`;

    return (
      <Clay className="app-container">
        <Row>
          <Col xs={3}>
            <DisplayData data={edu.organization.name} label="Организация" />
          </Col>
          <Col xs={3}>
            <DisplayData data={education} label="Период обучения" />
          </Col>
          <Col xs={3}>
            <DisplayData label="Образование"
                         data={edu.education_type &&
                         EDUCATION_TYPE_VALUES.find(value => value.value === edu.education_type).label} />
          </Col>
          <Col xs={3}>
            <DisplayData data={edu.speciality && edu.speciality.name} label="Специальность" />
          </Col>
        </Row>
      </Clay>
    );
  };

  renderCollapseBody = (edu) => {
    const org = edu.organization;

    const orgStr =  `${org.fact_address  && 'Адрес: '  + org.fact_address}` +
                    `${org.fact_address && org.phone ? ', ' : ''}` +
                    `${org.phone         && 'Тел.: ' + org.phone}` +
                    `${(org.fact_address || org.phone) && org.website ? ', ' : ''}` +
                    `${org.website       && 'Сайт: ' + org.website}` +
                    `${!org.fact_address && !org.phone && !org.website ? '—' : ''}`;

    return (
      <Clay padding={[0, 3, 3, 4]}>
        <Row>
          <Col xs={4}>
            <DisplayData data={orgStr} label="Информация об организации" />
          </Col>
          <Col xs={4}>
            <DisplayData data={edu.planned_date_end} label="Плановая дата окончания" />
          </Col>
          <Col xs={4}>
            <DisplayData data={edu.year_education} label="Год обучения" />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <DisplayData label="Форма обучения"
                         data={edu.study_mode &&
                         STUDY_MODES_VALUES.find(value => value.value === edu.study_mode).label} />
          </Col>
          <Col xs={4}>
            <DisplayData data={edu.degree && edu.degree.name} label="Степень" />
          </Col>
          <Col xs={4}>
            <DisplayData data={edu.education_program && edu.education_program.name} label="Квалификация" />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <DisplayData data={edu.specialization} label="Специализация" />
          </Col>
          <Col xs={4}>
            <DisplayData data={edu.education_program && edu.education_program.name} label="Образовательная программа" />
          </Col>
          <Col xs={4}>
            <DisplayData data={edu.course && edu.course.name} label="Курс" />
          </Col>
        </Row>
      </Clay>
    );
  };

  renderWorksList = () => {
    const eduList = this.props.eduList;

    if (eduList.length > 0) {
      return eduList.map((edu, index) => {
        return <StaffCollapse key={edu.id}
                              isOpen={index === 0}
                              collapseBody={this.renderCollapseBody(edu)}
                              collapseHeader={this.renderCollapseHeader(edu)} />;
      });
    }
    else {
      return (
        <StaffDisplayCard>
          Отсутствует информация об образовании
        </StaffDisplayCard>
      );
    }
  };

  render() {
    const {eduList, isLoading} = this.props;

    return (
      <Clay padding={[6, 0]}>
        {eduList && !isLoading && this.renderWorksList()}
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
      </Clay>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[EDUCATION_LIST_STORE];

  return {
    isLoading: store ? store.storeIsLoading : null,
    dataSource: store ? store.storeDataSource : null,
    eduList: store ? store.storeDisplayData && store.storeDisplayData.educations : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EducationList));