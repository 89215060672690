import React, {Component} from 'react';
import {createStore, applyMiddleware, compose} from 'redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {Clay} from '@n3/kit';

import {RouteWithSubRoutes} from './components/router/RouteWithSubRoutes';
import MainLayout from './components/layouts/MainLayout';
import Header from './components/layouts/Header';
import rootReducer from './store/reducers';
import Auth from './components/users/Auth';
import './assets/style/base.sass';

const enhancer = compose(
  applyMiddleware(thunk),
  window.devToolsExtension ? window.devToolsExtension() : f => f
);

//const store = createStore(rootReducer, applyMiddleware(thunk));
export const store = createStore(rootReducer, enhancer);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Clay bgColor="gray5" className="app-container__column staff-app">
            <Auth />
            <Header />
            <RouteWithSubRoutes exact path={'/'} component={MainLayout} />
          </Clay>
        </Router>
      </Provider>
    );
  }
}

export default App;