import React, {Component} from 'react';
import {Clay, FieldLabel, FieldWrapper, Input} from '@n3/kit';
import PropTypes from 'prop-types';

class StaffTextArea extends Component {
  render() {
    const {input, meta, label, isRequired, autoFocus, autoComplete, isDisabled, customStyle} = this.props;
    const {touched, error, warning} = meta;
    const {name} = input;

    return (
      <Clay>
        <FieldLabel isRequired={isRequired}>
          {label}
        </FieldLabel>

        <Clay padding={[1,0,0,0]} />

        <Input {...input}
               name={name}
               style={customStyle}
               component="textarea"
               disabled={isDisabled}
               autoFocus={autoFocus}
               hasError={touched && !!error}
               autoComplete={autoComplete ? autoComplete : null} />
        {touched &&
        ((error && <FieldWrapper errors={[error]} />) ||
          (warning && <span>{warning}</span>))}
      </Clay>
    );
  }
}

StaffTextArea.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  customStyle: PropTypes.object,
  autoComplete: PropTypes.string
};

export default StaffTextArea;