import React from 'react';
import {Clay, log} from '@n3/kit';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import api from '../../../API';
import * as form from './formConstructor';
import PageHeader from '../../layouts/PageHeader';
import * as messages from '../../../constants/messages';
import {mapErrorMessage} from '../../../services/mapper';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import OrganizationCreateFormContainer from './OrganizationCreateFormContainer';
import {ORGANIZATION_CREATE, ORGANISATIONS_LIST} from '../../../constants/pagesName';
import {appFormsCreateStore, appFormsDeleteStore} from '../../../store/appForms/actions';

class OrganizationCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breadCrumbs: [
        {
          url: `/organizations`,
          title: ORGANISATIONS_LIST
        },
        {
          url: null,
          title: ORGANIZATION_CREATE
        }
      ]
    };
  }

  componentDidMount() {
    this.props.appFormsCreateStore(form.FORM_NAME, form.ORGANIZATION_CREATE_FORM);
  }

  componentWillUnmount() {
    this.props.appFormsDeleteStore(form.FORM_NAME);
  }

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = (values) => {
    const request = '/organizations/';
    const data = mapValuesToSend(values);

    return api.request(request, {method: 'post', data})
      .then((resp) => {
        const id = resp.id;
        this.renderSuccessLog();
        this.props.history.push(`/organizations/update/${id}/1`);
      })
      .catch((error) => {
        const errorMessage = error.response.status === 400
          ? mapErrorMessage(error)
          : messages.SAVE_ERROR;
        this.renderErrorLog(errorMessage);
      });
  };

  handleCancelClick = (event) => {
    event.preventDefault();
    this.props.history.push('/organizations');
  };

  render() {
    const {breadCrumbs} = this.state;

    return (
      <AccessControl permission={permission.ORGANIZATION_CREATE} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader breadCrumbs={breadCrumbs} title={ORGANIZATION_CREATE} />

          <Clay bgColor="bg" padding={[3, 5]}>
            <OrganizationCreateFormContainer onSubmit={this.submit}
                                             handleCancelClick={this.handleCancelClick} />
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

const mapDispatchToProps = {
  appFormsCreateStore,
  appFormsDeleteStore
};

export default connect(null, mapDispatchToProps)(withRouter(OrganizationCreate));
