import React from 'react';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import {Clay, Button, Modal, log} from '@n3/kit';

import api from '../../../API';
import * as form from './formConstructor';
import * as messages from '../../../constants/messages';
import {mapErrorMessage} from '../../../services/mapper';
import {DOCUMENTS_LIST_DATA} from '../list/DocumentsList';
import {appStoreUpdateListData} from '../../../store/appStore/actions';
import DocumentCreateFormContainer from './DocumentCreateFormContainer';
import {mapToFormData, mapValuesToSend} from '../../../services/mapValuesToSend';

class DocumentCreate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpened: false
    };
  }

  toggleOpened = (event) => {
    event && event.preventDefault();
    this.setState(state => ({isOpened: !state.isOpened}));
  };

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: 'Документ был добавлен',
      color: 'success'
    });
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = (values) => {
    if (!(form.ATTACHMENTS in values)) {
      throw new SubmissionError({
        _error: messages.NO_ATTACHMENTS
      });
    }
    const request = '/documents/';
    const data = this.prepareDataToSend(values);

    return api.request(request, {method: 'post', data})
      .then((resp) => {
        this.toggleOpened();
        this.renderSuccessLog();

        const {docsList} = this.props;
        this.props.appStoreUpdateListData(DOCUMENTS_LIST_DATA, [...docsList, resp]);
      })
      .catch((error) => {
        const errorMessage = error.response.status === 400 ? messages.SAVE_ERROR : mapErrorMessage(error);
        this.renderErrorLog(errorMessage);
      });
  };

  prepareDataToSend = (values) => {
    const data = mapValuesToSend(values);
    for (let elem in data) {
      if (!data[elem]) {
        delete data[elem];
      }
    }
    data[form.ATTACHMENTS] = values[form.ATTACHMENTS];
    return mapToFormData(data);
  };

  render() {
    const {isOpened} = this.state;

    return (
      <Clay>
        <Button color="primary" onClick={(event) => this.toggleOpened(event)}>
          Добавить
        </Button>

        <Modal show={isOpened}
               onHide={this.toggleOpened}>
          <Modal.Body>
            <Clay padding={[3, 0]} font="h1">
              Добавление документа
            </Clay>

            <DocumentCreateFormContainer onSubmit={this.submit} onHide={this.toggleOpened} />
          </Modal.Body>
        </Modal>
      </Clay>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[DOCUMENTS_LIST_DATA];

  return {
    docsList: store ? store.storeListData : null
  };
};

const mapDispatchToProps = {
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCreate);