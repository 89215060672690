import React from 'react';
import {Clay} from '@n3/kit';
import ResetPasswordForm from './ResetPasswordForm';

class ResetPassword extends React.Component {

  render() {
    return (
      <Clay className="app-container__column_auth">
        <Clay className="auth__main-container">
          <Clay className="auth__header">
            Восстановление пароля
          </Clay>
          <ResetPasswordForm />
        </Clay>
      </Clay>
    );
  }
}

export default ResetPassword;