import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Clay, FieldLabel, Input} from '@n3/kit';

class StaffInputNumberFilter extends Component {
  onKeyPressed = (e) => {

    //backspace and arrows
    if (e.keyCode === 8 || e.keyCode === 40 || e.keyCode === 38) {
      return;
    }

    //десятичная точка
    if (!this.props.positiveValuesOnly && e.keyCode === 110) {
      return;
    }

    if (e.keyCode < 96 || e.keyCode > 105) {
      e.preventDefault();
    }
  };

  handleChange = (event) => {
    const name = event.target.name;

    const value = +event.target.value;

    if (this.props.positiveValuesOnly && value < 0) {
      return null;
    }

    if (this.props.integerValuesOnly && !Number.isInteger(value)) {
      return null;
    }

    this.props.handleChange(name, value);
  };

  render() {
    const {name, label, value, min} = this.props;

    return (
      <Clay>
        <FieldLabel>
          {label}
        </FieldLabel>

        <Clay padding={[1,0,0,0]} />

        <Input min={min}
               onKeyDown={this.onKeyPressed}
               name={name}
               type="number"
               value={value ? value : ''}
               onChange={(event) => this.handleChange(event)} />
      </Clay>
    );
  }
}

StaffInputNumberFilter.propTypes = {
  min: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  handleChange: PropTypes.func,
  positiveValuesOnly: PropTypes.bool
};

StaffInputNumberFilter.defaultProps = {
  positiveValuesOnly: false,
  integerValuesOnly: false
}

export default StaffInputNumberFilter;