import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Table, Tbody, Td, Th, Thead, Clay, LoadingArea} from '@n3/kit';
import PropTypes from 'prop-types';
import StaffOrdering from '../../layouts/StaffOrdering';
import * as filter from './filterConstructor';

class DemandsReportTable extends Component {

  renderTableBody = () => {
    const reportsList = this.props.listData;
    return reportsList.map(report => {
      return (
        <tr key={report.id}>
          <Td className='table__column_width--40'>{report.name}</Td>
          <Td>{report.opened_demands_count}</Td>
        </tr>
      );
    });
  };

  render() {
    const {listData, totalValues, storeName, isLoading} = this.props;

    return (
      <Clay>
        <Table className='table'>
          <Thead>
          <tr>
            <Th>
              Профессия
            </Th>
            <Th>
              <StaffOrdering storeName={storeName}
                             filteredParam={'opened_demands_count'}
                             filterName={filter.ORDERING}>
                Количество
              </StaffOrdering>
            </Th>
          </tr>
          </Thead>
          <Thead>
          <tr>
            <Th>Всего</Th>
            <Th>{totalValues && totalValues.opened_demands_count_total}</Th>
          </tr>
          </Thead>

          <Tbody>
          {(listData && !isLoading) && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
      </Clay>
    );
  }
}

DemandsReportTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null,
    totalValues: store ? store.storeTotalValues : null,
    isLoading: store ? store.storeIsLoading : null
  };
};

export default connect(mapStateToProps)(DemandsReportTable);