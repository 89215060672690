import React from 'react';
import {Clay, Button} from '@n3/kit';

import PageHeader from '../../layouts/PageHeader';
import DisplayData from '../../layouts/DisplayData';
import {USER_PROFILE} from '../../../constants/pagesName';

class UserProfileRead extends React.Component {
  render() {
    const {user} = this.props;

    return (
      <Clay className="app-container">
        <PageHeader title={USER_PROFILE} />

        <Clay padding={[3, 5, 3, 5]} className="app-container" bgColor='bg'>
          <DisplayData label="ФИО" data={user && user.full_name} />
          <DisplayData label="E-mail" data={user && user.username} />
          <DisplayData label="Телефон" data={user && user.phone} />
          <DisplayData label="Организация" data={user.organization && user.organization.name} />
        </Clay>
        <Clay padding={[3, 5, 3, 5]}>
          <Button onClick={()=>{this.props.history.push('/update');}}>
            Редактировать
          </Button>
        </Clay>
      </Clay>
    );
  }
}

export default UserProfileRead;