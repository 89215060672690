import axios from 'axios';
import {setUserRole} from '../app/actions';
import {setCookie} from '../../utils/Cookie';

const staffServer = process.env.REACT_APP_STAFF_SERVER;

export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE';

export const userUpdateProfile = (userProfile) => {
  return {
    type: USER_UPDATE_PROFILE,
    payload: userProfile
  };
};

export const getRequestUserProfile = (token, redirect) => {
  console.log('get profile');
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${staffServer}/users/profile/`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.data)
      .then(data => {
        dispatch(userUpdateProfile(data));
        dispatch(setUserRole(data.role));
        setCookie('role', data.role);
      })
      .catch(() => {
          redirect()
      });
  };
};