import React from 'react';
import {Clay} from '@n3/kit';
import {Link} from "react-router-dom";

class ResetPasswordMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null
    };
  }

  render() {
    const email = this.props.location.state.email;

    return (
      <Clay className="app-container__column_auth">
        <Clay className="auth__main-container">

          <Clay className="auth__header" padding={[0, 0, 4]}>
            Восстановление пароля
          </Clay>

          <Clay className="auth__password-reset-msg">
            <Clay>
              На адрес электронной почты
            </Clay>

            <Clay className="auth__password-reset-msg_highlighted">
              {email}
            </Clay>

            <Clay margin={[0, 0, 3]}>
              отправлено письмо с инструкцией по восстановлению пароля
            </Clay>

            <Link to="/login" >
              Перейти на форму Авторизации
            </Link>
          </Clay>
        </Clay>
      </Clay>
    );
  }
}

export default ResetPasswordMessage;