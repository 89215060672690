import React, {Component} from 'react';
import {Clay} from '@n3/kit';

class TitleWrap extends Component {
  render() {
    return (
      <Clay font='h5' padding={[5, 0]}
            className="app-container__font-size_13 app-container__color_gray-4 app-container__row_flex-start">
        <Clay padding={[3, 1, 0, 0]} margin={[0, 1, 0, 0]} bgColor="gray2" />
        {this.props.children}
      </Clay>
    );
  }
}

export default TitleWrap;