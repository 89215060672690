import * as types from './actions';
import {APP_UPDATE_SIDEBAR} from './actions';

const initialState = {
  appFilters: [],

  userRole: null,
  userToken: null,
  userAuthorised: false,
  appSidebar: '/'
};

export const appReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.APP_FILTER_SAVE:
      return {
        ...state,
        appFilters: state.appFilters
          .filter(filter => filter.name !== action.payload.name)
          .concat(action.payload)
      };
    case types.APP_FILTER_DELETE:
      return {
        ...state,
        appFilters: state.appFilters.filter(filter => filter.name !== action.payload.name)
      };

    case types.APP_SET_USER_ROLE:
      return {...state, userRole: action.payload};
    case types.APP_SET_USER_TOKEN:
      return {...state, userToken: action.payload};
    case types.APP_USER_LOGIN:
      return {...state, userAuthorised: true, userToken: action.payload};
    case types.APP_USER_LOGOUT:
      return {
        ...state,
        userRole: null,
        userToken: null,
        userAuthorised: action.payload
      };
    case APP_UPDATE_SIDEBAR:
      return {...state, appSidebar: action.payload};
    default:
      return state;
  }
};