import React from 'react';
import {connect} from 'react-redux';
import {Clay, Button} from '@n3/kit';
import {withRouter} from 'react-router-dom';

import PageHeader from '../../layouts/PageHeader';
import StaffSearch from '../../layouts/StaffSearch';
import StaffPaginator from '../../layouts/StaffPaginator';
import SpecialistsTableModal from './SpecialistsTableModal';
import {SPECIALIST_MODAL_CREATE} from '../../../constants/pagesName';
import {appStoreCreate, appStoreDelete, appStoreGetListData} from '../../../store/appStore/actions';

const STAFFING_AGENCY_STORE = 'staffingAgencyStore';

class SpecialistListForCrate extends React.Component {

  componentDidMount() {
    const dataSource = '/specialists/staffing_agency/';
    this.props.appStoreCreate(STAFFING_AGENCY_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(STAFFING_AGENCY_STORE);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {dataSource} = this.props;

    if (dataSource) {
      this.props.appStoreGetListData(STAFFING_AGENCY_STORE, dataSource);
    }
  }

  redirectToCreate = () => {
    this.props.history.push('/specialists/create/');
  };

  renderAddButton = () => {
    return (
      <Button color="primary"
              onClick={this.redirectToCreate}>
        Добавить специалиста
      </Button>
    );
  };

  render() {
    return (
      <Clay className="app-container">
        <PageHeader title={SPECIALIST_MODAL_CREATE}
                    bgColor="gray5"
                    controlButton={this.renderAddButton()} />

        <Clay padding={[3, 5]}>
          <Clay padding={[0, 0, 4, 0]}>
            <StaffSearch storeName={STAFFING_AGENCY_STORE} />
          </Clay>

          <SpecialistsTableModal storeName={STAFFING_AGENCY_STORE} />

          <Clay padding={[4, 0, 0]}>
            <StaffPaginator storeName={STAFFING_AGENCY_STORE} />
          </Clay>
        </Clay>
      </Clay>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[STAFFING_AGENCY_STORE];

  return {
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetListData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpecialistListForCrate));