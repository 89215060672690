import {connect} from 'react-redux';

import {appStoreCreate, appStoreDelete, appStoreGetListData} from '../../../store/appStore/actions';
import {appFilterDelete} from '../../../store/app/actions';
import SpecialistsList, {SPECIALIST_STORE} from './SpecialistsList';

const mapStateToProps = (state) => {
  const store = state.appStore[SPECIALIST_STORE];

  return {
    filters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appFilterDelete,
  appStoreGetListData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistsList);