import * as messages from '../../../constants/messages';
import * as form from './formConstructor';

export default (values) => {
  const errors = {};

  if (!values[form.DATE_START_WORK]) {
    errors[form.DATE_START_WORK] = messages.NOT_EMPTY;
  }
  if (!values[form.RANK] || !values[form.RANK].value) {
    errors[form.RANK] = messages.NOT_EMPTY;
  }

  return errors;
}