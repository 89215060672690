import React from 'react';
import {Clay, log} from '@n3/kit';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import api from '../../../API';
import * as form from './formConstructor';
import PageHeader from '../../layouts/PageHeader';
import * as messages from '../../../constants/messages';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import DemandCreateFormContainer from './DemandCreateFormContainer';
import {DEMAND_CREATE, DEMANDS_LIST} from '../../../constants/pagesName';
import {appFormsCreateStore, appFormsDeleteStore} from '../../../store/appForms/actions';

class DemandCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breadCrumbs: [
        {
          url: `/demands`,
          title: DEMANDS_LIST
        },
        {
          url: null,
          title: DEMAND_CREATE
        }
      ]
    };
  }

  componentWillMount() {
    this.props.appFormsCreateStore(form.FORM_NAME, form.DEMAND_CREATE_FORM);
  }

  componentWillUnmount() {
    this.props.appFormsDeleteStore(form.FORM_NAME);
  }

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = () => {
    log({
      title: 'Ошибка',
      message: messages.SAVE_ERROR,
      color: 'danger'
    });
  };

  submit = (values) => {
    const data = mapValuesToSend(values);

    return api.request(form.API, {method: 'post', data})
      .then((resp) => {
        const path = `/demands/read/${resp.id}/1`;
        this.props.history.push(path);
        this.renderSuccessLog();
      })
      .catch(() => {
        this.renderErrorLog();
      });
  };

  submitAndContinue = (values) => {
    const data = mapValuesToSend(values);

    return api.request(form.API, {method: 'post', data})
      .then((resp) => {
        this.renderSuccessLog();
        this.props.history.push(`/demands/update/${resp.id}/1`);
      })
      .catch(() => {
        this.renderErrorLog();
      });
  };

  handelCancelClick = (event) => {
    event.preventDefault();
    const path = `/demands`;

    this.props.history.push(path);
  };

  render() {
    const {breadCrumbs} = this.state;

    return (
      <AccessControl permission={permission.DEMAND_CREATE} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader breadCrumbs={breadCrumbs} title={DEMAND_CREATE} />

          <DemandCreateFormContainer onSubmit={this.submit}
                                     submitAndContinue={this.submitAndContinue}
                                     handelCancelClick={this.handelCancelClick} />
        </Clay>
      </AccessControl>
    );
  }
}

const mapDispatchToProps = {
  appFormsCreateStore,
  appFormsDeleteStore
};

export default connect(null, mapDispatchToProps)(withRouter(DemandCreate));