import React, {Component} from 'react';
import {Clay, Alert, Button, Tabs, ButtonsGroup} from '@n3/kit';
import StaffTab from '../../layouts/StaffTab';
import {Field} from 'redux-form';

import FormBody from './FormBody';
import * as form from './formConstructor';
import InputWrap from '../../form/InputWrap';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as dictionaries from '../../../constants/dictionariesSource';
import DisplayData from '../../layouts/DisplayData';

const PAGE_TABS = [
  {id: 1, title: 'Информация о потребности'},
  {id: 2, title: 'Вакансии'}
];

class DemandCreateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1
    };
  }

  setCurrentTab = (tabId) => {
    this.setState({
      currentTab: tabId
    });
  };

  handleChange = (name, value) => {
    this.props.change(name, value);
  };

  render() {
    const {currentTab} = this.state;
    const {error, handleSubmit, submitAndContinue, handelCancelClick, rank, formBody, user} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {error && <Alert color="danger" title="Ошибка:"> {error} </Alert>}

        <Clay bgColor="bg" padding={[0]}>
          <Clay className="app-container__row">
            <Clay padding={[3, 0, 3, 5]} className="app-container app-container__border_right">
              <Clay padding={[2, 0, 2, 0]}>
                <InputWrap size={8}>
                  <Field  label="Должность"
                          name={form.RANK}
                          isRequired={true}
                          selectorValue={rank}
                          component={StaffSelectorAsync}
                          dataSource={dictionaries.RANKS}
                          handleChange={this.handleChange}
                          defaultValue={form.SELECTOR_DEFAULT_VALUE} />
                </InputWrap>
              </Clay>
            </Clay>
            <Clay padding={[3, 0, 3, 5]} className="app-container">
              <DisplayData data={user.full_name} label='Автор' />
            </Clay>
          </Clay>
        </Clay>

        <Tabs
          tabs={PAGE_TABS}
          current={currentTab}
          setCurrentTab={this.setCurrentTab} />

        <Clay padding={[6, 5]}>
          <StaffTab tabId={1} currentTabId={currentTab}>
            <FormBody fields={formBody} handleChange={this.handleChange} />
          </StaffTab>

          <Clay margin={[4, 0]} className="app-container__row">
            <Button onClick={handelCancelClick}>
              Отмена
            </Button>
            <ButtonsGroup>
              <Button  color="primary" onClick={handleSubmit(submitAndContinue)}>
                Сохранить и продолжить
              </Button>
              <Button type="submit" color="primary">
                Сохранить
              </Button>
            </ButtonsGroup>
          </Clay>
        </Clay>
      </form>
    );
  }
}

export default DemandCreateForm;