import React, {Component} from 'react';
import {Clay} from '@n3/kit';
import UserProfileUpdateMapData from './UserProfileUpdateMapData';
import UserProfileUpdateForm from './UserProfileUpdateForm';
import PageHeader from '../../layouts/PageHeader';
import {USER_PROFILE_EDIT} from '../../../constants/pagesName';

export default class UserProfileUpdate extends Component {
  render() {
    return (
      <Clay className="app-container">
        <PageHeader title={USER_PROFILE_EDIT} />
        <UserProfileUpdateMapData>
          <UserProfileUpdateForm />
        </UserProfileUpdateMapData>
      </Clay>
    );
  }
}