import {connect} from 'react-redux';

import DocumentsList, {DOCUMENTS_LIST_DATA} from './DocumentsList';
import {appStoreCreate, appStoreDelete, appStoreGetListData} from '../../../store/appStore/actions';

const mapStateToProps = (state) => {
  const store = state.appStore[DOCUMENTS_LIST_DATA];

  return {
    listData: store ? store.storeListData : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetListData
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList);