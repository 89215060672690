import React from 'react';
import {connect} from 'react-redux';

import {Clay} from '@n3/kit';
import SidebarButton from './SidebarButton';
import * as pagesName from '../../constants/pagesName';
import * as permission from '../../constants/permissionsList';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSidebarOpen: true
    };
  }

  handleSidebarToggle = () => {
    this.setState(state => ({...state, isSidebarOpen: !state.isSidebarOpen}));
  };

  getSidebarWidth = () => {
    const {isSidebarOpen} = this.state;
    return isSidebarOpen ? 'sidebar sidebar_opened' : 'sidebar sidebar_closed';
  };

  renderSidebarToggle = () => {
    const {isSidebarOpen} = this.state;

    if (isSidebarOpen) {
      return (
        <Clay className="app-container__row_flex-end sidebar__button_toggle">
          <Clay padding={[0, 1]} className="sidebar__hide-container">
            Свернуть
          </Clay>
          <i className="fas fa-angle-double-left sidebar__text-container" />
        </Clay>
      );
    }
    else {
      return (
        <Clay className="app-container__row_center">
          <Clay>
            <i className="fas fa-angle-double-right " />
          </Clay>
        </Clay>
      );
    }
  };

  getPathToOrgInfo = () => {
    const {user} = this.props;

    if (user && user.organization) {
      return `/organizations/read/${user.organization.id}/1`;
    }
  };

  render() {
    const {isSidebarOpen} = this.state;
    const {userRole, userToken, appSidebar} = this.props;

    if (!userRole && !userToken) {
      return null;
    }

    return (
      <div className={this.getSidebarWidth()}>
        <Clay>
          <SidebarButton path="/organizations"
                         currentPage={appSidebar}
                         isSidebarOpen={isSidebarOpen}
                         title={pagesName.ORGANISATIONS_LIST}
                         icon='fas fa-briefcase'
                         permission={permission.ORGANIZATION_READ_LIST} />

          <SidebarButton currentPage={appSidebar}
                         isSidebarOpen={isSidebarOpen}
                         path={this.getPathToOrgInfo()}
                         title={pagesName.ORGANIZATION_READ_INFO}
                         icon='fas fa-briefcase'
                         permission={permission.ORGANIZATION_READ_INFO} />

          <SidebarButton path="/specialists"
                         currentPage={appSidebar}
                         isSidebarOpen={isSidebarOpen}
                         title={pagesName.SPECIALISTS_LIST[userRole]}
                         icon='fas fa-users'
                         permission={permission.SPECIALIST_READ_LIST} />

          <SidebarButton path="/demands"
                         currentPage={appSidebar}
                         isSidebarOpen={isSidebarOpen}
                         title={pagesName.DEMANDS_LIST}
                         icon='fas fa-door-open'
                         permission={permission.DEMAND_READ_LIST} />

          <SidebarButton path="/vacancy"
                         currentPage={appSidebar}
                         isSidebarOpen={isSidebarOpen}
                         title={pagesName.VACANCIES_LIST}
                         icon='fas fa-credit-card'
                         permission={permission.VACANCY_READ_LIST} />

          <SidebarButton path="/reports/1"
                         currentPage={appSidebar}
                         title={pagesName.REPORTING}
                         icon='fas fa-chart-line'
                         isSidebarOpen={isSidebarOpen}
                         permission={permission.REPORT_READ} />
        </Clay>

        <Clay onClick={() => this.handleSidebarToggle()}
              className='app-container__border_top sidebar__button'>
          {this.renderSidebarToggle()}
        </Clay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userRole: state.app.userRole,
    userToken: state.app.userToken,
    appSidebar: state.app.appSidebar
  };
};

export default connect(mapStateToProps)(Sidebar);