import React from 'react';
import {withRouter} from 'react-router-dom';
import {Clay, createDropdownMenu, EllipsisDropdown, Tabs, log} from '@n3/kit';

import API from '../../../API';
import VacancyHeader from './VacancyHeader';
import StaffTab from '../../layouts/StaffTab';
import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import VacancyDisplayData from './VacancyDisplayData';
import * as messages from '../../../constants/messages';
import {mapErrorMessage} from '../../../services/mapper';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';
import {VACANCY_READ, VACANCIES_LIST} from '../../../constants/pagesName';
import {VACANCY_UPDATE, VACANCY_DELETE} from '../../../constants/permissionsList';

export const VACANCY_READ_STORE = 'vacancyReadStore';

const PAGE_TABS = [
  {id: 1, title: 'Информация о вакансии'}
];

class VacancyRead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1,
      breadCrumbs: [
        {
          url: `/vacancy`,
          title: VACANCIES_LIST
        },
        {
          url: null,
          title: VACANCY_READ
        }
      ]
    };
  }

  componentDidMount() {
    this.vacancyId = this.props.match.params.id;
    this.createStore();
  }

  componentWillUnmount() {
    this.props.appStoreDelete(VACANCY_READ_STORE);
  }

  createStore = () => {
    const dataSource = `/vacancy/${this.vacancyId}`;
    this.props.appStoreCreate(VACANCY_READ_STORE, dataSource);
  };

  componentDidUpdate() {
    const {dataSource, displayData} = this.props;

    if (dataSource && !displayData) {
      this.props.appStoreGetDisplayData(VACANCY_READ_STORE, dataSource);
    }
  }

  redirectToUpdate = () => {
    const path = `/vacancy/update/${this.vacancyId}`;
    this.props.history.push(path);
  };

  redirectToRecruitment = () => {
    const path = `/vacancy/recruitment/${this.vacancyId}`;
    this.props.history.push(path);
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  onDelete = () => {
    const id = this.props.match.params.id;
    const request = `/vacancy/${id}/`;
    return API.request(request, {method: 'delete'})
      .then(() => {
        this.props.history.push('/vacancy');
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = error.response.status === 400
          ? mapErrorMessage(error)
          : messages.DELETE_ERROR;
        this.renderErrorLog(errorMessage);
      });
  };

  onCloseVacancy = (id) => {
    const request = `/vacancy/${id}/close/`;
    API.request(request, {method: 'patch'})
      .then(resp => {
        this.props.appStoreUpdateDisplayData(VACANCY_READ_STORE, resp);
      });
  };

  setCurrentTab = (tabId) => {
    this.setState({
      currentTab: tabId
    });
  };

  renderDropdown = (vacancy) => {
    if (vacancy.status !== 'opened') {
      return null;
    }

    const controlArr = [];

    const userRole = getCookie('role');

    if (checkPermission(userRole, VACANCY_UPDATE)) {
      controlArr.push({
        type: 'ellipsis',
        onClick: this.redirectToUpdate,
        label: 'Редактировать'
      });
    }

    if (checkPermission(userRole, VACANCY_DELETE)) {
      controlArr.push({
        type: 'ellipsis',
        onClick: this.onDelete,
        label: 'Удалить'
      });
    }

    if (controlArr.length === 0) {
      return null;
    }

    return (
      <EllipsisDropdown
        {...createDropdownMenu(
          controlArr
        )
        }
      />
    );
  };

  render() {
    const {currentTab, breadCrumbs} = this.state;
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    return (
      <AccessControl permission={permission.VACANCY_READ} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader title={VACANCY_READ}
                      breadCrumbs={breadCrumbs}
                      controlButton={this.renderDropdown(displayData)} />


          <VacancyHeader storeName={VACANCY_READ_STORE} />

          <Tabs
            tabs={PAGE_TABS}
            current={currentTab}
            setCurrentTab={this.setCurrentTab} />

          <StaffTab tabId={1} currentTabId={currentTab}>
            <VacancyDisplayData storeName={VACANCY_READ_STORE}
                                onCloseVacancy={this.onCloseVacancy}
                                onSearchSpecialists={this.redirectToRecruitment} />
          </StaffTab>
        </Clay>
      </AccessControl>
    );
  }
}

export default withRouter(VacancyRead);