import React from 'react';
import {Clay, Button} from '@n3/kit';

import PageHeader from '../../layouts/PageHeader';
import StaffSearch from '../../layouts/StaffSearch';
import OrganizationsTable from './OrganizationsTable';
import StaffPaginator from '../../layouts/StaffPaginator';
import AccessControl from '../../accessControl/AccessControl';
import OrganizationsListFilter from './OrganizationsListFilter';
import {ORGANISATIONS_LIST} from '../../../constants/pagesName';
import * as permission from '../../../constants/permissionsList';

export const ORGANISATION_STORE = 'organisationStore';

class OrganizationsList extends React.Component {

  componentWillMount() {
    const dataSource = '/organizations/';
    this.props.appStoreCreate(ORGANISATION_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(ORGANISATION_STORE);
  }

  componentDidUpdate(prevProps) {
    const dataSource = this.props.dataSource;
    const storeFilters = this.props.storeFilters;

    if (storeFilters.length === 0) {
      this.props.appStoreGetListData(ORGANISATION_STORE, dataSource);
    }
  }

  handleRedirect = () => {
    const path = 'organizations/create';
    this.props.history.push(path);
  };

  renderAddButton = () => {
    return (
      <AccessControl permission={permission.ORGANIZATION_CREATE}>
        <Clay className="app-container__row_flex-end">
          <Button color="primary" onClick={this.handleRedirect}>
            Добавить
          </Button>
        </Clay>
      </AccessControl>
    );
  };

  render() {
    return (
      <AccessControl permission={permission.ORGANIZATION_READ_LIST} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader title={ORGANISATIONS_LIST}
                      bgColor="gray5"
                      controlButton={this.renderAddButton()} />

          <Clay padding={[3, 5]}>
            <Clay padding={[0, 0, 2, 0]}>
              <OrganizationsListFilter storeName={ORGANISATION_STORE} />
            </Clay>

            <Clay padding={[0, 0, 6, 0]}>
              <StaffSearch storeName={ORGANISATION_STORE} />
            </Clay>

            <OrganizationsTable storeName={ORGANISATION_STORE} />

            <Clay padding={[0, 0, 2]} />

            <Clay padding={[2, 0, 0, 0]}>
              <StaffPaginator storeName={ORGANISATION_STORE} />
            </Clay>
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

export default OrganizationsList;