import React from 'react';
import {Field} from 'redux-form';
import {Button, Clay, Col, Row, log} from '@n3/kit';

import * as form from './formConstructor';
import StaffInput from '../../form/StaffInput';
import StaffSelector from '../../form/StaffSelector';
import StaffInputMask from '../../form/StaffInputMask';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as dictionaries from '../../../constants/dictionariesSource';
import {CATEGORY_VALUES_WITH_UNDEFINED} from '../../../constants/selectorsValues';

class OrganizationCreateForm extends React.Component {

  handleChange = (name, value) => {
    this.props.change(name, value);
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '2000'
    });
  };

  render() {
    const {handleSubmit, ownership_type, category, handleCancelClick} = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Clay padding={[2, 0, 2, 0]}>
          <Row>
            <Col xs={4}>
              <Field label="Сокращенное наименование"
                    name={form.NAME}
                    isRequired={true}
                    component={StaffInput} />
            </Col>

            <Col xs={4}>
              <Field label="Полное наименование"
                    name={form.FULL_NAME}
                    isRequired={true}
                    component={StaffInput} />
            </Col>

            <Col xs={4}>
              <Field label="ИНН"
                    name={form.INN}
                    isRequired={true}
                    inputMask={form.INN_MASK}
                    component={StaffInputMask} />
            </Col>
          </Row>
        </Clay>

        <Clay padding={[2, 0, 2, 0]}>
          <Row>
            <Col xs={4}>
              <Field label="Форма собственности"
                    name={form.OWNERSHIP_TYPE}
                    isRequired={true}
                    selectorValue={ownership_type}
                    component={StaffSelectorAsync}
                    handleChange={this.handleChange}
                    dataSource={dictionaries.OWNERSHIP_TYPES} />
            </Col>

            <Col xs={4}>
              <Field label="Категория организации"
                    name={form.CATEGORY}
                    isRequired={true}
                    selectorValue={category}
                    component={StaffSelector}
                    handleChange={this.handleChange}
                    options={CATEGORY_VALUES_WITH_UNDEFINED} />
            </Col>
          </Row>
        </Clay>

        <Clay margin={[3, 0, 0]} className="app-container__row">
          <Button type="button" onClick={handleCancelClick}>
            Отмена
          </Button>

          <Button type="submit" color="primary">
            Сохранить
          </Button>
        </Clay>
      </form>
    );
  }
}

export default OrganizationCreateForm;