import React, {Component} from 'react';
import {Clay} from '@n3/kit';

class StaffDisplayCard extends Component {
  render() {
    return (
      <Clay className="card card__container">
        {this.props.children}
      </Clay>
    );
  }
}

export default StaffDisplayCard;