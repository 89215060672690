import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';

import VacancyCreate from './create/VacancyCreate';
import VacancyUpdate from './update/VacancyUpdate';
import {appUpdateSidebar} from '../../store/app/actions';
import RecruitmentList from './recruitment/RecruitmentList';
import VacancyReadContainer from './read/VacancyReadContainer';
import VacanciesListContainer from './list/VacanciesListContainer';

class VacanciesRouter extends React.Component {

  componentDidMount() {
    const page = window.location.pathname;
    this.props.appUpdateSidebar(page);
  }

  render() {
    const path = this.props.match.url;

    return (
      <Switch>
        <Route path={path} exact component={VacanciesListContainer} />
        <Route path={`${path}/create/:id`} component={VacancyCreate} />
        <Route path={`${path}/update/:id`} component={VacancyUpdate} />
        <Route path={`${path}/read/:id`} component={VacancyReadContainer} />
        <Route path={`${path}/recruitment/:id`} component={RecruitmentList} />
      </Switch>
    );
  }
}

const mapDispatchToProps = {
  appUpdateSidebar
};

export default connect(null, mapDispatchToProps)(withRouter(VacanciesRouter));
