import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getCookie} from '../../../utils/Cookie';

import EducationFormContainer from './EducationFormContainer';
import * as permission from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';

class EducationUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formName: null
    };
  }

  componentDidMount() {
    const {edu} = this.props;
    const formName = `eduUpdateForm--${edu.id}`;

    this.setState(state => ({...state, formName}));
  }

  saveForm = (values) => {
    const {storeName} = this.props;
    this.props.saveForm(storeName, values);
  };

  updateForm = () => {
    const userRole = getCookie('role');

    if (checkPermission(userRole, permission.SPECIALIST_UPDATE_EDUCATION)) {
      return true;
    }
    else {
      const workOrgId = this.props.edu.organization.value;
      const userOrgId = this.props.user.organization && this.props.user.organization.id;

      return userOrgId && userOrgId === workOrgId;
    }
  };

  render() {
    const {edu} = this.props;
    const {formName} = this.state;

    if (!formName) {
      return null;
    }

    return (
      <EducationFormContainer form={formName}
                              initialValues={edu}
                              saveForm={this.saveForm}
                              updateForm={this.updateForm()} />
    );
  }
}

EducationUpdate.propTypes = {
  saveForm: PropTypes.func,
  storeName: PropTypes.string,
  edu: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(EducationUpdate));