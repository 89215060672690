import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Clay, FieldLabel, FieldWrapper, Input} from '@n3/kit';

class StaffInput extends Component {
  render() {
    const {input, meta, label, isRequired, autoFocus, type, autoComplete, isDisabled} = this.props;
    const {touched, error, warning} = meta;
    const {name} = input;

    return (
      <Clay>
        <FieldLabel isRequired={isRequired}>
          {label}
        </FieldLabel>

        <Clay padding={[1,0,0,0]} />

        <Input {...input}
               type={type}
               name={name}
               autoFocus={autoFocus}
               disabled={isDisabled}
               hasError={touched && !!error}
               autoComplete={autoComplete ? autoComplete : null} />
        {touched &&
        ((error && <FieldWrapper errors={[error]} />) ||
          (warning && <span>{warning}</span>))}
      </Clay>
    );
  }
}

StaffInput.propTypes = {
  type: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoComplete: PropTypes.string
};

export default StaffInput;