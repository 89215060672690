import React from 'react';
import PropTypes from 'prop-types';
import {appFormsCreateStore, appFormsDeleteStore} from '../../../store/appForms/actions';
import {connect} from 'react-redux';
import * as form from './formConstructor';
import {mapServerArray, mapServerObject} from '../../../services/mapper';
import {DISABILITY_VALUES, EDUCATION_TYPE_VALUES, STUDY_MODES_VALUES} from '../../../constants/selectorsValues';
import {appStoreCreate, appStoreDelete, appStoreUpdateListData} from '../../../store/appStore/actions';
import {
  WORKS_CREATE_LIST_STORE,
  WORKS_DELETE_LIST_STORE,
  WORKS_UPDATE_LIST_STORE
} from '../../works/update/WorksListForUpdate';
import {
  EDUCATIONS_CREATE_LIST_STORE,
  EDUCATIONS_DELETE_LIST_STORE,
  EDUCATIONS_UPDATE_LIST_STORE
} from '../../education/update/EducationsListForUpdate';
import {DOCUMENTS_LIST_DELETE} from '../../documents/list/DocumentsList';

class SpecialistMapData extends React.Component {

  componentWillUnmount() {
    this.props.appStoreDelete(DOCUMENTS_LIST_DELETE);
    this.props.appStoreDelete(WORKS_UPDATE_LIST_STORE);
    this.props.appStoreDelete(WORKS_DELETE_LIST_STORE);
    this.props.appStoreDelete(EDUCATIONS_UPDATE_LIST_STORE);
    this.props.appStoreDelete(EDUCATIONS_DELETE_LIST_STORE);

    this.props.appFormsDeleteStore(form.FORM_NAME);
  }

  componentDidUpdate() {
    const {displayData} = this.props;

    displayData && this.mapData(displayData);
  }

  mapData = (displayData) => {
    const formFields = {...form.SPECIALIST_UPDATE_FORM};

    for (let elem in displayData) {
      const value = displayData[elem];

      if (elem in form.SPECIALIST_UPDATE_FORM) {
        if (elem === form.WORKS) {
          this.mapWorksData(value);
        }
        else if (elem === form.EDUCATIONS) {
          this.mapEducationsData(value);
        }
        else if (Array.isArray(value)) {
          formFields[elem] = mapServerArray(value);
        }
        else if (value && typeof value === 'object') {
          formFields[elem] = mapServerObject(value);
        }
        else if (elem === form.GENDER) {
          formFields[elem] = {value, label: displayData.gender_display};
        }
        else if (elem === form.NATIONALITY) {
          formFields[elem] = {value, label: displayData.nationality_name};
        }
        else if (elem === form.EMPLOYMENT_STATUS) {
          formFields[elem] = {value, label: displayData.employment_status_display};
        }
        else if (elem === form.REGION) {
          formFields[elem] = value ? {value, label: displayData.region_name} : form.SPECIALIST_UPDATE_FORM[elem];
        }
        else if (elem === form.ADDRESS_UAS) {
          formFields[elem] = value ? {value, label: displayData.address_uas_name} : form.SPECIALIST_UPDATE_FORM[elem];
        }
        else if (elem === form.REGISTRATION_ADDRESS) {
          formFields[form.REGISTRATION_SPB] = !value.length;
          formFields[elem] = value;
        }
        else if (elem === form.RESIDENCE_ADDRESS) {
          formFields[form.REG_RES_ADDRESS_MATCH] = !value.length;
          formFields[elem] = value;
        }
        else if (elem === form.GRADE) {
          formFields[elem] = value ? {value, label: displayData.grade_name} : form.SPECIALIST_UPDATE_FORM[elem];
        }
        else if (elem === form.DISABILITY) {
          formFields[elem] = DISABILITY_VALUES.find(choice => choice.value === value);
        }
        else {
          formFields[elem] = value;
        }
      }
    }

    this.props.appStoreCreate(DOCUMENTS_LIST_DELETE);
    this.props.appStoreUpdateListData(DOCUMENTS_LIST_DELETE, []);

    this.props.appFormsCreateStore(form.FORM_NAME, formFields);
  };

  mapWorksData = (works) => {
    const worksList = works.map(work => {
      const formFields = {...form.SPECIALIST_WORK_FORM};

      for (let elem in work) {
        const value = work[elem];

        if (elem in form.SPECIALIST_WORK_FORM) {
          if (Array.isArray(value)) {
            formFields[elem] = mapServerArray(value);
          }
          else if (value && typeof value === 'object') {
            formFields[elem] = mapServerObject(value);
          }
          else if (elem === form.RANK) {
            formFields[elem] = value ? {value, label: work.rank_name} : form.SELECTOR_DEFAULT_VALUE;
          }
          else {
            formFields[elem] = value;
          }
        }
      }

      return formFields;
    });

    this.props.appStoreCreate(WORKS_CREATE_LIST_STORE);
    this.props.appStoreCreate(WORKS_UPDATE_LIST_STORE);
    this.props.appStoreCreate(WORKS_DELETE_LIST_STORE);

    this.props.appStoreUpdateListData(WORKS_CREATE_LIST_STORE, []);
    this.props.appStoreUpdateListData(WORKS_DELETE_LIST_STORE, []);
    this.props.appStoreUpdateListData(WORKS_UPDATE_LIST_STORE, worksList);
  };

  mapEducationsData = (educations) => {
    const educationsList = educations.map(edu => {
      const formFields = {...form.SPECIALIST_EDUCATION_FORM};

      for (let elem in edu) {
        const value = edu[elem];

        if (elem in form.SPECIALIST_EDUCATION_FORM) {
          if (Array.isArray(value)) {
            formFields[elem] = mapServerArray(value);
          }
          else if (value && typeof value === 'object') {
            formFields[elem] = mapServerObject(value);
          }
          else if (elem === form.EDUCATION_TYPE) {
            formFields[elem] = {...EDUCATION_TYPE_VALUES.find(selectorValue => selectorValue.value === value)};
          }
          else if (elem === form.STUDY_MODE) {
            formFields[elem] = {...STUDY_MODES_VALUES.find(selectorValue => selectorValue.value === value)};
          }
          else {
            formFields[elem] = value;
          }
        }
      }

      return formFields;
    });

    this.props.appStoreCreate(EDUCATIONS_CREATE_LIST_STORE);
    this.props.appStoreCreate(EDUCATIONS_UPDATE_LIST_STORE);
    this.props.appStoreCreate(EDUCATIONS_DELETE_LIST_STORE);

    this.props.appStoreUpdateListData(EDUCATIONS_CREATE_LIST_STORE, []);
    this.props.appStoreUpdateListData(EDUCATIONS_DELETE_LIST_STORE, []);
    this.props.appStoreUpdateListData(EDUCATIONS_UPDATE_LIST_STORE, educationsList);
  };

  render() {
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    return this.props.children;
  }
}

SpecialistMapData.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appFormsCreateStore,
  appFormsDeleteStore,
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistMapData);