import React from 'react';
import {Clay} from '@n3/kit';
import {connect} from 'react-redux';

import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import SpecialistMapData from './SpecialistMapData';
import SpecialistUpdateForm from './SpecialistUpdateForm';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {SPECIALIST_UPDATE, SPECIALISTS_LIST} from '../../../constants/pagesName';
import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';

export const SPECIALIST_UPDATE_STORE = 'specialistUpdateStore';

class SpecialistUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      breadcrumbs: []
    };

    this.userRole = getCookie('role');
  }

  componentDidMount() {
    const specId = this.props.match.params.id;
    const specType = this.props.match.params.type;
    const dataSource = `/specialists/${specType}s/${specId}/`;

    this.getBreadCrumbsList();
    this.props.appStoreCreate(SPECIALIST_UPDATE_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(SPECIALIST_UPDATE_STORE);
  }

  componentDidUpdate() {
    const {dataSource} = this.props;
    dataSource && this.props.appStoreGetDisplayData(SPECIALIST_UPDATE_STORE, dataSource);
  }

  getBreadCrumbsList = () => {

    this.setState(state => ({
      ...state,
      breadcrumbs: [
        {
          url: `/specialists`,
          title: SPECIALISTS_LIST[this.userRole]
        },
        {
          url: null,
          title: SPECIALIST_UPDATE[this.userRole]
        }
      ]
    }));
  };

  render() {
    const {breadcrumbs} = this.state;

    return (
      <AccessControl permission={permission.SPECIALIST_UPDATE} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader breadCrumbs={breadcrumbs}
                      title={SPECIALIST_UPDATE[this.userRole]} />

          <SpecialistMapData storeName={SPECIALIST_UPDATE_STORE}>
            <SpecialistUpdateForm />
          </SpecialistMapData>
        </Clay>
      </AccessControl>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[SPECIALIST_UPDATE_STORE];

  return {
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistUpdate);