import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Clay} from '@n3/kit';
import LoginForm from './LoginForm';

class Login extends Component {
  render() {
    return (
      <Clay className="app-container__column_auth">
        <Clay className="auth__main-container">
          <Clay className="auth__header">
            Авторизация
          </Clay>
          <LoginForm />
        </Clay>
      </Clay>
    );
  }
}

export default withRouter(Login);