import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route, withRouter} from 'react-router-dom';

import {appUpdateSidebar} from '../../store/app/actions';
import SpecialistCreate from './create/SpecialistCreate';
import SpecialistUpdate from './update/SpecialistUpdate';
import SpecialistReadContainer from './read/SpecialistReadContainer';
import SpecialistsListContainer from './list/SpecialistsListContainer';
import SpecialistListForCrate from './list/SpecialistListForCrate';

class SpecialistsRouter extends React.Component {

  componentDidMount() {
    const page = window.location.pathname;
    this.props.appUpdateSidebar(page);
  }

  render() {
    const path = this.props.match.url;

    return (
      <Switch>
        <Route path={path} exact component={SpecialistsListContainer} />
        <Route path={`${path}/create`} component={SpecialistCreate} />
        <Route path={`${path}/listForCrate`} component={SpecialistListForCrate} />
        <Route path={`${path}/update/:type/:id/:tab`} component={SpecialistUpdate} />
        <Route path={`${path}/read/:type/:id/:tab`} component={SpecialistReadContainer} />
      </Switch>
    );
  }
}

const mapDispatchToProps = {
  appUpdateSidebar
};

export default connect(null, mapDispatchToProps)(withRouter(SpecialistsRouter));