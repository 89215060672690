import React, { Component } from 'react';
import { connect } from 'react-redux';
import DoughnutChart from '../charts/doughnutChart/doughnutChart';
import PropTypes from 'prop-types';

class ReportChartData extends Component {
  render() {
    const { displayData } = this.props;

    if (!displayData) {
      return null;
    }

    const { title, value_1, value_2, legend_1, legend_2 } = displayData;
    const data = {
      labels: [
        legend_1,
        legend_2
      ],
      datasets: [{
        data: [value_1 - value_2, value_2],
        backgroundColor: this.props.colors,
        borderWidth: 0
      }]
    };

    return (
      <DoughnutChart
        data={data}
        title={title}
      />
    );
  }
}

ReportChartData.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];
  return {
    displayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(ReportChartData);