import React from 'react';
import {Clay, log} from '@n3/kit';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import API from '../../../API';
import * as form from './formConstructor';
import PageHeader from '../../layouts/PageHeader';
import * as messages from '../../../constants/messages';
import VacancyUpdateMapData from './VacancyUpdateMapData';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import VacancyUpdateFormContainer from './VacancyUpdateFormContainer';
import {VACANCY_UPDATE, VACANCIES_LIST} from '../../../constants/pagesName';
import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';

const VACANCY_UPDATE_STORE = 'vacancyUpdateStore';

class VacancyUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      breadCrumbs: [
        {
          url: `/vacancy`,
          title: VACANCIES_LIST
        },
        {
          url: null,
          title: VACANCY_UPDATE
        }
      ]
    };
  }

  componentDidMount() {
    this.vacancyId = this.props.match.params.id;
    const dataSource = `/vacancy/${this.vacancyId}/`;

    this.props.appStoreCreate(VACANCY_UPDATE_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(VACANCY_UPDATE_STORE);
  }

  componentDidUpdate() {
    const {dataSource} = this.props;
    dataSource && this.props.appStoreGetDisplayData(VACANCY_UPDATE_STORE, dataSource);
  }

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = () => {
    log({
      title: 'Ошибка',
      message: messages.SAVE_ERROR,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = (values) => {
    const data = this.prepareValuesToSend({...values});
    const dataSource = this.props.dataSource;

    return API.request(dataSource, {method: 'put', data})
      .then((resp) => {
        this.renderSuccessLog();
        this.props.history.push(`/vacancy/read/${resp.id}`);
      })
      .catch(() => {
        this.renderErrorLog();
      });
  };

  submitAndContinue = (values) => {
    const data = this.prepareValuesToSend({...values});
    const dataSource = this.props.dataSource;

    return API.request(dataSource, {method: 'put', data})
      .then(() => {
        this.renderSuccessLog();
      })
      .catch(() => {
        this.renderErrorLog();
      });
  };

  prepareValuesToSend = (values) => {
    for (let elem in values) {
      const value = values[elem];
      if ([form.EXPERIENCE_TO, form.SALARY_TO, form.EXPERIENCE_FROM, form.SALARY_FROM].indexOf(elem) > -1 && !value) {
        values[elem] = 0;
      }
    }

    return mapValuesToSend({...values, demand: values.demand.id});
  };

  handleCancelClick = (event) => {
    event.preventDefault();
    const demandId = this.props.match.params.id;
    const path = `/vacancy/read/${demandId}`;
    this.props.history.push(path);
  };

  render() {
    const {breadCrumbs} = this.state;

    return (
      <AccessControl permission={permission.VACANCY_UPDATE} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader title={VACANCY_UPDATE}
                      breadCrumbs={breadCrumbs} />

          <Clay padding={[0]}>
            <VacancyUpdateMapData storeName={VACANCY_UPDATE_STORE}>
              <VacancyUpdateFormContainer onSubmit={this.submit}
                                          submitAndContinue={this.submitAndContinue}
                                          handleCancelClick={this.handleCancelClick} />
            </VacancyUpdateMapData>
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[VACANCY_UPDATE_STORE];

  return {
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VacancyUpdate));