import * as types from './actions';

export const appFormsReducer = (state = {}, action = {}) => {
  const formName = action.payload ? action.payload.formName : null;

  switch (action.type) {
    case types.APP_FORMS_STORE_CREATE:
      return {
        ...state,
        [formName]: action.payload.form
      };
    case types.APP_FORMS_STORE_UPDATE:
      return {
        ...state,
        [formName]: {...state[formName], ...action.payload.form}
      };
    case types.APP_FORMS_STORE_DELETE:
      delete state[formName];
      return state;
    default:
      return state;
  }
};