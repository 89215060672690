import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {Clay, Alert, Button} from '@n3/kit';

import axios from 'axios';
import {setUserLogin} from '../../store/app/actions';
import StaffInput from '../form/StaffInput';
import * as messages from '../../constants/messages';
import {Link} from 'react-router-dom';

const AUTH_URL = process.env.REACT_APP_AUTH_SERVER;
const CLIENT_ID = process.env.REACT_APP_AUTH_SERVER_CLIENT_ID;
const GRANT_TYPE = process.env.REACT_APP_AUTH_SERVER_GRANT_TYPE;
const CLIENT_SECRET = process.env.REACT_APP_AUTH_SERVER_CLIENT_SECRET;

class LoginForm extends Component {
  submit = (values) => {
    const params = Object.assign(values, {
      client_id: CLIENT_ID,
      grant_type: GRANT_TYPE,
      client_secret: CLIENT_SECRET
    });

    if (!params.username || !params.password){
        throw new SubmissionError({
          _error: messages.INVALID_MESSAGE_OR_PASSWORD
        });
    }

    const bodyFormData = new FormData();
    bodyFormData.set('client_id', CLIENT_ID);
    bodyFormData.set('grant_type', GRANT_TYPE);
    bodyFormData.set('client_secret', CLIENT_SECRET);
    bodyFormData.set('username', params.username);
    bodyFormData.set('password', params.password);

    return axios(AUTH_URL,{
        method: 'POST',
        data: bodyFormData
      })
      .then(resp => {
        const token = resp.data.access_token;
        this.props.setUserLogin(token);
      })
      .catch(() => {
        throw new SubmissionError({
          _error: messages.INVALID_MESSAGE_OR_PASSWORD
        });
      });
  };

  render() {
    const {error, handleSubmit, submitting} = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>

        {error ? <Alert color="danger" title="Ошибка:">{error}</Alert> : <Clay padding={[4, 0, 0, 0]} />}

        <Field type="text"
               name="username"
               label="Логин"
               autoFocus={true}
               isRequired={true}
               autoComplete="email"
               component={StaffInput} />

        <Clay padding={[0, 0, 3, 0]} />

        <Field type="password"
               name="password"
               label="Пароль"
               isRequired={true}
               autoComplete="password"
               component={StaffInput} />

        <Clay margin={[3, 0, 0]} textAlign="center" className="app-container__column">
          <Clay padding={[0, 0, 6]} className="app-container app-container__font-size_14" >
            <Link to="/resetPassword" style={{color: '#3765A4'}} >
              Восстановить пароль
            </Link>
          </Clay>
          <Button type="submit" color="primary" disabled={submitting}>
            Войти
          </Button>
        </Clay>
      </form>
    );
  }
}

const mapDispatchToProps = {
  setUserLogin
};

LoginForm = connect(null, mapDispatchToProps)(LoginForm);

export default reduxForm({form: 'loginForm'})(LoginForm);
