export const FORM_NAME = 'userProfileForm';

export const ID = 'id';
export const USER_NAME = 'username';
export const LAST_NAME = 'last_name';
export const FIRST_NAME = 'first_name';
export const SECOND_NAME = 'second_name';

export const USER_PROFILE_UPDATE_FORM = {
  [LAST_NAME]: null,
  [FIRST_NAME]: null,
  [SECOND_NAME]: null
};