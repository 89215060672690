import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {setUserLogin, setUserToken, setUserRole} from '../../store/app/actions';
import {getCookie} from '../../utils/Cookie';

class Auth extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      prevPath: ''
    };
  }

  componentDidMount() {
    this.updateAppState();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({prevPath: this.props.location});
    }
  }

  componentDidUpdate(prevProps) {
    const token = this.props.userToken;
    const prevToken = prevProps.userToken;
    if (prevToken && prevToken !== token) {
      this.updateAppState();
    }
    else if (token) {
      this.redirectToMainLayout();
    }
  }

  updateAppState = () => {
    const token = getCookie('token');

    if (token) {
      this.props.setUserLogin(token);
    }
    else {
      const path = window.location.pathname;
      !path.includes('updatePassword') && this.props.history.push('/login');
    }
  };

  redirectToMainLayout() {
    const prevPathname = this.state.prevPath.pathname;
    const currentLocation = this.props.history.location.pathname;
    if (currentLocation === '/login') {
      setTimeout(() => {
        if (prevPathname === '/login' || prevPathname === '/hasNoAccess') {
          this.props.history.push('/');
        }
        else {
          this.props.history.goBack();
        }
      }, 100);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    userToken: state.app.userToken,
    userAuthorised: state.app.userAuthorised
  };
};

const mapDispatchToProps = {
  setUserRole,
  setUserToken,
  setUserLogin
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));
