import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Clay} from '@n3/kit';

import * as rolesList from '../../../constants/rolesList';

const MESSAGES = {
  [rolesList.BUILDER]: 'Вы ввели дату завершения работы или удалили текущую работу. ' +
  'После сохранения специалист будет удален из списка сотрудников, желаете продолжить?',
  [rolesList.EDU]: 'Вы ввели дату завершения обучения или удалили текущее образование. После сохранения ' +
  'специалист будет удален из списка учащихся, желаете продолжить?'
};

class ApproveModal extends React.Component {

  handleClick = () => {
    this.props.toggleOpened();
    this.props.setCanSave();
    setTimeout(() => {this.props.submit();}, 100);
  };

  handleCancel = () => {
    this.props.redirectAfterSave();
    this.props.toggleOpened();
  };

  render() {
    const {isOpened, role} = this.props;

    return (
      <Modal show={isOpened}
             onHide={this.handleCancel}>
        <Modal.Body>
          <Clay font='h1'>
            Подтвердите действие
          </Clay>
          <Clay padding={[5, 0]}>
            {role && MESSAGES[role]}
          </Clay>
          <Clay className="app-container__row">
            <Button onClick={this.handleCancel}>
              Отмена
            </Button>
            <Button color="primary" onClick={this.handleClick}>
              Продолжить
            </Button>
          </Clay>
        </Modal.Body>
      </Modal>
    );
  }
}

ApproveModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  toggleOpened: PropTypes.func.isRequired
};

export default ApproveModal;