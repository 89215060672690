import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {Clay} from '@n3/kit';
import {appStoreGetFilteredData, appStoreSaveFilters, appStoreUpdateFilter} from '../../../store/appStore/actions';
import {getFilterValue, mapFiltersToStr} from '../../../services/filters';
import {CATEGORY_VALUES} from '../../../constants/selectorsValues';
import StaffSelector from '../../form/StaffSelector';

const CATEGORY = 'category';

const ORGANISATIONS_LIST_FILTER = [
  {
    name: CATEGORY,
    label: 'Все',
    value: null
  }
];

class OrganizationsListFilter extends Component {

  componentDidMount() {
    const storeName = this.props.storeName;
    const filters = ORGANISATIONS_LIST_FILTER.slice();

    this.props.appStoreSaveFilters(storeName, filters);
  }

  componentDidUpdate() {
    this.getFilteredData();
  }

  getFilteredData = () => {
    const storeName = this.props.storeName;
    const dataSource = this.props.dataSource;
    const storeFilters = this.props.storeFilters;

    const request = `${dataSource}${mapFiltersToStr(storeFilters)}`;

    this.props.appStoreGetFilteredData(storeName, request);
  };

  handleSelectorChange = (name, selectorValue) => {
    const storeName = this.props.storeName;
    const label = selectorValue.label;
    const value = selectorValue.value;

    this.props.appStoreUpdateFilter(storeName, {name, label, value});
  };

  render() {
    const {storeFilters} = this.props;

    if (!storeFilters) {
      return null;
    }

    return (
      <Clay className='app-container__selector' padding={[0,4,2,0]}>
        <StaffSelector  label="Статус"
                        input={{name: CATEGORY}}
                        options={CATEGORY_VALUES}
                        handleChange={this.handleSelectorChange}
                        selectorValue={getFilterValue(storeFilters, CATEGORY)} />
      </Clay>
    );
  }
}

OrganizationsListFilter.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreSaveFilters,
  appStoreUpdateFilter,
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsListFilter);