import React, {Component} from 'react';

import {Clay} from '@n3/kit';
import HeaderLogo from './HeaderLogo';
import UserPicContainer from '../users/UserPicContainer';

class Header extends Component {
  render() {
    return (
      <Clay bgColor="bg" className="app-container app-container__border_bottom">
        <Clay padding={[3, 5]} font="default" className="app-container__row">
          <HeaderLogo />
          <UserPicContainer />
        </Clay>
      </Clay>
    );
  }
}

export default Header;