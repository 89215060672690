import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {change, formValueSelector, reduxForm} from 'redux-form';
import {log} from '@n3/kit';
import * as messages from '../../../constants/messages';
import validate from './formValidate';
import * as form from './formConstructor';
import VacancyCreateForm from './VacancyCreateForm';

VacancyCreateForm.propTypes = {
  handleCancelClick: PropTypes.func.isRequired
};

const VacancyCreateFormContainer = reduxForm({
    validate,
    form: form.FORM_NAME,
    enableReinitialize: true
  },
  {change})(VacancyCreateForm);

const selector = formValueSelector(form.FORM_NAME);

const mapStetToProps = (state) => {
  return {
    [form.RANK]: selector(state, form.RANK),
    [form.DEMAND]: selector(state, form.DEMAND),
    [form.AUTHOR]: selector(state, form.AUTHOR),
    [form.GENDER]: selector(state, form.GENDER),
    [form.PROFESSION]: selector(state, form.PROFESSION),
    [form.TECHNOLOGY]: selector(state, form.TECHNOLOGY),
    [form.NATIONALITY]: selector(state, form.NATIONALITY),
    [form.DATE_CREATED]: selector(state, form.DATE_CREATED),
    [form.ORGANISATION]: selector(state, form.ORGANISATION),
    [form.AVAILABILITY]: selector(state, form.AVAILABILITY),
    [form.STATUS_DISPLAY]: selector(state, form.STATUS_DISPLAY),
    [form.ACCESS_WORK_TYPE]: selector(state, form.ACCESS_WORK_TYPE),
    onSubmitFail: () => {
      log({
          title: 'Ошибка',
          message: messages.SAVE_ERROR,
          color: 'danger',
          timeout: '5000'}
        );
    },
    initialValues: state.appForms[form.FORM_NAME]
  };
};

const mapDispatchToProps = {};

export default connect(mapStetToProps, mapDispatchToProps)(withRouter(VacancyCreateFormContainer));