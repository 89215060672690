import React from 'react';
import PropTypes from 'prop-types';
import {Clay, Button} from '@n3/kit';

class StaffCollapse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    const isOpen = this.props.isOpen;
    this.setState(state => ({...state, isOpen}));
  }

  handelClick = (event) => {
    event.preventDefault();
    this.setState(state => ({...state, isOpen: !state.isOpen}));
  };

  render() {
    const {isOpen} = this.state;
    const {collapseHeader, collapseBody} = this.props;

    return (
      <Clay marginSequence={2} bgColor="bg">
        <Clay padding={[0, 3]} className="app-container__row_flex-start">
          <Clay padding={[0, 3, 0 , 0]}>
            <Button onlyIcon
                    onClick={this.handelClick}
                    className="app-container__border_none app-container__bg_none">
              {isOpen ? <i className="fas fa-chevron-up fa-lg" /> : <i className="fas fa-chevron-down fa-lg" />}
            </Button>
          </Clay>
          {collapseHeader}
        </Clay>
        {isOpen &&
        <Clay padding={[3, 0, 0, 6]} className="app-container__border_top">
          {collapseBody}
        </Clay>}
      </Clay>
    );
  }
}

StaffCollapse.propTypes = {
  isOpen: PropTypes.bool,
  collapseBody: PropTypes.object,
  collapseHeader: PropTypes.object
};

export default StaffCollapse;