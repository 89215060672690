import React from 'react';
import {Clay} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import * as filter from './filterConstructor';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as dictionaries from '../../../constants/dictionariesSource';
import {getFilterValue, mapFiltersToStr} from '../../../services/filters';
import {appStoreGetFilteredData, appStoreSaveFilters, appStoreUpdateFilter} from '../../../store/appStore/actions';

const RANK = 'rank';
const RANK_FILTER = {name: RANK, label: 'Все', value: null};
const ORDERING_FILTER = {name: filter.ORDERING, label: null, value: null};

class SpecialistsListFilterRank extends React.Component {

  componentDidMount() {
    const {dataSource} = this.props;

    if (dataSource) {
      this.setFilters();
    }
  }

  componentDidUpdate(prevProps) {
    const storeFilters = this.props.storeFilters;

    !storeFilters.length ? this.setFilters() : this.getFilteredData();
  }

  setFilters = () => {
    const storeName = this.props.storeName;
    const filters = [...[RANK_FILTER], ...[ORDERING_FILTER]];

    this.props.appStoreSaveFilters(storeName, filters);
  };

  getFilteredData = () => {
    const storeName = this.props.storeName;
    const dataSource = this.props.dataSource;
    const storeFilters = this.props.storeFilters;

    const request = `${dataSource}${mapFiltersToStr(storeFilters)}`;

    this.props.appStoreGetFilteredData(storeName, request);
  };

  handleSelectorChange = (name, selectorValue) => {
    const storeName = this.props.storeName;
    const label = selectorValue.label;
    const value = selectorValue.value;

    this.props.appStoreUpdateFilter(storeName, {name, label, value});
  };

  render() {
    const {storeFilters} = this.props;

    if (!storeFilters) {
      return null;
    }

    return (
      <Clay className='app-container__selector'>
        <StaffSelectorAsync label="Должность"
                            input={{name: RANK}}
                            defaultValue={RANK_FILTER}
                            dataSource={dictionaries.RANKS}
                            handleChange={this.handleSelectorChange}
                            selectorValue={getFilterValue(storeFilters, RANK)} />
      </Clay>
    );
  }
}

SpecialistsListFilterRank.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    appFilters: state.app.appFilters,
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreSaveFilters,
  appStoreUpdateFilter,
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistsListFilterRank);