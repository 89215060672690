import React, {Component} from 'react';
import {Clay} from '@n3/kit';

import {DEMANDS_REPORT} from '../../../constants/pagesName';
import ReportChartContainer from '../charts/ReportChart';
import DemandsReportTable from './DemandsReportTable';
import StaffSearch from '../../layouts/StaffSearch';
import StaffPaginator from '../../layouts/StaffPaginator';
import DemandsReportFilter from './DemandsReportFilter';

export const DEMANDS_REPORT_STORE = 'demandsReportStore';

class DemandsReport extends Component {

  componentWillMount() {
    const dataSource = '/reports/professions/demands/';
    this.props.appStoreCreate(DEMANDS_REPORT_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(DEMANDS_REPORT_STORE);
  }

  componentDidUpdate() {
    const {dataSource, storeFilters} = this.props;

    if (storeFilters.length === 0) {
      this.props.appStoreGetListData(DEMANDS_REPORT_STORE, dataSource);
    }
  }

  render() {
    const chartSettingsFirst = {
      dataSource: '/reports/diagrams/organization_demands/',
      storeName: 'demandsChartStore',
      colors: [
        '#F6C383',
        '#596CCA'
      ]
    };

    const chartSettingsSecond = {
      dataSource: '/reports/diagrams/open_vacancy/',
      storeName: 'openVacancyChartStore',
      colors: [
        '#F69A8B',
        '#487E94'
      ]
    };

    return (
      <Clay padding={[3, 5]} className="app-container">
        <Clay className="app-container__row_flex-start">
          <ReportChartContainer settings={chartSettingsFirst} />
          <ReportChartContainer settings={chartSettingsSecond} />
        </Clay>

        <Clay font='h1' padding={[3, 0]}>
          {DEMANDS_REPORT}
        </Clay>

        <StaffSearch storeName={DEMANDS_REPORT_STORE} />

        <Clay padding={[3, 0]}>
          <DemandsReportFilter storeName={DEMANDS_REPORT_STORE} />
        </Clay>

        <DemandsReportTable storeName={DEMANDS_REPORT_STORE} />

        <Clay padding={[3, 0, 0]}>
          <StaffPaginator storeName={DEMANDS_REPORT_STORE} />
        </Clay>
      </Clay>
    );
  }
}

export default DemandsReport;