import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Clay} from '@n3/kit';
import {Field} from 'redux-form';

import * as form from './formConstructor';
import StaffSelector from '../../form/StaffSelector';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import * as dictionaries from '../../../constants/dictionariesSource';
import {GENDER_VALUES_WITH_UNDEFINED} from '../../../constants/selectorsValues';

class FormBody extends Component {
  render() {
    const {fields, handleChange} = this.props;

    if (!fields) {
      return null;
    }

    const {gender, profession, technology, access_work_type} = fields;

    return (
      <StaffDisplayCard>
        <Clay padding={[2, 0, 2, 0]}>
          <Row>
            <Col xs={6}>
              <Field  label="Профессия"
                      isRequired={true}
                      name={form.PROFESSION}
                      selectorValue={profession}
                      handleChange={handleChange}
                      component={StaffSelectorAsync}
                      dataSource={dictionaries.PROFESSIONS}
                      defaultValue={form.SELECTOR_DEFAULT_VALUE} />
            </Col>

            <Col xs={6}>
              <Field  label="Пол"
                      name={form.GENDER}
                      selectorValue={gender}
                      component={StaffSelector}
                      handleChange={handleChange}
                      options={GENDER_VALUES_WITH_UNDEFINED} />
            </Col>
          </Row>
        </Clay>
        <Clay padding={[2, 0, 2, 0]}>
          <Row>
            <Col xs={6}>
              <Field  isMulti={true}
                      label="Допуск к виду работ"
                      handleChange={handleChange}
                      name={form.ACCESS_WORK_TYPE}
                      component={StaffSelectorAsync}
                      selectorValueArr={access_work_type}
                      dataSource={dictionaries.WORK_TYPES} />
            </Col>

            <Col xs={6}>
              <Field  isMulti={true}
                      label="Технологии"
                      name={form.TECHNOLOGY}
                      handleChange={handleChange}
                      selectorValueArr={technology}
                      component={StaffSelectorAsync}
                      dataSource={dictionaries.TECHNOLOGIES} />
            </Col>
          </Row>
        </Clay>
      </StaffDisplayCard>
    );
  }
}

FormBody.propTypes = {
  fields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default FormBody;