import * as roles from './rolesList';

export const DEMANDS = 'DEMANDS';
export const SPECIALISTS = 'SPECIALISTS';

export const dataSourceList = {
  [roles.KS]: {
    [DEMANDS]: '/demands/',
    [SPECIALISTS]: '/specialists/'
  },

  [roles.BUILDER]: {
    [DEMANDS]: '/demands/contractor/',
    [SPECIALISTS]: '/specialists/employees/'
  },

  [roles.EDU]: {
    [SPECIALISTS]: '/specialists/students/'
  },

  [roles.STAFFING]: {
    [SPECIALISTS]: '/specialists/jobseekers/'
  }
};