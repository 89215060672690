import React from 'react';
import {Clay, log} from '@n3/kit';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import API from '../../../API';
import * as form from './formConstructor';
import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import * as messages from '../../../constants/messages';
import {mapErrorMessage} from '../../../services/mapper';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import SpecialistCreateFormContainer from './SpecialistCreateFormContainer';
import {SPECIALIST_CREATE, SPECIALISTS_LIST} from '../../../constants/pagesName';
import {appFormsCreateStore, appFormsDeleteStore} from '../../../store/appForms/actions';

class SpecialistCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breadCrumbs: []
    };
  }

  componentWillMount() {
    this.userRole = getCookie('role');
    this.props.appFormsCreateStore(form.FORM_NAME, form.SPECIALIST_CREATE_FORM[this.userRole]);
    this.getBreadCrumbsList();
  }

  componentWillUnmount() {
    this.props.appFormsDeleteStore(form.FORM_NAME);
  }

  getBreadCrumbsList = () => {
    let breadCrumbs = [
      {
        url: `/specialists`,
        title: SPECIALISTS_LIST[this.userRole]
      },
      {
        url: null,
        title: SPECIALIST_CREATE[this.userRole]
      }
    ];

    this.setState(state => ({...state, breadCrumbs}));
  };

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = (values) => {
    const userRole = getCookie('role');
    const data = this.prepareToSend(mapValuesToSend(values));

    return API.request(form.API[userRole], {method: 'post', data})
      .then((resp) => {
        this.renderSuccessLog();
        this.props.history.push(`/specialists/update/${resp.employment_status}/${resp.id}/1`);
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = error.response.status === 400
          ? mapErrorMessage(error)
          : messages.SAVE_ERROR;
        this.renderErrorLog(errorMessage);
      });
  };

  prepareToSend = (data) => {
    data[form.SNILS] = data[form.SNILS].toString().replace(/-/g, '').replace(/ /g, '');

    if (form.WORK in data) {
      data[form.WORK] = {[form.RANK]: data[form.RANK], [form.DATE_START_WORK]: data[form.DATE_START_WORK]};
      delete data[form.DATE_START_WORK];
      delete data[form.RANK];
    }

    if (form.EDUCATION in data) {
      data[form.EDUCATION] = {[form.DATE_START_EDUCATION]: data[form.DATE_START_EDUCATION]};
      delete data[form.DATE_START_EDUCATION];
    }

    if (!data[form.SECOND_NAME]) {
      delete data[form.SECOND_NAME];
    }

    return data;
  };

  handleCancelClick = (event) => {
    event.preventDefault();
    this.props.history.push('/specialists');
  };

  render() {
    const {breadCrumbs} = this.state;

    return (
      <AccessControl permission={permission.SPECIALIST_CREATE} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader breadCrumbs={breadCrumbs}
                      title={SPECIALIST_CREATE[this.userRole]} />

          <Clay bgColor="bg" padding={[3, 5]}>
            <SpecialistCreateFormContainer  onSubmit={this.submit}
                                            handleCancelClick={this.handleCancelClick} />
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userRole: state.app.userRole
  };
};

const mapDispatchToProps = {
  appFormsCreateStore,
  appFormsDeleteStore
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpecialistCreate));