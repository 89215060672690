import React, {Component} from 'react';
import {Table, Thead, Tbody, Th, Td, LoadingArea, Clay} from '@n3/kit';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {EMPTY_VALUE_SYMBOL, NON_BREAKING_SPACE} from '../../../constants/symbolsList';

class OrganizationsTable extends Component {

  redirectToRead = (id) => {
    const path = `/organizations/read/${id}/1`;
    this.props.history.push(path);
  };

  renderTableBody = () => {
    const orgList = this.props.listData;
    return orgList.map(org => {
      return (
        <tr key={org.id}
            className="app-container__cursor_pointer"
            onClick={() => this.redirectToRead(org.id)}>
          <Td>{org.category_display}</Td>
          <Td>{org.name}</Td>
          <Td hasWordBreak>{org.full_name}</Td>
          <Td hasWordBreak>{org.juristic_address || EMPTY_VALUE_SYMBOL}</Td>
        </tr>
      );
    });
  };

  render() {
    const {listData, isLoading} = this.props;
    const categoryHeader = `Категория${NON_BREAKING_SPACE}организации`;
    const fullNameHeader = `Полное${NON_BREAKING_SPACE}наименование`;
    const addressHeader = `Юридический${NON_BREAKING_SPACE}адрес`;

    return (
      <Clay>
        <Table className='table'>
          <Thead>
          <tr>
            <Th className='table__column_width--20'>{categoryHeader}</Th>
            <Th className='table__column_width--15'>Наименование</Th>
            <Th className='table__column_width--40'>{fullNameHeader}</Th>
            <Th>{addressHeader}</Th>
          </tr>
          </Thead>

          <Tbody>
          {listData && !isLoading && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
      </Clay>
    );
  }
}

OrganizationsTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null,
    isLoading: store ? store.storeIsLoading : null
  };
};

export default connect(mapStateToProps)(withRouter(OrganizationsTable));