import * as form from './formConstructor';
import * as messages from '../../../constants/messages';

export default (values) => {
  const errors = {};

  if (!values[form.NAME]) {
    errors[form.NAME] = messages.NOT_EMPTY;
  }
  if (!values[form.NUMBER]) {
    errors[form.NUMBER] = messages.NOT_EMPTY;
  }
  if (!values[form.DOC_DATE]) {
    errors[form.DOC_DATE] = messages.NOT_EMPTY;
  }
  if (!values[form.TYPE] || !values[form.TYPE].value) {
    errors[form.TYPE] = messages.NOT_EMPTY;
  }

  return errors;
}