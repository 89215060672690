import React, {Component} from 'react';
import VacancyCreateDemandsList from './VacancyCreateDemandsList';
import {Clay, Button, Modal} from '@n3/kit';
import {VACANCY_CREATE} from '../../../constants/permissionsList';
import AccessControl from '../../accessControl/AccessControl';

class VacancyCreateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false
    };
  }

  toggleOpened = () => {
    this.setState(state => ({...state, isOpened: !state.isOpened}));
  };

  render() {
    const {isOpened} = this.state;

    return (
      <Clay>
        <AccessControl permission={VACANCY_CREATE}>
          <Button color="primary" onClick={this.toggleOpened}>
            Добавить
          </Button>
          <Modal
            show={isOpened}
            onHide={this.toggleOpened}>

            <Modal.Body>
              <VacancyCreateDemandsList />
            </Modal.Body>
          </Modal>
        </AccessControl>
      </Clay>
    );
  }
}

export default VacancyCreateModal;