import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Table, Tbody, Td, Th, Thead, Clay, LoadingArea} from '@n3/kit';
import PropTypes from 'prop-types';
import StaffOrdering from '../../layouts/StaffOrdering';
import * as filter from './filterConstructor';
import {NON_BREAKING_SPACE} from '../../../constants/symbolsList';

class SpecialistsReportTable extends Component {

  renderTableBody = () => {
    const specList = this.props.listData;
    return specList.map(spec => {
      return (
        <tr key={spec.id}>
          <Td className='table__column_width--40'>{spec.name}</Td>
          <Td className='table__column_width--12'>{spec.students_and_jobseekers_counts}</Td>
          <Td className='table__column_width--12'>{spec.students_count}</Td>
          <Td>{spec.jobseekers_count}</Td>
        </tr>
      );
    });
  };

  render() {
    const {listData, totalValues, storeName, isLoading} = this.props;
    const totalsHeader = `Общее${NON_BREAKING_SPACE}количество`

    return (
      <Clay>
        <Table className='table'>
          <Thead>
            <tr>
              <Th>
                Профессия
              </Th>
              <Th>
                <StaffOrdering  storeName={storeName}
                                filteredParam={'students_and_jobseekers_counts'}
                                filterName={filter.ORDERING}>
                  {totalsHeader}
                </StaffOrdering>
              </Th>
              <Th>
                <StaffOrdering  storeName={storeName}
                                filteredParam={'students_count'}
                                filterName={filter.ORDERING}>
                  Учащиеся
                </StaffOrdering>
              </Th>
              <Th>
                <StaffOrdering  storeName={storeName}
                                filteredParam={'jobseekers_count'}
                                filterName={filter.ORDERING}>
                  Соискатели
                </StaffOrdering>
              </Th>
            </tr>
          </Thead>
          <Thead>
            <tr>
              <Th>Всего</Th>
              <Th>{totalValues && totalValues.students_and_jobseekers_counts_total}</Th>
              <Th>{totalValues && totalValues.students_count_total}</Th>
              <Th>{totalValues && totalValues.jobseekers_count_total}</Th>
            </tr>
          </Thead>

          <Tbody>
          {listData && !isLoading && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
      </Clay>
    );
  }
}

SpecialistsReportTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null,
    totalValues: store ? store.storeTotalValues : null,
    isLoading: store ? store.storeIsLoading : null
  };
};

export default connect(mapStateToProps)(SpecialistsReportTable);