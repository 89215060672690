import React from 'react';
import {Clay, Tabs} from '@n3/kit';
import StaffTab from '../layouts/StaffTab';

import PageHeader from '../layouts/PageHeader';
import {REPORTS} from '../../constants/pagesName';
import AccessControl from '../accessControl/AccessControl';
import * as permissions from '../../constants/permissionsList';
import DemandsReportContainer from './demandsReport/DemandsReportContainer';
import OrientationReportContainer from './orientationReport/OrientationReportContainer';
import SpecialistsReportContainer from './specialistsReport/SpecialistsReportContainer';
import {appUpdateSidebar} from '../../store/app/actions';
import {connect} from 'react-redux';

const PAGE_TABS = [
  {id: 1, title: 'Потребность'},
  {id: 2, title: 'Специалисты'},
  {id: 3, title: 'Профессиональная ориентация'}
];

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1
    };
  }

  componentDidMount() {
    const {tab} = this.props.match.params;
    const page = window.location.pathname;
    this.props.appUpdateSidebar(page);
    this.setState({currentTab: tab * 1});
  }

  setCurrentTab = (tabId) => {
    this.setState({currentTab: tabId});
    this.props.history.push(`/reports/${tabId}`);
  };

  render() {
    const {currentTab} = this.state;

    return (
      <AccessControl permission={permissions.REPORT_READ} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader bgColor="gray5" title={REPORTS} />

          <Tabs tabs={PAGE_TABS}
                current={currentTab}
                setCurrentTab={this.setCurrentTab} />

          <StaffTab tabId={1} currentTabId={currentTab}>
            <DemandsReportContainer />
          </StaffTab>

          <StaffTab tabId={2} currentTabId={currentTab}>
            <SpecialistsReportContainer />
          </StaffTab>

          <StaffTab tabId={3} currentTabId={currentTab}>
            <OrientationReportContainer />
          </StaffTab>
        </Clay>
      </AccessControl>
    );
  }
}

const mapDispatchToProps = {
  appUpdateSidebar
};

export default connect(null, mapDispatchToProps)(Reports);