import React, {Component} from 'react';
import {Clay, Datepicker, FieldLabel, FieldWrapper, Button} from '@n3/kit';
import PropTypes from 'prop-types';
import moment from 'moment';

class StaffDatepicker extends Component {

  handleChange = (name, value) => {
    const date = value ? moment(value).format('DD.MM.YYYY') : null;
    this.props.handleChange(name, date);
  };

  handleClick = (event, name) => {
    event.preventDefault();
    this.props.handleChange(name, null);
  };

  render() {
    const {input, meta, label, isRequired, autoFocus, isDisabled} = this.props;
    const {touched, error, warning} = meta;
    const {name, value} = input;

    return (
      <Clay>
        <FieldLabel isRequired={isRequired}>
          {label}
        </FieldLabel>

        <Clay padding={[1,0,0,0]} />

        <Clay>
          <Clay className="app-container__row_flex-start">
            <Datepicker {...input}
                        name={name}
                        disabled={isDisabled}
                        autoFocus={autoFocus}
                        hasError={touched && !!error}
                        onChange={(value) => this.handleChange(name, value)} />
            <Clay padding={[0, 1]} />
            <Button onlyIcon
                    size="small"
                    disabled={isDisabled || !value.length}
                    onClick={(event) => {this.handleClick(event, name);}}>
              <i className="fas fa-times" />
            </Button>
          </Clay>
          {touched &&
          ((error && <FieldWrapper errors={[error]} />) ||
            (warning && <span>{warning}</span>))}
        </Clay>
      </Clay>
    );
  }
}

StaffDatepicker.propsType = {
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.string,
  handleChange: PropTypes.func
};

export default StaffDatepicker;