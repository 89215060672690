import React from 'react';
import {withRouter, Route, Switch} from 'react-router-dom';

import Footer from './Footer';
import Sidebar from './Sidebar';
import Login from '../users/Login';
import Reports from '../reports/Reports';
import HasNoAccess from '../users/HasNoAccess';
import ResetPassword from '../users/ResetPassword';
import DemandsRouter from '../demands/DemandsRouter';
import UpdatePassword from '../users/UpdatePassword';
import VacanciesRouter from '../vacancy/VacanciesRouter';
import NotificationsList from '../notification/Notifications';
import {RouteWithSubRoutes} from '../router/RouteWithSubRoutes';
import SpecialistsRouter from '../specialists/SpecialistsRouter';
import ResetPasswordMessage from '../users/ResetPasswordMessage';
import OrganizationsRouter from '../organization/OrganizationsRouter';
import UserProfileUpdate from '../userProfile/update/UserProfileUpdate';
import UserProfileReadContainer from '../userProfile/read/UserProfileReadContainer';

class MainLayout extends React.Component {
  render() {
    return (
      <div className="app-container__row_flex-start app-container__row_flex-height">
        <Sidebar />
        <div className="app-container__column app-container__column_overflow">
          <div className="app-container app-container__flex">
            <NotificationsList />
            <Switch>
              <Route path="/" exact component={UserProfileReadContainer} />

              <Route path="/login" component={Login} />
              <Route path="/hasNoAccess" component={HasNoAccess} />
              <Route path="/update" component={UserProfileUpdate} />
              <Route path="/resetPassword" component={ResetPassword} />
              <Route path="/updatePassword/:code" component={UpdatePassword} />
              <Route path="/resetPasswordMessage" component={ResetPasswordMessage} />

              <RouteWithSubRoutes path="/reports/:tab" component={Reports} />
              <RouteWithSubRoutes path="/demands" component={DemandsRouter} />
              <RouteWithSubRoutes path="/vacancy" component={VacanciesRouter} />
              <RouteWithSubRoutes path="/specialists" component={SpecialistsRouter} />
              <RouteWithSubRoutes path="/organizations" component={OrganizationsRouter} />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(MainLayout);