import * as roles from '../../../constants/rolesList';

export const API = {
  [roles.EDU]: '/specialists/students/create/',
  [roles.BUILDER]: '/specialists/employees/create/',
  [roles.STAFFING]: '/specialists/jobseekers/create/'
};

export const FORM_NAME = 'specialistCreateForm';

export const WORK = 'work';
export const RANK = 'rank';
export const SNILS = 'snils';
export const GENDER = 'gender';
export const EDUCATION = 'education';
export const BIRTH_DAY = 'birth_day';
export const LAST_NAME = 'last_name';
export const FIRST_NAME = 'first_name';
export const SECOND_NAME = 'second_name';
export const NATIONALITY = 'nationality';
export const DATE_START_WORK = 'date_start';
export const DATE_START_EDUCATION = 'date_start';

export const SPECIALIST_CREATE_FORM = {

  [roles.EDU]: {
    [SNILS]: null,
    [EDUCATION]: {},
    [BIRTH_DAY]: null,
    [LAST_NAME]: null,
    [FIRST_NAME]: null,
    [SECOND_NAME]: null,
    [DATE_START_EDUCATION]: null,
    [GENDER]: {value: null, label: 'Выберите...'},
    [NATIONALITY]: {value: null, label: 'Выберите...'}
  },

  [roles.BUILDER]: {
    [WORK]: {},
    [SNILS]: null,
    [BIRTH_DAY]: null,
    [LAST_NAME]: null,
    [FIRST_NAME]: null,
    [SECOND_NAME]: null,
    [DATE_START_WORK]: null,
    [RANK]: {value: null, label: 'Выберите...'},
    [GENDER]: {value: null, label: 'Выберите...'},
    [NATIONALITY]: {value: null, label: 'Выберите...'}
  },

  [roles.STAFFING]: {
    [SNILS]: null,
    [BIRTH_DAY]: null,
    [LAST_NAME]: null,
    [FIRST_NAME]: null,
    [SECOND_NAME]: null,
    [RANK]: {value: null, label: 'Выберите...'},
    [GENDER]: {value: null, label: 'Выберите...'},
    [NATIONALITY]: {value: null, label: 'Выберите...'}
  }

};

export const SNILS_MASK = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
