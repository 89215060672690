import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {change, formValueSelector, reduxForm} from 'redux-form';
import {log} from '@n3/kit';

import validate from './formValidator';
import * as form from './formConstructor';
import DemandUpdateForm from './DemandUpdateForm';
import * as messages from '../../../constants/messages';

const DemandUpdateFormContainer = reduxForm({
    validate,
    form: form.FORM_NAME,
    enableReinitialize: true
  },
  {change})(DemandUpdateForm);

const selector = formValueSelector(form.FORM_NAME);

const mapStateToProps = (state) => {
  return {
    formBody: {
      [form.GENDER]: selector(state, form.GENDER),
      [form.PROFESSION]: selector(state, form.PROFESSION),
      [form.TECHNOLOGY]: selector(state, form.TECHNOLOGY),
      [form.ACCESS_WORK_TYPE]: selector(state, form.ACCESS_WORK_TYPE)
    },

    [form.RANK]: selector(state, form.RANK),
    [form.STATUS]: selector(state, form.STATUS),
    [form.AUTHOR]: selector(state, form.AUTHOR),
    [form.DATE_CLOSE]: selector(state, form.DATE_CLOSE),
    [form.DATE_CREATED]: selector(state, form.DATE_CREATED),
    [form.STATUS_DISPLAY]: selector(state, form.STATUS_DISPLAY),
    onSubmitFail: () => {
      log({
          title: 'Ошибка',
          message: messages.SAVE_ERROR,
          color: 'danger',
          timeout: '5000'}
        );
    },

    initialValues: state.appForms[form.FORM_NAME]
  };
};

export default connect(mapStateToProps)(withRouter(DemandUpdateFormContainer));