import React from 'react';
import PropTypes from 'prop-types';

class CheckPage extends React.Component {

  checkPage = () => {
    const {condition} = this.props;
    const page = window.location.href;

    return page.includes(condition);
  };

  render() {
    if (this.checkPage()) {
      return this.props.children;
    }
    else {
      return null;
    }
  }
}

CheckPage.propTypes = {
  condition: PropTypes.string.isRequired
};

export default CheckPage;