import * as types from './actions';

const initialStore = {
  storeFilters: [],
  storeListData: null,
  storeTotalValues: {},
  storeCurrentPage: 1,
  storeIsLoading: true,
  storeTotalPages: null,
  storeDataSource: null,
  storeDisplayData: null
};

export const appStoreReducer = (state = {}, action = {}) => {
  const storeName = action.payload ? action.payload.storeName : null;

  switch (action.type) {
    case types.APP_STORE_CREATE_STORE:
      return {
        ...state,
        [storeName]: {
          ...initialStore,
          storeDataSource: action.payload.dataSource
        }
      };
    case types.APP_STORE_SAVE_FILTERS:
      return {
        ...state,
        [storeName]: {
          ...state[storeName],
          storeFilters: [...action.payload.filters]
        }
      };
    case types.APP_STORE_UPDATE_FILTER:
      const filters = state[storeName].storeFilters;
      const filterName = action.payload.filter.name;
      return {
        ...state,
        [storeName]: {
          ...state[storeName],
          storeFilters: filters
            .filter(filter => filter.name !== filterName)
            .concat(action.payload.filter),
          storeIsLoading: true
        }
      };
    case types.APP_STORE_UPDATE_LIST_DATA:
      return {
        ...state,
        [storeName]: {
          ...state[storeName],
          storeIsLoading: false,
          storeListData: action.payload.listData,
          storeTotalPages: action.payload.totalPages,
          storeTotalValues: action.payload.totalValues
        }
      };
    case types.APP_STORE_UPDATE_CURRENT_PAGE:
      return {
        ...state,
        [storeName]: {
          ...state[storeName],
          storeIsLoading: true,
          storeCurrentPage: action.payload.currentPage
        }
      };
    case types.APP_STORE_UPDATE_DISPLAY_DATA:
      return {
        ...state,
        [storeName]: {
          ...state[storeName],
          storeIsLoading: false,
          storeDisplayData: action.payload.displayData
        }
      };
    case types.APP_STORE_DELETE_STORE:
      delete state[storeName];
      return state;
    default:
      return state;
  }
};