import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Doughnut} from 'react-chartjs-2';
import {Clay} from '@n3/kit';

export default class DoughnutChart extends Component {
  render() {
    const title = this.props.title;
    const options =
      {
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        aspectRatio: 1,
        title: {
          display: true,
          text: title,
          position: 'top'
        },
        legend: {
          display: true,
          position: 'right',
          labels: {
            usePointStyle: true
          }
        },
        tooltips: {
          enabled: false
        }
      };

    return (
      <Clay style={{height: '150px', width: '450px'}}>
        <Doughnut data={this.props.data}
                  options={options} />
      </Clay>
    );
  }
}

DoughnutChart.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};
