import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Clay, LoadingArea, Row, Col} from '@n3/kit';

import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import StaffCollapse from '../../layouts/StaffCollapse';
import DisplayData from '../../layouts/DisplayData';

const WORKS_LIST_STORE = 'worksListStore';

class WorksList extends React.Component {

  componentDidMount() {
    const specId = this.props.match.params.id;
    const dataSource = `/specialists/${specId}/works/`;

    this.props.appStoreCreate(WORKS_LIST_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(WORKS_LIST_STORE);
  }

  componentDidUpdate(prevProps, prevState) {
    const {dataSource, worksList} = this.props;

    if (dataSource && !worksList) {
      this.props.appStoreGetDisplayData(WORKS_LIST_STORE, dataSource);
    }
  }

  renderCollapseHeader = (work) => {
    const experience = `${work.date_start} - ${work.date_end ? work.date_end : 'настоящее время'}`;

    return (
      <Clay className="app-container">
        <Row>
          <Col xs={4}>
            <DisplayData data={work.organization.name} label="Организация" />
          </Col>
          <Col xs={4}>
            <DisplayData data={experience} label="Период работы" />
          </Col>
          <Col xs={4}>
            <DisplayData data={work.rank_name} label="Должность" />
          </Col>
        </Row>
      </Clay>
    );
  };

  renderCollapseBody = (work) => {
    const org = work.organization;

    const orgStr =  `${org.fact_address  && 'Адрес: '  + org.fact_address}` +
                    `${org.fact_address && org.phone ? ', ' : ''}` +
                    `${org.phone         && 'Тел.: ' + org.phone}` +
                    `${(org.fact_address || org.phone) && org.website ? ', ' : ''}` +
                    `${org.website       && 'Сайт: ' + org.website}` +
                    `${!org.fact_address && !org.phone && !org.website ? '—' : ''}`;

    return (
      <Clay padding={[0, 3, 3, 4]} className="app-container__break-word">
        <Row>
          <Col xs={4}>
            <DisplayData data={orgStr} label="Информация об организации" />
          </Col>
          <Col xs={4}>
            <DisplayData data={work.brigate} label="Бригада" />
          </Col>
          <Col xs={4}>
            <DisplayData data={work.main_work ? 'Основная работа' : 'Совместительство'} label="Тип занятости" />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <DisplayData data={work.oks} label="Объекты капитального строительства" />
          </Col>
          <Col xs={4}>
            <DisplayData data={work.complex_projects} label="Комплексные проекты" />
          </Col>
        </Row>
      </Clay>
    );
  };

  renderWorksList = () => {
    const {worksList} = this.props;

    if (worksList.length) {
      return worksList.map((work, index) => {
        return <StaffCollapse key={work.id}
                              isOpen={index === 0}
                              collapseBody={this.renderCollapseBody(work)}
                              collapseHeader={this.renderCollapseHeader(work)} />;
      });
    }
    else {
      return (
        <StaffDisplayCard>
          Отсутствует информации об опыте работы
        </StaffDisplayCard>
      );
    }
  };

  render() {
    const {worksList, isLoading} = this.props;

    return (
      <Clay padding={[6, 0]}>
        {isLoading && <LoadingArea />}
        {worksList && this.renderWorksList()}
      </Clay>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[WORKS_LIST_STORE];

  return {
    isLoading: store ? store.storeIsLoading : null,
    dataSource: store ? store.storeDataSource : null,
    worksList: store ? store.storeDisplayData && store.storeDisplayData.works : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WorksList));