import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {Clay} from '@n3/kit';
import AccessControl from '../../accessControl/AccessControl';

import {appStoreGetFilteredData, appStoreSaveFilters, appStoreUpdateFilter} from '../../../store/appStore/actions';
import {getFilterValue, mapFiltersToStr} from '../../../services/filters';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as dictionaries from '../../../constants/dictionariesSource';
import * as permission from '../../../constants/permissionsList';
import {STATUS_VALUES} from '../../../constants/selectorsValues';
import StaffSelector from '../../form/StaffSelector';
import * as filter from './filterConstructor';
import CheckPage from '../../layouts/CheckPage';

class VacanciesListFilter extends Component {

  componentDidUpdate(prevProps) {
    const storeFilters = this.props.storeFilters;

    !storeFilters.length ? this.setFilters() : this.getFilteredData();
  }

  setFilters = () => {
    const storeName = this.props.storeName;
    const appFilters = this.props.appFilters;
    const filters = [...filter.VACANCIES_LIST_FILTER];

    if (appFilters.length) {
      appFilters.forEach(appFilter => {
        const index = filters.findIndex(filter => filter.name === appFilter.name);

        if (index > -1) {
          filters[index] = appFilter;
        }
      });
    }

    this.props.appStoreSaveFilters(storeName, filters);
  };

  getFilteredData = () => {
    const storeName = this.props.storeName;
    const dataSource = this.props.dataSource;
    const storeFilters = this.props.storeFilters;

    const request = `${dataSource}${mapFiltersToStr(storeFilters)}`;

    this.props.appStoreGetFilteredData(storeName, request);
  };

  handleSelectorChange = (name, selectorValue) => {
    const storeName = this.props.storeName;
    const label = selectorValue.label;
    const value = selectorValue.value;

    this.props.appStoreUpdateFilter(storeName, {name, label, value});
  };

  render() {
    const {storeFilters} = this.props;

    if (!storeFilters) {
      return null;
    }

    return (
      <Clay className="app-container app-container__row_just-row">
        <Clay className='app-container__selector' padding={[0,4,2,0]}>
          <StaffSelector  label="Статус"
                          input={{name: filter.STATUS}}
                          options={STATUS_VALUES}
                          handleChange={this.handleSelectorChange}
                          selectorValue={getFilterValue(storeFilters, filter.STATUS)} />
        </Clay>
        <CheckPage condition="vacancy">
          <AccessControl permission={permission.VACANCY_VIEW_ORGANIZATION} accessCheck={true}>
            <Clay className='app-container__selector' padding={[0,4,2,0]}>
              <StaffSelectorAsync label="Организация"
                                  input={{name: filter.ORGANIZATION}}
                                  dataSource={dictionaries.ORGANIZATIONS_CONTRACTOR}
                                  handleChange={this.handleSelectorChange}
                                  selectorValue={getFilterValue(storeFilters, filter.ORGANIZATION)} />
            </Clay>
          </AccessControl>
          <Clay className='app-container__selector' padding={[0,4,2,0]}>
            <StaffSelectorAsync label="Профессия"
                                input={{name: filter.PROFESSION}}
                                dataSource={dictionaries.PROFESSIONS}
                                handleChange={this.handleSelectorChange}
                                selectorValue={getFilterValue(storeFilters, filter.PROFESSION)} />
          </Clay>
        </CheckPage>
      </Clay>
    );
  }
}

VacanciesListFilter.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    appFilters: state.app.appFilters,
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreSaveFilters,
  appStoreUpdateFilter,
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(VacanciesListFilter);