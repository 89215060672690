import React, {Component} from 'react';
import {Table, Tbody, Td, Th, Thead, Clay, LoadingArea} from '@n3/kit';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import StaffOrdering from '../../layouts/StaffOrdering';
import * as filter from './filterConstructor';

import {appStoreCreate, appStoreDelete} from '../../../store/appStore/actions';
import * as specialistFilters from '../../specialists/list/filterConstructor';
import * as demandsFilter from '../../demands/list/filterConstructor';
import {appFilterSave} from '../../../store/app/actions';
import {NON_BREAKING_SPACE} from '../../../constants/symbolsList';

class OrientationReportTable extends Component {

  redirectToDemands = (id, name) => {
    this.saveAppFilterDemands(id, name);
    this.props.history.push('/demands');
  };

  redirectToSpecialists = (professionId, professionName, employmentStatus) => {
    this.saveAppFilterSpecialists(professionId, professionName, employmentStatus);
    this.props.history.push('/specialists/');
  };

  saveAppFilterDemands = (professionId, professionName) => {
    const filterProf = {name: demandsFilter.PROFESSION, value: professionId, label: professionName};
    const filterStatus = {name: demandsFilter.STATUS, value: 'opened', label: 'Открыто'};

    this.props.appFilterSave(filterProf);
    this.props.appFilterSave(filterStatus);
  };

  saveAppFilterSpecialists = (professionId, professionName, employmentStatus) => {
    const filterActive = {name: specialistFilters.ACTIVE, label: null, value: true};
    const filterProf = {name: specialistFilters.PROFESSION, value: professionId, label: professionName};
    const filterEmploymentStatus = {
      name: specialistFilters.EMPLOYMENT_STATUS,
      value: employmentStatus,
      label: employmentStatus === 'student' ? 'Учащийся' : 'Соискатель'
    };
    this.props.appFilterSave(filterProf);
    this.props.appFilterSave(filterEmploymentStatus);
    this.props.appFilterSave(filterActive);
  };

  renderTableBody = () => {
    const profList = this.props.listData;
    return profList.map(prof => {
      return (
        <tr key={prof.id}>
          <Td className='table__column_width--40'>{prof.name}</Td>
          <Td className='table__column_width--12 app-container__cursor_pointer'
              onClick={() => this.redirectToDemands(prof.id, prof.name)}>
            {prof.opened_demands_count}
          </Td>
          <Td className='table__column_width--12 app-container__cursor_pointer'
              onClick={() => this.redirectToSpecialists(prof.id, prof.name, 'student')}>
            {prof.students_count}
          </Td>
          <Td className='app-container__cursor_pointer'
              onClick={() => this.redirectToSpecialists(prof.id, prof.name, 'jobseeker')}>
            {prof.jobseekers_count}
          </Td>
        </tr>
      );
    });
  };

  render() {
    const {listData, totalValues, storeName, isLoading} = this.props;
    const totalDemandsHeader = `Всего${NON_BREAKING_SPACE}потребностей`;

    return (
      <Clay>
        <Table className='table'>
          <Thead>
            <tr>
              <Th>
                Профессия
              </Th>
              <Th>
                <StaffOrdering  storeName={storeName}
                                filteredParam={'opened_demands_count'}
                                filterName={filter.ORDERING}>
                  {totalDemandsHeader}
                </StaffOrdering>
              </Th>
              <Th>
                <StaffOrdering  storeName={storeName}
                                filteredParam={'students_count'}
                                filterName={filter.ORDERING}>
                  Учащиеся
                </StaffOrdering>
              </Th>
              <Th>
                <StaffOrdering  storeName={storeName}
                                filteredParam={'jobseekers_count'}
                                filterName={filter.ORDERING}>
                  Соискатели
                </StaffOrdering>
              </Th>
            </tr>
          </Thead>
          <Thead>
            <tr>
              <Th>Всего</Th>
              <Th>{totalValues && totalValues.opened_demands_count_total}</Th>
              <Th>{totalValues && totalValues.students_count_total}</Th>
              <Th>{totalValues && totalValues.jobseekers_count_total}</Th>
            </tr>
          </Thead>

          <Tbody>
            {listData && !isLoading && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
      </Clay>
    );
  }
}

OrientationReportTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null,
    totalValues: store ? store.storeTotalValues : null,
    isLoading: store ? store.storeIsLoading : null
  };
};

const mapDispatchToProps = {
  appFilterSave,
  appStoreCreate,
  appStoreDelete
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrientationReportTable));