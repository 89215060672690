import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Clay, Row, Col} from '@n3/kit';

import EducationUpdate from './EducationUpdate';
import DisplayData from '../../layouts/DisplayData';
import StaffCollapse from '../../layouts/StaffCollapse';
import EducationCreate from '../create/EducationCreate';
import AccessControl from '../../accessControl/AccessControl';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import * as form from '../../specialists/update/formConstructor';
import * as permission from '../../../constants/permissionsList';
import {appStoreUpdateListData} from '../../../store/appStore/actions';
import {getCookie} from '../../../utils/Cookie';
import * as rolesList from '../../../constants/rolesList';

export const EDUCATIONS_CREATE_LIST_STORE = 'educationCreateListStore';
export const EDUCATIONS_UPDATE_LIST_STORE = 'educationUpdateListStore';
export const EDUCATIONS_DELETE_LIST_STORE = 'educationDeleteListStore';

class EducationsListForUpdate extends React.Component {

  updateListData = (storeName, values) => {
    const {eduCreateList, eduUpdateList} = this.props;

    switch (storeName) {
      case EDUCATIONS_CREATE_LIST_STORE:
        eduCreateList[eduCreateList.findIndex(data => data.id === values.id)] = {...values};
        this.props.appStoreUpdateListData(storeName, eduCreateList);
        break;
      case EDUCATIONS_UPDATE_LIST_STORE:
        eduUpdateList[eduUpdateList.findIndex(data => data.id === values.id)] = {...values};
        this.props.appStoreUpdateListData(storeName, eduUpdateList);
        break;
      default:
        break;
    }
  };

  handleClick = (event) => {
    event.preventDefault();

    const {eduCreateList, user} = this.props;
    const id = `${new Date().getUTCMilliseconds()}`;
    const organization = user.role === rolesList.EDU
      ? {value: user.organization.id, label: user.organization.name}
      : form.SELECTOR_DEFAULT_VALUE;

    this.props.appStoreUpdateListData(EDUCATIONS_CREATE_LIST_STORE,
      [...eduCreateList, {...form.SPECIALIST_EDUCATION_FORM, id, organization}]
    );
  };

  handleDelete = (event, eduId) => {
    event.preventDefault();

    const {eduDeleteList, eduUpdateList} = this.props;

    this.props.appStoreUpdateListData(EDUCATIONS_DELETE_LIST_STORE, [...eduDeleteList, eduId]);
    this.props.appStoreUpdateListData(EDUCATIONS_UPDATE_LIST_STORE, eduUpdateList.filter(edu => edu.id !== eduId));
  };

  renderDeleteButton = (eduId) => {
    return (
      <Col xs={1}>
        <Clay padding={[3, 0]} textAlign="right">
          <Button onlyIcon onClick={(event) => this.handleDelete(event, eduId)}>
            <i className="fas fa-trash-alt" />
          </Button>
        </Clay>
      </Col>
    );
  };

  canDelete = (orgId) => {
    const userRole = getCookie('role');
    const {organization} = this.props.user;
    const userOrgId = organization ? organization.id : null;

    return userRole === rolesList.EDU && userOrgId ? userOrgId === orgId : true;
  };

  renderCollapseHeader = (edu) => {
    const {id, date_start, date_end, organization, education_type, speciality} = edu;
    const education = date_start
      ? date_end
        ? `${date_start} - ${date_end}`
        : `${date_start} - по настоящее время`
      : null;

    return (
      <Clay className="app-container">
        <Row>
          <Col xs={11}>
            <Row>
              <Col xs={3}>
                <DisplayData data={organization.value && organization.label} label="Организация" />
              </Col>
              <Col xs={3}>
                <DisplayData data={education} label="Период обучения" />
              </Col>
              <Col xs={3}>
                <DisplayData data={education_type && education_type.label} label="Образование" />
              </Col>
              <Col xs={3}>
                <DisplayData data={speciality && speciality.label} label="Специальность" />
              </Col>
            </Row>
          </Col>
          <AccessControl permission={permission.EDUCATION_DELETE} accessCheck={this.canDelete(organization.value)}>
            {this.renderDeleteButton(id)}
          </AccessControl>
        </Row>
      </Clay>
    );
  };

  renderEduList = () => {
    const {eduCreateList, eduUpdateList} = this.props;

    if (eduUpdateList.length) {
      return eduUpdateList.map((edu, index) => (
        <StaffCollapse key={index}
                       isOpen={index === 0}
                       collapseHeader={this.renderCollapseHeader(edu)}
                       collapseBody={<EducationUpdate edu={edu}
                                                      key={edu.id}
                                                      saveForm={this.updateListData}
                                                      storeName={EDUCATIONS_UPDATE_LIST_STORE} />} />
      ));
    }
    else if (!eduCreateList.length) {
      return (
        <StaffDisplayCard>
          Отсутствует информация об образовании
        </StaffDisplayCard>
      );
    }
  };

  render() {
    const {eduUpdateList} = this.props;

    if (!eduUpdateList) {
      return (
        <StaffDisplayCard>
          Отсутствует информация об образовании
        </StaffDisplayCard>
      );
    }

    return (
      <Clay padding={[6, 5, 0]}>
        {this.renderEduList()}

        <EducationCreate storeName={EDUCATIONS_CREATE_LIST_STORE} saveForm={this.updateListData} />

        <AccessControl permission={permission.EDUCATION_CREATE}>
          <Clay padding={[3, 0]}>
            <Button color="primary" onClick={(event) => this.handleClick(event)}>
              Добавить
            </Button>
          </Clay>
        </AccessControl>
      </Clay>
    );
  }
}

const mapStateToProps = (state) => {
  const eduCreateList = state.appStore[EDUCATIONS_CREATE_LIST_STORE];
  const eduUpdateList = state.appStore[EDUCATIONS_UPDATE_LIST_STORE];
  const eduDeleteList = state.appStore[EDUCATIONS_DELETE_LIST_STORE];

  return {
    user: state.user,
    eduCreateList: eduCreateList ? eduCreateList.storeListData : null,
    eduUpdateList: eduUpdateList ? eduUpdateList.storeListData : null,
    eduDeleteList: eduDeleteList ? eduDeleteList.storeListData : null
  };
};

const mapDispatchToProps = {
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter((EducationsListForUpdate)));