import React from 'react';
import {Clay} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import DisplayData from '../../layouts/DisplayData';

class OrganizationReadDataHeader extends React.Component {

  render() {
    const {orgDisplayData} = this.props;

    if (!orgDisplayData) {
      return null;
    }

    const {
      name, category_display
    } = orgDisplayData;

    return (
      <Clay padding={[3,5,3,5]} bgColor="bg">
        <DisplayData label="Сокращённое наименование" data={name} />
        <DisplayData label="Категория организации" data={category_display} />
      </Clay>
    );
  }
}

OrganizationReadDataHeader.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    orgDisplayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(OrganizationReadDataHeader);