import {formValueSelector, reduxForm, change} from 'redux-form';
import {connect} from 'react-redux';
import SpecialistCreateForm from './SpecialistCreateForm';
import * as form from './formConstructor';
import validate from './formValidate';
import {withRouter} from 'react-router-dom';
import {log} from '@n3/kit';
import * as messages from '../../../constants/messages';

const SpecialistCreateFormContainer = reduxForm({
    validate,
    form: form.FORM_NAME,
    enableReinitialize: true
  },
  {change})(SpecialistCreateForm);

const selector = formValueSelector(form.FORM_NAME);

const mapStetToProps = (state) => {
  return {
    [form.WORK]: selector(state, form.WORK),
    [form.RANK]: selector(state, form.RANK),
    [form.NATIONALITY]: selector(state, form.NATIONALITY),
    onSubmitFail: () => {
      log({
          title: 'Ошибка',
          message: messages.SAVE_ERROR,
          color: 'danger',
          timeout: '5000'}
        );
    },
    userRole: state.app.userRole,
    initialValues: state.appForms[form.FORM_NAME]
  };
};

export default connect(mapStetToProps)(withRouter(SpecialistCreateFormContainer));