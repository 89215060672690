import React from 'react';
import {Field} from 'redux-form';
import {Row, Col, Button, Clay} from '@n3/kit';

import * as form from './formConstructor';
import StaffInput from '../../form/StaffInput';
import StaffSelector from '../../form/StaffSelector';
import StaffInputMask from '../../form/StaffInputMask';
import StaffDatepicker from '../../form/StaffDatepicker';
import AccessControl from '../../accessControl/AccessControl';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import {GENDER_VALUES} from '../../../constants/selectorsValues';
import * as permission from '../../../constants/permissionsList';
import * as dictionaries from '../../../constants/dictionariesSource';

class SpecialistCreateForm extends React.Component {

  handleChange = (name, value) => {
    this.props.change(name, value);
  };

  render() {
    const {handleSubmit, handleCancelClick, rank, nationality} = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Clay padding={[2, 0, 2, 0]}>
          <Row>
            <Col xs={4}>
              <Field label="Фамилия"
                    name={form.LAST_NAME}
                    isRequired={true}
                    component={StaffInput} />
            </Col>

            <Col xs={4}>
              <Field label="Имя"
                    name={form.FIRST_NAME}
                    isRequired={true}
                    component={StaffInput} />
            </Col>

            <Col xs={4}>
              <Field label="Отчество"
                    name={form.SECOND_NAME}
                    component={StaffInput} />
            </Col>
          </Row>
        </Clay>

        <Clay padding={[2, 0, 2, 0]}>
          <Row>
            <Col xs={4}>
              <Field label="Гражданство"
                     name={form.NATIONALITY}
                     isRequired={true}
                     selectorValue={nationality}
                     component={StaffSelectorAsync}
                     dataSource={dictionaries.COUNTRIES}
                     handleChange={this.handleChange} />
            </Col>

            <Col xs={4}>
              <Field label="Пол"
                     name={form.GENDER}
                     isRequired={true}
                     options={GENDER_VALUES}
                     component={StaffSelector}
                     handleChange={this.handleChange} />
            </Col>

            <Col xs={4}>
              <Field label="Дата рождения"
                     isRequired={true}
                     name={form.BIRTH_DAY}
                     component={StaffDatepicker}
                     handleChange={this.handleChange} />
            </Col>
          </Row>
        </Clay>

        <Clay padding={[2, 0, 2, 0]}>
          <Row>
            <Col xs={4}>
              <Field label="СНИЛС"
                    isRequired={true}
                    name={form.SNILS}
                    component={StaffInputMask}
                    inputMask={form.SNILS_MASK} />
            </Col>

            <AccessControl permission={permission.SPECIALIST_CREATE_ADD_WORK}>
              <Col xs={4}>
                <Field name={form.RANK}
                      selectorValue={rank}
                      isRequired={true}
                      component={StaffSelectorAsync}
                      dataSource={dictionaries.RANKS}
                      label="Должность в Вашей организации"
                      handleChange={this.handleChange} />
              </Col>

              <Col xs={4}>
                <Field label="Дата начала работы"
                      isRequired={true}
                      name={form.DATE_START_WORK}
                      component={StaffDatepicker}
                      handleChange={this.handleChange} />
              </Col>
            </AccessControl>

            <AccessControl permission={permission.SPECIALIST_CREATE_ADD_EDUCATION}>
              <Col xs={4}>
                <Field label="Дата начала обучения"
                      component={StaffDatepicker}
                      isRequired={true}
                      name={form.DATE_START_EDUCATION}
                      handleChange={this.handleChange} />
              </Col>
            </AccessControl>
          </Row>
        </Clay>

        <Clay margin={[3, 0, 0, 0]} className="app-container__row">
          <Button type="button" onClick={handleCancelClick}>
            Отмена
          </Button>

          <Button type="submit" color="primary">
            Сохранить
          </Button>
        </Clay>
      </form>
    );
  }
}

export default SpecialistCreateForm;