import React from 'react';
import {Clay} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import TitleWrap from '../../layouts/TitleWrap';
import DisplayData from '../../layouts/DisplayData';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import {mapServerArrayToOneString} from '../../../services/mapper';

class SpecialistDisplayData extends React.Component {

  renderRegistration = () => {
    const {region_name, registration_address, address_uas_name, apartment} = this.props.displayData;

    if (address_uas_name && address_uas_name.length) {
      return `${address_uas_name}, квартира ${apartment ? apartment : '-'}`;
    }
    else if (registration_address) {
      return `${region_name ? region_name + ',' : ''} ${registration_address}`;
    }
  };

  render() {
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    const {
      phone, fax, email, website, residence_address, profession, technology, access_work_type, grade_name,
      disability_display, phone_additory, okses, complex_projects
    } = displayData;

    return (
      <Clay padding={[0, 0, 3]}>
        <TitleWrap>
          Контакты
        </TitleWrap>
        <StaffDisplayCard>
          <Clay className="grid grid__3 app-container__break-word">
            <Clay>
              <DisplayData data={phone} label='Телефон' />
              <DisplayData data={email} label='Email' />
            </Clay>
            <Clay>
              <DisplayData data={phone_additory} label='Дополнительный телефон' />
              <DisplayData data={fax} label='Факс' />
            </Clay>
            <DisplayData data={website} label='Сайт' />
          </Clay>
        </StaffDisplayCard>

        <TitleWrap>
          Адрес
        </TitleWrap>
        <StaffDisplayCard>
          <Clay className="grid grid__2 app-container__break-word">
              <DisplayData data={this.renderRegistration()} label='Адрес регистрации' />
              <DisplayData label='Адрес проживания'
                           data={residence_address ? residence_address : 'Совпадает с адресом регистрации'} />
          </Clay>
        </StaffDisplayCard>

        <TitleWrap>
          Информация
        </TitleWrap>
        <StaffDisplayCard>
          <Clay className="grid grid__2">
              <DisplayData data={mapServerArrayToOneString(profession)} label='Профессии' />

              <Clay className="grid grid__2">
                <DisplayData data={grade_name} label='Разряд' />
                <DisplayData data={disability_display} label='Инвалидность' />
              </Clay>
          </Clay>

          <Clay className="grid grid__2">
              <DisplayData data={mapServerArrayToOneString(technology)} label='Технологии' />
              <DisplayData data={mapServerArrayToOneString(access_work_type)} label='Допуск к видам работ' />
          </Clay>

          <Clay className="grid grid__2">
              <DisplayData data={okses.filter(elem => elem.length).join(', ')}
                           label='Объекты капитального строительства' />
              <DisplayData data={complex_projects.filter(elem => elem.length).join(', ')} label='Комплексные проекты' />
          </Clay>
        </StaffDisplayCard>
      </Clay>
    );
  }
}

SpecialistDisplayData.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(withRouter(SpecialistDisplayData));