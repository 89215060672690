import React, {Component} from 'react';
import {Clay, Col, Row} from '@n3/kit';
import {Field} from 'redux-form';

import StaffInput from '../../form/StaffInput';
import * as form from '../create/formConstructor';
import StaffInputMask from '../../form/StaffInputMask';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import validator from './formValidate';

class FormContacts extends Component {

  componentDidMount() {
    const {formValues} = this.props;

    formValues && Object.keys(validator(formValues)).forEach(field => {
      this.props.change('_validationHack', Date.now());
      this.props.touch(form.FORM_NAME, field);
    });
  }

  handelCancelClick = (event) => {
    event.preventDefault();

    const specId = this.props.match.params.id;
    const path = `/organizations/read/${specId}/`;

    this.props.history.push(path);
  };

  render() {
    return (
      <StaffDisplayCard>
        <Clay padding={[2, 0, 0, 0]} />
        <Row>
          <Col xs={3}>
            <Field label="Телефон"
                   name={form.PHONE}
                   component={StaffInputMask}
                   inputMask={form.PHONE_MASK} />
          </Col>

          <Col xs={3}>
            <Field label="E-mail"
                   name={form.EMAIL}
                   component={StaffInput} />
          </Col>

          <Col xs={3}>
            <Field label="Сайт"
                   name={form.WEBSITE}
                   component={StaffInput} />
          </Col>

          <Col xs={3}>
            <Field label="Режим работы"
                   name={form.WORK_SCHEDULE}
                   component={StaffInput} />
          </Col>
        </Row>

        <Clay padding={[4, 0, 2, 0]}>
          <Row>
            <Col xs={3}>
              <Field label="Юридический адрес"
                     name={form.JURISTIC_ADDRESS}
                     component={StaffInput} />
            </Col>

            <Col xs={3}>
              <Field label="Фактический адрес"
                     name={form.FACT_ADDRESS}
                     component={StaffInput} />
            </Col>

            <Col xs={3}>
              <Field label="Почтовый адрес"
                     name={form.POSTAL_ADDRESS}
                     component={StaffInput} />
            </Col>

            <Col xs={3}>
              <Field label="Руководитель"
                     name={form.MANAGER}
                     component={StaffInput} />
            </Col>
          </Row>
        </Clay>

      </StaffDisplayCard>
    );
  }
}

export default FormContacts;