import React, {Component} from 'react';
import {SearchInput} from '@n3/kit';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {appStoreGetListData} from '../../store/appStore/actions';
import {mapFiltersToStr} from '../../services/filters';

class StaffSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: ''
    };
  }

  handleChange = (value) => {
    const storeName = this.props.storeName;
    const dataSource = this.props.dataSource;
    const storeFilters = this.props.storeFilters;
    const filteredStr = mapFiltersToStr(storeFilters);

    const request = filteredStr
      ? `${dataSource}${filteredStr}&q=${value}`
      : `${dataSource}?q=${value}`;

    this.setState({value});
    this.props.appStoreGetListData(storeName, request);
  };

  render() {
    const {value} = this.state;
    const {dataSource} = this.props;

    if (!dataSource) {
      return null;
    }

    return (
      <SearchInput value={value}
                   placeholder="Поиск"
                   onValueChange={this.handleChange} />
    );
  }
}

StaffSearch.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    dataSource: store ? store.storeDataSource : null,
    storeFilters: store ? store.storeFilters : null
  };
};

const mapDispatchToProps = {
  appStoreGetListData
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffSearch);