import React, {Component} from 'react';
import {Field} from 'redux-form';
import {Clay} from '@n3/kit';

import InputWrap from '../../form/InputWrap';
import StaffInput from '../../form/StaffInput';
import * as form from '../create/formConstructor';

class FormHeader extends Component {
  render() {
    return (
      <Clay padding={[2, 5, 2, 0]}>
        <InputWrap size={6}>
          <Field label="Сокращённое наименование"
                 isRequired={true}
                 name={form.NAME}
                 component={StaffInput} />
        </InputWrap>
      </Clay>
    );
  }
}

export default FormHeader;