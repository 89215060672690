export const API = '/vacancy/';

export const FORM_NAME = 'vacancyUpdateForm';

export const NAME = 'name';
export const DEMAND = 'demand';
export const GENDER = 'gender';
export const STATUS = 'status';
export const SALARY_TO = 'salary_to';
export const PROFESSION = 'profession';
export const DATE_CLOSE = 'date_close';
export const TECHNOLOGY = 'technology';
export const DESCRIPTION = 'description';
export const SALARY_FROM = 'salary_from';
export const NATIONALITY = 'nationality';
export const DATE_CREATED = 'date_created';
export const ORGANISATION = 'organization';
export const EXPERIENCE_TO = 'experience_to';
export const STATUS_DISPLAY = 'status_display';
export const EXPERIENCE_FROM = 'experience_from';
export const ACCESS_WORK_TYPE = 'access_work_type';
export const DESCRIPTION_TERMS = 'description_terms';
export const ADDITIONAL_CLAIMS = 'additional_claims';
export const AVAILABILITY = 'availability_handicapped';

export const SELECTOR_DEFAULT_VALUE = {value: null, label: 'Выберите...'};

export const VACANCY_UPDATE_FORM = {
  [NAME]: null,
  [DEMAND]: null,
  [STATUS]: null,
  [TECHNOLOGY]: [],
  [SALARY_TO]: null,
  [DESCRIPTION]: '',
  [DATE_CLOSE]: null,
  [SALARY_FROM]: null,
  [ORGANISATION]: null,
  [DATE_CREATED]: null,
  [EXPERIENCE_TO]: null,
  [AVAILABILITY]: false,
  [ACCESS_WORK_TYPE]: [],
  [STATUS_DISPLAY]: null,
  [EXPERIENCE_FROM]: null,
  [DESCRIPTION_TERMS]: '',
  [ADDITIONAL_CLAIMS]: '',
  [GENDER]: SELECTOR_DEFAULT_VALUE,
  [PROFESSION]: SELECTOR_DEFAULT_VALUE,
  [NATIONALITY]: SELECTOR_DEFAULT_VALUE
};