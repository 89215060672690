import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Table, Tbody, Td, Th, Thead} from '@n3/kit';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {NON_BREAKING_SPACE} from '../../../constants/symbolsList';

class VacancyCreateDemandTable extends Component {

  renderLink = (id) => {
    const url = this.props.match.url;
    return <Link to={`${url}/create/${id}`}>Создать вакансию</Link>;
  };

  renderTableBody = () => {
    const demandsList = this.props.listData;
    return demandsList.map(demand => {
      return (
        <tr key={demand.id}>
          <Td>{demand.rank.name}</Td>
          <Td>{demand.author.full_name}</Td>
          <Td>{demand.date_created}</Td>
          <Td>{this.renderLink(demand.id)}</Td>
        </tr>
      );
    });
  };

  render() {
    const {listData} = this.props;
    const dateCreateHeader = `Дата${NON_BREAKING_SPACE}создания`;

    if (!listData) {
      return null;
    }

    return (
      <Table className='table'>
        <Thead>
        <tr>
          <Th>Должность</Th>
          <Th>Автор</Th>
          <Th>{dateCreateHeader}</Th>
          <Th></Th>
        </tr>
        </Thead>

        <Tbody>
        {this.renderTableBody()}
        </Tbody>
      </Table>
    );
  }
}

VacancyCreateDemandTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null
  };
};

export default connect(mapStateToProps)(withRouter(VacancyCreateDemandTable));