export const TOO_OLD = 'Слишком давняя дата';
export const ONLY_WHOLE = 'Только целые числа';
export const SAVE_ERROR = 'Ошибка при сохранении';
export const SAVE_SUCCESS = 'Изменения сохранены';
export const DELETE_ERROR = 'Ошибка при удалении';
export const INVALID_DATA = 'Некорректные данные';
export const WRONG_WEBSITE = 'Некорректный адрес';
export const WRONG_PHONE = 'Неверный номер телефона';
export const NOT_EMPTY = 'Поле не должно быть пустым';
export const MAX_LENGTH_200 = 'Максимум 200 символов';
export const MAX_LENGTH_1000 = 'Максимум 1000 символов';
export const PASSWORDS_NOT_EQUAL = 'Пароли не совпадают';
export const NOT_BE_LESS_ZERO = 'Не может быть меньше нуля';
export const WRONG_EMAIL = 'Неверный адрес электронной почты';
export const NOT_BE_LESS_MIN = 'Не может быть меньше минимума';
export const SERVER_ERROR = 'Упс!!! Что-то пошло не так... =(';
export const NOT_BE_MORE_MAX = 'Не может быть больше максимума';
export const NO_ATTACHMENTS = 'Хотя бы один файл должен быть загружен';
export const INVALID_MESSAGE_OR_PASSWORD = 'Неверный логин или пароль';
export const PASSWORDS_MUST_NOT_EQUAL = 'Старый и новый пароли не должны совпадать';
export const PASSWORD_MUST_CONTAIN = 'Пароль должен содержать не менее 6 символов, строчные и прописные буквы, и цифры';
