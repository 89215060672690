import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Paginator} from '@n3/kit';

import {appStoreGetFilteredData} from '../../store/appStore/actions';
import {mapFiltersToStr} from '../../services/filters';

class StaffPaginator extends Component {
  setPage = (page) => {
    const filters = this.props.filters;
    const storeName = this.props.storeName;
    const dataSource = this.props.dataSource;
    const request = `${dataSource}${mapFiltersToStr(filters, page)}`;

    this.props.appStoreGetFilteredData(storeName, request, page);
  };

  render() {
    const {totalPages, currentPage} = this.props;

    if (!totalPages || !currentPage) {
      return null;
    }

    return (
      <Paginator page={currentPage}
                 pageCount={totalPages}
                 onPageChange={this.setPage} />
    );
  }
}

StaffPaginator.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const demandStore = state.appStore[storeName];

  if (demandStore) {
    return {
      filters: demandStore.storeFilters,
      dataSource: demandStore.storeDataSource,
      totalPages: demandStore.storeTotalPages,
      currentPage: demandStore.storeCurrentPage
    };
  }

  return {};
};

const mapDispatchToProps = {
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffPaginator);