import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Col, Clay, Row} from '@n3/kit';

import EducationFormContainer from '../update/EducationFormContainer';
import {appStoreUpdateListData} from '../../../store/appStore/actions';
import {getCookie} from '../../../utils/Cookie';
import * as rolesList from '../../../constants/rolesList';

class EducationCreate extends React.Component {

  saveForm = (values) => {
    const {storeName} = this.props;
    this.props.saveForm(storeName, values);
  };

  updateForm = () => {
    const userRole = getCookie('role');
    return userRole !== rolesList.EDU;
  };

  handleDelete = (event, formId) => {
    event.preventDefault();
    const {storeName, eduList} = this.props;
    this.props.appStoreUpdateListData(storeName, [...eduList.filter(edu => edu.id !== formId)]);
  };

  renderCreateEduForms = () => {
    const {eduList} = this.props;

    return eduList.map(edu => (
      <Clay key={edu.id} bgColor="bg" margin={[0, 0, 2, 0]} padding={[3, 3, 0, 6]}>
        <Row>
          <Col xs={11}>
            <EducationFormContainer createForm={true}
                                    initialValues={edu}
                                    saveForm={this.saveForm}
                                    updateForm={true}
                                    form={`eduCreateForm--${edu.id}`} />
          </Col>
          <Col xs={1}>
            <Clay padding={[3, 0]}>
              <Button onlyIcon onClick={(event) => this.handleDelete(event, edu.id)}>
                <i className="fas fa-trash-alt" />
              </Button>
            </Clay>
          </Col>
        </Row>
      </Clay>
    ));
  };

  render() {
    return (
      <Clay padding={[2, 0]}>
        {this.renderCreateEduForms()}
      </Clay>
    );
  }
}

EducationCreate.propTypes = {
  saveForm: PropTypes.func,
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    user: state.user,
    eduList: store ? store.storeListData : null
  };
};

const mapDispatchToProps = {
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(EducationCreate);