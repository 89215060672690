export const STATUS_VALUES = [
  {
    value: null,
    label: 'Все'
  },
  {
    value: 'opened',
    label: 'Открыто'
  },
  {
    value: 'closed',
    label: 'Закрыто'
  }
];

export const CATEGORY_VALUES = [
  {
    value: null,
    label: 'Все'
  },
  {
    value: 'educational_institution',
    label: 'Образовательное учреждение'
  },
  {
    value: 'contractor_entity',
    label: 'Подрядная организация'
  },
  {
    value: 'staffing_agency',
    label: 'Кадровое агенство'
  }
];

export const CATEGORY_VALUES_WITH_UNDEFINED = [
  {
    value: null,
    label: 'Выберите...'
  },
  {
    value: 'educational_institution',
    label: 'Образовательное учреждение'
  },
  {
    value: 'contractor_entity',
    label: 'Подрядная организация'
  },
  {
    value: 'staffing_agency',
    label: 'Кадровое агенство'
  }
];

export const GENDER_VALUES = [
  {
    value: null,
    label: 'Выберите...'
  },
  {
    value: 'male',
    label: 'Мужской'
  },
  {
    value: 'female',
    label: 'Женский'
  }
];

export const GENDER_VALUES_WITH_UNDEFINED = [
  {
    value: '',
    label: '-----'
  },
  {
    value: 'male',
    label: 'Мужской'
  },
  {
    value: 'female',
    label: 'Женский'
  }
];

export const EMPLOYMENT_STATUS_VALUES = [
  {
    value: null,
    label: 'Все'
  },
  {
    value: 'employee',
    label: 'Сотрудник'
  },
  {
    value: 'student',
    label: 'Учащийся'
  },
  {
    value: 'jobseeker',
    label: 'Соискатель'
  }
];

export const EDUCATION_TYPE_VALUES = [
  {
    value: null,
    label: 'Все'
  },
  {
    value: 'higher',
    label: 'Высшее'
  },
  {
    value: 'secondary',
    label: 'Среднее'
  },
  {
    value: 'additional',
    label: 'Дополнительное'
  }
];

export const DISABILITY_VALUES = [
  {
    value: '',
    label: 'Нет'
  },
  {
    value: 'first_group',
    label: '1 группа'
  },
  {
    value: 'second_group',
    label: '2 группа'
  },
  {
    value: 'third_group',
    label: '3 группа'
  }
];

export const STUDY_MODES_VALUES = [
  {
    value: 'intramunal',
    label: 'Очная'
  },
  {
    value: 'in_ex_tramunal',
    label: 'Очно-заочная'
  },
  {
    value: 'extramunal',
    label: 'Заочная'
  },
  {
    value: 'external',
    label: 'Экстернат'
  }
];

export const DOCUMENTS_STATUSES_VALUES = [
  {value: null, label: 'Все'},
  {value: 'archive', label: 'Архивный'},
  {value: 'active', label: 'Действующий'}
];