import * as form from './formConstructor';
import * as messages from '../../../constants/messages';

export default (values) => {
  const errors = {};

  if (!values[form.NAME]) {
    errors[form.NAME] = messages.NOT_EMPTY;
  }
  if (!values[form.FULL_NAME]) {
    errors[form.FULL_NAME] = messages.NOT_EMPTY;
  }
  if (!values[form.INN]) {
    errors[form.INN] = messages.NOT_EMPTY;
  }
  else if (values[form.INN].includes('_')) {
    errors[form.INN] = messages.INVALID_DATA;
  }
  if (!values[form.CATEGORY] || !values[form.CATEGORY].value) {
    errors[form.CATEGORY] = messages.NOT_EMPTY;
  }
  if (!values[form.OWNERSHIP_TYPE] || !values[form.OWNERSHIP_TYPE].value) {
    errors[form.OWNERSHIP_TYPE] = messages.NOT_EMPTY;
  }

  return errors;
}