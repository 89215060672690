import React from 'react';

import {Clay} from '@n3/kit';

const Footer = () => {
  return (
    <Clay className="app-container__row_flex-start app-container__border_top">
      <Clay padding={[3, 5]}>
        <Clay font="caption" color="gray4">
          &copy; Комитет по строительству Санкт-Петербурга, 2019
        </Clay>
        <Clay font="caption" color="gray4">
          Все права защищены
        </Clay>
      </Clay>
    </Clay>
  );
};

export default Footer;
