import React from 'react';
import {Clay} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import DisplayData from '../../layouts/DisplayData';
import userPic from '../../../assets/images/user.png';
import AccessControl from '../../accessControl/AccessControl';
import {SPECIALIST_READ_EMPLOYMENT_STATUS, SPECIALIST_READ_ACTIVE} from '../../../constants/permissionsList';

class SpecialistDisplayDataHeader extends React.Component {

  renderUserPic = (photo) => {
    if (photo) {
      return (
        <Clay textAlign="center"
              bgColor="gray5"
              className="specialist_header__photo">
          <img src={photo} alt="" style={{width: 'auto', height: '100%'}} />
        </Clay>
      );
    }
    else {
      return (
        <Clay textAlign="center"
              bgColor="primary4"
              className="app-container__border specialist_header__photo">
          <img src={userPic} alt=""
               style={{width: 'auto', height: '90%'}} />
        </Clay>
      );
    }
  };

  render() {
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    const {
      first_name, last_name, second_name, birth_day, nationality_name,
      gender_display, snils, active, employment_status_display, photo
    } = displayData;

    return (
      <Clay padding={[3, 5]} bgColor="bg" className="app-container__row_flex-start">
        {this.renderUserPic(photo)}
        <Clay margin={[0, 5]} className="app-container">
          <Clay className="specialist_header__column">
            <DisplayData data={last_name} label='Фамилия' />
            <DisplayData data={first_name} label='Имя' />
            <DisplayData data={second_name} label='Отчество' />
            <DisplayData data={birth_day} label='Дата рождения' />
          </Clay>

          <Clay className="specialist_header__column">
            <DisplayData data={nationality_name} label='Гражданство' />
            <DisplayData data={gender_display} label='Пол' />
            <DisplayData data={snils} label='СНИЛС' />
          </Clay>

          <AccessControl permission={SPECIALIST_READ_ACTIVE}>
            <Clay className="specialist_header__column">
              <AccessControl permission={SPECIALIST_READ_EMPLOYMENT_STATUS}>
                <DisplayData data={employment_status_display} label='Статус занятости' />
              </AccessControl>
              <DisplayData label='Тип пользователя'
                           data={active ? 'Активный пользователь' : 'Неактивный пользователь'} />
            </Clay>
          </AccessControl>

        </Clay>
      </Clay>
    );
  }
}

SpecialistDisplayDataHeader.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(withRouter(SpecialistDisplayDataHeader));