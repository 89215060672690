import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change, formValueSelector, reduxForm} from 'redux-form';
import {withRouter} from 'react-router-dom';
import {Field} from 'redux-form';
import {Clay, Col, Row, Button, log} from '@n3/kit';
import StaffInput from '../../form/StaffInput';
import TitleWrap from '../../layouts/TitleWrap';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import * as form from './formConstructor';
import validate from './formValidate';
import {mapErrorMessage} from '../../../services/mapper';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import api from '../../../API';
import * as messages from '../../../constants/messages';
import {getRequestUserProfile} from '../../../store/user/actions';
import ChangePasswordForm from '../changePassword/ChangePasswordForm';

class UserProfileUpdateForm extends Component {

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = (values) => {
    const data = mapValuesToSend(values);
    const request = '/users/profile/';

    return api.request(request, {method: 'patch', data})
      .then(() => {
        const token = this.props.token;
        this.props.getRequestUserProfile(token);

        const path = '/';
        this.props.history.push(path);
        this.renderSuccessLog();
      })
      .catch((error) => {
        const errorMessage = error.response.status === 400 ? messages.SAVE_ERROR : mapErrorMessage(error);
        this.renderErrorLog(errorMessage);
      });
  }

  render() {
    const {handleSubmit, formBody} = this.props;

    if (!formBody) {
      return null;
    }

    return (
      <Clay>
        <form onSubmit={handleSubmit(this.submit)}>
          <Clay padding={[0, 5, 0, 5]}>
            <TitleWrap>
              Основные сведения
            </TitleWrap>
            <StaffDisplayCard>
              <Row>
                <Col xs={4}>
                  <Clay padding={[2, 0, 2, 0]}>
                    <Field  label="Фамилия"
                            isRequired={true}
                            name={form.LAST_NAME}
                            component={StaffInput} />
                  </Clay>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <Clay padding={[2, 0, 2, 0]}>
                    <Field  label="Имя"
                            isRequired={true}
                            name={form.FIRST_NAME}
                            component={StaffInput} />
                  </Clay>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <Clay padding={[2, 0, 2, 0]}>
                    <Field label="Отчество"
                          isRequired={false}
                          name={form.SECOND_NAME}
                          component={StaffInput} />
                  </Clay>
                </Col>
              </Row>
            </StaffDisplayCard>
          </Clay>
          <Clay padding={[3, 5, 0, 5]} className="app-container__row">
            <Button type="reset" onClick={()=>{this.props.history.push('/');}}>
              Отмена
            </Button>
            <Button type="submit" color="primary">
              Сохранить изменения
            </Button>
          </Clay>
        </form>
        <ChangePasswordForm token={this.props.token} />
      </Clay>
    );
  }
}

const selector = formValueSelector(form.FORM_NAME);

const mapStateToProps = (state) => {

  return {
    formBody: {
      [form.FIRST_NAME]: selector(state, form.FIRST_NAME),
      [form.SECOND_NAME]: selector(state, form.SECOND_NAME),
      [form.LAST_NAME]: selector(state, form.LAST_NAME),
    },
    initialValues: state.appForms[form.FORM_NAME],
    user: state.user,
    token: state.app.userToken
  };
};

const mapDispatchToProps = {
  getRequestUserProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(reduxForm({
  validate,
  form: form.FORM_NAME,
  enableReinitialize: true
},
{change})(UserProfileUpdateForm)));