import React from 'react';
import {Button} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {DEMAND_READ_STORE} from './DemandRead';
import AccessControl from '../../accessControl/AccessControl';
import {DEMAND_CLOSE} from '../../../constants/permissionsList';
import {appStoreUpdateDisplayData} from '../../../store/appStore/actions';

class DemandReadControlButton extends React.Component {

  redirectToCreate = () => {
    const demandId = this.props.demandId;
    const path = `/vacancy/create/${demandId}`;
    this.props.history.push(path);
  };

  render() {
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    const {status} = displayData;

    return (
      <AccessControl permission={DEMAND_CLOSE} accessCheck={status === 'opened'}>
        <Button color="primary" onClick={this.redirectToCreate}>
          Создать вакансию
        </Button>
      </AccessControl>
    );
  }
}

DemandReadControlButton.propTypes = {
  storeName: PropTypes.string.isRequired,
  onDemandClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const store = state.appStore[DEMAND_READ_STORE];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

const mapDispatchToProps = {
  appStoreUpdateDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DemandReadControlButton));