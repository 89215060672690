import {connect} from 'react-redux';
import UserProfileRead from './UserProfileRead';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.app.userToken
  };
};

export default connect(mapStateToProps)(UserProfileRead);