import React, {Component} from 'react';
import {Clay} from '@n3/kit';

import {ORIENTATION_REPORT} from '../../../constants/pagesName';
import OrientationReportFilter from './OrientationReportFilter';
import OrientationReportTable from './OrientationReportTable';
import StaffPaginator from '../../layouts/StaffPaginator';
import StaffSearch from '../../layouts/StaffSearch';

export const ORIENTATION_REPORT_STORE = 'orientationReportStore';

class OrientationReport extends Component {

  componentWillMount() {
    const dataSource = '/reports/professions/vocational_orientation/';
    this.props.appStoreCreate(ORIENTATION_REPORT_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(ORIENTATION_REPORT_STORE);
  }

  componentDidUpdate() {
    const {dataSource, storeFilters} = this.props;

    if (storeFilters.length === 0) {
      this.props.appStoreGetListData(ORIENTATION_REPORT_STORE, dataSource);
    }
  }

  render() {
    return (
      <Clay padding={[3, 5]} className="app-container">
        <Clay font='h1' padding={[0, 0, 3]}>
          {ORIENTATION_REPORT}
        </Clay>

        <StaffSearch storeName={ORIENTATION_REPORT_STORE} />

        <Clay padding={[3, 0]} className="app-container__row_flex-start">
          <OrientationReportFilter storeName={ORIENTATION_REPORT_STORE} />
        </Clay>

        <OrientationReportTable storeName={ORIENTATION_REPORT_STORE} />

        <Clay padding={[3, 0, 0]}>
          <StaffPaginator storeName={ORIENTATION_REPORT_STORE} />
        </Clay>
      </Clay>
    );
  }
}

export default OrientationReport;