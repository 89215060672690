import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Clay, Col, Row} from '@n3/kit';

class InputWrap extends Component {
  render() {
    const {size} = this.props;

    return (
      <Clay paddingSequence={3}>
        <Row>
          <Col xs={size}>
            {this.props.children}
          </Col>
        </Row>
      </Clay>
    );
  }
}

InputWrap.propTypes = {
  size: PropTypes.number
};

export default InputWrap;