import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Clay, Row, Col} from '@n3/kit';

import WorkFormContainer from '../update/WorkFormContainer';
import {appStoreUpdateListData} from '../../../store/appStore/actions';

class WorkCreate extends React.Component {

  saveForm = (values) => {
    const {storeName} = this.props;
    this.props.saveForm(storeName, values);
  };

  handleDelete = (event, formId) => {
    event.preventDefault();
    const {storeName, worksList} = this.props;
    this.props.appStoreUpdateListData(storeName, [...worksList.filter(work => work.id !== formId)]);
  };

  renderCreateWorkForms = () => {
    const {worksList} = this.props;

    return worksList.map(work => (
      <Clay key={work.id} bgColor="bg" padding={[3, 3, 0, 6]} margin={[0, 0, 2, 0]}>
        <Row>
          <Col xs={11}>
            <WorkFormContainer updateForm={true}
                               createWork={true}
                               initialValues={work}
                               saveForm={this.saveForm}
                               form={`createWorkForm--${work.id}`} />
          </Col>
          <Col xs={1}>
            <Clay padding={[3, 0]}>
              <Button onlyIcon onClick={(event) => this.handleDelete(event, work.id)}>
                <i className="fas fa-trash-alt" />
              </Button>
            </Clay>
          </Col>
        </Row>
      </Clay>
    ));
  };

  render() {
    return (
      <Clay padding={[0, 0, 0, 0]} margin={[2, 0, 2, 0]}>
        {this.renderCreateWorkForms()}
      </Clay>
    );
  }
}

WorkCreate.propTypes = {
  saveForm: PropTypes.func,
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    worksList: store ? store.storeListData : null
  };
};

const mapDispatchToProps = {
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkCreate);