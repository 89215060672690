import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {change, formValueSelector, getFormValues, reduxForm} from 'redux-form';

import validate from './formValidate';
import * as form from './formConstructor';
import EducationForm from './EducationForm';

const EducationFormContainer = reduxForm({
    validate,
    enableReinitialize: true
  },
  {change})(EducationForm);

EducationFormContainer.propTypes = {
  saveForm: PropTypes.func,
  updateForm: PropTypes.bool,
  createForm: PropTypes.bool
};

const mapStetToProps = (state, ownProps) => {
  const formName = ownProps.form;

  const selector = formValueSelector(formName);

  return {
    [form.ID]: selector(state, form.ID),
    [form.COURSE]: selector(state, form.COURSE),
    [form.DEGREE]: selector(state, form.DEGREE),
    [form.STUDY_MODE]: selector(state, form.STUDY_MODE),
    [form.SPECIALITY]: selector(state, form.SPECIALITY),
    [form.ORGANIZATION]: selector(state, form.ORGANIZATION),
    [form.QUALIFICATION]: selector(state, form.QUALIFICATION),
    [form.SPECIALIZATION]: selector(state, form.SPECIALIZATION),
    [form.EDUCATION_TYPE]: selector(state, form.EDUCATION_TYPE),
    [form.EDUCATION_PROGRAM]: selector(state, form.EDUCATION_PROGRAM),

    formValues: getFormValues(formName)(state)
  };
};

export default connect(mapStetToProps)(EducationFormContainer);