import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';

import {PERMISSIONS_LIST} from '../../constants/permissionsList';

class AccessControl extends React.Component {
  hasPermission = () => {
    const {userRole, permission} = this.props;

    if (userRole) {
      const userPermissions = PERMISSIONS_LIST[userRole];
      return userPermissions.indexOf(permission) > -1;
    }
    return true;
  };

  render() {
    const {accessCheck, redirect} = this.props;
    const access = accessCheck === undefined ? true : accessCheck;

    if (this.hasPermission() && access) {
      return this.props.children;
    }
    else {
      if (redirect) {
        return <Redirect to={redirect} />;
      }
      else {
        return null;
      }
    }

  }
}

AccessControl.propTypes = {
  redirect: PropTypes.string,
  accessCheck: PropTypes.bool,
  permission: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    userRole: state.app.userRole
  };
};

export default connect(mapStateToProps)(AccessControl);