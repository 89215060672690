import React from 'react';
import {Clay, Button} from '@n3/kit';
import {getCookie} from '../../../utils/Cookie';

import PageHeader from '../../layouts/PageHeader';
import SpecialistsTable from './SpecialistsTable';
import StaffSearch from '../../layouts/StaffSearch';
import StaffPaginator from '../../layouts/StaffPaginator';
import SpecialistsListFilter from './SpecialistsListFilter';
import {SPECIALISTS_LIST} from '../../../constants/pagesName';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import SpecialistsListFilterRank from './SpecialistsListFilterRank';
import {PROFESSION, EMPLOYMENT_STATUS, ACTIVE} from './filterConstructor';
import {dataSourceList, SPECIALISTS} from '../../../constants/dataSourcesList';

export const SPECIALIST_STORE = 'specialistStore';

class SpecialistsList extends React.Component {

  componentDidMount() {
    const {dataSource} = this.props;

    if (!dataSource) {
      this.createStore();
    }
  }

  componentWillUnmount() {
    this.props.appStoreDelete(SPECIALIST_STORE);
    this.props.appFilterDelete({name: ACTIVE});
    this.props.appFilterDelete({name: PROFESSION});
    this.props.appFilterDelete({name: EMPLOYMENT_STATUS});
  }

  createStore = () => {
    this.userRole = getCookie('role');
    if (this.userRole) {
      const dataSource = dataSourceList[this.userRole][SPECIALISTS];
      this.props.appStoreCreate(SPECIALIST_STORE, dataSource);
    }
  };

  redirectToCreate = () => {
    this.props.history.push('/specialists/create/');
  };

  redirectToList = () => {
    this.props.history.push('/specialists/listForCrate/');
  };

  renderAddButton = () => {
    return (
      <Clay>
        <AccessControl permission={permission.SPECIALIST_CREATE_FROM_LIST}>
          <Button color="primary" onClick={this.redirectToCreate}>
            Добавить
          </Button>
        </AccessControl>

        <AccessControl permission={permission.SPECIALIST_UPDATE_CHANGE_STATUS}>
          <Button color="primary" onClick={this.redirectToList}>
            Добавить
          </Button>
        </AccessControl>
      </Clay>
    );
  };

  render() {
    const userRole = this.userRole;

    return (
      <AccessControl permission={permission.SPECIALIST_READ_LIST} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader title={SPECIALISTS_LIST[userRole]}
                      bgColor="gray5"
                      controlButton={this.renderAddButton()} />

          <Clay padding={[3, 5]}>
            <SpecialistsListFilter storeName={SPECIALIST_STORE} />

            <AccessControl permission={permission.SPECIALIST_READ_LIST_FILTER_RANK}>
              <SpecialistsListFilterRank storeName={SPECIALIST_STORE} />
            </AccessControl>

            <Clay padding={[0, 0, 2]} />

            <Clay padding={[2, 0, 0, 0]}>
              <StaffSearch storeName={SPECIALIST_STORE} />
            </Clay>

            <Clay padding={[0, 0, 6]} />

            <SpecialistsTable storeName={SPECIALIST_STORE} />

            <Clay padding={[0, 0, 2]} />

            <Clay padding={[2, 0, 0, 0]}>
              <StaffPaginator storeName={SPECIALIST_STORE} />
            </Clay>
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

export default SpecialistsList;