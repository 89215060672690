import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appStoreCreate, appStoreDelete, appStoreGetDisplayData } from '../../../store/appStore/actions';
import ReportChartData from './ReportChartData';



class ReportChart extends Component {

  componentDidMount() {
    this.createStore();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.dataSource){
      this.props.appStoreGetDisplayData(this.props.settings.storeName, this.props.settings.dataSource);
    }
  }

  componentWillUnmount() {
    this.props.appStoreDelete(this.props.settings.storeName);
  }

  createStore = () => {
    this.props.appStoreCreate(this.props.settings.storeName, this.props.settings.dataSource);
  };

  render() {
    return (
      <ReportChartData  storeName={this.props.settings.storeName} colors={this.props.settings.colors} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const store = state.appStore[ownProps.storeName];

  return {
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportChart);