export const mapFiltersToStr = (filters, page = null) => {
  const filterStr = filters
    .map(filter => filter.value ? `${filter.name}=${filter.value}` : null)
    .filter(field => !!field).join('&');

  const pageStr = page && `page=${page}`;

  return filterStr.length > 0 ?
    `?${filterStr}${pageStr ? `&${pageStr}` : ''}` :
    `${pageStr ? `?${pageStr}` : ''}`;
};

export const getFilterValue = (filters, filterName) => {
  return filters.filter(filter => filter.name === filterName)[0];
};