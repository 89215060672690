export const mapValuesToSend = (values) => {
  let dataToSend = {};

  for (let elem in values) {
    const value = values[elem];

    if (Array.isArray(value)) {
      dataToSend[elem] = value.map(i => i.value);
    }
    else if (value && typeof value === 'object') {
      dataToSend[elem] = value.value;
    }
    else {
      dataToSend[elem] = value;
    }
  }

  return dataToSend;
};

export const mapToFormData = (data) => {
  const formData = new FormData();
  for (let elem in data) {
    const value = data[elem];

    !Array.isArray(value) ?
      formData.append(`${elem}`, value) :
      value.forEach(value => formData.append(`${elem}`, value));
  }
  return formData;
};