export const APP_FORMS_STORE_CREATE = 'APP_FORMS_STORE_CREATE';
export const APP_FORMS_STORE_UPDATE = 'APP_FORMS_STORE_UPDATE';
export const APP_FORMS_STORE_DELETE = 'APP_FORMS_STORE_DELETE';

export const appFormsCreateStore = (formName, form) => {
  return {
    type: APP_FORMS_STORE_CREATE,
    payload: {formName, form}
  };
};

export const appFormsUpdateStore = (formName, form) => {
  return {
    type: APP_FORMS_STORE_CREATE,
    payload: {formName, form}
  };
};

export const appFormsDeleteStore = (formName) => {
  return {
    type: APP_FORMS_STORE_DELETE,
    payload: {formName}
  };
};