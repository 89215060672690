import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {change, formValueSelector, reduxForm} from 'redux-form';

import validate from './formValidate';
import * as form from './formConstructor';
import VacancyUpdateForm from './VacancyUpdateForm';
import {log} from '@n3/kit';
import * as messages from '../../../constants/messages';

VacancyUpdateForm.propTypes = {
  handleCancelClick: PropTypes.func.isRequired
};

const VacancyUpdateFormContainer = reduxForm({
    validate,
    form: form.FORM_NAME,
    enableReinitialize: true
  },
  {change})(VacancyUpdateForm);

const selector = formValueSelector(form.FORM_NAME);

const mapStateToProps = (state) => {
  return {
    [form.DEMAND]: selector(state, form.DEMAND),
    [form.GENDER]: selector(state, form.GENDER),
    [form.STATUS]: selector(state, form.STATUS),
    [form.DATE_CLOSE]: selector(state, form.DATE_CLOSE),
    [form.PROFESSION]: selector(state, form.PROFESSION),
    [form.TECHNOLOGY]: selector(state, form.TECHNOLOGY),
    [form.NATIONALITY]: selector(state, form.NATIONALITY),
    [form.ORGANISATION]: selector(state, form.ORGANISATION),
    [form.DATE_CREATED]: selector(state, form.DATE_CREATED),
    [form.AVAILABILITY]: selector(state, form.AVAILABILITY),
    [form.STATUS_DISPLAY]: selector(state, form.STATUS_DISPLAY),
    [form.ACCESS_WORK_TYPE]: selector(state, form.ACCESS_WORK_TYPE),
    onSubmitFail: () => {
      log({
          title: 'Ошибка',
          message: messages.SAVE_ERROR,
          color: 'danger',
          timeout: '5000'}
        );
    },
    initialValues: state.appForms[form.FORM_NAME]
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VacancyUpdateFormContainer));