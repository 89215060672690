import React from 'react';
import {Clay} from '@n3/kit';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import AccessControl from '../../accessControl/AccessControl';
import OrganizationUpdateForm from './OrganizationUpdateForm';
import * as permission from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';
import OrganizationUpdateMapData from './OrganizationUpdateMapData';
import {ORGANIZATION_READ_LIST} from '../../../constants/permissionsList';
import {ORGANIZATION_UPDATE, ORGANISATIONS_LIST} from '../../../constants/pagesName';
import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';

const ORGANIZATION_UPDATE_STORE = 'organizationUpdateStore';

class OrganizationUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1,
      breadCrumbs: [
        {
          url: `/organizations`,
          title: ORGANISATIONS_LIST
        },
        {
          url: null,
          title: ORGANIZATION_UPDATE
        }
      ]
    };
  }

  componentDidMount() {
    const orgId = this.props.match.params.id;
    const dataSource = `/organizations/${orgId}/`;

    this.props.appStoreCreate(ORGANIZATION_UPDATE_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(ORGANIZATION_UPDATE_STORE);
  }

  componentDidUpdate() {
    const {dataSource} = this.props;

    if (dataSource) {
      this.props.appStoreGetDisplayData(ORGANIZATION_UPDATE_STORE, dataSource);
    }
  }

  getBreadCrumbs = () => {
    const {breadCrumbs} = this.state;
    const role = getCookie('role');

    return checkPermission(role, ORGANIZATION_READ_LIST) ? breadCrumbs : null;
  };

  render() {

    return (
      <AccessControl permission={permission.ORGANIZATION_UPDATE} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader title={ORGANIZATION_UPDATE}
                      breadCrumbs={this.getBreadCrumbs()} />

          <OrganizationUpdateMapData storeName={ORGANIZATION_UPDATE_STORE}>
            <OrganizationUpdateForm />
          </OrganizationUpdateMapData>
        </Clay>
      </AccessControl>
    );
  }
}

const mapSateToProps = (state) => {
  const store = state.appStore[ORGANIZATION_UPDATE_STORE];

  return {
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapSateToProps, mapDispatchToProps)(withRouter(OrganizationUpdate));