export const mapServerArray = (array) => {
  return array.map(i => {return {value: i.id, label: i.name};});
};

export const mapServerObject = (object) => {
  if ('id' in object && 'name' in object) {
    return {value: object.id, label: object.name};
  }
  return object;
};

export const mapServerArrayToOneString = (arr) => {
  return arr.map(item => item.name).join(', ');
};

export const mapObjectToServer = (object) => {
  return {id: object.value, name: object.label};
};

export const mapErrorMessage = (error) => {
  let errors = [];
  const data = error.response.data;

  if (Array.isArray(data)) {
    errors = [...data];
  }
  else {
    for (let elem in data) {
      const value = data[elem];

      if (Array.isArray(value)) {
        errors = [
          ...errors, ...value.map(error => {
            if (typeof error === 'string') {
              return error;
            }
            else {
              const msg = Object.values(error);
              console.log(msg[0]);
              return msg[0] && msg[1] && msg[0].length === msg[1].length
                ? 'Заполните обязательные поля'
                : msg[0][0] === 'Это поле не может быть пустым' ? 'Заполните обязательное поле' : msg[0];
            }
          })
        ];
      }
      else {
        const data2 = value;
        if (Array.isArray(data2)) {
          errors = [...data2];
        }
        else {
          for (let elem in data2) {
            const value1 = data2[elem];
            if (Array.isArray(value1)) {
              errors = [
                ...errors, ...value1.map(error => {
                  if (typeof error === 'string') {
                    return error;
                  }
                  else {
                    return Object.values(error)[0];
                  }
                })
              ];
            }
          }
        }
      }
    }
  }

  return errors.length && errors.join('; ');
};