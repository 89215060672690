import React, {Component} from 'react';
import {Clay, Table, Tbody, Td, Th, Thead, LoadingArea} from '@n3/kit';
import {EMPTY_VALUE_SYMBOL, NON_BREAKING_SPACE} from '../../../constants/symbolsList';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import * as permission from '../../../constants/permissionsList';
import AccessControl from '../../accessControl/AccessControl';
import StaffOrdering from '../../layouts/StaffOrdering';
import * as filter from './filterConstructor';

class SpecialistsTable extends Component {

  redirectToRead = (spec) => {
    const url = `/specialists/read/${spec.employment_status}/${spec.id}/1`;
    this.props.history.push(url);
  };

  renderTableBody = () => {
    const specList = this.props.listData;
    return specList.map(spec => {
      return (
        <tr key={spec.id}
            className="app-container__cursor_pointer"
            onClick={() => this.redirectToRead(spec)}>

          <Td hasWordBreak>{spec.full_name}</Td>

          <AccessControl permission={permission.SPECIALIST_READ_LIST_STATUS}>
            <Td>{spec.employment_status_display}</Td>
          </AccessControl>

          <AccessControl permission={permission.SPECIALIST_READ_LIST_RANK}>
            <Td>{spec.rank && spec.rank.name}</Td>
          </AccessControl>

          <Td>{spec.birth_day}</Td>
          <Td>{spec.snils}</Td>

          <AccessControl permission={permission.SPECIALIST_READ_LIST_ORGANIZATION}>
            <Td hasWordBreak>{spec.organization_actual ? spec.organization_actual.name : EMPTY_VALUE_SYMBOL}</Td>
          </AccessControl>
        </tr>
      );
    });
  };

  render() {
    const {listData, isLoading, storeName} = this.props;
    const statusHeader = `Статус${NON_BREAKING_SPACE}занятости`;
    const birthDayHeader = `Дата${NON_BREAKING_SPACE}рождения`;

    return (
      <Clay>
        <Table className='table'>
          <Thead>
          <tr>
            <Th className='table__column_width--30'>ФИО</Th>

            <AccessControl permission={permission.SPECIALIST_READ_LIST_STATUS}>
              <Th className='table__column_width--15'>{statusHeader}</Th>
            </AccessControl>

            <AccessControl permission={permission.SPECIALIST_READ_LIST_RANK}>
              <Th className='table__column_width--25'>Должность</Th>
            </AccessControl>

            <Th className='table__column_width--15'>
              <StaffOrdering storeName={storeName}
                             filteredParam={'birth_day'}
                             filterName={filter.ORDERING}>
                {birthDayHeader}
              </StaffOrdering>
            </Th>

            <Th className='table__column_width--15'>СНИЛС</Th>

            <AccessControl permission={permission.SPECIALIST_READ_LIST_ORGANIZATION}>
              <Th>Организация</Th>
            </AccessControl>
          </tr>
          </Thead>

          <Tbody>
          {listData && !isLoading && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
      </Clay>
    );
  }
}

SpecialistsTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null,
    isLoading: store ? store.storeIsLoading : null
  };
};

export default connect(mapStateToProps)(withRouter(SpecialistsTable));