import React, {Component} from 'react';
import {Table, Tbody, Td, Th, Thead} from '@n3/kit';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {NON_BREAKING_SPACE} from '../../../constants/symbolsList';

import SpecialistUpdateStatus from '../update/SpecialistUpdateStatus';

class SpecialistsTableModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      specId: null,
      isOpened: false
    };
  }

  toggleOpened = () => {
    this.setState(state => ({...state, isOpened: !state.isOpened}));
  };

  handleClick = (specId) => {
    this.setState({specId, isOpened: true});
  };

  renderTableBody = () => {
    const {specList} = this.props;

    return specList.map(spec => (
      <tr key={spec.id}
          className="app-container__cursor_pointer"
          onClick={() => this.handleClick(spec.id)}>

        <Td>{spec.full_name}</Td>
        <Td>{spec.employment_status_display}</Td>
        <Td>{spec.birth_day}</Td>
        <Td>{spec.snils}</Td>
      </tr>
    ));
  };

  render() {
    const {specId, isOpened} = this.state;
    const {specList, storeName} = this.props;
    const statusHeader = `Статус${NON_BREAKING_SPACE}занятости`;
    const birthDayHeader = `Дата${NON_BREAKING_SPACE}рождения`;

    if (!specList) {
      return null;
    }

    return (
      <Table className='table'>
        <Thead>
        <tr>
          <Th>ФИО</Th>
          <Th>{statusHeader}</Th>
          <Th>{birthDayHeader}</Th>
          <Th>СНИЛС</Th>
        </tr>
        </Thead>

        <Tbody>
        {this.renderTableBody()}
        </Tbody>

        <SpecialistUpdateStatus specId={specId}
                                isOpened={isOpened}
                                storeName={storeName}
                                toggleOpened={this.toggleOpened} />
      </Table>
    );
  }
}

SpecialistsTableModal.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    specList: store ? store.storeListData : null
  };
};

export default connect(mapStateToProps)(SpecialistsTableModal);