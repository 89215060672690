import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Clay, FieldLabel, FieldWrapper, Select} from '@n3/kit';

import api from '../../API';

class StaffSelectorAsync extends Component {

  constructor(props) {
    super(props);

    this.state = {
      next: null
    };
  }

  loadOptions = async(search, prevOptions) => {
    let filteredOptions;

    if (search) {
      const params = `?q=${search}`;
      filteredOptions = await this.requestLoadOptions(params);
    }
    else if (prevOptions.length === 0) {
      filteredOptions = await this.requestLoadOptions();
      const {defaultValue, selectorValue} = this.props;
      defaultValue ?
        filteredOptions.results.unshift(defaultValue) :
        selectorValue && filteredOptions.results.unshift(selectorValue);
    }
    else {
      const nextPage = Math.ceil(prevOptions.length / 9);
      const params = `?page=${nextPage}`;
      filteredOptions = await this.requestLoadOptions(params);
    }

    const hasMore = !!this.state.next;

    return {
      options: filteredOptions.results,
      hasMore
    };
  };

  requestLoadOptions = (params = '') => {
    const {next} = this.state;
    const dataSource = this.props.dataSource;
    const request = next ? next :`${dataSource}${params}`;

    return api.request(request, {method: 'get'})
      .then(resp => {
        this.setState({next: resp.next});
        return {
          totalCount: resp.count,
          results: Array.isArray(resp) ?
            resp.map(result => {
              return {
                value: result.id,
                label: result.name
              };
            }) :
            resp.results.map(result => {
              return {
                value: result.id,
                label: result.name ? result.name : result.short_address
              };
            })
        };
      });
  };

  render() {
    const {
      input, meta, isRequired, isDisabled, label, selectorValue, selectorValueArr,
      handleChange, isMulti
    } = this.props;

    const {name} = input;

    return (
      <Clay>
        <FieldLabel isRequired={isRequired}>
          {label}
        </FieldLabel>

        <Clay padding={[1, 0, 0, 0]} />

        <Select.Async isMulti={isMulti}
                      isDisabled={isDisabled}
                      loadOptions={this.loadOptions}
                      hasError={meta && meta.touched && !!meta.error}
                      onChange={(value) => handleChange(name, value)}
                      value={isMulti ? selectorValueArr : selectorValue} />
        {meta && meta.touched &&
        ((meta.error && <FieldWrapper errors={[meta.error]} />) ||
          (meta.warning && <span>{meta.warning}</span>))}
      </Clay>
    );
  }
}

StaffSelectorAsync.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  dataSource: PropTypes.string,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.object,
  selectorValue: PropTypes.object,
  selectorValueArr: PropTypes.array
};

export default StaffSelectorAsync;