export const KS = 'ks';
export const EDU = 'edu';
export const FKSR = 'fksr';
export const BUILDER = 'builder';
export const STAFFING = 'staffing';

export const rolesList = {
  [KS]: [
    'organizations:read',
    'organizations:listRead',
    'organizations:create',
    'organizations:delete',
    'organizations:edit',

    'specialists:read',
    'specialists:update',
    'specialists:update:changeActive',
    'specialists:delete',
    'specialists:listRead',
    'specialists:listRead:Filter',
    'specialists:listRead:Status',
    'specialists:listRead:Organizations',

    'demands:edit',
    'demands:delete',
    'demands:listRead',
    'demands:professionFilter',

    'vacancy:read',
    'vacancy:listRead',
    'vacancy:delete',
    'vacancy:edit',

    'reporting:read',
    'reporting:listRead',

    'work:update',
    'work:update:org'
  ],

  [FKSR]: [
    'organizations:read',
    'organizations:listRead',

    'specialists:read',
    'specialists:listRead',

    'demands:listRead',

    'vacancy:read',
    'vacancy:listRead',

    'work:update'
  ],

  [BUILDER]: [
    'infOrganizations:read',

    'specialists:read',
    'specialists:create',
    'specialists:update',
    'specialists:listRead',
    'specialists:create:work',
    'specialists:listRead:Rank',

    'demands:edit',
    'demands:read',
    'demands:close',
    'demands:create',
    'demands:delete',
    'demands:listRead',

    'vacancy:read',
    'vacancy:create',
    'vacancy:listRead',
    'vacancy:statusFilter',

    'work:update'
  ],

  [EDU]: [
    'infOrganizations:read',

    'specialists:create',
    'specialists:update',
    'specialists:listRead',
    'specialists:create:startDate',

    'vacancy:read',
    'vacancy:listRead',

    'work:update'
  ],

  [STAFFING]: [
    'organizations:read',
    'organizations:listRead',

    'specialists:create',
    'specialists:update',
    'specialists:listRead',

    'vacancy:read',
    'vacancy:listRead',

    'work:update'
  ]
};