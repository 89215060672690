import {connect} from 'react-redux';
import {change, formValueSelector, reduxForm} from 'redux-form';
import {log} from '@n3/kit';
import validate from './formValidate';
import * as form from './formConstructor';
import {withRouter} from 'react-router-dom';
import DocumentCreateForm from './DocumentCreateForm';
import * as messages from '../../../constants/messages';

const DocumentCreateFormContainer = reduxForm({
    validate,
    form: form.FORM_NAME
  },
  {change})(DocumentCreateForm);

const selector = formValueSelector(form.FORM_NAME);

const mapStetToProps = (state) => {
  return {
    onSubmitFail: () => {
      log({
          title: 'Ошибка',
          message: messages.SAVE_ERROR,
          color: 'danger',
          timeout: '5000'}
        );
    },
    [form.TYPE]: selector(state, form.TYPE),
    [form.SPECIALIST]: selector(state, form.SPECIALIST),
    [form.ATTACHMENTS]: selector(state, form.ATTACHMENTS)
  };
};

export default connect(mapStetToProps)(withRouter(DocumentCreateFormContainer));