import * as messages from '../../../constants/messages';

export const REQUIRED_INPUT = value => (value ? undefined : messages.NOT_EMPTY);
export const REQUIRED_SELECTOR = value => ((value && value.value) ? undefined : messages.NOT_EMPTY);

export const EMAIL = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
    ? messages.WRONG_EMAIL
    : undefined
);

export const WEBSITE = value => (
  value && !/(ftp|http|https):(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(|([\w#!:.?+=&%@!]))?/.test(value)
    ? messages.WRONG_WEBSITE
    : undefined
);

export const PHONE = value => (
  value && value.includes('_')
    ? messages.WRONG_PHONE
    : undefined
);