import React from 'react';
import {Clay, log} from '@n3/kit';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import api from '../../../API';
import PageHeader from '../../layouts/PageHeader';
import DemandUpdateMapData from './DemandUpdateMapData';
import * as messages from '../../../constants/messages';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import DemandUpdateFormContainer from './DemandUpdateFormContainer';
import {DEMAND_UPDATE, DEMANDS_LIST} from '../../../constants/pagesName';
import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';

export const DEMAND_UPDATE_STORE = 'demandUpdateStore';

class DemandUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      breadCrumbs: [
        {
          url: `/demands`,
          title: DEMANDS_LIST
        },
        {
          url: null,
          title: DEMAND_UPDATE
        }
      ]
    };
  }

  componentWillMount() {
    this.demandId = this.props.match.params.id;
    const dataSource = `/demands/contractor/${this.demandId}/`;
    this.props.appStoreCreate(DEMAND_UPDATE_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(DEMAND_UPDATE_STORE);
  }

  componentDidUpdate() {
    const dataSource = this.props.dataSource;
    if (dataSource) {
      this.props.appStoreGetDisplayData(DEMAND_UPDATE_STORE, dataSource);
    }
  }

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger'
    });
  };

  submit = (values) => {
    const data = mapValuesToSend(values);
    const dataSource = this.props.dataSource;

    return api.request(dataSource, {method: 'put', data})
      .then(() => {
        this.renderSuccessLog();
        const {tab} = this.props.match.params;
        this.props.history.push(`/demands/read/${this.demandId}/${tab}`);
      })
      .catch(() => {
        this.renderErrorLog(messages.SAVE_ERROR);
      });
  };

  submitAndContinue = (values) => {
    const data = mapValuesToSend(values);
    const dataSource = this.props.dataSource;

    return api.request(dataSource, {method: 'put', data})
      .then(() => {
        this.renderSuccessLog();
        const {tab} = this.props.match.params;
        this.props.history.push(`/demands/update/${this.demandId}/${tab}`);
      })
      .catch(() => {
        this.renderErrorLog(messages.SAVE_ERROR);
      });
  };

  handelCancelClick = (event) => {
    event.preventDefault();
    const {id, tab} = this.props.match.params;
    const path = `/demands/read/${id}/${tab}`;

    this.props.history.push(path);
  };

  render() {
    const {breadCrumbs} = this.state;

    return (
      <AccessControl permission={permission.DEMAND_UPDATE} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader breadCrumbs={breadCrumbs} title={DEMAND_UPDATE} />

          <DemandUpdateMapData storeName={DEMAND_UPDATE_STORE}>
            <DemandUpdateFormContainer onSubmit={this.submit}
                                       submitAndContinue={this.submitAndContinue}
                                       handelCancelClick={this.handelCancelClick} />
          </DemandUpdateMapData>
        </Clay>
      </AccessControl>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[DEMAND_UPDATE_STORE];

  return {
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DemandUpdate));