import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as filter from './filterConstructor';

import {appStoreGetFilteredData, appStoreSaveFilters, appStoreUpdateFilter} from '../../../store/appStore/actions';
import {getFilterValue, mapFiltersToStr} from '../../../services/filters';
import StaffSimpleCheckbox from '../../form/StaffSimpleCheckbox';
import InputWrap from '../../form/InputWrap';
import {NON_BREAKING_SPACE} from '../../../constants/symbolsList';

class OrientationReportFilter extends Component {

  componentDidMount() {
    const storeName = this.props.storeName;
    const filters = filter.ORIENTATION_REPORT_FILTER.slice();

    this.props.appStoreSaveFilters(storeName, filters);
  }

  componentDidUpdate() {
    this.getFilteredData();
  }

  getFilteredData = () => {
    const storeName = this.props.storeName;
    const dataSource = this.props.dataSource;
    const storeFilters = this.props.storeFilters;

    const request = `${dataSource}${mapFiltersToStr(storeFilters)}`;

    this.props.appStoreGetFilteredData(storeName, request);
  };

  handleInputChange = (name, value) => {
    const storeName = this.props.storeName;
    this.props.appStoreUpdateFilter(storeName, {name, value});
  };

  getInputFilterValue = (filterName) => {
    const storeFilters = this.props.storeFilters;
    const filter = getFilterValue(storeFilters, filterName);
    return filter && filter.value;
  };

  render() {
    const { storeFilters } = this.props;
    const labelText = `Есть${NON_BREAKING_SPACE}потребность`;

    if (!storeFilters) {
      return null;
    }

    return (
      <InputWrap size={4}>
        <StaffSimpleCheckbox label={labelText}
                             input={{name: filter.IS_DEMANDS}}
                             handleChange={this.handleInputChange}
                             checkboxValue={this.getInputFilterValue(filter.IS_DEMANDS)} />
      </InputWrap>
    );
  }
}

OrientationReportFilter.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreSaveFilters,
  appStoreUpdateFilter,
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(OrientationReportFilter);