import React from 'react';
import {Clay} from '@n3/kit';

class HasNoAccess extends React.Component {

  render() {
    return (
        <Clay font={'h1'} padding={[3, 5]}>
          Недостаточно прав
        </Clay>
    );
  }
}

export default HasNoAccess;