import * as messages from '../../../constants/messages';
import * as form from './formConstructor';

export default (values) => {
  const errors = {};
  if (!values[form.NAME]) {
    errors[form.NAME] = messages.NOT_EMPTY;
  }
  else if (values[form.NAME] && values[form.NAME].length > 200) {
    errors[form.NAME] = messages.MAX_LENGTH_200;
  }

  if (values[form.DESCRIPTION] && values[form.DESCRIPTION].length > 1000) {
    errors[form.DESCRIPTION] = messages.MAX_LENGTH_1000;
  }

  if (values[form.ADDITIONAL_CLAIMS] && values[form.ADDITIONAL_CLAIMS].length > 1000) {
    errors[form.ADDITIONAL_CLAIMS] = messages.MAX_LENGTH_1000;
  }

  if (values[form.DESCRIPTION_TERMS] && values[form.DESCRIPTION_TERMS].length > 1000) {
    errors[form.DESCRIPTION_TERMS] = messages.MAX_LENGTH_1000;
  }

  if (!values[form.PROFESSION] || !values[form.PROFESSION].value) {
    errors[form.PROFESSION] = messages.NOT_EMPTY;
  }
  if (values[form.EXPERIENCE_TO] * 1 < 0) {
    errors[form.EXPERIENCE_TO] = messages.NOT_BE_LESS_ZERO;
  }
  else if (values[form.EXPERIENCE_TO] && values[form.EXPERIENCE_TO].toString().includes('.')) {
    errors[form.EXPERIENCE_TO] = messages.ONLY_WHOLE;
  }
  else if (values[form.EXPERIENCE_TO] * 1 > 0 && values[form.EXPERIENCE_TO] * 1 < values[form.EXPERIENCE_FROM] * 1) {
    errors[form.EXPERIENCE_TO] = messages.NOT_BE_LESS_MIN;
  }

  if (values[form.EXPERIENCE_FROM] * 1 < 0) {
    errors[form.EXPERIENCE_FROM] = messages.NOT_BE_LESS_ZERO;
  }
  else if (values[form.EXPERIENCE_FROM] && values[form.EXPERIENCE_FROM].toString().includes('.')) {
    errors[form.EXPERIENCE_FROM] = messages.ONLY_WHOLE;
  }
  else if (values[form.EXPERIENCE_TO] * 1 > 0 && values[form.EXPERIENCE_FROM] * 1 > values[form.EXPERIENCE_TO] * 1) {
    errors[form.EXPERIENCE_FROM] = messages.NOT_BE_MORE_MAX;
  }

  if (values[form.SALARY_TO] * 1 < 0) {
    errors[form.SALARY_TO] = messages.NOT_BE_LESS_ZERO;
  }
  else if (values[form.SALARY_TO] && values[form.SALARY_TO].toString().includes('.')) {
    errors[form.SALARY_TO] = messages.ONLY_WHOLE;
  }
  else if (values[form.SALARY_TO] * 1 > 0 && values[form.SALARY_TO] * 1 < values[form.SALARY_FROM] * 1) {
    errors[form.SALARY_TO] = messages.NOT_BE_LESS_MIN;
  }
  if (values[form.SALARY_FROM] * 1 < 0) {
    errors[form.SALARY_FROM] = messages.NOT_BE_LESS_ZERO;
  }
  else if (values[form.SALARY_FROM] && values[form.SALARY_FROM].toString().includes('.')) {
    errors[form.SALARY_FROM] = messages.ONLY_WHOLE;
  }
  else if (values[form.SALARY_TO] * 1 > 0 && values[form.SALARY_FROM] * 1 > values[form.SALARY_TO] * 1) {
    errors[form.SALARY_FROM] = messages.NOT_BE_MORE_MAX;
  }

  return errors;
};