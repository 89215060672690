import React from 'react';
import PropTypes from 'prop-types';
import {Breadcrumbs} from '@n3/kit';

class StaffBreadCrumbs extends React.Component {

  render() {
    const {breadcrumbs} = this.props;

    return (
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    );
  }
}

StaffBreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired
};

export default StaffBreadCrumbs;