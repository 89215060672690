import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {change, formValueSelector, getFormValues, reduxForm} from 'redux-form';
import {Alert, Button, ButtonsGroup, Clay, log, Tabs} from '@n3/kit';

import api from '../../../API';
import FormBody from './FormBody';
import validate from './formValidate';
import FormHeader from './FormHeader';
import validator from './formValidate';
import FormContacts from './FormContacts';
import StaffTab from '../../layouts/StaffTab';
import {getCookie} from '../../../utils/Cookie';
import * as form from '../create/formConstructor';
import DisplayData from '../../layouts/DisplayData';
import * as roles from '../../../constants/rolesList';
import * as messages from '../../../constants/messages';
import {mapErrorMessage} from '../../../services/mapper';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import DocumentCreate from '../../documents/create/DocumentCreate';
import {DOCUMENTS_LIST_DELETE} from '../../documents/list/DocumentsList';
import DocumentsListContainer from '../../documents/list/DocumentsListContainer';

const PAGE_TABS = [
  {id: 1, title: 'Основная информация'},
  {id: 2, title: 'Контакты'},
  {id: 3, title: 'Документы'}
];

class OrganizationUpdateForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1
    };
  }

  componentDidMount() {
    const {tab} = this.props.match.params;
    this.setState({currentTab: tab * 1});
  }

  setCurrentTab = (tabId) => {
    this.setState({currentTab: tabId});
  };

  handleChange = (name, value) => {
    this.props.change(name, value);
  };

  handelCancelClick = (event) => {
    event.preventDefault();

    const {id, tab} = this.props.match.params;
    const path = `/organizations/read/${id}/${tab}`;

    this.props.history.push(path);
  };

  deleteDocs = () => {
    const {deleteDocsList} = this.props;

    deleteDocsList.forEach(docId => {
      const request = `/documents/${docId}`;
      api.request(request, {method: 'delete'});
    });
  };

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = (message, title = null) => {
    log({
      title: title ? title : 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = (values) => {
    if (values.date_ogrn === '') {
      values.date_ogrn = null;
    }

    const data = this.prepareValuesToSend(values);
    const {id, tab} = this.props.match.params;
    const request = `/organizations/${id}/`;

    this.deleteDocs();

    return data && api.request(request, {method: 'put', data})
      .then(() => {
        const path = `/organizations/read/${id}/${tab}`;
        this.props.history.push(path);
        this.renderSuccessLog();
      })
      .catch((error) => {
        this.renderErrorLog(mapErrorMessage(error));
      });
  };

  submitAndContinue = (values) => {
    if (values.date_ogrn === '') {
      values.date_ogrn = null;
    }

    const data = this.prepareValuesToSend(values);
    const orgId = this.props.match.params.id;
    const request = `/organizations/${orgId}/`;

    this.deleteDocs();

    return data && api.request(request, {method: 'put', data})
      .then(() => {
        this.renderSuccessLog();
      })
      .catch((error) => {
        this.renderErrorLog(mapErrorMessage(error));
      });
  };

  prepareValuesToSend = (values) => {
    if (Object.keys(validator(values)).length) {
      const key = Object.keys(validator(values))[0];
      if ([form.FULL_NAME, form.OWNERSHIP_TYPE, form.INN, form.KPP, form.OGRN].indexOf(key) !== -1) {
        this.renderErrorLog(validator(values)[key], 'Ошибка во вкладке "Основная информация"');
      }
      else {
        this.renderErrorLog(validator(values)[key], 'Ошибка во вкладке "Контакты"');
      }
      return null;
    }

    return mapValuesToSend(values);
  };

  render() {
    const userRole = getCookie('role');
    const {currentTab} = this.state;
    const {error, handleSubmit, category_display, name, formBody} = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        {error && <Alert color="danger" title="Ошибка:"> {error} </Alert>}

        <Clay padding={[3, 5, 3, 5]} bgColor="bg">
          <AccessControl permission={permission.ORGANIZATION_UPDATE} accessCheck={userRole === roles.KS}>
            <FormHeader />
          </AccessControl>
          <AccessControl permission={permission.ORGANIZATION_UPDATE} accessCheck={userRole !== roles.KS}>
            <Clay padding={[0]}>
              <DisplayData label="Сокращённое наименование" data={name} />
            </Clay>
          </AccessControl>
          <Clay padding={[0]}>
            <DisplayData label="Категория организации" data={category_display} />
          </Clay>
        </Clay>

        <Tabs tabs={PAGE_TABS}
              current={currentTab}
              setCurrentTab={this.setCurrentTab} />

        <Clay margin={[6, 5]}>
          <StaffTab tabId={1} currentTabId={currentTab}>
            <FormBody fields={formBody} handleChange={this.handleChange} {...this.props} submit={this.submit} />
          </StaffTab>

          <StaffTab tabId={2} currentTabId={currentTab}>
            <FormContacts {...this.props} />
          </StaffTab>

          <StaffTab tabId={3} currentTabId={currentTab}>
            <DocumentsListContainer forUpdate={true}
                                    docsType="by_organization"
                                    renderControlButton={<DocumentCreate />} />
          </StaffTab>
        </Clay>

        <Clay padding={[3, 5]} className="app-container__row">
          <Button onClick={(event) => this.handelCancelClick(event)}>
            Отмена
          </Button>
          <ButtonsGroup>
            <Button color="primary" onClick={handleSubmit(this.submitAndContinue)}>
              Сохранить и продолжить
            </Button>
            <Button type="submit" color="primary">
              Сохранить
            </Button>
          </ButtonsGroup>
        </Clay>
      </form>
    );
  }
}

const selector = formValueSelector(form.FORM_NAME);

const mapStateToProps = (state) => {
  const deleteDocsList = state.appStore[DOCUMENTS_LIST_DELETE];

  return {
    formBody: {
      [form.CATEGORY]: selector(state, form.CATEGORY),
      [form.FULL_NAME]: selector(state, form.FULL_NAME),
      [form.ACTIVITY_TYPE]: selector(state, form.ACTIVITY_TYPE),
      [form.OWNERSHIP_TYPE]: selector(state, form.OWNERSHIP_TYPE),
      [form.CONSTRUCTION_TYPE]: selector(state, form.CONSTRUCTION_TYPE)
    },

    [form.CATEGORY_DISPLAY]: selector(state, form.CATEGORY_DISPLAY),
    [form.NAME]: selector(state, form.NAME),
    initialValues: state.appForms[form.FORM_NAME],

    formValues: getFormValues(form.FORM_NAME)(state),

    deleteDocsList: deleteDocsList ? deleteDocsList.storeListData : null
  };
};

export default connect(mapStateToProps)(withRouter(reduxForm({
    validate,
    form: form.FORM_NAME,
    enableReinitialize: true
  },
  {change})(OrganizationUpdateForm)));