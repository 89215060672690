import React from 'react';
import {Field} from 'redux-form';
import {Link} from 'react-router-dom';
import {Button, Clay, Row, Col, FieldLabel, ButtonsGroup, Tabs, Tag} from '@n3/kit';

import * as form from './formConstructor';
import InputWrap from '../../form/InputWrap';
import StaffInput from '../../form/StaffInput';
import {getCookie} from '../../../utils/Cookie';
import TitleWrap from '../../layouts/TitleWrap';
import DisplayData from '../../layouts/DisplayData';
import StaffTextArea from '../../form/StaffTextArea';
import StaffSelector from '../../form/StaffSelector';
import AccessControl from '../../accessControl/AccessControl';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as permission from '../../../constants/permissionsList';
import StaffSimpleCheckbox from '../../form/StaffSimpleCheckbox';
import {checkPermission} from '../../../services/checkPermission';
import * as dictionaries from '../../../constants/dictionariesSource';
import {GENDER_VALUES_WITH_UNDEFINED} from '../../../constants/selectorsValues';
import {DEMAND_READ, VACANCY_LINK_ORGANIZATION, VACANCY_VIEW_ORGANIZATION} from '../../../constants/permissionsList';

const PAGE_TABS = [
  {id: 1, title: 'Информация о вакансии'}
];

class VacancyUpdateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1
    };
  }

  componentDidUpdate() {
    const {demand} = this.props;
    const demandId = this.props.match.params.id;

    !demand && this.props.change(form.DEMAND, demandId * 1);
  }

  handleSelectorChange = (name, value) => {
    this.props.change(name, value);
  };

  setCurrentTab = (tabId) => {
    this.setState({
      currentTab: tabId
    });
  };

  renderOrgLink = (displayData) => {
    const {organization} = displayData;
    const path = `/organizations/read/${organization.id}`;

    return <Link to={path} target="_blank">{organization.name}</Link>;
  };

  renderOrganization = (displayData) => {
    const {organization} = displayData;
    const userRole = getCookie('role');

    return checkPermission(userRole, VACANCY_LINK_ORGANIZATION)
      ? <DisplayData data={this.renderOrgLink(displayData)} label='Организация' />
      : <DisplayData data={organization.name} label='Организация' />;
  };

  renderDemandLink = (demand) => {
    const path = `/demands/read/${demand.id}`;
    const linkTitle = `Потребность на должность ${demand.rank.name} от ${demand.date_created}`;

    return <Link to={path} target="_blank">{linkTitle}</Link>;
  };

  render() {
    const {currentTab} = this.state;
    const {
      handleSubmit, submitAndContinue, gender, availability_handicapped, handleCancelClick,
      technology, profession, nationality, access_work_type, status, status_display, demand, date_created, date_close
    } = this.props;

    if (!demand || !demand.rank) {
      return null;
    }

    const isClosed = status === 'closed' ? false : true;

    return (
      <AccessControl permission={permission.VACANCY_UPDATE} accessCheck={isClosed} redirect={'/hasNoAccess'}>
        <form onSubmit={handleSubmit}>

        <Clay padding={[0]} bgColor="bg">
          <Clay className="app-container__row">
            <Clay padding={[3, 5, 3, 5]} className="app-container app-container__border_right">
              <DisplayData data={<Tag color={status === 'closed' ? 'red' : 'green'}>{status_display}</Tag>}
                          label='Статус' />
              <Clay padding={[2, 0, 2, 0]}>
                <InputWrap size={8}>
                  <Field label="Название"
                        name={form.NAME}
                        isRequired={true}
                        component={StaffInput} />
                </InputWrap>
              </Clay>
              <AccessControl permission={DEMAND_READ}>
                <DisplayData data={this.renderDemandLink(demand)} label='Потребность' />
              </AccessControl>
            </Clay>
            <Clay padding={[3, 5, 3, 5]} className="app-container">
              <DisplayData data={demand.author.full_name} label='Автор' />
              <AccessControl permission={VACANCY_VIEW_ORGANIZATION}>
                <Clay padding={[2, 0, 0, 0]}>
                  {this.renderOrganization(demand)}
                </Clay>
              </AccessControl>
              <Row>
                <Col xs={4}>
                  <DisplayData data={date_created} label='Дата создания' />
                </Col>
                <Col xs={4}>
                  <DisplayData data={date_close} label='Дата закрытия' />
                </Col>
              </Row>
            </Clay>
          </Clay>
        </Clay>

        <Tabs
          tabs={PAGE_TABS}
          current={currentTab}
          setCurrentTab={this.setCurrentTab} />

        <Clay padding={[0, 5]}>
          <TitleWrap>
            Обязанности
          </TitleWrap>

          <StaffDisplayCard>
            <Clay padding={[2, 0, 2, 0]}>
              <InputWrap size={12}>
                <Field label="Описание вакансии"
                      component={StaffTextArea}
                      name={form.DESCRIPTION}
                      customStyle={{height: '85px'}} />
              </InputWrap>
            </Clay>
          </StaffDisplayCard>


          <TitleWrap>
            Требования
          </TitleWrap>

          <StaffDisplayCard>
            <Clay padding={[2, 0, 2, 0]}>
              <InputWrap size={4}>
                <FieldLabel>
                  Опыт работы
                </FieldLabel>
                <Row>
                  <Col xs={6}>
                    <Field min={0}
                          label="От"
                          type="number"
                          component={StaffInput}
                          name={form.EXPERIENCE_FROM} />
                  </Col>
                  <Col xs={6}>
                    <Field label="До"
                          type="number"
                          component={StaffInput}
                          name={form.EXPERIENCE_TO} />
                  </Col>
                </Row>
              </InputWrap>
            </Clay>

            <Clay padding={[2, 0, 2, 0]}>
              <Row>
                <Col xs={4}>
                  <Field label="Профессия"
                        name={form.PROFESSION}
                        isRequired={true}
                        selectorValue={profession}
                        component={StaffSelectorAsync}
                        dataSource={dictionaries.PROFESSIONS}
                        handleChange={this.handleSelectorChange}
                        defaultValue={form.SELECTOR_DEFAULT_VALUE} />
                </Col>
                <Col xs={4}>
                  <Field label="Пол"
                        name={form.GENDER}
                        selectorValue={gender}
                        component={StaffSelector}
                        options={GENDER_VALUES_WITH_UNDEFINED}
                        handleChange={this.handleSelectorChange} />
                </Col>
                <Col xs={4}>
                  <Field label="Гражданство"
                        name={form.NATIONALITY}
                        selectorValue={nationality}
                        component={StaffSelectorAsync}
                        dataSource={dictionaries.COUNTRIES}
                        handleChange={this.handleSelectorChange}
                        defaultValue={form.SELECTOR_DEFAULT_VALUE} />
                </Col>
              </Row>
            </Clay>

            <Clay padding={[2, 0, 2, 0]}>
              <Row>
                <Col xs={6}>
                  <Field isMulti={true}
                        label="Доступ к виду работ"
                        name={form.ACCESS_WORK_TYPE}
                        component={StaffSelectorAsync}
                        selectorValueArr={access_work_type}
                        dataSource={dictionaries.WORK_TYPES}
                        handleChange={this.handleSelectorChange} />
                </Col>
                <Col xs={6}>
                  <Field isMulti={true}
                        label="Технологии"
                        name={form.TECHNOLOGY}
                        component={StaffSelectorAsync}
                        selectorValueArr={technology}
                        dataSource={dictionaries.TECHNOLOGIES}
                        handleChange={this.handleSelectorChange} />
                </Col>
              </Row>
            </Clay>


            <Clay padding={[2, 0, 2, 0]}>
              <Row>
                <Col xs={6}>
                  <Field label="Дополнительные требования"
                        name={form.ADDITIONAL_CLAIMS}
                        component={StaffTextArea} />
                </Col>
                <Col xs={6}>
                  <Clay padding={[4, 0]}>
                    <Field name={form.AVAILABILITY}
                          component={StaffSimpleCheckbox}
                          checkboxValue={availability_handicapped}
                          handleChange={this.handleSelectorChange}
                          label="Доступность для людей с ограниченными возможностями" />
                  </Clay>
                </Col>
              </Row>
            </Clay>
          </StaffDisplayCard>

          <TitleWrap>
            Условия
          </TitleWrap>

          <StaffDisplayCard>
            <Clay padding={[2, 0, 2, 0]}>
              <InputWrap size={4}>
                <FieldLabel>
                  Уровень зарплаты
                </FieldLabel>
                <Row>
                  <Col xs={6}>
                    <Field min={0}
                          label="От"
                          type="number"
                          component={StaffInput}
                          name={form.SALARY_FROM} />
                  </Col>
                  <Col xs={6}>
                    <Field label="До"
                          type="number"
                          name={form.SALARY_TO}
                          component={StaffInput} />
                  </Col>
                </Row>
              </InputWrap>
            </Clay>

            <Clay padding={[2, 0, 2, 0]}>
              <InputWrap size={12}>
                <Field label="Описание условий"
                      component={StaffTextArea}
                      name={form.DESCRIPTION_TERMS}
                      customStyle={{height: '85px'}} />
              </InputWrap>
            </Clay>
          </StaffDisplayCard>

          <Clay padding={[3, 0]} className="app-container__row">
            <Button onClick={handleCancelClick}>
              Отмена
            </Button>
            <ButtonsGroup>
              <Button color="primary" onClick={handleSubmit(submitAndContinue)}>
                Сохранить и продолжить
              </Button>
              <Button type="submit" color="primary">
                Сохранить
              </Button>
            </ButtonsGroup>
          </Clay>

        </Clay>

        </form>
      </AccessControl>
    );
  }
}

export default VacancyUpdateForm;