import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';

import DemandCreate from './create/DemandCreate';
import DemandUpdate from './update/DemandUpdate';
import {appUpdateSidebar} from '../../store/app/actions';
import DemandViewContainer from './read/DemandReadContainer';
import DemandsListContainer from './list/DemandsListContainer';

class DemandsRouter extends React.Component {

  componentDidMount() {
    const page = window.location.pathname;
    this.props.appUpdateSidebar(page);
  }

  render() {
    const path = this.props.match.url;

    return (
      <Switch>
        <Route path={path} exact component={DemandsListContainer} />
        <Route path={`${path}/create`} component={DemandCreate} />
        <Route path={`${path}/read/:id/:tab`} component={DemandViewContainer} />
        <Route path={`${path}/update/:id/:tab`} component={DemandUpdate} />
      </Switch>
    );
  }
}

const mapDispatchToProps = {
  appUpdateSidebar
};

export default connect(null, mapDispatchToProps)(withRouter(DemandsRouter));
