import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import {Clay, Col, Row} from '@n3/kit';

import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import StaffTextArea from '../../form/StaffTextArea';
import * as form from '../create/formConstructor';
import StaffInputMask from '../../form/StaffInputMask';
import StaffDatepicker from '../../form/StaffDatepicker';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as dictionaries from '../../../constants/dictionariesSource';
import {getCookie} from '../../../utils/Cookie';
import * as roles from '../../../constants/rolesList';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import validator from './formValidate';

class FormBody extends React.Component {

  componentDidMount() {
    const {formValues} = this.props;

    formValues && Object.keys(validator(formValues)).forEach(field => {
      this.props.change('_validationHack', Date.now());
      this.props.touch(form.FORM_NAME, field);
    });
  }

  render() {
    const userRole = getCookie('role');
    const {fields, handleChange} = this.props;

    if (!fields) {
      return null;
    }

    const {ownership_type, activity_type, construction_type} = fields;

    return (
      <StaffDisplayCard>
        <Clay padding={[2, 0, 0, 0]} />
        <Row>
          <Col xs={6}>
            <Field label="Полное наименование"
                   isRequired={userRole === roles.KS}
                   isDisabled={userRole !== roles.KS}
                   name={form.FULL_NAME}
                   component={StaffTextArea} />
          </Col>

          <Col xs={6}>
            <Field label="Форма собственности"
                   name={form.OWNERSHIP_TYPE}
                   isRequired={true}
                   handleChange={handleChange}
                   selectorValue={ownership_type}
                   component={StaffSelectorAsync}
                   dataSource={dictionaries.OWNERSHIP_TYPES}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE} />
          </Col>
        </Row>

        <Clay padding={[4, 0, 0]} />

        <Row>
          <Col xs={3}>
            <Field label="ИНН"
                   name={form.INN}
                   isRequired={true}
                   inputMask={form.INN_MASK}
                   component={StaffInputMask} />
          </Col>

          <Col xs={3}>
            <Field label="КПП"
                   name={form.KPP}
                   isRequired={true}
                   inputMask={form.KPP_MASK}
                   component={StaffInputMask} />
          </Col>

          <Col xs={3}>
            <Field label="ОГРН"
                   name={form.OGRN}
                   isRequired={true}
                   inputMask={form.OGRN_MASK}
                   component={StaffInputMask} />
          </Col>

          <Col xs={3}>
            <Field label="Дата регистрации"
                   name={form.DATE_OGRN}
                   isRequired={false}
                   component={StaffDatepicker}
                   handleChange={handleChange} />
          </Col>
        </Row>

        <Clay padding={[0, 0, 4, 0]} />

        <Row>
          <Col xs={6}>
            <Field label="Вид деятельности"
                   isMulti={true}
                   name={form.ACTIVITY_TYPE}
                   component={StaffSelectorAsync}
                   selectorValueArr={activity_type}
                   handleChange={handleChange}
                   dataSource={dictionaries.ACTIVITY_TYPES}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE} />
          </Col>

          <AccessControl permission={permission.ORGANIZATION_CREATE}>
            <Col xs={6}>
              <Field label="Типы строительства"
                     isMulti={true}
                     name={form.CONSTRUCTION_TYPE}
                     component={StaffSelectorAsync}
                     handleChange={handleChange}
                     selectorValueArr={construction_type}
                     defaultValue={form.SELECTOR_DEFAULT_VALUE}
                     dataSource={dictionaries.CONSTRUCTION_TYPES} />
            </Col>
          </AccessControl>
        </Row>
        <Clay padding={[2, 0, 0]} />
      </StaffDisplayCard>
    );
  }
}

FormBody.propTypes = {
  fields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default FormBody;