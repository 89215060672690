import {setCookie} from '../../utils/Cookie';

export const APP_FILTER_DELETE = 'APP_FILTER_DELETE';
export const APP_FILTER_SAVE = 'APP_GLOBAL_FILTER_SAVE';

export const APP_USER_LOGIN = 'APP_USER_LOGIN';
export const APP_USER_LOGOUT = 'APP_USER_LOGOUT';
export const APP_SET_USER_ROLE = 'APP_SET_USER_ROLE';
export const APP_SET_USER_TOKEN = 'APP_SET_USER_TOKEN';

export const APP_UPDATE_SIDEBAR = 'APP_UPDATE_SIDEBAR';

export const appFilterSave = (filter) => {
  return {
    type: APP_FILTER_SAVE,
    payload: filter
  };
};

export const appFilterDelete = (filter) => {
  return {
    type: APP_FILTER_DELETE,
    payload: filter
  };
};

export const setUserRole = (userRole) => {
  return {
    type: APP_SET_USER_ROLE,
    payload: userRole
  };
};

export const setUserToken = (userToken) => {
  return {
    type: APP_SET_USER_TOKEN,
    payload: userToken
  };
};

export const setUserLogin = (token) => {
  console.log('login user');
  setCookie('token', token);

  return {
    type: APP_USER_LOGIN,
    payload: token
  };
};

export const setUserLogout = () => {
  return {
    type: APP_USER_LOGOUT,
    payload: false
  };
};

export const appUpdateSidebar = (page) => {
  return {
    type: APP_UPDATE_SIDEBAR,
    payload: page
  };
};

