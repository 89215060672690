import React, {Component} from 'react';
import {Clay} from '@n3/kit';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {appStoreGetFilteredData, appStoreSaveFilters, appStoreUpdateFilter} from '../../../store/appStore/actions';
import {EDUCATION_TYPE_VALUES, EMPLOYMENT_STATUS_VALUES} from '../../../constants/selectorsValues';
import {getFilterValue, mapFiltersToStr} from '../../../services/filters';
import StaffInputNumberFilter from '../../form/StaffInputNumberFilter';
import * as dictionaries from '../../../constants/dictionariesSource';
import StaffSimpleCheckbox from '../../form/StaffSimpleCheckbox';
import * as permission from '../../../constants/permissionsList';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import AccessControl from '../../accessControl/AccessControl';
import StaffSelector from '../../form/StaffSelector';
import * as filter from './filterConstructor';

class SpecialistsListFilter extends Component {

  componentDidMount() {
    const {dataSource} = this.props;

    if (dataSource) {
      this.setFilters();
    }
  }

  componentDidUpdate() {
    const storeFilters = this.props.storeFilters;

    !storeFilters.length ? this.setFilters() : this.getFilteredData();
  }

  setFilters = () => {
    const storeName = this.props.storeName;
    const appFilters = this.props.appFilters;
    const filters = [...filter.SPECIALISTS_LIST_FILTER];

    if (appFilters.length) {
      appFilters.forEach(appFilter => {
        const index = filters.findIndex(filter => filter.name === appFilter.name);

        if (index > -1) {
          filters[index] = appFilter;
        }
      });
    }

    this.props.appStoreSaveFilters(storeName, filters);
  };

  getFilteredData = () => {
    const storeName = this.props.storeName;
    const dataSource = this.props.dataSource;
    const storeFilters = this.props.storeFilters;

    const request = `${dataSource}${mapFiltersToStr(storeFilters)}`;

    this.props.appStoreGetFilteredData(storeName, request);
  };

  handleSelectorChange = (name, selectorValue) => {
    const storeName = this.props.storeName;
    const label = selectorValue.label;
    const value = selectorValue.value;

    this.props.appStoreUpdateFilter(storeName, {name, label, value});
  };

  handleInputChange = (name, value) => {
    const storeName = this.props.storeName;
    this.props.appStoreUpdateFilter(storeName, {name, value});
  };

  getInputFilterValue = (filterName) => {
    const storeFilters = this.props.storeFilters;
    const filter = getFilterValue(storeFilters, filterName);
    return filter && filter.value;
  };

  getSelectorDefaultValue = (name) => {
    return filter.SPECIALISTS_LIST_FILTER.find(filter => filter.name === name);
  };

  render() {
    const {storeFilters} = this.props;

    if (!storeFilters) {
      return null;
    }

    return (
      <AccessControl permission={permission.SPECIALIST_READ_LIST_FILTER}>
        <Clay className="app-container app-container__row_just-row">
          <Clay className='app-container__selector' padding={[0,4,2,0]}>
            <StaffSelector  label="Статус занятости"
                            options={EMPLOYMENT_STATUS_VALUES}
                            handleChange={this.handleSelectorChange}
                            input={{name: filter.EMPLOYMENT_STATUS}}
                            selectorValue={getFilterValue(storeFilters, filter.EMPLOYMENT_STATUS)} />
          </Clay>
          <Clay className='app-container__selector' padding={[0,4,2,0]}>
            <StaffSelectorAsync label="Организация"
                                input={{name: filter.ORGANIZATION}}
                                dataSource={dictionaries.ORGANIZATIONS}
                                handleChange={this.handleSelectorChange}
                                defaultValue={this.getSelectorDefaultValue(filter.ORGANIZATION)}
                                selectorValue={getFilterValue(storeFilters, filter.ORGANIZATION)} />
          </Clay>

          <Clay className='app-container__selector' padding={[0,4,2,0]}>
            <StaffSelectorAsync label="Профессия"
                                input={{name: filter.PROFESSION}}
                                dataSource={dictionaries.PROFESSIONS}
                                handleChange={this.handleSelectorChange}
                                defaultValue={this.getSelectorDefaultValue(filter.PROFESSION)}
                                selectorValue={getFilterValue(storeFilters, filter.PROFESSION)} />
          </Clay>

          <Clay className='app-container__selector' padding={[0,4,2,0]}>
            <StaffSelectorAsync label="Технологии"
                                input={{name: filter.TECHNOLOGY}}
                                dataSource={dictionaries.TECHNOLOGIES}
                                handleChange={this.handleSelectorChange}
                                defaultValue={this.getSelectorDefaultValue(filter.TECHNOLOGY)}
                                selectorValue={getFilterValue(storeFilters, filter.TECHNOLOGY)} />
          </Clay>

          <Clay className='app-container__selector' padding={[0,4,2,0]}>
            <StaffSelector  label="Образование"
                            options={EDUCATION_TYPE_VALUES}
                            input={{name: filter.EDUCATION_TYPE}}
                            handleChange={this.handleSelectorChange}
                            selectorValue={getFilterValue(storeFilters, filter.EDUCATION_TYPE)} />
          </Clay>

          <Clay className='app-container__selector' padding={[0,4,2,0]}>
            <StaffInputNumberFilter min={0}
                                    label="Опыт работы"
                                    name={filter.EXPERIENCE}
                                    positiveValuesOnly={true}
                                    integerValuesOnly={true}
                                    handleChange={this.handleInputChange}
                                    value={this.getInputFilterValue(filter.EXPERIENCE)} />
          </Clay>
        </Clay>
        <Clay>
          <StaffSimpleCheckbox  label="Активный"
                                input={{name: filter.ACTIVE}}
                                handleChange={this.handleInputChange}
                                checkboxValue={this.getInputFilterValue(filter.ACTIVE)} />
        </Clay>
      </AccessControl>
    );
  }
}

SpecialistsListFilter.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    appFilters: state.app.appFilters,
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreSaveFilters,
  appStoreUpdateFilter,
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistsListFilter);