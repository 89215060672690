import React from 'react';
import {Row, Col} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import * as filter from './filterConstructor';
import StaffSelector from '../../form/StaffSelector';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as dictionaries from '../../../constants/dictionariesSource';
import {getFilterValue, mapFiltersToStr} from '../../../services/filters';
import {DOCUMENTS_STATUSES_VALUES} from '../../../constants/selectorsValues';
import {appStoreGetFilteredData, appStoreSaveFilters, appStoreUpdateFilter} from '../../../store/appStore/actions';

class DocumentsListFilter extends React.Component {

  componentDidMount() {
    this.setFilters();
  }

  componentDidUpdate(prevProps) {
    const {storeFilters} = this.props;

    !storeFilters.length ?
      this.setFilters() :
      storeFilters !== prevProps.storeFilters && this.getFilteredData();
  }

  setFilters = () => {
    const storeName = this.props.storeName;
    const filters = [...filter.DOCUMENTS_FILTERS];

    this.props.appStoreSaveFilters(storeName, filters);
  };

  getFilteredData = () => {
    const {storeName, dataSource, storeFilters} = this.props;

    const request = `${dataSource}${mapFiltersToStr(storeFilters)}`;

    this.props.appStoreGetFilteredData(storeName, request);
  };

  handleSelectorChange = (name, selectorValue) => {
    const storeName = this.props.storeName;
    const label = selectorValue.label;
    const value = selectorValue.value;

    this.props.appStoreUpdateFilter(storeName, {name, label, value});
  };

  getSelectorDefaultValue = (name) => {
    return filter.DOCUMENTS_FILTERS.find(filter => filter.name === name);
  };

  render() {
    const {storeFilters} = this.props;

    if (!storeFilters) {
      return null;
    }

    return (
      <Row>
        <Col xs={3}>
          <StaffSelectorAsync label="Тип документа"
                              input={{name: filter.TYPE}}
                              dataSource={dictionaries.DOC_TYPES}
                              handleChange={this.handleSelectorChange}
                              defaultValue={this.getSelectorDefaultValue(filter.TYPE)}
                              selectorValue={getFilterValue(storeFilters, filter.TYPE)} />
        </Col>
        <Col xs={3}>
          <StaffSelector label="Статус документа"
                         options={DOCUMENTS_STATUSES_VALUES}
                         input={{name: filter.STATUS}}
                         handleChange={this.handleSelectorChange}
                         selectorValue={getFilterValue(storeFilters, filter.STATUS)} />
        </Col>
      </Row>
    );
  }
}

DocumentsListFilter.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreSaveFilters,
  appStoreUpdateFilter,
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsListFilter);