const DICTIONARIES = 'dictionaries';

export const RANKS = `${DICTIONARIES}/ranks`;
export const GRADES = `${DICTIONARIES}/grades`;
export const REGIONS = `${DICTIONARIES}/regions`;
export const DEGREES = `${DICTIONARIES}/degrees`;
export const COURSES = `${DICTIONARIES}/courses`;
export const DOC_TYPES = `${DICTIONARIES}/doc_types`;
export const COUNTRIES = `${DICTIONARIES}/countries`;
export const WORK_TYPES = `${DICTIONARIES}/work_types`;
export const PROFESSIONS = `${DICTIONARIES}/professions`;
export const ADDRESS_UAS = `${DICTIONARIES}/addresses_uas`;
export const TECHNOLOGIES = `${DICTIONARIES}/technologies`;
export const SPECIALITIES = `${DICTIONARIES}/specialities`;
export const ACTIVITY_TYPES = `${DICTIONARIES}/activity_types`;
export const OWNERSHIP_TYPES = `${DICTIONARIES}/ownership_types`;
export const EDUCATION_PROGRAMS = `${DICTIONARIES}/education_programs`;
export const CONSTRUCTION_TYPES = `${DICTIONARIES}/construction_types`;

export const ORGANIZATIONS = '/organizations/';
export const ORGANIZATIONS_CONTRACTOR = '/organizations/?category=contractor_entity';
export const ORGANIZATIONS_EDUCATIONAL = '/organizations/?category=educational_institution';
