import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import * as form from '../create/formConstructor';
import {DOCUMENTS_LIST_DELETE} from '../../documents/list/DocumentsList';
import {mapServerArray, mapServerObject} from '../../../services/mapper';
import {appFormsCreateStore, appFormsDeleteStore} from '../../../store/appForms/actions';
import {appStoreCreate, appStoreDelete, appStoreUpdateListData} from '../../../store/appStore/actions';

class OrganizationUpdateMapData extends React.Component {

  componentWillUnmount() {
    const {storeName} = this.props;
    this.props.appFormsDeleteStore(storeName);
    this.props.appStoreDelete(DOCUMENTS_LIST_DELETE);
  }

  componentDidUpdate() {
    const {displayData} = this.props;

    if (displayData) {
      this.mapData(displayData);
    }
  }

  mapData = (displayData) => {
    const formFields = {};

    for (let elem in displayData) {
      const value = displayData[elem];

      if (elem in form.ORGANISATION_UPDATE_FORM) {
        if (Array.isArray(value)) {
          formFields[elem] = mapServerArray(value);
        }
        else if (value && typeof value === 'object') {
          formFields[elem] = mapServerObject(value);
        }
        else if (elem === form.CATEGORY) {
          formFields[elem] = value ?
            {value, label: displayData.category_name}
            : form.SELECTOR_DEFAULT_VALUE;
        }
        else if (elem === form.OWNERSHIP_TYPE) {
          formFields[elem] = value
            ? {value, label: displayData.ownership_type_display}
            : form.SELECTOR_DEFAULT_VALUE;
        }
        else {
          formFields[elem] = value;
        }
      }
    }

    this.props.appStoreCreate(DOCUMENTS_LIST_DELETE);
    this.props.appStoreUpdateListData(DOCUMENTS_LIST_DELETE, []);

    this.props.appFormsCreateStore(form.FORM_NAME, formFields);
  };

  render() {
    return this.props.children;
  }
}

OrganizationUpdateMapData.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownState) => {
  const storeName = ownState.storeName;
  const store = state.appStore[storeName];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

const mapDispatchToProps = {
  appStoreDelete,
  appStoreCreate,
  appFormsCreateStore,
  appFormsDeleteStore,
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUpdateMapData);