import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AccessControl from '../accessControl/AccessControl';
import {withRouter} from 'react-router-dom';

class SidebarButton extends Component {
  setFocus = () => {
    const {path, currentPage} = this.props;

    if (currentPage.includes(path)) {
      return 'sidebar__button_active';
    }
  };

  handleClick = () => {
    const {path} = this.props;
    this.props.history.push(path);
  };

  renderSidebarButton = () => {
    const {isSidebarOpen, title, icon} = this.props;

    if (isSidebarOpen) {
      return (
        <div onClick={this.handleClick}
             className={`sidebar__button ${this.setFocus()}`}>
          <div className='sidebar__title'>
            <div className='sidebar__icon-container'>
              <i className={icon} />
            </div>
            <div className='sidebar__delimiter'>
              &nbsp;
            </div>
            <div className='sidebar__text-container'>
              {title}
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div onClick={this.handleClick}
             className={`sidebar__button sidebar__button_closed ${this.setFocus()}`}>
          <i className={icon} />
        </div>
      );
    }
  };

  render() {
    const {permission} = this.props;

    return (
      <AccessControl permission={permission} accessCheck={true}>
        {this.renderSidebarButton()}
      </AccessControl>
    );
  }
}

SidebarButton.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  handleClick: PropTypes.func,
  permission: PropTypes.string,
  currentPage: PropTypes.string,
  isSidebarOpen: PropTypes.bool
};

export default withRouter(SidebarButton);