import React from 'react';
import {Clay, Button} from '@n3/kit';

import DemandsTable from './DemandsTable';
import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import StaffSearch from '../../layouts/StaffSearch';
import DemandsListFilter from './DemandsListFilter';
import {PROFESSION, STATUS} from './filterConstructor';
import {DEMANDS_LIST} from '../../../constants/pagesName';
import StaffPaginator from '../../layouts/StaffPaginator';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {dataSourceList, DEMANDS} from '../../../constants/dataSourcesList';

export const DEMAND_STORE = 'demandStore';

class DemandsList extends React.Component {

  componentWillMount() {
    const userRole = getCookie('role');

    if (userRole) {
      const dataSource = dataSourceList[userRole][DEMANDS];
      this.props.appStoreCreate(DEMAND_STORE, dataSource);
    }
  }

  componentWillUnmount() {
    this.props.appStoreDelete(DEMAND_STORE);
    this.props.appFilterDelete({name: PROFESSION});
    this.props.appFilterDelete({name: STATUS});
  }

  handleClick = () => {
    const path = this.props.match.url;
    this.props.history.push(`${path}/create`);
  };

  renderAddButton = () => {
    return (
      <AccessControl permission={permission.DEMAND_CREATE}>
        <Button color="primary"
                onClick={this.handleClick}>
          Добавить
        </Button>
      </AccessControl>
    );
  };

  render() {
    return (
      <AccessControl permission={permission.DEMAND_READ_LIST} redirect={'/hasNoAccess'}>
        <Clay className="app-container app-container__list-area">
          <PageHeader title={DEMANDS_LIST}
                      bgColor="gray5"
                      controlButton={this.renderAddButton()} />

          <Clay padding={[3, 5]}>
            <DemandsListFilter storeName={DEMAND_STORE} />

            <Clay padding={[2, 0, 6]}>
              <StaffSearch storeName={DEMAND_STORE} />
            </Clay>

            <DemandsTable storeName={DEMAND_STORE} />

            <Clay padding={[0, 0, 2]} />

            <Clay padding={[2, 0, 0, 0]}>
              <StaffPaginator storeName={DEMAND_STORE} />
            </Clay>
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

export default DemandsList;