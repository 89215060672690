import React from 'react';
import {Clay} from '@n3/kit';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import DocumentsTable from './DocumentsTable';
import StaffSearch from '../../layouts/StaffSearch';
import DocumentsListFilter from './DocumentsListFilter';
import StaffPaginator from '../../layouts/StaffPaginator';

export const DOCUMENTS_LIST_DATA = 'documentsListData';
export const DOCUMENTS_LIST_DELETE = 'documentsListDelete';

class DocumentsList extends React.Component {

  componentDidMount() {
    const docsType = this.props.docsType;
    const id = this.props.match.params.id;
    const dataSource = `/documents/${docsType}/${id}/`;
    this.props.appStoreCreate(DOCUMENTS_LIST_DATA, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(DOCUMENTS_LIST_DATA);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {dataSource, listData} = this.props;

    if (dataSource && !listData) {
      this.props.appStoreGetListData(DOCUMENTS_LIST_DATA, dataSource);
    }
  }

  render() {
    const {forUpdate} = this.props;

    return (
      <Clay>
        <Clay margin={[0, 0, 3]}>
          <Clay padding={[0, 0, 3, 0]}>
            <DocumentsListFilter storeName={DOCUMENTS_LIST_DATA} />
          </Clay>

          <StaffSearch storeName={DOCUMENTS_LIST_DATA} />
        </Clay>

        <DocumentsTable storeName={DOCUMENTS_LIST_DATA} forUpdate={forUpdate} />

        <Clay padding={[3, 0]} className="app-container__row">
          <StaffPaginator storeName={DOCUMENTS_LIST_DATA} />

          {this.props.renderControlButton}
        </Clay>
      </Clay>
    );
  }
}

DocumentsList.propTypes = {
  forUpdate: PropTypes.bool,
  docsType: PropTypes.string.isRequired,
  renderControlButton: PropTypes.object
};

export default withRouter(DocumentsList);