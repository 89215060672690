import { connect } from 'react-redux';

import {appStoreCreate, appStoreDelete, appStoreGetListData} from '../../../store/appStore/actions';
import VacanciesList, {VACANCY_STORE} from './VacanciesList';

const mapStateToProps = (state) => {
  const store = state.appStore[VACANCY_STORE];

  return {
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetListData,
};

export default connect(mapStateToProps, mapDispatchToProps)(VacanciesList);