import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Table, Thead, Tbody, Th, Td, Clay, LoadingArea, ListPlaceholder} from '@n3/kit';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {NON_BREAKING_SPACE} from '../../../constants/symbolsList';

class RecruitmentTable extends Component {

  renderTableBody = () => {
    const specList = this.props.listData;

    return specList.map(spec => {
      return (
        <tr key={spec.id}>
          <Td>{spec.employment_status_display}</Td>
          <Td>{spec.full_name}</Td>
          <Td>{spec.organization_actual.phone ? `${spec.organization_actual.name} (${spec.organization_actual.phone})` : spec.organization_actual.name}</Td>
        </tr>
      );
    });
  };

  render() {
    const {listData, isLoading} = this.props;
    const organizationHeader = `Контактная${NON_BREAKING_SPACE}организация`

    return (
      <Clay>
        <Table className='table'>
          <Thead>
          <tr>
            <Th className='table__column_width--10'>Статус</Th>
            <Th className='table__column_width--20'>ФИО</Th>
            <Th className='table__column_width--35'>{organizationHeader}</Th>
          </tr>
          </Thead>

          <Tbody>
          {listData && !isLoading && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
        {listData && !isLoading && !listData.length &&
        <ListPlaceholder> Не найдено ни одного специалиста </ListPlaceholder>}
      </Clay>
    );
  }
}

RecruitmentTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null,
    isLoading: store ? store.storeIsLoading : null
  };
};

export default connect(mapStateToProps)(withRouter(RecruitmentTable));
