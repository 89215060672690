export const ID = 'id';
export const DEGREE = 'degree';
export const COURSE = 'course';
export const DATE_END = 'date_end';
export const DATE_START = 'date_start';
export const SPECIALITY = 'speciality';
export const STUDY_MODE = 'study_mode';
export const ORGANIZATION = 'organization';
export const QUALIFICATION = 'qualification';
export const EDUCATION_TYPE = 'education_type';
export const YEAR_EDUCATION = 'year_education';
export const SPECIALIZATION = 'specialization';
export const PLANNED_DATE_END = 'planned_date_end';
export const EDUCATION_PROGRAM = 'education_program';

export const EDUCATION_UPDATE_FORM = {
  [ID]: null,
  [DATE_END]: null,
  [DATE_START]: null,
  [YEAR_EDUCATION]: null,
  [PLANNED_DATE_END]: null,
  [COURSE]: {value: null, label: 'Выберите...'},
  [DEGREE]: {value: null, label: 'Выберите...'},
  [STUDY_MODE]: {value: null, label: 'Выберите...'},
  [SPECIALITY]: {value: null, label: 'Выберите...'},
  [ORGANIZATION]: {value: null, label: 'Выберите...'},
  [QUALIFICATION]: {value: null, label: 'Выберите...'},
  [EDUCATION_TYPE]: {value: null, label: 'Выберите...'},
  [SPECIALIZATION]: {value: null, label: 'Выберите...'},
  [EDUCATION_PROGRAM]: {value: null, label: 'Выберите...'}
};


