import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {change, formValueSelector, reduxForm} from 'redux-form';
import {log} from '@n3/kit';
import * as messages from '../../../constants/messages';

import validate from './formValidate';
import * as form from './formConstructor';
import OrganizationCreateForm from './OrganizationCreateForm';

const OrganizationCreateFormContainer = reduxForm({
    validate,
    form: form.FORM_NAME,
    enableReinitialize: true
  },
  {change})(OrganizationCreateForm);

const selector = formValueSelector(form.FORM_NAME);

const mapStetToProps = (state) => {
  return {
    [form.CATEGORY]: selector(state, form.CATEGORY),
    [form.OWNERSHIP_TYPE]: selector(state, form.OWNERSHIP_TYPE),
    onSubmitFail: () => {
      log({
          title: 'Ошибка',
          message: messages.SAVE_ERROR,
          color: 'danger',
          timeout: '5000'}
        );
    },
    initialValues: state.appForms[form.FORM_NAME]
  };
};

export default connect(mapStetToProps)(withRouter(OrganizationCreateFormContainer));