import React from 'react';
import {Clay} from '@n3/kit';
import PropTypes from 'prop-types';

import VacanciesTable from './VacanciesTable';
import CheckPage from '../../layouts/CheckPage';
import PageHeader from '../../layouts/PageHeader';
import StaffSearch from '../../layouts/StaffSearch';
import VacancyCreateModal from './VacancyCreateModal';
import VacanciesListFilter from './VacanciesListFilter';
import StaffPaginator from '../../layouts/StaffPaginator';
import {VACANCIES_LIST} from '../../../constants/pagesName';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';

export const VACANCY_STORE = 'vacancyStore';

class VacanciesList extends React.Component {

  componentDidMount() {
    const dataSource = '/vacancy/';
    this.props.appStoreCreate(VACANCY_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(VACANCY_STORE);
  }

  render() {
    const {renderDemandButtons} = this.props;

    return (
      <AccessControl permission={permission.VACANCY_READ_LIST} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <CheckPage condition="vacancy">
            <PageHeader title={VACANCIES_LIST}
                        bgColor="gray5"
                        controlButton={<VacancyCreateModal />} />
          </CheckPage>

          <Clay padding={[3, 5]}>
            <VacanciesListFilter storeName={VACANCY_STORE} />

            <Clay padding={[2, 0, 6, 0]}>
              <StaffSearch storeName={VACANCY_STORE} />
            </Clay>

            <VacanciesTable storeName={VACANCY_STORE} />

            <Clay padding={[0, 0, 2]} />

            <Clay padding={[2, 0, 0]} className="app-container__row">
              <StaffPaginator storeName={VACANCY_STORE} />
              {renderDemandButtons}
            </Clay>
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

VacanciesList.propTypes = {
  renderDemandButtons: PropTypes.any
};

export default VacanciesList;