import React from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Clay, createDropdownMenu, EllipsisDropdown, Tabs, log} from '@n3/kit';

import api from '../../../API';
import StaffTab from '../../layouts/StaffTab';
import DemandReadInfo from './DemandReadInfo';
import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import DemandDisplayData from './DemandDisplayData';
import * as messages from '../../../constants/messages';
import {mapErrorMessage} from '../../../services/mapper';
import {DEMAND} from '../../vacancy/list/filterConstructor';
import AccessControl from '../../accessControl/AccessControl';
import DemandReadControlButton from './DemandReadControlButton';
import * as permission from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';
import {DEMANDS_READ, DEMANDS_LIST} from '../../../constants/pagesName';
import {DEMANDS, dataSourceList} from '../../../constants/dataSourcesList';
import VacanciesListContainer from '../../vacancy/list/VacanciesListContainer';

export const DEMAND_READ_STORE = 'demandReadStore';

const PAGE_TABS = [
  {id: 1, title: 'Информация о потребности'},
  {id: 2, title: 'Вакансии'}
];

class DemandRead extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1,
      demandId: null,
      breadCrumbs: [
        {
          url: `/demands`,
          title: DEMANDS_LIST
        },
        {
          url: null,
          title: DEMANDS_READ
        }
      ]
    };
  }

  setCurrentTab = (tabId) => {
    this.setState({currentTab: tabId});
    const {id} = this.props.match.params;
    this.props.history.push(`/demands/read/${id}/${tabId}`);
  };

  componentDidMount() {
    const {tab} = this.props.match.params;
    this.demandId = this.props.match.params.id;
    this.saveAppFilter();
    this.setState(state => ({...state, demandId: this.props.match.params.id, currentTab: tab * 1}));
  }

  componentWillUnmount() {
    this.props.appStoreDelete(DEMAND_READ_STORE);
    this.props.appFilterDelete({name: DEMAND});
  }

  componentDidUpdate(prevProps) {
    const {userRole, displayData} = this.props;

    if (userRole && !displayData) {
      this.createStore(userRole);
    }
  }

  createStore = (userRole) => {
    const {demandId} = this.state;
    const dataSource = userRole && `${dataSourceList[userRole][DEMANDS]}${demandId}`;

    this.props.appStoreCreate(DEMAND_READ_STORE, dataSource);
    this.props.appStoreGetDisplayData(DEMAND_READ_STORE, dataSource);
  };

  saveAppFilter = () => {
    const value = this.demandId;
    const filter = {name: DEMAND, value, label: null};

    this.props.appFilterSave(filter);
  };

  redirectToUpdate = () => {
    const {id, tab} = this.props.match.params;
    const path = `/demands/update/${id}/${tab}`;
    this.props.history.push(path);
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  onClose = () => {
    const {demandId} = this.state;
    const request = `/demands/contractor/${demandId}/close/`;

    api.request(request, {method: 'patch'})
      .then(resp => {
        this.props.appStoreUpdateDisplayData(DEMAND_READ_STORE, resp);
      });
  };

  onDelete = () => {
    const id = this.props.match.params.id;
    const request = `/demands/contractor/${id}/`;
    return api.request(request, {method: 'delete'})
      .then(() => {
        this.props.history.push('/demands');
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = error.response.status === 400
          ? mapErrorMessage(error)
          : messages.DELETE_ERROR;
        this.renderErrorLog(errorMessage);
      });
  };

  renderDropdown = (demand) => {
    if (demand && demand.status !== 'opened') {
      return null;
    }

    const controlArr = [];

    const userRole = getCookie('role');

    if (checkPermission(userRole, permission.DEMAND_UPDATE)) {
      controlArr.push({
        type: 'ellipsis',
        onClick: this.redirectToUpdate,
        label: 'Редактировать'
      });
    }

    const accessCheck = demand && demand.vacancies.length === 0;

    if (checkPermission(userRole, permission.DEMAND_DELETE, accessCheck)) {
      controlArr.push({
        type: 'ellipsis',
        onClick: this.onDelete,
        label: 'Удалить'
      });
    }

    if (controlArr.length === 0) {
      return null;
    }

    return (
      <EllipsisDropdown {...createDropdownMenu(controlArr)} />
    );
  };

  render() {
    const {currentTab, breadCrumbs} = this.state;
    const {displayData} = this.props;

    return (
      <AccessControl permission={permission.DEMAND_READ} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader title={DEMANDS_READ}
                      breadCrumbs={breadCrumbs}
                      controlButton={this.renderDropdown(displayData)} />

          <DemandDisplayData storeName={DEMAND_READ_STORE} />

          <Tabs
            tabs={PAGE_TABS}
            current={currentTab}
            setCurrentTab={this.setCurrentTab} />

          <Clay padding={[0, 0, 3]} />

          <StaffTab tabId={1} currentTabId={currentTab}>
            <DemandReadInfo storeName={DEMAND_READ_STORE} onDemandClose={this.onClose} />
          </StaffTab>

          <StaffTab tabId={2} currentTabId={currentTab}>
            <VacanciesListContainer renderDemandButtons={
              <DemandReadControlButton demandId={this.demandId}
                                       storeName={DEMAND_READ_STORE}
                                       onDemandClose={this.onClose} />} />
          </StaffTab>

          <AccessControl permission={permission.DEMAND_CLOSE}
                         accessCheck={displayData && displayData.status === 'opened'}>
            <Clay className='app-container__row_flex-end' padding={[0, 5, 5, 0]}>
              <Button color="primary" onClick={this.onClose}>
                Закрыть
              </Button>
            </Clay>
          </AccessControl>
        </Clay>
      </AccessControl>
    );
  }
}

export default withRouter(DemandRead);