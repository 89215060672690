import React from 'react';
import {Field} from 'redux-form';
import {Alert, Clay, Col, FieldLabel, Row} from '@n3/kit';

import * as validator from './formValidate';
import StaffInput from '../../form/StaffInput';
import {getCookie} from '../../../utils/Cookie';
import StaffTextArea from '../../form/StaffTextArea';
import StaffDatepicker from '../../form/StaffDatepicker';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as form from '../../specialists/update/formConstructor';
import StaffSimpleCheckbox from '../../form/StaffSimpleCheckbox';
import * as permission from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';
import * as dictionaries from '../../../constants/dictionariesSource';
import AccessControl from '../../accessControl/AccessControl';
import * as rolesList from '../../../constants/rolesList';

class WorkForm extends React.Component {

  componentDidUpdate(prevProps, prevState) {
    const formName = this.props.form;
    const {invalid, saveCounter} = this.props.formValues;
    const prevSaveCounter = prevProps.formValues && prevProps.formValues.saveCounter;

    console.log(invalid, saveCounter);

    if (saveCounter && saveCounter !== prevSaveCounter && formName && invalid) {
      Object.keys(form.SPECIALIST_WORK_FORM).forEach(field => this.props.touch(form, field));
    }
  }

  componentWillUnmount() {
    const {formValues, invalid} = this.props;
    this.props.saveForm({...formValues, invalid});
  }

  canUpdate = (permission) => {
    const {createWork, updateForm} = this.props;
    const userRole = getCookie('role');

    if (userRole === rolesList.BUILDER) {
      return checkPermission(userRole, permission, updateForm);
    }
    else {
      return createWork ? true : checkPermission(userRole, permission, updateForm);
    }
  };

  handleChange = (name, value) => {
    const {form} = this.props;
    this.props.touch(form, name);
    this.props.change(name, value);
  };

  render() {
    const {error, rank, organization, main_work, createWork} = this.props;

    return (
      <Clay padding={[2, 3, 5, 4]}>
        {error && <Alert color="danger" title="Ошибка:"> {error} </Alert>}

        <Row>
          <Col xs={6}>
            <FieldLabel>
              Период работы
            </FieldLabel>
            <Row>
              <Col xs={6}>
                <Field label="Дата начала"
                       name={form.DATE_START}
                       component={StaffDatepicker}
                       handleChange={this.handleChange}
                       validate={validator.REQUIRED_INPUT}
                       isRequired={this.canUpdate(permission.WORK_UPDATE)}
                       isDisabled={!this.canUpdate(permission.WORK_UPDATE)} />
              </Col>
              <Col xs={6}>
                <AccessControl permission={permission.WORK_CREATE_DATE_END_REQ}>
                  <Field label="Дата окончания"
                         name={form.DATE_END}
                         isRequired={createWork}
                         component={StaffDatepicker}
                         handleChange={this.handleChange}
                         validate={validator.REQUIRED_INPUT}
                         isDisabled={!this.canUpdate(permission.WORK_UPDATE)} />
                </AccessControl>
                <AccessControl permission={permission.WORK_UPDATE}>
                  <Field label="Дата окончания"
                         name={form.DATE_END}
                         component={StaffDatepicker}
                         handleChange={this.handleChange}
                         isDisabled={!this.canUpdate(permission.WORK_UPDATE)} />
                </AccessControl>
              </Col>
            </Row>
          </Col>
        </Row>

        <Clay padding={[4, 0]}>
          <Row>
            <Col xs={3}>
              <Field label="Должность"
                     name={form.RANK}
                     selectorValue={rank}
                     component={StaffSelectorAsync}
                     dataSource={dictionaries.RANKS}
                     handleChange={this.handleChange}
                     defaultValue={form.SELECTOR_DEFAULT_VALUE}
                     isDisabled={!this.canUpdate(permission.WORK_UPDATE)} />
            </Col>

            <Col xs={3}>
              <Field label="Организация"
                     name={form.ORGANIZATION}
                     selectorValue={organization}
                     component={StaffSelectorAsync}
                     handleChange={this.handleChange}
                     defaultValue={form.SELECTOR_DEFAULT_VALUE}
                     validate={validator.REQUIRED_SELECTOR}
                     dataSource={dictionaries.ORGANIZATIONS_CONTRACTOR}
                     isRequired={this.canUpdate(permission.WORK_UPDATE_ORGANIZATION)}
                     isDisabled={!this.canUpdate(permission.WORK_UPDATE_ORGANIZATION)} />
            </Col>

            <Col xs={3}>
              <Field label="Бригада"
                     name={form.BRIGATE}
                     component={StaffInput}
                     isDisabled={!this.canUpdate(permission.WORK_UPDATE)} />
            </Col>

            <Col xs={3}>
              <Clay padding={[4, 0]}>
                <Field label="Основная работа"
                       name={form.MAIN_WORK}
                       checkboxValue={main_work}
                       component={StaffSimpleCheckbox}
                       handleChange={this.handleChange}
                       isDisabled={!this.canUpdate(permission.WORK_UPDATE)} />
              </Clay>
            </Col>
          </Row>
        </Clay>
        <Row>
          <Col xs={6}>
            <Field label="Объекты капитального строительства"
                   name={form.OKS}
                   component={StaffTextArea}
                   isDisabled={!this.canUpdate(permission.WORK_UPDATE)} />
          </Col>
          <Col xs={6}>
            <Field label="Комплексные проекты"
                   name={form.COMPLEX_PROJECTS}
                   component={StaffTextArea}
                   isDisabled={!this.canUpdate(permission.WORK_UPDATE)} />
          </Col>
        </Row>
      </Clay>
    );
  }
}

export default WorkForm;