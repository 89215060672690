import React from 'react';
import PropTypes from 'prop-types';
import {Clay} from '@n3/kit';

import userPic from '../../assets/images/user.png';

class StaffImageLoader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      image: null
    };
  }

  handleChange = (event) => {
    const {name} = this.props.input;
    const image = event.target.files[0];

    this.setState({image: URL.createObjectURL(image)});
    this.props.handleChange(name, image);
  };

  renderPhotoLoader = () => {
    const {pathToFile} = this.props;

    if (!pathToFile) {
      return (
        <Clay textAlign="center"
              bgColor="primary4"
              className="app-container__border specialist_header__photo specialist_header__photo_edit">
          <img src={userPic} alt=""
               style={{width: 'auto', height: '90%'}}/>
        </Clay>
      );
    }
    else if (typeof pathToFile === 'string') {
      return (
        <Clay textAlign="center"
              bgColor="gray5"
              className="specialist_header__photo specialist_header__photo_edit">
          <img src={pathToFile} alt="" style={{width: 'auto', height: '100%'}}/>
        </Clay>
      );
    }
    else {
      return (
        <Clay textAlign="center"
              bgColor="gray5"
              className="specialist_header__photo specialist_header__photo_edit">
          <img src={this.state.image} alt="" style={{width: 'auto', height: '100%'}}/>
        </Clay>
      );
    }
  };

  handleDeletePhoto = (event) => {
    event.preventDefault();
    const {name} = this.props.input;
    this.props.handleChange(name, null);
  };

  renderDeletePhoto = () => {
    const {pathToFile} = this.props;

    return pathToFile
      ? (
        <Clay margin={[0, 1, 0, 0]}>
          <label className="app-button app-button_small app-button__delete-button"
                 onClick={(event) => this.handleDeletePhoto(event)} >
            Удалить
          </label>
        </Clay>
      )
      : null;
  };

  render() {
    return (
      <Clay textAlign="center"
            margin={[0, 0, 0, 0]}
            style={{width: '350px'}}>
        {this.renderPhotoLoader()}
        <Clay margin={[3, 0, 0]} className="app-container__row_center">
          {this.renderDeletePhoto()}
          <label className="app-button app-button_small">
            <input type="file" onChange={this.handleChange} />
            Изменить фото
          </label>
        </Clay>
      </Clay>
    );
  }
}

StaffImageLoader.propTypes = {
  input: PropTypes.object,
  pathToFile: PropTypes.any
};

export default StaffImageLoader;