import React from 'react';
import {Row, Col} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import DisplayData from '../../layouts/DisplayData';
import AccessControl from '../../accessControl/AccessControl';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import * as permission from '../../../constants/permissionsList';
import {mapServerArrayToOneString} from '../../../services/mapper';

class OrganizationReadData extends React.Component {
  render() {
    const {orgDisplayData} = this.props;

    if (!orgDisplayData) {
      return null;
    }

    const {
      full_name, inn, ownership_type_display, kpp, ogrn, date_ogrn, activity_type, construction_type
    } = orgDisplayData;

    return (
      <StaffDisplayCard>
        <Row>
          <Col xs={6}>
            <DisplayData label="Полное наименование" data={full_name} />
          </Col>
          <Col xs={6}>
            <DisplayData label="Форма собственности" data={ownership_type_display} />
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <DisplayData label="ИНН" data={inn} />
          </Col>
          <Col xs={3}>
            <DisplayData label="КПП" data={kpp} />
          </Col>
          <Col xs={3}>
            <DisplayData label="ОГРН" data={ogrn} />
          </Col>
          <Col xs={3}>
            <DisplayData label="Дата регистрации" data={date_ogrn} />
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <DisplayData label="Виды деятельности" data={mapServerArrayToOneString(activity_type)} />
          </Col>
          <AccessControl permission={permission.ORGANIZATION_CREATE}>
            <Col xs={6}>
              <DisplayData label="Типы строительства" data={mapServerArrayToOneString(construction_type)} />
            </Col>
          </AccessControl>
        </Row>
      </StaffDisplayCard>
    );
  }
}

OrganizationReadData.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    orgDisplayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(OrganizationReadData);