import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {Alert, Button, Clay} from '@n3/kit';
import StaffInput from '../form/StaffInput';
import {withRouter} from 'react-router-dom';
import api from '../../API';
import * as messages from '../../constants/messages';

const CLIENT_ID = process.env.REACT_APP_AUTH_SERVER_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_AUTH_SERVER_CLIENT_SECRET;

const PASSWORD = 'password';
const REPEAT_PASSWORD = 'repeat_password';

const validate = (values) => {
  const errors = {};

  if(!values[PASSWORD]) {
    errors[PASSWORD] = messages.NOT_EMPTY
  }
  if(!values[REPEAT_PASSWORD]) {
    errors[REPEAT_PASSWORD] = messages.NOT_EMPTY
  }
  else if (values[PASSWORD] && values[REPEAT_PASSWORD] && values[PASSWORD] !== values[REPEAT_PASSWORD]) {
    errors[REPEAT_PASSWORD] = messages.PASSWORDS_NOT_EQUAL
  }

    return errors;
};

class UpdatePasswordForm extends Component {
  submit = (data) => {
    const request = '/users/password_reset/';
    const params = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET
    };

    delete data[REPEAT_PASSWORD];
    data.code = this.props.match.params.code;

    return api.request(request, {method: 'PATCH', params, data})
      .then(() => {
        this.props.history.push('/login');
      })
      .catch(() => {
        throw new SubmissionError({
          _error: messages.INVALID_MESSAGE_OR_PASSWORD
        });
      });
  };

  render() {
    const {error, handleSubmit, pristine, submitting} = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        {error && <Alert color="danger" title="Ошибка:">{error}</Alert>}

        <Field type="text"
               name={PASSWORD}
               label="Пароль"
               autoFocus={true}
               isRequired={true}
               autoComplete="email"
               component={StaffInput} />

        <Field type="password"
               name={REPEAT_PASSWORD}
               label="Повторите пароль"
               isRequired={true}
               autoComplete="password"
               component={StaffInput} />

        <Clay margin={[4, 0, 0]} textAlign="center" className="app-container__column">
          <Button type="submit" color="primary" disabled={pristine || submitting}>
            Сохранить
          </Button>
        </Clay>
      </form>
    );
  }
}

export default reduxForm({form: 'updatePasswordForm', validate})(withRouter(UpdatePasswordForm));