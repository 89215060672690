import {connect} from 'react-redux';

import {appStoreCreate, appStoreDelete, appStoreGetListData} from '../../../store/appStore/actions';
import OrientationReport, {ORIENTATION_REPORT_STORE} from './OrientationReport';

const mapStateToProps = (state) => {
  const demandStore = state.appStore[ORIENTATION_REPORT_STORE];

  return {
    storeFilters: demandStore ? demandStore.storeFilters : null,
    dataSource: demandStore ? demandStore.storeDataSource : null,
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetListData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrientationReport);