import React from 'react';
import {Row, Col} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import DisplayData from '../../layouts/DisplayData';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';

class OrganizationReadContacts extends React.Component {

  render() {
    const {orgDisplayData} = this.props;

    if (!orgDisplayData) {
      return null;
    }

    console.log(orgDisplayData);

    const {phone, email, work_schedule, website, juristic_address, fact_address, postal_address, manager} = orgDisplayData;

    return (
      <StaffDisplayCard>
        <Row>
          <Col xs={3}>
            <DisplayData label="Телефон" data={phone} />
          </Col>
          <Col xs={3}>
            <DisplayData label="E-mail" data={email} />
          </Col>
          <Col xs={3}>
            <DisplayData label="Режим работы" data={work_schedule} />
          </Col>
          <Col xs={3}>
            <DisplayData label="Сайт" data={website} />
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <DisplayData label="Юридический адрес" data={juristic_address} />
          </Col>
          <Col xs={3}>
            <DisplayData label="Фактический адрес" data={fact_address} />
          </Col>
          <Col xs={3}>
            <DisplayData label="Почтовый адрес" data={postal_address} />
          </Col>
          <Col xs={3}>
            <DisplayData label="Руководитель" data={manager} />
          </Col>
        </Row>
      </StaffDisplayCard>
    );
  }
}

OrganizationReadContacts.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    orgDisplayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(OrganizationReadContacts);