import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Clay} from '@n3/kit';

import {
  appStoreCreate,
  appStoreDelete,
  appStoreSaveFilters,
  appStoreGetFilteredData
} from '../../../store/appStore/actions';
import {getCookie} from '../../../utils/Cookie';
import {mapFiltersToStr} from '../../../services/filters';
import StaffPaginator from '../../layouts/StaffPaginator';
import StaffSearch from '../../layouts/StaffSearch';
import VacancyCreateDemandTable from './VacancyCreateDemandTable';
import {dataSourceList, DEMANDS} from '../../../constants/dataSourcesList';
import {VACANCY_MODAL_CREATE} from '../../../constants/pagesName';

const VACANCY_CREATE_DEMANDS_LIST = 'vacancyCreateDemandsList';

class VacancyCreateDemandsList extends Component {

  componentWillMount() {
    const userRole = getCookie('role');
    const dataSource = dataSourceList[userRole][DEMANDS];

    this.props.appStoreCreate(VACANCY_CREATE_DEMANDS_LIST, dataSource);
    this.saveFilters();
  }

  componentWillUnmount() {
    this.props.appStoreDelete(VACANCY_CREATE_DEMANDS_LIST);
  }

  componentDidUpdate(prevProps) {
    this.getFilteredData();
  }

  saveFilters = () => {
    const filters = [{name: 'status', value: 'opened'}];

    this.props.appStoreSaveFilters(VACANCY_CREATE_DEMANDS_LIST, filters);
  };

  getFilteredData = () => {
    const dataSource = this.props.dataSource;
    const storeFilters = this.props.storeFilters;

    const request = `${dataSource}${mapFiltersToStr(storeFilters)}`;

    this.props.appStoreGetFilteredData(VACANCY_CREATE_DEMANDS_LIST, request);
  };

  render() {
    return (
      <Clay>
        <Clay font='h2'>{VACANCY_MODAL_CREATE}</Clay>
        <Clay padding={[3, 0]}>
          <StaffSearch storeName={VACANCY_CREATE_DEMANDS_LIST} />
        </Clay>
        <VacancyCreateDemandTable storeName={VACANCY_CREATE_DEMANDS_LIST} />

        <Clay padding={[3, 0]}
              className="staff-app_container staff-app_container--hor-space-between">
          <StaffPaginator storeName={VACANCY_CREATE_DEMANDS_LIST} />
        </Clay>
      </Clay>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[VACANCY_CREATE_DEMANDS_LIST];

  return {
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreSaveFilters,
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(VacancyCreateDemandsList);