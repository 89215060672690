import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {FieldLabel, Clay, Row, Col, Tag} from '@n3/kit';
import {withRouter} from 'react-router-dom';

import DisplayData from '../../layouts/DisplayData';
import AccessControl from '../../accessControl/AccessControl';
import {EMPTY_VALUE_SYMBOL} from '../../../constants/symbolsList';
import {DEMAND_VIEW_ORGANIZATION} from '../../../constants/permissionsList';

class DemandDisplayData extends React.Component {

  renderOrganizationLink = (author) => {
    return (
      <AccessControl permission={DEMAND_VIEW_ORGANIZATION} accessCheck={true}>
        <Clay padding={[3, 0]}>
          <FieldLabel>Организация</FieldLabel>
          {author.organization.name ? (
            <Clay>
              <Link to={`/organizations/read/${author.organization.id}/1`} target="_blank">
                {author.organization.name}
              </Link>
            </Clay>) : (<p>{EMPTY_VALUE_SYMBOL}</p>)}
        </Clay>
      </AccessControl>
    );
  };

  render() {
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    const {status_display, status, author, rank, date_created, date_close} = displayData;

    return (
      <Clay bgColor="bg">
        <Clay className="app-container__row">
          <Clay padding={[3, 0, 3, 5]} className="app-container">
            <DisplayData data={<Tag color={status === 'closed' ? 'red' : 'green'}>{status_display}</Tag>} label='Статус' />
            <DisplayData data={rank.name} label='Должность' />
          </Clay>
          <Clay padding={[3, 0, 3, 5]} className="app-container app-container__border_left">
            <DisplayData data={author.full_name} label='Автор' />
            {this.renderOrganizationLink(author)}
            <Clay padding={[0, 0, 0, 0]} />
            <Row>
              <Col xs={4}>
                <DisplayData data={date_created} label='Дата создания' />
              </Col>
              <Col xs={4}>
                <DisplayData data={date_close} label='Дата закрытия' />
              </Col>
            </Row>
          </Clay>
        </Clay>
      </Clay>
    );
  }
}

DemandDisplayData.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(withRouter(DemandDisplayData));