import * as role from './rolesList';

export const ORGANIZATION_READ = 'ORGANIZATION_READ';
export const ORGANIZATION_CREATE = 'ORGANIZATION_CREATE';
export const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE';
export const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE';
export const ORGANIZATION_READ_LIST = 'ORGANIZATION_READ_LIST';
export const ORGANIZATION_READ_INFO = 'ORGANIZATION_READ_INFO';

export const SPECIALIST_READ = 'SPECIALIST_READ';
export const SPECIALIST_CREATE = 'SPECIALIST_CREATE';
export const SPECIALIST_DELETE = 'SPECIALIST_DELETE';
export const SPECIALIST_UPDATE = 'SPECIALIST_UPDATE';
export const SPECIALIST_READ_LIST = 'SPECIALIST_READ_LIST';
export const SPECIALIST_READ_ACTIVE = 'SPECIALIST_READ_ACTIVE';
export const SPECIALIST_UPDATE_WORK = 'SPECIALIST_UPDATE_WORK';
export const SPECIALIST_READ_LIST_RANK = 'SPECIALIST_READ_LIST_RANK';
export const SPECIALIST_CREATE_ADD_WORK = 'SPECIALIST_CREATE_ADD_WORK';
export const SPECIALIST_READ_LIST_STATUS = 'SPECIALIST_READ_LIST_STATUS';
export const SPECIALIST_READ_LIST_FILTER = 'SPECIALIST_READ_LIST_FILTER';
export const SPECIALIST_UPDATE_EDUCATION = 'SPECIALIST_UPDATE_EDUCATION';
export const SPECIALIST_CREATE_FROM_LIST = 'SPECIALIST_CREATE_FROM_LIST';
export const SPECIALIST_CREATE_ADD_EDUCATION = 'SPECIALIST_CREATE_ADD_EDUCATION';
export const SPECIALIST_UPDATE_CHANGE_ACTIVE = 'SPECIALIST_UPDATE_CHANGE_ACTIVE';
export const SPECIALIST_UPDATE_CHANGE_STATUS = 'SPECIALIST_UPDATE_CHANGE_STATUS';
export const SPECIALIST_READ_LIST_FILTER_RANK = 'SPECIALIST_READ_LIST_FILTER_RANK';
export const SPECIALIST_READ_LIST_ORGANIZATION = 'SPECIALIST_READ_LIST_ORGANIZATION';
export const SPECIALIST_READ_EMPLOYMENT_STATUS = 'SPECIALIST_READ_EMPLOYMENT_STATUS';

export const DEMAND_READ = 'DEMAND_READ';
export const DEMAND_CREATE = 'DEMAND_CREATE';
export const DEMAND_UPDATE = 'DEMAND_UPDATE';
export const DEMAND_CLOSE = 'DEMAND_CLOSE';
export const DEMAND_DELETE = 'DEMAND_DELETE';
export const DEMAND_FILTER = 'DEMAND_FILTER';
export const DEMAND_READ_LIST = 'DEMAND_READ_LIST';
export const DEMAND_VIEW_ORGANIZATION = 'DEMAND_VIEW_ORGANIZATION';
export const DEMAND_FILTER_PROFESSION = 'DEMAND_FILTER_PROFESSION';
export const DEMAND_FILTER_ORGANIZATION = 'ORGANIZATION';

export const VACANCY_READ = 'VACANCY_READ';
export const VACANCY_CLOSE = 'VACANCY_CLOSE';
export const VACANCY_CREATE = 'VACANCY_CREATE';
export const VACANCY_UPDATE = 'VACANCY_UPDATE';
export const VACANCY_DELETE = 'VACANCY_DELETE';
export const VACANCY_READ_LIST = 'VACANCY_READ_LIST';
export const VACANCY_VIEW_ORGANIZATION = 'VACANCY_VIEW_ORGANIZATION';
export const VACANCY_LINK_ORGANIZATION = 'VACANCY_LINK_ORGANIZATION';
export const VACANCY_SEARCH_SPECIALISTS = 'VACANCY_SEARCH_SPECIALISTS';

export const REPORT_READ = 'REPORT_READ';

export const WORK_CREATE = 'WORK_CREATE';
export const WORK_UPDATE = 'WORK_UPDATE';
export const WORK_DELETE = 'WORK_DELETE';
export const WORK_CREATE_DATE_END_REQ = 'WORK_CREATE_DATE_END_REQ';
export const WORK_UPDATE_ORGANIZATION = 'WORK_UPDATE_ORGANIZATION';
export const WORK_CREATE_ORGANIZATION = 'WORK_CREATE_ORGANIZATION';

export const EDUCATION_CREATE = 'EDUCATION_CREATE';
export const EDUCATION_UPDATE = 'EDUCATION_UPDATE';
export const EDUCATION_DELETE = 'EDUCATION_DELETE';
export const EDUCATION_CREATE_DATE_END_REQ = 'EDUCATION_CREATE_DATE_END_REQ';
export const EDUCATION_UPDATE_ORGANISATION = 'EDUCATION_UPDATE_ORGANISATION';
export const EDUCATION_CREATE_ORGANISATION = 'EDUCATION_CREATE_ORGANISATION';

export const NOTIFY_READ_LIST = 'NOTIFY_READ_LIST';

export const PERMISSIONS_LIST = {

  [role.KS]: [
    ORGANIZATION_READ,
    ORGANIZATION_CREATE,
    ORGANIZATION_UPDATE,
    ORGANIZATION_DELETE,
    ORGANIZATION_READ_LIST,

    SPECIALIST_READ,
    SPECIALIST_UPDATE,
    SPECIALIST_DELETE,
    SPECIALIST_READ_LIST,
    SPECIALIST_READ_ACTIVE,
    SPECIALIST_UPDATE_WORK,
    SPECIALIST_READ_LIST_FILTER,
    SPECIALIST_READ_LIST_STATUS,
    SPECIALIST_UPDATE_EDUCATION,
    SPECIALIST_READ_EMPLOYMENT_STATUS,
    SPECIALIST_UPDATE_CHANGE_ACTIVE,
    SPECIALIST_READ_LIST_ORGANIZATION,

    DEMAND_READ,
    DEMAND_READ_LIST,
    DEMAND_VIEW_ORGANIZATION,
    DEMAND_FILTER_PROFESSION,
    DEMAND_FILTER_ORGANIZATION,

    VACANCY_READ,
    VACANCY_READ_LIST,
    VACANCY_VIEW_ORGANIZATION,
    VACANCY_LINK_ORGANIZATION,

    REPORT_READ,

    WORK_CREATE,
    WORK_UPDATE,
    WORK_DELETE,
    WORK_UPDATE_ORGANIZATION,
    WORK_CREATE_ORGANIZATION,

    EDUCATION_CREATE,
    EDUCATION_UPDATE,
    EDUCATION_DELETE,
    EDUCATION_UPDATE_ORGANISATION,
    EDUCATION_CREATE_ORGANISATION
  ],

  [role.FKSR]: [
    ORGANIZATION_READ,
    ORGANIZATION_READ_LIST,

    SPECIALIST_READ,
    SPECIALIST_UPDATE,
    SPECIALIST_DELETE,
    SPECIALIST_READ_LIST,
    SPECIALIST_READ_ACTIVE,

    DEMAND_READ,
    DEMAND_READ_LIST,
    DEMAND_FILTER_PROFESSION,
    DEMAND_FILTER_ORGANIZATION,

    VACANCY_READ,
    VACANCY_CREATE,
    VACANCY_UPDATE,
    VACANCY_DELETE,
    VACANCY_READ_LIST,
    VACANCY_VIEW_ORGANIZATION,
    VACANCY_LINK_ORGANIZATION,

    WORK_UPDATE
  ],

  [role.BUILDER]: [
    ORGANIZATION_UPDATE,
    ORGANIZATION_READ_INFO,

    SPECIALIST_READ,
    SPECIALIST_CREATE,
    SPECIALIST_UPDATE,
    SPECIALIST_DELETE,
    SPECIALIST_READ_LIST,
    SPECIALIST_READ_LIST_RANK,
    SPECIALIST_CREATE_ADD_WORK,
    SPECIALIST_UPDATE_CHANGE_STATUS,
    SPECIALIST_READ_LIST_FILTER_RANK,

    DEMAND_READ,
    DEMAND_CREATE,
    DEMAND_UPDATE,
    DEMAND_DELETE,
    DEMAND_READ_LIST,
    DEMAND_CLOSE,

    VACANCY_READ,
    VACANCY_CLOSE,
    VACANCY_CREATE,
    VACANCY_UPDATE,
    VACANCY_DELETE,
    VACANCY_READ_LIST,
    VACANCY_SEARCH_SPECIALISTS,

    WORK_UPDATE,
    WORK_CREATE,
    WORK_DELETE,

    NOTIFY_READ_LIST
  ],

  [role.EDU]: [
    ORGANIZATION_UPDATE,
    ORGANIZATION_READ_INFO,

    SPECIALIST_READ,
    SPECIALIST_CREATE,
    SPECIALIST_UPDATE,
    SPECIALIST_DELETE,
    SPECIALIST_READ_LIST,
    SPECIALIST_CREATE_FROM_LIST,
    SPECIALIST_CREATE_ADD_EDUCATION,

    VACANCY_READ,
    VACANCY_READ_LIST,
    VACANCY_VIEW_ORGANIZATION,

    EDUCATION_CREATE,
    EDUCATION_UPDATE,
    EDUCATION_DELETE
  ],

  [role.STAFFING]: [
    ORGANIZATION_UPDATE,
    ORGANIZATION_READ_INFO,

    SPECIALIST_READ,
    SPECIALIST_CREATE,
    SPECIALIST_UPDATE,
    SPECIALIST_DELETE,
    SPECIALIST_READ_LIST,
    SPECIALIST_UPDATE_WORK,
    SPECIALIST_UPDATE_EDUCATION,
    SPECIALIST_CREATE_FROM_LIST,

    VACANCY_READ,
    VACANCY_READ_LIST,
    VACANCY_VIEW_ORGANIZATION,

    WORK_DELETE,
    WORK_CREATE,
    WORK_CREATE_DATE_END_REQ,
    WORK_CREATE_ORGANIZATION,

    EDUCATION_CREATE,
    EDUCATION_DELETE,
    EDUCATION_CREATE_DATE_END_REQ,
    EDUCATION_CREATE_ORGANISATION
  ]
};
