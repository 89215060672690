import axios from 'axios';
import {getCookie} from './utils/Cookie';

const KomstroyAPI = () => {
  const r = (url, extra = {}) => {
    const headers = {};
    const token = getCookie('token');
    const STAFF_SERVER_API = process.env.REACT_APP_STAFF_SERVER;

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    extra.headers = extra.headers || {};

    axios.defaults.baseURL = extra && extra.baseURL ? extra.baseURL : STAFF_SERVER_API;
    return new Promise((resolve, reject) => {
      axios({
        url,
        ...extra,
        headers: {
          ...headers,
          ...extra.headers
        },
        timeout: 60000
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  return {
    request: r
  };
};

const api = KomstroyAPI();

export default api;
export const request = api.request;