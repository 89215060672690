import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {Clay, Col, Row, FieldWrapper, Input, Button, FieldLabel, log} from '@n3/kit';
import TitleWrap from '../../layouts/TitleWrap';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import * as messages from '../../../constants/messages';
import api from '../../../API';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import {mapErrorMessage} from '../../../services/mapper';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      server_error: false,
      values: {
        password_actual: '',
        password_new: '',
        password_confirm: ''
      },
      errors: {
        password_actual: '',
        password_new: '',
        password_confirm: ''
      }
    };
  }

  onValueChange = (name, value) => {
    this.setState((state) => {
      return {
        values: {
          ...state.values,
          [name]: value
        }
      };
    });
  };

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = (message) => {
    log({
      title: 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = () => {
    this.setState({server_error: false});
    this.errors = {};
    let hasErrors = this.validatePasswords();

    if (!hasErrors) {
      const {password_actual: old_password, password_new: new_password} = this.state.values;
      const values = {};
      values.old_password = old_password;
      values.new_password = new_password;
      const data = mapValuesToSend(values);
      const request = '/users/password_change/';

      return api.request(request, {method: 'patch', data})
        .then(() => {

          const path = '/';
          this.props.history.push(path);
          this.renderSuccessLog();
        })
        .catch((error) => {
          this.setState({server_error: true});
          const errorMessage = error.response.status === 400 ? messages.SAVE_ERROR : mapErrorMessage(error);
          this.renderErrorLog(errorMessage);
        });
    }
  };

  validatePasswords = () => {
    let hasErrors = false;

    if (this.state.values.password_actual === '') {
      this.errors.password_actual = messages.NOT_EMPTY;
      hasErrors = true;
    }

    if (this.state.values.password_new === '') {
      this.errors.password_new = messages.NOT_EMPTY;
      hasErrors = true;
    }

    if (this.state.values.password_confirm === '') {
      this.errors.password_confirm = messages.NOT_EMPTY;
      hasErrors = true;
    }

    if (this.state.values.password_new.length > 0 && this.state.values.password_confirm.length > 0 &&
      this.state.values.password_new !== this.state.values.password_confirm) {
      this.errors.password_new = messages.PASSWORDS_NOT_EQUAL;
      hasErrors = true;
    }

    if (this.state.values.password_new.length > 0 &&
      this.state.values.password_new.search(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/) < 0) {
      this.errors.password_new = messages.PASSWORD_MUST_CONTAIN;
      hasErrors = true;
    }

    if (this.state.values.password_actual.length > 0 && this.state.values.password_actual ===
      this.state.values.password_new) {
      this.errors.password_new = messages.PASSWORDS_MUST_NOT_EQUAL;
      hasErrors = true;
    }
    this.setState((state) => {
      return {
        errors: {
          ...state.errors,
          ...this.errors
        }
      };
    });

    return hasErrors;
  };

  render() {
    return (
      <Clay padding={[0, 5, 5, 5]}>
        <TitleWrap>
          Смена пароля
        </TitleWrap>
        <StaffDisplayCard>
          <Row>
            <Col xs={4}>
              <Clay padding={[2, 0, 1, 0]}>
                <Clay padding={[0, 0, 1, 0]}>
                  <FieldLabel isRequired
                              htmlFor="password-actual">
                    Старый пароль
                  </FieldLabel>
                </Clay>
                <FieldWrapper errors={[this.state.errors.password_actual]}>
                  <Input  id="password-actual"
                          readOnly={false}
                            type="password"
                          placeholder="Введите старый пароль"
                          value={this.state.values.password_actual}
                          hasError={!(this.state.errors.password_actual.length === 0)}
                          onChange={(evt) => { this.onValueChange('password_actual', evt.target.value); }}/>
                </FieldWrapper>
              </Clay>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Clay padding={[2, 0, 1, 0]}>
                <Clay padding={[0, 0, 1, 0]}>
                  <FieldLabel isRequired
                              htmlFor="password-new">
                    Новый пароль
                  </FieldLabel>
                </Clay>
                <FieldWrapper errors={[this.state.errors.password_new]}>
                  <Input  id="password-new"
                          readOnly={false}
                          type="password"
                          placeholder="Введите новый пароль"
                          value={this.state.values.password_new}
                          hasError={!(this.state.errors.password_new.length === 0)}
                          onChange={(evt) => { this.onValueChange('password_new', evt.target.value); }}/>
                </FieldWrapper>
              </Clay>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Clay padding={[2, 0, 1, 0]}>
                <Clay padding={[0, 0, 1, 0]}>
                  <FieldLabel isRequired
                              htmlFor="password-confirm">
                    Повторите пароль
                  </FieldLabel>
                </Clay>
                  <FieldWrapper errors={[this.state.errors.password_confirm]}>
                    <Input  id="password-confirm"
                            readOnly={false}
                            type="password"
                            placeholder="Повторите новый пароль"
                            value={this.state.values.password_confirm}
                            hasError={!(this.state.errors.password_confirm.length === 0)}
                            onChange={(evt) => { this.onValueChange('password_confirm', evt.target.value); }}/>
                  </FieldWrapper>
              </Clay>
            </Col>
          </Row>
        </StaffDisplayCard>
        <Clay padding={[3, 0, 0, 0]} className="app-container__row">
          <Clay />
          <Button color="primary" onClick={this.submit}>
            Изменить пароль
          </Button>
        </Clay>
      </Clay>
    );
  }
}

export default withRouter(ChangePasswordForm);