import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {appStoreCreate, appStoreDelete, appStoreGetListData} from '../../../store/appStore/actions';
import OrganizationsList, {ORGANISATION_STORE} from './OrganizationsList';

const mapStateToProps = (state) => {
  const store = state.appStore[ORGANISATION_STORE];

  return {
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetListData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrganizationsList));