import React, {Component} from 'react';
import {Clay} from '@n3/kit';

import UpdatePasswordForm from './UpdatePasswordForm';

class UpdatePassword extends Component {
  render() {
    return (
      <Clay className="app-container__column_center">
        <Clay bgColor="bg" padding={[6]} style={{width: '370px'}}>
          <Clay font="h5" textAlign="center">
            Восстановление пароля
          </Clay>
          <UpdatePasswordForm />
        </Clay>
      </Clay>
    );
  }
}

export default UpdatePassword;