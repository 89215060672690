export const NAME = 'name';
export const TYPE = 'type';
export const NUMBER = 'number';
export const DOC_DATE = 'doc_date';
export const SPECIALIST = 'specialist';
export const ATTACHMENTS = 'attachments';
export const VALID_UNTIL = 'valid_until';
export const ORGANIZATION = 'organization';

export const FORM_NAME = 'documentCreateForm';

export const DOCUMENT_CREATE_FORM = {
  [NAME]: null,
  [NUMBER]: null,
  [DOC_DATE]: null,
  [SPECIALIST]: null,
  [VALID_UNTIL]: null,
  [ATTACHMENTS]: null,
  [ORGANIZATION]: null,
  [TYPE]: {value: null, label: 'Выберите...'}
};
