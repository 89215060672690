import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Clay, FieldLabel, FieldWrapper, Select} from '@n3/kit';

class StaffSelector extends Component {
  render() {
    const {input, meta, isRequired, label, selectorValue, options, handleChange, isDisabled} = this.props;
    const {name} = input;

    return (
      <Clay>
        <FieldLabel isRequired={isRequired}>
          {label}
        </FieldLabel>

        <Clay padding={[1,0,0,0]} />

        <Select options={options}
                value={selectorValue}
                isDisabled={isDisabled}
                hasError={meta && meta.touched && !!meta.error}
                onChange={(value) => handleChange(name, value)} />
        {meta && meta.touched &&
        ((meta.error && <FieldWrapper errors={[meta.error]} />) ||
          (meta.warning && <span>{meta.warning}</span>))}
      </Clay>
    );
  }
}

StaffSelector.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
  selectorValue: PropTypes.object
};

export default StaffSelector;