import React from 'react';
import {Field} from 'redux-form';
import {Clay, Row, Col, Button, Tabs, ButtonsGroup, Tag} from '@n3/kit';

import FormBody from './FormBody';
import * as form from './formConstructor';
import InputWrap from '../../form/InputWrap';
import StaffTab from '../../layouts/StaffTab';
import DisplayData from '../../layouts/DisplayData';
import AccessControl from '../../accessControl/AccessControl';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as permission from '../../../constants/permissionsList';
import * as dictionaries from '../../../constants/dictionariesSource';

const PAGE_TABS = [
  {id: 1, title: 'Информация о потребности'},
  {id: 2, title: 'Вакансии'}
];

class DemandUpdateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1
    };
  }

  componentDidMount() {
    const {tab} = this.props.match.params;
    this.setState({currentTab: tab * 1});
  }

  setCurrentTab = (tabId) => {
    this.setState({currentTab: tabId});
    const {id} = this.props.match.params;
    this.props.history.push(`/demands/update/${id}/${tabId}`);
  };

  handleChange = (name, value) => {
    this.props.change(name, value);
  };

  render() {
    const {currentTab} = this.state;
    const {
      handleSubmit, submitAndContinue, handelCancelClick, rank, status, status_display, author, formBody,
      date_created, date_close
    } = this.props;

    if (!author) {
      return null;
    }

    const isClosed = status === 'closed' ? false : true;

    return (
      <AccessControl permission={permission.DEMAND_UPDATE} accessCheck={isClosed} redirect={'/hasNoAccess'}>
        <form onSubmit={handleSubmit}>
          <Clay bgColor="bg">
            <Clay className="app-container__row">
              <Clay padding={[3, 0, 3, 5]} className="app-container  app-container__border_right">
                <DisplayData data={<Tag color={status === 'closed' ? 'red' : 'green'}>{status_display}</Tag>}
                            label='Статус' />
                <Clay padding={[2, 0, 2, 0]}>
                  <InputWrap size={8}>
                    <Field label="Должность"
                          name={form.RANK}
                          isRequired={true}
                          selectorValue={rank}
                          component={StaffSelectorAsync}
                          dataSource={dictionaries.RANKS}
                          handleChange={this.handleChange}
                          defaultValue={form.SELECTOR_DEFAULT_VALUE} />
                  </InputWrap>
                </Clay>
                <Clay />
              </Clay>
              <Clay padding={[3, 0, 3, 5]} className="app-container">
                <DisplayData data={author.full_name} label='Автор' />
                <Row>
                  <Col xs={4}>
                    <DisplayData data={date_created} label='Дата создания' />
                  </Col>
                  <Col xs={4}>
                    <DisplayData data={date_close} label='Дата закрытия' />
                  </Col>
                </Row>
              </Clay>
            </Clay>
          </Clay>

          <Tabs
            tabs={PAGE_TABS}
            current={currentTab}
            setCurrentTab={this.setCurrentTab} />

          <Clay padding={[6, 5]}>
            <StaffTab tabId={1} currentTabId={currentTab}>
              <FormBody fields={formBody} handleChange={this.handleChange} />
            </StaffTab>

            <Clay margin={[4, 0]} className="app-container__row">
              <Button onClick={handelCancelClick}>
                Отмена
              </Button>
              <ButtonsGroup>
                <Button color="primary" onClick={handleSubmit(submitAndContinue)}>
                  Сохранить и продолжить
                </Button>
                <Button type="submit" color="primary">
                  Сохранить
                </Button>
              </ButtonsGroup>
            </Clay>
          </Clay>
        </form>
      </AccessControl>
    );
  }
}

export default DemandUpdateForm;