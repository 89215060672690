export const API = '/demands/contractor/';

export const FORM_NAME = 'demandCreateForm';

export const RANK = 'rank';
export const GENDER = 'gender';
export const PROFESSION = 'profession';
export const TECHNOLOGY = 'technology';
export const ACCESS_WORK_TYPE = 'access_work_type';

export const SELECTOR_DEFAULT_VALUE = {value: null, label: 'Выберите...'};

export const DEMAND_CREATE_FORM = {
  [GENDER]: {},
  [TECHNOLOGY]: [],
  [ACCESS_WORK_TYPE]: [],
  [RANK]: SELECTOR_DEFAULT_VALUE,
  [PROFESSION]: SELECTOR_DEFAULT_VALUE
};