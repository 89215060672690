import {connect} from 'react-redux';

import {appStoreCreate, appStoreDelete, appStoreGetListData} from '../../../store/appStore/actions';
import SpecialistsReport, {SPECIALIST_REPORT_STORE} from './SpecialistsReport';

const mapStateToProps = (state) => {
  const demandStore = state.appStore[SPECIALIST_REPORT_STORE];

  return {
    storeFilters: demandStore ? demandStore.storeFilters : null,
    dataSource: demandStore ? demandStore.storeDataSource : null,
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetListData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistsReport);