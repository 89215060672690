import React from 'react';
import PropTypes from 'prop-types';

class StaffTab extends React.Component {
  render() {
    const {tabId, currentTabId} = this.props;

    if (tabId === currentTabId) {
      return this.props.children;
    }
    else {
      return null;
    }
  }
}

StaffTab.propTypes = {
  tabId: PropTypes.number.isRequired,
  currentTabId: PropTypes.number.isRequired
};

export default StaffTab;