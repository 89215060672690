import React from 'react';
import {Clay} from '@n3/kit';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

import * as form from './formConstructor';
import * as validator from './formValidate';
import InputWrap from '../../form/InputWrap';
import StaffInput from '../../form/StaffInput';
import DisplayData from '../../layouts/DisplayData';
import StaffImageLoader from '../../form/StaffImageLoader';
import AccessControl from '../../accessControl/AccessControl';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as permission from '../../../constants/permissionsList';
import StaffSimpleCheckbox from '../../form/StaffSimpleCheckbox';
import * as dictionaries from '../../../constants/dictionariesSource';

class HeaderForm extends React.Component {

  render() {
    const {formFields, handleChange} = this.props;

    if (!formFields) {
      return null;
    }

    const {photo, nationality, gender, active, snils, birth_day, employment_status} = formFields;

    return (
      <Clay className="app-container__row_flex-start">
        <Clay style={{margin: '0px 0px auto 0px'}}>
          <Field name={form.PHOTO}
                 pathToFile={photo}
                 component={StaffImageLoader}
                 handleChange={handleChange} />
        </Clay>
        <Clay className="app-container" margin={[0, 5]}>
          <Clay className="specialist_header__column">
            <Clay padding={[2, 0, 2, 0]}>
              <InputWrap>
                <Field label="Фамилия"
                      isRequired={true}
                      name={form.LAST_NAME}
                      component={StaffInput}
                      validate={validator.REQUIRED_INPUT} />
              </InputWrap>
            </Clay>

            <Clay padding={[2, 0, 2, 0]}>
              <InputWrap>
                <Field label="Имя"
                      isRequired={true}
                      name={form.FIRST_NAME}
                      component={StaffInput}
                      validate={validator.REQUIRED_INPUT} />
              </InputWrap>
            </Clay>

            <Clay padding={[2, 0, 2, 0]}>
              <InputWrap>
                <Field label="Отчество"
                      name={form.SECOND_NAME}
                      component={StaffInput} />
              </InputWrap>
            </Clay>

            <Clay padding={[2, 0, 2, 0]}>
              <InputWrap>
                <Field label="Гражданство"
                      isRequired={true}
                      name={form.NATIONALITY}
                      selectorValue={nationality}
                      component={StaffSelectorAsync}
                      handleChange={handleChange}
                      dataSource={dictionaries.COUNTRIES}
                      defaultValue={form.SELECTOR_DEFAULT_VALUE}
                      validate={validator.REQUIRED_SELECTOR} />
              </InputWrap>
            </Clay>
          </Clay>

          <Clay className="specialist_header__column">
            <Clay padding={[0, 0, 2, 0]}>
              <DisplayData label="СНИЛС" data={snils} />
            </Clay>
            <Clay padding={[2, 0, 2, 0]}>
              <DisplayData label="Дата рождения" data={birth_day} />
            </Clay>
            <Clay padding={[2, 0, 2, 0]}>
              <DisplayData label="Пол" data={gender && gender.label} />
            </Clay>
          </Clay>

          <AccessControl permission={permission.SPECIALIST_UPDATE_CHANGE_ACTIVE}>
            <Clay className="specialist_header__column">
              <AccessControl permission={permission.SPECIALIST_READ_EMPLOYMENT_STATUS}>
                <DisplayData label="Статус занятости" data={employment_status && employment_status.label} />
              </AccessControl>
              <Clay style={{ padding: '30px 0px 0px 0px' }}>
                <Field label="Активный"
                      name={form.ACTIVE}
                      checkboxValue={active}
                      component={StaffSimpleCheckbox}
                      handleChange={handleChange} />
              </Clay>
            </Clay>
          </AccessControl>
        </Clay>
      </Clay>
    );
  }
}

HeaderForm.propTypes = {
  formFields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default HeaderForm;