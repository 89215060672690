import React from 'react';
import {withRouter} from 'react-router-dom';
import {createDropdownMenu, ButtonDropdown, Clay} from '@n3/kit';

import {removeCookie} from '../../utils/Cookie';
import userPic from '../../assets/images/user.png';
import {NOTIFICATIONS_LIST_STORE} from '../notification/Notifications';

class UserPic extends React.Component {

  componentDidUpdate(prevProps) {
    const token = this.props.userToken;
    if (token && token !== prevProps.userToken) {
      this.props.getRequestUserProfile(token, this.logoutUser);
    }
  }

  redirectToProfile = () => {
    this.props.history.push('/');
  };

  logoutUser = () => {
    this.props.history.push('/');
    this.props.appUpdateSidebar('/');
    removeCookie('role');
    removeCookie('token');
    this.props.appStoreUpdateListData(NOTIFICATIONS_LIST_STORE, []);
    this.props.setUserLogout();
  };

  render() {
    const {user, userToken} = this.props;

    const fullName = user.full_name;
    const organizationName = user.organization ? user.organization.name : null;

    if (!userToken) {
      return null;
    }

    return (
      <div className="app-container__row_flex-end user-pic">
        <Clay margin={[0, 1]} textAlign="right" className="user-pic__data-wrap">
          <div className="app-container__color_blue-1">
            {fullName}
          </div>
          <div className="app-container__font-size_12 app-container__color_gray-4">
            {organizationName}
          </div>
        </Clay>

        <ButtonDropdown
          {...createDropdownMenu([
            {
              type: 'button',
              onClick: this.redirectToProfile,
              label: 'Профиль'
            }, {
              type: 'button',
              onClick: this.logoutUser,
              label: 'Выйти'
            }])}>
          <img src={userPic} style={{width: '34px'}} alt="img" />
        </ButtonDropdown>
      </div>
    );
  }
}

export default withRouter(UserPic);