import React, {Component} from 'react';
import {Row, Col, Clay} from '@n3/kit';
import {connect} from 'react-redux';

import PropTypes from 'prop-types';
import DisplayData from '../../layouts/DisplayData';
import {mapServerArrayToOneString} from '../../../services/mapper';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';

class DemandReadInfo extends Component {
  render() {
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    const {profession, gender_display, access_work_type, technology} = displayData;

    return (
      <Clay margin={[3, 5]}>
        <StaffDisplayCard>
          <Row>
            <Col xs={6}>
              <DisplayData data={profession.name} label='Профессия' />
            </Col>
            <Col xs={6}>
              <DisplayData data={gender_display} label='Пол' />
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <DisplayData data={mapServerArrayToOneString(access_work_type)} label='Допуск к виду работ' />
            </Col>
            <Col xs={6}>
              <DisplayData data={mapServerArrayToOneString(technology)} label='Технологии' />
            </Col>
          </Row>
        </StaffDisplayCard>
      </Clay>
    );
  }
}

DemandReadInfo.propTypes = {
  storeName: PropTypes.string.isRequired,
  onDemandClose: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];
  return {
    displayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(DemandReadInfo);