import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import {appReducer} from './app/reducers';
import {appStoreReducer} from './appStore/reducers';
import {appFormsReducer} from './appForms/reducers';

import {userReducer} from './user/reducers';

export default combineReducers({
  app: appReducer,
  appStore: appStoreReducer,
  appForms: appFormsReducer,

  form: formReducer,

  user: userReducer
});