import * as form from './formConstructor';
import * as messages from '../../../constants/messages';
import moment from 'moment';

export default (values) => {
  const errors = {};

  if (!values[form.FIRST_NAME]) {
    errors[form.FIRST_NAME] = messages.NOT_EMPTY;
  }
  if (!values[form.LAST_NAME]) {
    errors[form.LAST_NAME] = messages.NOT_EMPTY;
  }

  if (!values[form.BIRTH_DAY]) {
    errors[form.BIRTH_DAY] = messages.NOT_EMPTY;
  }
  else {
    const minBirthDay = moment('01.01.1929', 'DD.MM.YYYY');
    const diff = minBirthDay.diff(moment(values[form.BIRTH_DAY], 'DD.MM.YYYY'), 'years');

    if (diff >= 0) {
      errors[form.BIRTH_DAY] = messages.TOO_OLD;
    }
  }

  if (values[form.NATIONALITY] && !values[form.NATIONALITY].value) {
    errors[form.NATIONALITY] = messages.NOT_EMPTY;
  }
  if (values[form.GENDER] && !values[form.GENDER].value) {
    errors[form.GENDER] = messages.NOT_EMPTY;
  }
  if (!values[form.SNILS]) {
    errors[form.SNILS] = messages.NOT_EMPTY;
  }
  else if (values[form.SNILS] && values[form.SNILS].includes('_')) {
    errors[form.SNILS] = messages.INVALID_DATA;
  }
  if (values[form.RANK] && !values[form.RANK].value) {
    errors[form.RANK] = messages.NOT_EMPTY;
  }
  if (!values[form.DATE_START_WORK]) {
    errors[form.DATE_START_WORK] = messages.NOT_EMPTY;
  }

  return errors;
}