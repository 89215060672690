import React, {Component} from 'react';
import {SortSwitcher} from '@n3/kit';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {appStoreUpdateFilter} from '../../store/appStore/actions';

class StaffOrdering extends Component {

  handleClick = (activeParam) => {
    const {storeName, filter} = this.props;
    const ordering = filter.value === null ?
      {...filter, value: `${activeParam}`} :
      filter.value.includes('-') ? {...filter, value: null} : {...filter, value: `-${activeParam}`};

    this.props.appStoreUpdateFilter(storeName, ordering);
  };

  setOrdering = () => {
    const {filter, filteredParam} = this.props;

    if (filter.value && filter.value.includes(filteredParam)) {
      return filter.value ? !filter.value.includes('-') : false;
    }
    return false;
  };

  render() {
    const {filter, filteredParam} = this.props;

    if (!filter) {
      return null;
    }

    return (
      <SortSwitcher param={filteredParam}
                    setSorting={this.handleClick}
                    activeParam={filter.value && filter.value.includes(filteredParam) && (filter.value.length - filteredParam.length <= 1) ? filteredParam : null}
                    asc={this.setOrdering()}>
        {this.props.children}
      </SortSwitcher>
    );
  }
}

StaffOrdering.propTypes = {
  storeName: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  filteredParam: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const filterName = ownProps.filterName;
  const store = state.appStore[storeName];

  return {
    filter: store ?
      (store.storeFilters ? store.storeFilters.find(filter => filter.name === filterName) : null)
      : null
  };
};

const mapDispatchToProps = {
  appStoreUpdateFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffOrdering);