export const ACTIVE = 'active';
export const ORDERING = 'ordering';
export const TECHNOLOGY = 'technology';
export const PROFESSION = 'profession';
export const EXPERIENCE = 'experience_min';
export const ORGANIZATION = 'organization';
export const EDUCATION_TYPE = 'education_type';
export const EMPLOYMENT_STATUS = 'employment_status';

export const SPECIALISTS_LIST_FILTER = [
  {name: ACTIVE, label: null, value: true},
  {name: ORDERING, label: null, value: null},
  {name: EXPERIENCE, label: null, value: null},
  {name: PROFESSION, label: 'Все', value: null},
  {name: TECHNOLOGY, label: 'Все', value: null},
  {name: ORGANIZATION, label: 'Все', value: null},
  {name: EDUCATION_TYPE, label: 'Все', value: null},
  {name: EMPLOYMENT_STATUS, label: 'Все', value: null}
];