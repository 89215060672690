import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {appFormsCreateStore, appFormsDeleteStore} from '../../../store/appForms/actions';
import {mapServerArray, mapServerObject} from '../../../services/mapper';
import * as form from '../update/formConstructor';
import {GENDER_VALUES_WITH_UNDEFINED} from '../../../constants/selectorsValues';

class DemandUpdateMapData extends React.Component {

  componentWillUnmount() {
    this.props.appFormsDeleteStore(form.FORM_NAME);
  }

  componentDidUpdate() {
    const displayData = this.props.displayData;
    if (displayData) {
      this.mapData(displayData);
    }
  }

  mapData = (displayData) => {
    const formFields = {};

    for (let elem in displayData) {
      const value = displayData[elem];

      if (elem in form.DEMAND_UPDATE_FORM) {

        if (Array.isArray(value)) {
          formFields[elem] = mapServerArray(value);
        } else if (value && typeof value === 'object') {
          formFields[elem] = mapServerObject(value);
        } else if (elem === form.GENDER) {
          formFields[elem] = GENDER_VALUES_WITH_UNDEFINED.find(gender => gender.value === value);
        } else {
          formFields[elem] = value;
        }
      }
    }

    this.props.appFormsCreateStore(form.FORM_NAME, formFields);
  };

  render() {
    return this.props.children;
  }
}

DemandUpdateMapData.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

const mapDispatchToProps = {
  appFormsCreateStore,
  appFormsDeleteStore
};

export default connect(mapStateToProps, mapDispatchToProps)(DemandUpdateMapData);