import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Clay, Tabs, ButtonsGroup, log} from '@n3/kit';
import {change, formValueSelector, reduxForm} from 'redux-form';

import api from '../../../API';
import HeaderForm from './HeaderForm';
import * as form from './formConstructor';
import StaffTab from '../../layouts/StaffTab';
import BaseInformationForm from './BaseInformationForm';
import * as messages from '../../../constants/messages';
import {mapErrorMessage} from '../../../services/mapper';
import {SPECIALIST_UPDATE_STORE} from './SpecialistUpdate';
import * as permission from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';
import DocumentCreate from '../../documents/create/DocumentCreate';
import DocumentsListContainer from '../../documents/list/DocumentsListContainer';
import {mapToFormData, mapValuesToSend} from '../../../services/mapValuesToSend';
import {appStoreUpdateDisplayData, appStoreUpdateListData} from '../../../store/appStore/actions';
import WorksListForUpdate, {
  WORKS_CREATE_LIST_STORE,
  WORKS_DELETE_LIST_STORE,
  WORKS_UPDATE_LIST_STORE
} from '../../works/update/WorksListForUpdate';
import EducationsListForUpdate, {
  EDUCATIONS_CREATE_LIST_STORE,
  EDUCATIONS_DELETE_LIST_STORE,
  EDUCATIONS_UPDATE_LIST_STORE
} from '../../education/update/EducationsListForUpdate';
import * as rolesList from '../../../constants/rolesList';
import {DOCUMENTS_LIST_DELETE} from '../../documents/list/DocumentsList';
import {getCookie} from '../../../utils/Cookie';
import ApproveModal from './ApproveModal';
import * as validator from './formValidate';

const PAGE_TABS = [
  {id: 1, title: 'Основная информация'},
  {id: 2, title: 'Опыт работы'},
  {id: 3, title: 'Образование'},
  {id: 4, title: 'Документы'}
];

class SpecialistUpdateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1,
      canSave: false,
      isOpened: false,
      renderCreateForm: true,
      redirectAfterSave: false
    };
  }

  componentDidMount() {
    const {user} = this.props;
    const tab = this.props.match.params.tab;
    this.setState({
      currentTab: tab * 1,
      canSave: ![rolesList.BUILDER, rolesList.EDU].includes(user.role)
    });
  }

  componentDidUpdate(prevProps, prevState) {

  }

  setCurrentTab = (tabId) => {
    this.setState({currentTab: tabId});
  };

  handleChange = (name, value) => {
    this.props.change(name, value);
  };

  handleCancel = (event) => {
    event.preventDefault();

    const {type, id, tab} = this.props.match.params;
    const path = `/specialists/read/${type}/${id}/${tab}`;

    this.props.history.push(path);
  };

  saveForm = async(values) => {
    const specId = this.props.match.params.id;
    const specType = this.props.match.params.type;
    const request = `/specialists/${specType}s/${specId}/`;
    const data = this.prepareDataToSend({...values});

    return data && await api.request(request, {method: 'PATCH', data});
  };

  workRequest = async(method) => {
    const userRole = getCookie('role');
    const {worksCreateList, worksUpdateList, worksDeleteList, user, worksUpdateUpdateList} = this.props;
    const specId = this.props.match.params.id;
    const request = `/specialists/${specId}/works/`;
    let data;

    switch (method) {
      case 'put':
        if (checkPermission(userRole, permission.WORK_UPDATE)) {
          if (userRole === rolesList.BUILDER) {
            const userOrgId = user.organization.id;
            const workList = worksUpdateList.filter(work => work.organization.value === userOrgId);

            data = workList.length
              ? {
                [form.WORKS]: workList.map(workValues => {
                  const values = {...workValues};
                  delete values[form.ORGANIZATION];
                  return mapValuesToSend(values);
                })
              }
              : null;
            const list = worksUpdateUpdateList.map(work => (
              {...work, saveCounter: work.saveCounter ? work.saveCounter++ : 1}
            ));

            this.props.appStoreUpdateListData(WORKS_UPDATE_LIST_STORE, list);
          }
          else if (!checkPermission(userRole, permission.WORK_UPDATE_ORGANIZATION)) {
            data = worksUpdateList.length && {
              [form.WORKS]: worksUpdateList.map(workValues => {
                const values = {...workValues};
                delete values[form.ORGANIZATION];
                return mapValuesToSend(values);
              })
            };

            const list = worksUpdateUpdateList.map(work => (
              {...work, saveCounter: work.saveCounter ? work.saveCounter++ : 1}
            ));

            this.props.appStoreUpdateListData(WORKS_UPDATE_LIST_STORE, list);
          }
          else {
            data = worksUpdateList.length && {
              [form.WORKS]: worksUpdateList.map(workValues => {
                return mapValuesToSend(workValues);
              })
            };

            const list = worksUpdateUpdateList.map(work => (
              {...work, saveCounter: work.saveCounter ? work.saveCounter++ : 1}
            ));

            this.props.appStoreUpdateListData(WORKS_UPDATE_LIST_STORE, list);
          }
        }
        break;
      case 'post':
        if (!checkPermission(userRole, permission.WORK_CREATE_ORGANIZATION)) {
          data = worksCreateList.length && {
            [form.WORKS]: worksCreateList.map(workValues => {
              this.props.appStoreUpdateListData(WORKS_CREATE_LIST_STORE, [
                  ...worksCreateList.filter(work => work.id !== workValues.id),
                  {...workValues, saveCounter: workValues.saveCounter ? workValues.saveCounter++ : 1}
                ]
              );
              const values = {...workValues};
              delete values[form.ORGANIZATION];
              delete values[form.ID];
              return mapValuesToSend(values);
            })
          };
        }
        else {
          data = worksCreateList.length && {
            [form.WORKS]: worksCreateList.map(workValues => {
              this.props.appStoreUpdateListData(WORKS_CREATE_LIST_STORE, [
                  ...worksCreateList.filter(work => work.id !== workValues.id),
                  {...workValues, saveCounter: workValues.saveCounter ? workValues.saveCounter++ : 1}
                ]
              );
              const values = {...workValues};
              delete values[form.ID];
              return mapValuesToSend(values);
            })
          };
        }
        break;
      case 'delete':
        data = worksDeleteList.length && {[form.WORKS]: worksDeleteList};
        break;
      default:
        break;
    }

    return data && await api.request(request, {method, data});
  };

  eduRequest = async(method) => {
    const userRole = getCookie('role');
    const {eduCreateList, eduUpdateList, eduDeleteList, user} = this.props;
    const specId = this.props.match.params.id;
    const request = `/specialists/${specId}/educations/`;
    let data = null;

    switch (method) {
      case 'put':
        if (checkPermission(userRole, permission.EDUCATION_UPDATE)) {
          if (userRole === rolesList.EDU) {
            const userOrgId = user.organization.id;
            const eduList = eduUpdateList.filter(edu => edu.organization.value === userOrgId);

            data = eduList.length
              ? {
                [form.EDUCATIONS]: eduList.map(eduValues => {
                  const values = {...eduValues};
                  delete values[form.ORGANIZATION];
                  return mapValuesToSend(values);
                })
              }
              : null;
          }
          else if (!checkPermission(userRole, permission.EDUCATION_UPDATE_ORGANISATION)) {
            data = eduUpdateList.length
              ? {
                [form.EDUCATIONS]: eduUpdateList.map(eduValues => {
                  const values = {...eduValues};
                  delete values[form.ORGANIZATION];
                  return mapValuesToSend(values);
                })
              }
              : null;
          }
          else {
            data = eduUpdateList.length
              ? {[form.EDUCATIONS]: eduUpdateList.map(eduValues => (mapValuesToSend(eduValues)))}
              : null;
          }
        }
        break;
      case 'post':
        if (!checkPermission(userRole, permission.EDUCATION_CREATE_ORGANISATION)) {
          data = eduCreateList.length
            ? {
              [form.EDUCATIONS]: eduCreateList.map(eduValues => {
                this.props.appStoreUpdateListData(EDUCATIONS_CREATE_LIST_STORE, [
                    ...eduCreateList.filter(edu => edu.id !== eduValues.id),
                    {...eduValues, saveCounter: eduValues.saveCounter ? eduValues.saveCounter++ : 1}
                  ]
                );
                const values = {...eduValues};
                delete values[form.ID];
                delete values[form.ORGANIZATION];
                return mapValuesToSend(values);
              })
            }
            : null;
        }
        else {
          data = eduCreateList.length
            ? {
              [form.EDUCATIONS]: eduCreateList.map(eduValues => {
                this.props.appStoreUpdateListData(EDUCATIONS_CREATE_LIST_STORE, [
                    ...eduCreateList.filter(edu => edu.id !== eduValues.id),
                    {...eduValues, saveCounter: eduValues.saveCounter ? eduValues.saveCounter++ : 1}
                  ]
                );
                const values = {...eduValues};
                delete values[form.ID];
                return mapValuesToSend(values);
              })
            }
            : null;
        }
        break;
      case 'delete':
        data = eduDeleteList.length ? {[form.EDUCATIONS]: eduDeleteList} : null;
        break;
      default:
        break;
    }

    return data && await api.request(request, {method, data});
  };

  deleteDocs = () => {
    const {deleteDocsList} = this.props;

    deleteDocsList.forEach(docId => {
      const request = `/documents/${docId}`;
      api.request(request, {method: 'delete'});
    });
  };

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = (message, title = null) => {
    log({
      title: title ? title : 'Ошибка',
      message: message,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = async(values) => {
    const {canSave, redirectAfterSave} = this.state;
    const {worksCreateList, eduCreateList, worksUpdateList, eduUpdateList, user} = this.props;

    if (canSave) {
      await Promise.all([
          this.deleteDocs(),
          this.workRequest('delete'),
          this.workRequest('put'),
          this.workRequest('post'),
          this.eduRequest('delete'),
          this.eduRequest('put'),
          this.eduRequest('post')
        ])
        .then(() => {
          Promise.all([
              this.saveForm(values)
            ])
            .then((resp) => {
              const data = resp[0];
              const {type, id} = this.props.match.params;
              const path = `/specialists/read/${type}/${id}/1`;

              if (redirectAfterSave && data) {
                this.props.history.push('/specialists/');
                this.renderSuccessLog();
              }
              else if (data) {
                this.props.history.push(path);
                this.renderSuccessLog();
              }
            })
            .catch(error => {
              this.renderErrorLog(mapErrorMessage(error), 'Ошибка во вкладке "Основная инфрмация"');
            });
        })
        .catch((error) => {
          switch (Object.keys(error.response.data)[0]) {
            case form.WORKS:
              this.renderErrorLog(mapErrorMessage(error), 'Ошибка во вкладке "Опыт работы"');
              break;
            case form.EDUCATIONS:
              this.renderErrorLog(mapErrorMessage(error), 'Ошибка во вкладке "Образование"');
              break;
            default:
              this.renderErrorLog(mapErrorMessage(error),
                mapErrorMessage(error).includes('работа')
                  ? 'Ошибка во вкладке "Опыт работы"'
                  : mapErrorMessage(error).includes('образование')
                  ? 'Ошибка во вкладке "Образование"'
                  : 'Ошибка во вкладке "Основная инфрмация"');
          }
        });
    }
    else if (
      (user.role === rolesList.EDU && (
        eduCreateList.findIndex(edu => !edu[form.DATE_END]) === -1 &&
        eduUpdateList.findIndex(edu => !edu[form.DATE_END]) === -1
      )) ||
      (user.role === rolesList.BUILDER && (
        worksCreateList.findIndex(work => !work[form.DATE_END]) === -1 &&
        worksUpdateList.findIndex(work => !work[form.DATE_END]) === -1
      ))
    ) {
      this.redirectAfterSave();
      this.toggleOpened();
    }
    else {
      this.setCanSave();
      setTimeout(() => {this.submit(values);}, 100);
    }
  };

  submitAndContinue = async(values) => {
    const {canSave} = this.state;
    const {worksCreateList, eduCreateList, worksUpdateList, eduUpdateList, user} = this.props;

    if (canSave) {
      return await Promise.all([
          this.deleteDocs(),
          this.workRequest('delete'),
          this.workRequest('put'),
          this.workRequest('post'),
          this.eduRequest('delete'),
          this.eduRequest('put'),
          this.eduRequest('post')
        ])
        .then(() => {
          Promise.all([
              this.saveForm(values)
            ])
            .then(resp => {
              if (resp[0]) {
                this.renderSuccessLog();
                this.props.appStoreUpdateDisplayData(SPECIALIST_UPDATE_STORE, resp[0]);
              }
              this.setCanSave();
            })
            .catch(error => {
              this.renderErrorLog(mapErrorMessage(error), 'Ошибка во вкладке "Основная инфрмация"');
            });
        })
        .catch((error) => {
          switch (Object.keys(error.response.data)[0]) {
            case form.WORKS:
              this.renderErrorLog(mapErrorMessage(error), 'Ошибка во вкладке "Опыт работы"');
              break;
            case form.EDUCATIONS:
              this.renderErrorLog(mapErrorMessage(error), 'Ошибка во вкладке "Образование"');
              break;
            default:
              this.renderErrorLog(mapErrorMessage(error),
                mapErrorMessage(error).includes('работа')
                  ? 'Ошибка во вкладке "Опыт работы"'
                  : mapErrorMessage(error).includes('образование')
                  ? 'Ошибка во вкладке "Образование"'
                  : 'Ошибка во вкладке "Основная инфрмация"');
          }
        });
    }
    else if (
      (user.role === rolesList.EDU && (
        eduCreateList.findIndex(edu => !edu[form.DATE_END]) === -1 &&
        eduUpdateList.findIndex(edu => !edu[form.DATE_END]) === -1
      )) ||
      (user.role === rolesList.BUILDER && (
        worksCreateList.findIndex(work => !work[form.DATE_END]) === -1 &&
        worksUpdateList.findIndex(work => !work[form.DATE_END]) === -1
      ))
    ) {
      this.toggleOpened();
      this.redirectAfterSave();
    }
    else {
      this.setCanSave();
      setTimeout(() => {this.submitAndContinue(values);}, 100);
    }
  };

  prepareDataToSend = (values) => {

    const userRole = getCookie('role');

    values[form.GRADE] = {...values[form.GRADE], value: values[form.GRADE].value ? values[form.GRADE].value : ''};
    values[form.REGION] = {...values[form.REGION], value: values[form.REGION].value ? values[form.REGION].value : ''};

    if (!checkPermission(userRole, permission.SPECIALIST_UPDATE_CHANGE_ACTIVE)) {
      delete values[form.ACTIVE];
    }

    if (values[form.PHONE].includes('_') || validator.EMAIL(values[form.EMAIL]) ||
      validator.WEBSITE(values[form.WEBSITE])) {
      const errors = [
        values[form.PHONE].includes('_'),
        validator.EMAIL(values[form.EMAIL]),
        validator.WEBSITE(values[form.WEBSITE])
      ];
      this.renderErrorLog(errors.join(', '), 'Ошибка во вкладке "Основная информация"');
      return null;
    }

    if (values[form.REGISTRATION_SPB]) {
      values[form.REGION] = '';
      values[form.REGISTRATION_ADDRESS] = '';
    }
    else {
      values[form.APARTMENT] = '';
      values[form.ADDRESS_UAS] = '';
    }

    if (values[form.REG_RES_ADDRESS_MATCH]) {
      values[form.RESIDENCE_ADDRESS] = '';
    }

    if (typeof values[form.PHOTO] === 'string') {
      delete values[form.PHOTO];
    }

    delete values[form.WORKS];
    delete values[form.EDUCATIONS];

    if (values[form.PHOTO]) {
      const data = mapValuesToSend({...values});
      data[form.PHOTO] = values[form.PHOTO];
      return mapToFormData(data);
    }

    return mapValuesToSend(values);
  };

  toggleOpened = () => {
    this.setState(state => ({...state, isOpened: !state.isOpened}));
  };

  setCanSave = () => {
    this.setState(state => ({...state, canSave: !state.canSave}));
  };

  redirectAfterSave = () => {
    this.setState(state => ({...state, redirectAfterSave: !state.redirectAfterSave}));
  };

  render() {
    const {currentTab, renderCreateForm, isOpened} = this.state;
    const {handleSubmit, headerForm, baseForm, user} = this.props;

    return (
      <form onSubmit={handleSubmit}>

        <Clay bgColor="bg" padding={[3, 5]}>
          <HeaderForm formFields={headerForm} handleChange={this.handleChange} />
        </Clay>

        <Tabs tabs={PAGE_TABS}
              current={currentTab}
              setCurrentTab={this.setCurrentTab} />

        <StaffTab tabId={1} currentTabId={currentTab}>
          <BaseInformationForm formFields={baseForm} handleChange={this.handleChange} {...this.props} />
        </StaffTab>

        <StaffTab tabId={2} currentTabId={currentTab}>
          <WorksListForUpdate renderCreateForm={renderCreateForm} />
        </StaffTab>

        <StaffTab tabId={3} currentTabId={currentTab}>
          <EducationsListForUpdate />
        </StaffTab>

        <StaffTab tabId={4} currentTabId={currentTab}>
          <Clay padding={[6, 5]}>
            <DocumentsListContainer forUpdate={true}
                                    docsType="by_specialist"
                                    renderControlButton={<DocumentCreate />} />
          </Clay>
        </StaffTab>

        <Clay padding={[3, 5]} className="app-container__row">
          <Button onClick={event => this.handleCancel(event)}>
            Отмена
          </Button>
          <ButtonsGroup>
            <Button color="primary" onClick={handleSubmit(this.submitAndContinue)}>
              Сохранить и продолжить
            </Button>
            <Button color="primary" onClick={handleSubmit(this.submit)}>
              Сохранить
            </Button>
          </ButtonsGroup>
        </Clay>

        <ApproveModal isOpened={isOpened}
                      toggleOpened={this.toggleOpened}
                      setCanSave={this.setCanSave}
                      role={user && user.role}
                      redirectAfterSave={this.redirectAfterSave}
                      submit={handleSubmit(this.submit)} />
      </form>
    );
  }
}

const selector = formValueSelector(form.FORM_NAME);

const getValuesList = (state, formName, storeName) => {
  const forms = state.form;
  const formValues = Object.keys(forms)
    .filter(form => form.includes(formName))
    .map(key => forms[key].values);

  const store = state.appStore[storeName];
  const worksStore = store && store.storeListData;

  return formValues.length ? formValues : worksStore;
};

const mapStateToProps = (state) => {
  const deleteDocsList = state.appStore[DOCUMENTS_LIST_DELETE];
  const worksDeleteList = state.appStore[WORKS_DELETE_LIST_STORE];
  const eduDeleteList = state.appStore[EDUCATIONS_DELETE_LIST_STORE];

  const workStore = state.appStore[WORKS_UPDATE_LIST_STORE];

  return {
    headerForm: {
      [form.PHOTO]: selector(state, form.PHOTO),
      [form.SNILS]: selector(state, form.SNILS),
      [form.GENDER]: selector(state, form.GENDER),
      [form.ACTIVE]: selector(state, form.ACTIVE),
      [form.BIRTH_DAY]: selector(state, form.BIRTH_DAY),
      [form.NATIONALITY]: selector(state, form.NATIONALITY),
      [form.EMPLOYMENT_STATUS]: selector(state, form.EMPLOYMENT_STATUS)
    },

    baseForm: {
      [form.GRADE]: selector(state, form.GRADE),
      [form.PHONE]: selector(state, form.PHONE),
      [form.REGION]: selector(state, form.REGION),
      [form.DISABILITY]: selector(state, form.DISABILITY),
      [form.TECHNOLOGY]: selector(state, form.TECHNOLOGY),
      [form.PROFESSION]: selector(state, form.PROFESSION),
      [form.ADDRESS_UAS]: selector(state, form.ADDRESS_UAS),
      [form.ACCESS_WORK_TYPE]: selector(state, form.ACCESS_WORK_TYPE),
      [form.REGISTRATION_SPB]: selector(state, form.REGISTRATION_SPB),
      [form.REG_RES_ADDRESS_MATCH]: selector(state, form.REG_RES_ADDRESS_MATCH)
    },

    initialValues: state.appForms[form.FORM_NAME],

    worksCreateList: getValuesList(state, 'createWorkForm', WORKS_CREATE_LIST_STORE),
    worksUpdateList: getValuesList(state, 'updateWorkForm', WORKS_UPDATE_LIST_STORE),
    worksUpdateUpdateList: workStore ? workStore.storeListData : null,
    worksDeleteList: worksDeleteList ? worksDeleteList.storeListData : null,

    eduCreateList: getValuesList(state, 'eduCreateForm', EDUCATIONS_CREATE_LIST_STORE),
    eduUpdateList: getValuesList(state, 'eduUpdateForm', EDUCATIONS_UPDATE_LIST_STORE),
    eduDeleteList: eduDeleteList ? eduDeleteList.storeListData : null,

    deleteDocsList: deleteDocsList ? deleteDocsList.storeListData : null,

    user: state.user
  };
};

const mapDispatchToProps = {
  appStoreUpdateListData,
  appStoreUpdateDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(
  reduxForm({
      form: form.FORM_NAME,
      enableReinitialize: true
    },
    {change})(SpecialistUpdateForm)
));