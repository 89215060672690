import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Clay } from '@n3/kit';
import { EMPTY_VALUE_SYMBOL } from '../../constants/symbolsList';

class DisplayData extends Component {
  render() {
    const data = this.props.data;
    const label = this.props.label;

    return (
      <Clay padding={[2, 0, 2, 0]}>
        <Clay margin={[0, 0, 1, 0]}
              className="app-container__font-size_13 app-container__color_gray-4">
          {label}
        </Clay>
        <Clay font="default">
          {data ? data : EMPTY_VALUE_SYMBOL}
        </Clay>
      </Clay>
    );
  }
}

DisplayData.propTypes = {
  data: PropTypes.any,
  label: PropTypes.string
};

export default DisplayData;