export const FORM_NAME = 'organizationForm';

export const INN = 'inn';
export const KPP = 'kpp';
export const OGRN = 'ogrn';
export const NAME = 'name';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const WEBSITE = 'website';
export const MANAGER = 'manager';
export const CATEGORY = 'category';
export const DATE_OGRN = 'date_ogrn';
export const FULL_NAME = 'full_name';
export const FACT_ADDRESS = 'fact_address';
export const WORK_SCHEDULE = 'work_schedule';
export const ACTIVITY_TYPE = 'activity_type';
export const OWNERSHIP_TYPE = 'ownership_type';
export const POSTAL_ADDRESS = 'postal_address';
export const JURISTIC_ADDRESS = 'juristic_address';
export const CATEGORY_DISPLAY = 'category_display';
export const CONSTRUCTION_TYPE = 'construction_type';

export const SELECTOR_DEFAULT_VALUE = {value: null, label: 'Выберите...'};

export const ORGANIZATION_CREATE_FORM = {
  [INN]: null,
  [NAME]: null,
  [FULL_NAME]: null,
  [CATEGORY]: SELECTOR_DEFAULT_VALUE,
  [OWNERSHIP_TYPE]: SELECTOR_DEFAULT_VALUE
};

export const ORGANISATION_UPDATE_FORM = {
  [INN]: null,
  [KPP]: null,
  [NAME]: null,
  [OGRN]: null,
  [PHONE]: null,
  [EMAIL]: null,
  [MANAGER]: null,
  [WEBSITE]: null,
  [DATE_OGRN]: null,
  [FULL_NAME]: null,
  [ACTIVITY_TYPE]: [],
  [FACT_ADDRESS]: null,
  [WORK_SCHEDULE]: null,
  [POSTAL_ADDRESS]: null,
  [CATEGORY_DISPLAY]:null,
  [CONSTRUCTION_TYPE]: [],
  [JURISTIC_ADDRESS]: null,
  [CATEGORY]: SELECTOR_DEFAULT_VALUE,
  [OWNERSHIP_TYPE]: SELECTOR_DEFAULT_VALUE
};

export const KPP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const INN_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const OGRN_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const PHONE_MASK = [
  '+',
  '7',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];