export const RANK = 'rank';
export const STATUS = 'status';
export const PROFESSION = 'profession';
export const ORGANIZATION = 'organization';

export const DEMANDS_LIST_FILTERS = [
  {
    name: RANK,
    label: 'Все',
    value: null
  },
  {
    name: STATUS,
    label: 'Все',
    value: null
  },
  {
    name: PROFESSION,
    label: 'Все',
    value: null
  },
  {
    name: ORGANIZATION,
    label: 'Все',
    value: null
  }
];