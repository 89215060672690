import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {Alert, Button, Clay} from '@n3/kit';

import api from '../../API';
import {withRouter, Link} from 'react-router-dom';
import StaffInput from '../form/StaffInput';
import * as messages from '../../constants/messages';

const CLIENT_ID = process.env.REACT_APP_AUTH_SERVER_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_AUTH_SERVER_CLIENT_SECRET;

class ResetPasswordForm extends Component {
  submit = (data) => {
    const request = 'users/confirmation_code/';
    const params = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET
    };

    return api.request(request, {method: 'post', params, data})
      .then(() => {
        this.props.history.push({pathname: '/resetPasswordMessage', state: {email: data.username}});
      })
      .catch(() => {
        throw new SubmissionError({
          _error: messages.INVALID_MESSAGE_OR_PASSWORD
        });
      });
  };

  render() {
    const {error, handleSubmit} = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        {error ? <Alert color="danger" title="Ошибка:">{error}</Alert> : <Clay padding={[4, 0, 0, 0]} />}

        <Field type="text"
               name="username"
               label="Логин"
               autoFocus={true}
               isRequired={true}
               autoComplete="email"
               component={StaffInput} />

        <Clay margin={[6, 0, 0]} textAlign="center" className="app-container__row_center">

          <Link to="/login" className="n3__button">
            Отмена
          </Link>
          <Clay padding={[0, 2]}/>
          <Button type="submit" color="primary">
            Отправить
          </Button>

        </Clay>
      </form>
    );
  }
}

export default withRouter(reduxForm({form: 'resetForm'})(ResetPasswordForm));