import React, {Component} from 'react';
import {Clay, FieldLabel, FieldWrapper, InputMask} from '@n3/kit';
import PropTypes from 'prop-types';

class StaffInputMask extends Component {
  render() {
    const {input, meta, label, isRequired, autoFocus, inputMask, autoComplete, disabled} = this.props;
    const {touched, error, warning} = meta;
    const {name} = input;

    return (
      <Clay>
        <FieldLabel isRequired={isRequired}>
          {label}
        </FieldLabel>

        <Clay padding={[1, 0, 0, 0]} />

        <InputMask {...input}
                   name={name}
                   mask={inputMask}
                   disabled={disabled}
                   autoFocus={autoFocus}
                   hasError={touched && !!error}
                   autoComplete={autoComplete ? autoComplete : null} />
        {touched &&
        ((error && <FieldWrapper errors={[error]} />) ||
          (warning && <span>{warning}</span>))}
      </Clay>
    );
  }
}

StaffInputMask.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  inputMask: PropTypes.array,
  autoComplete: PropTypes.string
};

export default StaffInputMask;