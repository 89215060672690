import React from 'react';
import {connect} from 'react-redux';
import {appFormsCreateStore, appFormsDeleteStore} from '../../../store/appForms/actions';
import * as form from './formConstructor';

class UserProfileUpdateMapData extends React.Component {

  componentDidMount() {
    const {user} = this.props;

    if (user.id) {
      this.mapData(user);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user.id !== nextProps.user.id) {
      if (nextProps.user.id) {
        this.mapData(nextProps.user);
      }
    }
  }

  componentWillUnmount() {
    this.props.appFormsDeleteStore(form.FORM_NAME);
  }

  mapData = (displayData) => {
    const formFields = {};

    for (let elem in displayData) {
      const value = displayData[elem];
      if (elem in form.USER_PROFILE_UPDATE_FORM) {
        formFields[elem] = value;
      }
    }

    this.props.appFormsCreateStore(form.FORM_NAME, formFields);
  };

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.app.userToken
  };
};

const mapDispatchToProps = {
  appFormsCreateStore,
  appFormsDeleteStore
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileUpdateMapData);
