import React from 'react';

import {Clay} from '@n3/kit';

const FILE_TYPES = ['pdf', 'rtf', 'doc', 'docx', 'xlsx', 'xls', 'jpg', 'jpeg', 'png', 'tiff', 'xml', 'zip'];

const mapObjectToArray = (object) => {
  let array = [];

  for (let elem in object) {
    if (Number.isInteger(elem * 1)) {
      array.push(object[elem]);
    }
  }

  return array;
};

class StaffFileUploader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      error: null
    };
  }

  handleChange = (event) => {
    const {name} = this.props;
    const files = mapObjectToArray(event.target.files);

    if (this.isFilesValid(files)) {
      this.props.handleChange(name, [...this.state.files, ...files]);
      this.setState(state => ({error: null, files: [...state.files, ...files]}));
    }
  };

  isFilesValid = (files) => {
    let isValid = true;

    files.forEach(file => {
      const fileSize = file.size / 1024 / 1024;
      let fileType = file.name.split('.');
      fileType = fileType[fileType.length - 1];

      console.log(FILE_TYPES.indexOf(fileType) === -1, fileSize > 50, '***',
        FILE_TYPES.indexOf(fileType) === -1 || fileSize > 50);

      if (FILE_TYPES.indexOf(fileType) === -1 || fileSize > 50) {
        this.setState({error: `${file.name} - недопустимый формат файла`});
        isValid = false;
      }
    });

    return isValid;
  };

  deleteDocument = (event, fileName) => {
    event.preventDefault();

    const {name} = this.props;
    const {files} = this.state;

    this.props.handleChange(name, [...files.filter(file => file.name !== fileName)]);
    this.setState(state => ({...state, files: state.files.filter(file => file.name !== fileName)}));
  };

  renderUploadedFile = () => {
    const {files} = this.state;

    return files.map(file => (
      <Clay key={file.name} paddingSequence={2} textAlign="center" className="app-container__row_just-row">
        <Clay color="gray3" padding={[0, 2, 0, 0]}>
          {file.name}
        </Clay>

        <span className="app-container__cursor_pointer"
              onClick={(event) => this.deleteDocument(event, file.name)}>
          <i className="fas fa-times" />
        </span>
      </Clay>
    ));
  };

  render() {
    const {error} = this.state;

    return (
      <Clay margin={[3, 0, 0]}>
        <Clay className="app-container__row">
          <label className="app-button">
            <input type="file" onChange={this.handleChange} multiple={true} />
            Выберите файл
          </label>
        </Clay>
        <Clay padding={[3, 0]}>
          <Clay>Размер не должен превышать 50,0 Мб</Clay>
          <Clay>Допустимые форматы: pdf, rtf, doc, docx, xlsx, xls, jpg, jpeg, png, tiff, xml, zip</Clay>
        </Clay>
        {error && <Clay paddingSequence={2} color="danger">{error}</Clay>}
        {this.renderUploadedFile()}
      </Clay>
    );
  }
}

export default StaffFileUploader;