import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route, withRouter} from 'react-router-dom';

import OrganizationRead from './read/OrganizationRead';
import {appUpdateSidebar} from '../../store/app/actions';
import OrganizationCreate from './create/OrganizationCreate';
import OrganizationUpdate from './update/OrganizationUpdate';
import OrganizationsListContainer from './list/OrganizationsListContainer';

class OrganizationsRouter extends React.Component {

  componentDidMount() {
    const page = window.location.pathname;
    this.props.appUpdateSidebar(page);
  }

  render() {
    const path = this.props.match.url;

    return (
      <Switch>
        <Route path={`${path}`} exact component={OrganizationsListContainer} />
        <Route path={`${path}/create`} component={OrganizationCreate} />
        <Route path={`${path}/read/:id/:tab`} component={OrganizationRead} />
        <Route path={`${path}/update/:id/:tab`} component={OrganizationUpdate} />
      </Switch>
    );
  }
}

const mapDispatchToProps = {
  appUpdateSidebar
};

export default connect(null, mapDispatchToProps)(withRouter(OrganizationsRouter));
