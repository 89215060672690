import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Clay, Table, Tbody, Td, Th, Thead, ListPlaceholder, LoadingArea, Button} from '@n3/kit';

import {getCookie} from '../../../utils/Cookie';
import {DOCUMENTS_LIST_DATA, DOCUMENTS_LIST_DELETE} from './DocumentsList';
import {NON_BREAKING_SPACE} from '../../../constants/symbolsList';
import {appStoreUpdateListData} from '../../../store/appStore/actions';

class DocumentsTable extends React.Component {

  handleDeleteDocument = (event, id) => {
    event.preventDefault();

    const {documentsList, deleteDocsList} = this.props;

    this.props.appStoreUpdateListData(DOCUMENTS_LIST_DELETE, [...deleteDocsList, id]);
    this.props.appStoreUpdateListData(DOCUMENTS_LIST_DATA, documentsList.filter(doc => doc.id !== id));

  };

  handleDownloadDocuments = (event, doc) => {
    event.preventDefault();
    const token = getCookie('token');
    const API = process.env.REACT_APP_STAFF_SERVER;
    const request = {
      file: `${API}/documents/${doc.id}/archive/?token=${token}`
    };

    window.open(request.file);
  };

  renderTableBody = () => {
    const {documentsList, forUpdate} = this.props;

    if (documentsList.length) {
      return documentsList.map(doc => {
        return (
          <tr key={doc.id}>
            <Td hasWordBreak>{doc.name}</Td>
            <Td>{doc.type && doc.type.name}</Td>
            <Td>{doc.number}</Td>
            <Td>{doc.doc_date}</Td>
            <Td>{doc.valid_until}</Td>
            <Td>
              <Button onlyIcon onClick={(event) => this.handleDownloadDocuments(event, doc)}>
                <i className="fas fa-file-download" />
              </Button>
            </Td>
            {forUpdate && (
              <Td>
                <Button onlyIcon onClick={(event) => this.handleDeleteDocument(event, doc.id)}>
                  <i className="fas fa-trash-alt" />
                </Button>
              </Td>
            )}
          </tr>
        );
      });
    }
  };

  render() {
    const {documentsList, isLoading, forUpdate} = this.props;
    const documentTypeHeader = `Тип${NON_BREAKING_SPACE}документа`;
    const documentNumberHeader = `Номер${NON_BREAKING_SPACE}документа`;
    const documentDateHeader = `Дата${NON_BREAKING_SPACE}документа`;
    const documentDateExpHeader = `Действителен${NON_BREAKING_SPACE}до`;

    return (
      <Clay>
        <Table className='table'>
          <Thead>
          <tr>
            <Th className='table__column_width--25'>Название</Th>
            <Th className='table__column_width--15'>{documentTypeHeader}</Th>
            <Th className='table__column_width--15'>{documentNumberHeader}</Th>
            <Th className='table__column_width--15'>{documentDateHeader}</Th>
            <Th className='table__column_width--15'>{documentDateExpHeader}</Th>
            <Th className='table__column_width--10'>Загрузить</Th>
            {forUpdate && <Th>Удалить</Th>}
          </tr>
          </Thead>

          <Tbody>
          {documentsList && !isLoading && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
        {documentsList && !isLoading && !documentsList.length &&
        <ListPlaceholder> Не найдено ни одного элемента </ListPlaceholder>}
      </Clay>
    );
  }
}

DocumentsTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];
  const deleteDocsList = state.appStore[DOCUMENTS_LIST_DELETE];

  return {
    isLoading: store ? store.storeIsLoading : null,
    dataSource: store ? store.storeDataSource : null,
    documentsList: store ? store.storeListData : null,
    deleteDocsList: deleteDocsList ? deleteDocsList.storeListData : null
  };
};

const mapDispatchToProps = {
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable);