import {connect} from 'react-redux';

import {appStoreCreate, appStoreDelete, appStoreGetDisplayData, appStoreUpdateDisplayData} from '../../../store/appStore/actions';
import {appFilterDelete, appFilterSave} from '../../../store/app/actions';
import DemandRead, {DEMAND_READ_STORE} from './DemandRead';

const mapStateToProps = (state) => {
  const store = state.appStore[DEMAND_READ_STORE];

  return {
    userRole: state.app.userRole,
    dataSource: store ? store.storeDataSource : null,
    displayData: store ? store.storeDisplayData : null
  };
};

const mapDispatchToProps = {
  appFilterSave,
  appStoreCreate,
  appStoreDelete,
  appFilterDelete,
  appStoreGetDisplayData,
  appStoreUpdateDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(DemandRead);