import React from 'react';
import {Clay, createDropdownMenu, EllipsisDropdown, Tabs} from '@n3/kit';

import api from '../../../API';
import StaffTab from '../../layouts/StaffTab';
import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import WorksList from '../../works/list/WorksList';
import SpecialistDisplayData from './SpecialistDisplayData';
import EducationList from '../../education/list/EducationList';
import {checkPermission} from '../../../services/checkPermission';
import SpecialistDisplayDataHeader from './SpecialistDisplayDataHeader';
import {SPECIALIST_READ, SPECIALISTS_LIST} from '../../../constants/pagesName';
import DocumentsListContainer from '../../documents/list/DocumentsListContainer';
import {SPECIALIST_DELETE, SPECIALIST_UPDATE} from '../../../constants/permissionsList';

export const SPECIALIST_READ_STORE = 'specialistReadStore';

const PAGE_TABS = [
  {id: 1, title: 'Основная информация'},
  {id: 2, title: 'Опыт работы'},
  {id: 3, title: 'Образование'},
  {id: 4, title: 'Документы'}
];

class SpecialistRead extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1,
      breadCrumbs: []
    };
  }

  componentDidMount() {
    const {tab} = this.props.match.params;

    this.userRole = getCookie('role');
    this.setState({currentTab: tab * 1});
    this.createStore();
    this.getBreadCrumbsList();
  }

  componentWillUnmount() {
    this.props.appStoreDelete(SPECIALIST_READ_STORE);
  }

  componentDidUpdate(prevProps) {
    const dataSource = this.props.dataSource;
    this.props.appStoreGetDisplayData(SPECIALIST_READ_STORE, dataSource);
  }

  createStore = () => {

    const {id, type} = this.props.match.params;
    const dataSource = `/specialists/${type}s/${id}/`;

    this.props.appStoreCreate(SPECIALIST_READ_STORE, dataSource);
  };

  setCurrentTab = (tabId) => {
    this.setState({currentTab: tabId});
    const {type, id} = this.props.match.params;
    this.props.history.push(`/specialists/read/${type}/${id}/${tabId}`);
  };

  getBreadCrumbsList = () => {
    let breadCrumbs = [];

    breadCrumbs = [
      {
        url: `/specialists`,
        title: SPECIALISTS_LIST[this.userRole]
      },
      {
        url: null,
        title: SPECIALIST_READ[this.userRole]
      }
    ];

    this.setState(state => ({...state, breadCrumbs}));
  };

  onDelete = () => {
    const specId = this.props.match.params.id;
    const specType = this.props.match.params.type;
    const request = `/specialists/${specType}s/${specId}/`;

    return api.request(request, {method: 'delete'})
      .then(() => {
        this.props.history.push('/specialists');
      })
      .catch((error) => {
        console.error(error);
        const path = '/specialists/';
        this.props.history.push(path);
      });
  };

  redirectToUpdate = () => {
    const {type, id, tab} = this.props.match.params;
    const path = `/specialists/update/${type}/${id}/${tab}`;
    this.props.history.push(path);
  };

  renderDropdown = () => {
    const controlArr = [];

    if (checkPermission(this.userRole, SPECIALIST_UPDATE)) {
      controlArr.push({
        type: 'ellipsis',
        onClick: this.redirectToUpdate,
        label: 'Редактировать'
      });
    }

    if (checkPermission(this.userRole, SPECIALIST_DELETE)) {
      controlArr.push({
        type: 'ellipsis',
        onClick: this.onDelete,
        label: 'Удалить'
      });
    }

    if (controlArr.length === 0) {
      return null;
    }

    return (
      <EllipsisDropdown
        {...createDropdownMenu(
          controlArr
        )
        }
      />
    );
  };

  render() {
    const {currentTab, breadCrumbs} = this.state;
    const {userRole} = this.props;

    return (
      <Clay className="app-container">
          <PageHeader breadCrumbs={breadCrumbs}
                      controlButton={this.renderDropdown()}
                      title={userRole && SPECIALIST_READ[userRole]} />

          <SpecialistDisplayDataHeader storeName={SPECIALIST_READ_STORE} />

          <Tabs tabs={PAGE_TABS}
                current={currentTab}
                setCurrentTab={this.setCurrentTab} />

          <Clay padding={[0, 5]}>
            <StaffTab tabId={1} currentTabId={currentTab}>
              <SpecialistDisplayData storeName={SPECIALIST_READ_STORE} />
            </StaffTab>

            <StaffTab tabId={2} currentTabId={currentTab}>
              <WorksList />
            </StaffTab>

            <StaffTab tabId={3} currentTabId={currentTab}>
              <EducationList />
            </StaffTab>

            <StaffTab tabId={4} currentTabId={currentTab}>
              <Clay padding={[6, 0]}>
                <DocumentsListContainer docsType="by_specialist" />
              </Clay>
            </StaffTab>
          </Clay>
        </Clay>
    );
  }
}

export default SpecialistRead;