import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import {Clay, Col, Row} from '@n3/kit';

import * as form from './formConstructor';
import * as validator from './formValidate';
import StaffInput from '../../form/StaffInput';
import StaffSelector from '../../form/StaffSelector';
import StaffInputMask from '../../form/StaffInputMask';
import AccessControl from '../../accessControl/AccessControl';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import StaffSimpleCheckbox from '../../form/StaffSimpleCheckbox';
import * as permission from '../../../constants/permissionsList';
import {DISABILITY_VALUES} from '../../../constants/selectorsValues';
import * as dictionaries from '../../../constants/dictionariesSource';
import TitleWrap from '../../layouts/TitleWrap';

class BaseInformationForm extends React.Component {

  componentDidMount() {
    const {formFields} = this.props;
    const {
      phone, registration_spb, address_uas, registration_address, reg_res_address_match,
      residence_address
    } = formFields;

    if (phone && phone.includes('_')) {
      this.props.touch(form.FORM_NAME, form.PHONE);
    }

    if (registration_spb && !address_uas.length) {
      this.props.touch(form.FORM_NAME, form.ADDRESS_UAS);
    }
    else if (!registration_spb && !registration_address) {
      this.props.touch(form.FORM_NAME, form.REGISTRATION_ADDRESS);
    }

    if (!reg_res_address_match && !residence_address) {
      this.props.touch(form.FORM_NAME, form.RESIDENCE_ADDRESS);
    }
  }

  render() {
    const {formFields, handleChange} = this.props;
    const {
      registration_spb, address_uas, region, reg_res_address_match, profession, technology, access_work_type,
      grade, disability
    } = formFields;

    return (
      <Clay padding={[0, 5]}>
        <TitleWrap>
          Контакты
        </TitleWrap>
        <Clay className="card card__container card__container_edit grid grid__3 grid__3_gap-row">
          <Clay className="grid grid__3_gap-row">
            <Field label="Телефон"
                   name={form.PHONE}
                   component={StaffInputMask}
                   inputMask={form.PHONE_MASK}
                   validate={validator.PHONE} />
            <Field label="E-mail"
                   name={form.EMAIL}
                   component={StaffInput}
                   validate={validator.EMAIL} />
          </Clay>

          <Clay className="grid grid__3_gap-row">
            <Field component={StaffInput}
                   name={form.PHONE_ADDITORY}
                   label="Дополнительный телефон" />
            <Field label="Факс"
                   name={form.FAX}
                   component={StaffInput} />
          </Clay>

          <Field label="Сайт"
                 name={form.WEBSITE}
                 component={StaffInput}
                 validate={validator.WEBSITE} />
        </Clay>

        <TitleWrap>
          Адрес
        </TitleWrap>
        <Clay className="card card__container card__container_edit">
          <Clay>
            <Clay padding={[0, 0, 2, 0]}>
              <Row>
                <Col xs={6}>
                  <Field name={form.REGISTRATION_SPB}
                         checkboxValue={registration_spb}
                         component={StaffSimpleCheckbox}
                         label="Регистрация в Санкт-Петербурге"
                         handleChange={handleChange} />
                </Col>
                <Col xs={6}>
                  <Field component={StaffSimpleCheckbox}
                         label="Адрес проживания совпадает с адресом регистрации"
                         handleChange={handleChange}
                         name={form.REG_RES_ADDRESS_MATCH}
                         checkboxValue={reg_res_address_match} />
                </Col>
              </Row>
            </Clay>
            <AccessControl permission={permission.SPECIALIST_UPDATE} accessCheck={!registration_spb}>
              <Clay padding={[2, 0, 2, 0]}>
                <Row>
                  <Col xs={4}>
                    <Field label="Регион"
                           name={form.REGION}
                           selectorValue={region}
                           component={StaffSelectorAsync}
                           handleChange={handleChange}
                           dataSource={dictionaries.REGIONS}
                           defaultValue={form.SELECTOR_DEFAULT_VALUE}
                           style={{maxWidth: '260px'}} />
                  </Col>
                </Row>
              </Clay>
            </AccessControl>
            <Clay padding={[2, 0, 0, 0]}>
              <Row>
                <AccessControl permission={permission.SPECIALIST_UPDATE} accessCheck={registration_spb}>
                  <Col xs={4}>
                    <Field name={form.ADDRESS_UAS}
                           label="Адрес регистрации"
                           isRequired={true}
                           selectorValue={address_uas}
                           component={StaffSelectorAsync}
                           handleChange={handleChange}
                           dataSource={dictionaries.ADDRESS_UAS}
                           defaultValue={form.SELECTOR_DEFAULT_VALUE}
                           validate={registration_spb && validator.REQUIRED_SELECTOR} />
                  </Col>
                  <Col xs={2}>
                    <Field type="number"
                           label="Квартира"
                           name={form.APARTMENT}
                           component={StaffInput} />
                  </Col>
                </AccessControl>
                <AccessControl permission={permission.SPECIALIST_UPDATE} accessCheck={!registration_spb}>
                  <Col xs={6}>
                    <Field label="Адрес регистрации"
                           component={StaffInput}
                           isRequired={true}
                           name={form.REGISTRATION_ADDRESS}
                           validate={!registration_spb && validator.REQUIRED_INPUT} />
                  </Col>
                </AccessControl>
                <AccessControl permission={permission.SPECIALIST_UPDATE} accessCheck={!reg_res_address_match}>
                  <Col xs={5}>
                    <Field component={StaffInput}
                           label="Адрес проживания"
                           isRequired={true}
                           name={form.RESIDENCE_ADDRESS}
                           validate={!reg_res_address_match && validator.REQUIRED_INPUT} />
                  </Col>
                </AccessControl>
              </Row>
            </Clay>
          </Clay>
        </Clay>

        <TitleWrap>
          Информация
        </TitleWrap>
        <Clay className="card card__container card__container_edit grid grid__2 grid__2_gap-row">
          <Field isMulti={true}
                 label="Профессии"
                 name={form.PROFESSION}
                 handleChange={handleChange}
                 selectorValueArr={profession}
                 component={StaffSelectorAsync}
                 dataSource={dictionaries.PROFESSIONS} />

          <Clay className="grid grid__2 grid__2_gap-row">
            <Field label="Разряд"
                   name={form.GRADE}
                   selectorValue={grade}
                   handleChange={handleChange}
                   component={StaffSelectorAsync}
                   dataSource={dictionaries.GRADES}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE} />

            <Field label="Инвалидность"
                   name={form.DISABILITY}
                   component={StaffSelector}
                   selectorValue={disability}
                   options={DISABILITY_VALUES}
                   handleChange={handleChange} />
          </Clay>

          <Field isMulti={true}
                 label="Допуск к видам работ"
                 handleChange={handleChange}
                 name={form.ACCESS_WORK_TYPE}
                 component={StaffSelectorAsync}
                 selectorValueArr={access_work_type}
                 dataSource={dictionaries.WORK_TYPES} />

          <Field isMulti={true}
                 label="Технологии"
                 name={form.TECHNOLOGY}
                 handleChange={handleChange}
                 selectorValueArr={technology}
                 component={StaffSelectorAsync}
                 dataSource={dictionaries.TECHNOLOGIES} />
        </Clay>
      </Clay>
    );
  }
}

BaseInformationForm.propTypes = {
  formFields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default BaseInformationForm;