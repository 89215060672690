export const DEMAND = 'demand';
export const STATUS = 'status';
export const ORDERING = 'ordering';
export const PROFESSION = 'profession';
export const ORGANIZATION = 'organization';

export const VACANCIES_LIST_FILTER = [
  {name: DEMAND, label: null, value: null},
  {name: STATUS, label: 'Все', value: null},
  {name: ORDERING, label: null, value: null},
  {name: PROFESSION, label: 'Все', value: null},
  {name: ORGANIZATION, label: 'Все', value: null}
];