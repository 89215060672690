import React, {Component} from 'react';
import {Clay} from '@n3/kit';

import {SPECIALISTS_REPORT} from '../../../constants/pagesName';
import SpecialistReportFilter from './SpecialistsReportFilter';
import ReportChartContainer from '../charts/ReportChart';
import SpecialistReportTable from './SpecialistsReportTable';
import StaffPaginator from '../../layouts/StaffPaginator';
import StaffSearch from '../../layouts/StaffSearch';

export const SPECIALIST_REPORT_STORE = 'specialistReportStore';

class SpecialistsReport extends Component {

  componentWillMount() {
    const dataSource = '/reports/professions/specialists/';
    this.props.appStoreCreate(SPECIALIST_REPORT_STORE, dataSource);
  }

  componentWillUnmount() {
    this.props.appStoreDelete(SPECIALIST_REPORT_STORE);
  }

  componentDidUpdate() {
    const {dataSource, storeFilters} = this.props;

    if (storeFilters.length === 0) {
      this.props.appStoreGetListData(SPECIALIST_REPORT_STORE, dataSource);
    }
  }

  render() {

    const chartSettingsFirst = {
      dataSource: '/reports/diagrams/work_part_time/',
      storeName: 'partTimeWorkersChartStore',
      colors: [
        '#F6C383',
        '#596CCA'
      ]
    };

    const chartSettingsSecond = {
      dataSource: '/reports/diagrams/staffing/',
      storeName: 'staffingChartStore',
      colors: [
        '#F69A8B',
        '#487E94'
      ]
    };

    return (
      <Clay padding={[3, 5]} className="app-container">
        <Clay className="app-container__row_flex-start">
          <ReportChartContainer settings={chartSettingsFirst} />
          <ReportChartContainer settings={chartSettingsSecond} />
        </Clay>

        <Clay font='h1' padding={[3, 0]}>
          {SPECIALISTS_REPORT}
        </Clay>

        <StaffSearch storeName={SPECIALIST_REPORT_STORE} />

        <Clay padding={[3, 0]}>
          <SpecialistReportFilter storeName={SPECIALIST_REPORT_STORE} />
        </Clay>

        <SpecialistReportTable storeName={SPECIALIST_REPORT_STORE} />

        <Clay padding={[3, 0, 0]}>
          <StaffPaginator storeName={SPECIALIST_REPORT_STORE} />
        </Clay>
      </Clay>
    );
  }
}

export default SpecialistsReport;