import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Row, Clay, Button, ButtonsGroup} from '@n3/kit';

import TitleWrap from '../../layouts/TitleWrap';
import DisplayData from '../../layouts/DisplayData';
import AccessControl from '../../accessControl/AccessControl';
import {mapServerArrayToOneString} from '../../../services/mapper';
import {VACANCY_CLOSE, VACANCY_SEARCH_SPECIALISTS} from '../../../constants/permissionsList';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';

const MEASURE_YEAR = 1;
const MEASURE_ROUBLE = 2;

class VacancyDisplayData extends React.Component {


  showRange = (from, till, measureUnit) => {
    let result;
    const measureFrom = this.getMeasureUnit(measureUnit, from);
    const measureTill = this.getMeasureUnit(measureUnit, till);

    if (from>0 && till>0) {
      result = `от ${from} ${measureFrom} до ${till} ${measureTill}`;
    } else if (from>0 && till===0) {
      result = `от ${from} ${measureFrom}`;
    } else if (from===0 && till>0) {
      measureUnit === MEASURE_ROUBLE
      ? result = `до ${till} ${measureTill}`
      : result = `от 0 лет до ${till} ${measureTill}`;
    } else {
      result = '';
    }

    return result;
  };

  getMeasureUnit = (type, value) => {
    let result = 'руб.';

    if (type === MEASURE_YEAR) {
      result = this.yearAsText(value);
    }

    return result;
  };

  yearAsText = (yearValue) => {
    let txt = 'лет';
    let count = yearValue % 100;
    let strCount = String(count);

    if (strCount.endsWith('1') && !strCount.endsWith('11')) {
      txt = 'года';
    }

    return txt;
  };


  onClose = (id) => {
    this.props.onCloseVacancy(id);
  };

  render() {
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    const {
      id, description, description_terms, availability_handicapped, profession_name, nationality_name, gender_display,
      access_work_type, technology, additional_claims, salary_from, salary_to, experience_from, experience_to, status
    } = displayData;

    return (
      <Clay padding={[0, 5]}>
        <Clay padding={[0, 0, 3]}>
          <TitleWrap>
            Обязанности
          </TitleWrap>
          <StaffDisplayCard>
            <DisplayData data={description} label='Описание вакансии' />
          </StaffDisplayCard>
          <TitleWrap>
            Требования
          </TitleWrap>
          <StaffDisplayCard>
            <DisplayData data={this.showRange(experience_from, experience_to, MEASURE_YEAR)} label='Опыт работы' />
            <Row>
              <Col xs={6}>
                <DisplayData data={profession_name} label='Профессия' />
              </Col>
              <Col xs={2}>
                <DisplayData data={gender_display} label='Пол' />
              </Col>
              <Col xs={3}>
                <DisplayData data={nationality_name} label='Гражданство' />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <DisplayData data={mapServerArrayToOneString(access_work_type)} label='Допуск к виду работ' />
              </Col>
              <Col xs={6}>
                <DisplayData data={mapServerArrayToOneString(technology)} label='Технологии' />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <DisplayData data={additional_claims} label='Дополнительные требования' />
              </Col>
              <Col xs={6}>
                <DisplayData  data={availability_handicapped ? 'Доступно' : 'Недоступно'}
                              label='Доступность для людей с ограниченными возможностями' />
              </Col>
            </Row>
          </StaffDisplayCard>
          <TitleWrap>
            Условия
          </TitleWrap>
          <StaffDisplayCard>
            <DisplayData data={this.showRange(salary_from, salary_to, MEASURE_ROUBLE)} label='Уровень заработной платы' />
            <DisplayData data={description_terms} label='Описание условий' />
          </StaffDisplayCard>
          <Clay padding={[3, 0]} className="app-container__row_flex-end">
            <ButtonsGroup>
              <AccessControl permission={VACANCY_CLOSE} accessCheck={status === 'opened'}>
                <Button color="primary" onClick={() => this.onClose(id)}>
                  Закрыть
                </Button>
              </AccessControl>
              <AccessControl permission={VACANCY_SEARCH_SPECIALISTS} accessCheck={status === 'opened'}>
                <Button color="primary" onClick={this.props.onSearchSpecialists}>
                  Подобрать специалиста
                </Button>
              </AccessControl>
            </ButtonsGroup>
          </Clay>
        </Clay>
      </Clay>
    );
  }
}

VacancyDisplayData.propTypes = {
  storeName: PropTypes.string.isRequired,
  onSearchSpecialists: PropTypes.func.isRequired,
  onCloseVacancy: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(withRouter(VacancyDisplayData));