import React from 'react';
import {Clay, Tag, Row, Col} from '@n3/kit';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {getCookie} from '../../../utils/Cookie';
import DisplayData from '../../layouts/DisplayData';
import AccessControl from '../../accessControl/AccessControl';
import {checkPermission} from '../../../services/checkPermission';
import {DEMAND_READ, VACANCY_LINK_ORGANIZATION, VACANCY_VIEW_ORGANIZATION} from '../../../constants/permissionsList';

class VacancyHeader extends React.Component {

  renderDemandLink = (displayData) => {
    const {demand} = displayData;
    const path = `/demands/read/${demand.id}/1`;
    const linkTitle = `Потребность на должность ${demand.rank.name} от ${demand.date_created}`;

    return <Link to={path} target="_blank">{linkTitle}</Link>;
  };

  renderOrgLink = (displayData) => {
    const {organization, organization_name} = displayData;
    const path = `/organizations/read/${organization}/1`;

    return <Link to={path} target="_blank">{organization_name}</Link>;
  };

  renderOrganization = (displayData) => {
    const {organization_name} = displayData;
    const userRole = getCookie('role');

    return checkPermission(userRole, VACANCY_LINK_ORGANIZATION)
      ? <DisplayData data={this.renderOrgLink(displayData)} label='Организация' />
      : <DisplayData data={organization_name} label='Организация' />;
  };

  render() {
    const {displayData} = this.props;

    if (!displayData) {
      return null;
    }

    const {demand, name, status, status_display, date_created, date_close} = displayData;

    return (
      <Clay bgColor="bg">
        <Clay className="grid grid__2 grid__2_no-gap">
          <Clay padding={[3, 5, 3, 5]}>
            <DisplayData data={<Tag color={status === 'closed' ? 'red' : 'green'}>{status_display}</Tag>} label='Статус' />
            <DisplayData data={name} label='Название' />
            <AccessControl permission={DEMAND_READ}>
              <DisplayData data={this.renderDemandLink(displayData)} label='Потребность' />
            </AccessControl>
          </Clay>
          <Clay padding={[3, 5, 3, 5]} className="app-container__border_left">
            <DisplayData data={demand.author.full_name} label='Автор' />
            <AccessControl permission={VACANCY_VIEW_ORGANIZATION}>
              {this.renderOrganization(displayData)}
            </AccessControl>
            <Row>
              <Col xs={4}>
                <DisplayData data={date_created} label='Дата создания' />
              </Col>
              <Col xs={4}>
                <DisplayData data={date_close} label='Дата закрытия' />
              </Col>
            </Row>
          </Clay>
        </Clay>
      </Clay>
    );
  }
}

VacancyHeader.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    displayData: store ? store.storeDisplayData : null
  };
};

export default connect(mapStateToProps)(VacancyHeader);