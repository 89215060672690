import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {change, formValueSelector, getFormValues, reduxForm} from 'redux-form';

import WorkForm from './WorkForm';
import * as form from './formConstructor';

const WorkFormContainer = reduxForm({
    enableReinitialize: true
  },
  {change})(WorkForm);

WorkFormContainer.propTypes = {
  saveForm: PropTypes.func,
  updateForm: PropTypes.bool,
  createWork: PropTypes.bool
};

const mapStetToProps = (state, ownProps) => {
  const formName = ownProps.form;
  const selector = formValueSelector(formName);

  return {
    [form.ID]: selector(state, form.ID),
    [form.RANK]: selector(state, form.RANK),
    [form.MAIN_WORK]: selector(state, form.MAIN_WORK),
    [form.ORGANIZATION]: selector(state, form.ORGANIZATION),

    formValues: getFormValues(formName)(state)
  };
};

export default connect(mapStetToProps)(WorkFormContainer);