import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Table, Thead, Tbody, Th, Td, Clay, LoadingArea, Tag} from '@n3/kit';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {EMPTY_VALUE_SYMBOL, NON_BREAKING_SPACE} from '../../../constants/symbolsList';
import {DEMAND_READ} from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';
import * as permission from '../../../constants/permissionsList';
import AccessControl from '../../accessControl/AccessControl';
import {getCookie} from '../../../utils/Cookie';

class DemandsTable extends Component {

  redirectToRead = (demand) => {
    const url = this.props.match.url;
    const userRole = getCookie('role');

    if (checkPermission(userRole, DEMAND_READ)) {
      this.props.history.push(`${url}/read/${demand.id}/1`);
    }
  };

  renderTableBody = () => {
    const userRole = getCookie('role');
    const demandsList = this.props.listData;
    const rowClassName = checkPermission(userRole, DEMAND_READ) ? 'app-container__cursor_pointer' : '';

    return demandsList.map(demand => {
      return (
        <tr key={demand.id}
            className={rowClassName}
            onClick={() => this.redirectToRead(demand)}>

          <Td>
            <Tag color={demand.status === 'opened' ? 'green' : 'red'}>
              {demand.status === 'opened' ? 'Открыто' : 'Закрыто'}
            </Tag>
          </Td>
          <Td hasWordBreak>{demand.rank.name}</Td>
          <AccessControl permission={permission.DEMAND_FILTER_PROFESSION} accessCheck={true}>
            <Td hasWordBreak>{demand.profession.name}</Td>
          </AccessControl>
          <AccessControl permission={permission.DEMAND_FILTER_ORGANIZATION} accessCheck={true}>
            <Td hasWordBreak>{demand.organization.name}</Td>
          </AccessControl>
          <Td>{demand.date_created}</Td>
          <Td>{demand.date_close || EMPTY_VALUE_SYMBOL}</Td>
        </tr>
      );
    });
  };

  render() {
    const {listData, isLoading} = this.props;
    const dateBegin = `Дата${NON_BREAKING_SPACE}создания`;
    const dateEnd = `Дата${NON_BREAKING_SPACE}закрытия`;
    const userRole = getCookie('role');
    const canViewProfession = checkPermission(userRole, permission.DEMAND_FILTER_PROFESSION);

    const widthStatus = 'table__column_width--10';
    const widthRank = canViewProfession ? 'table__column_width--20' : 'table__column_width--30';
    const widthProfession = 'table__column_width--20';
    const widthOrganization = 'table__column_width--20';
    const widthDateCreate = canViewProfession ? 'table__column_width--12' : 'table__column_width--30';

    return (
      <Clay>
        <Table className='table'>
          <Thead>
          <tr>
            <Th className={widthStatus}>Статус</Th>
            <Th className={widthRank}>Должность</Th>
            <AccessControl permission={permission.DEMAND_FILTER_PROFESSION} accessCheck={true}>
              <Th className={widthProfession}>Профессия</Th>
            </AccessControl>
            <AccessControl permission={permission.DEMAND_FILTER_ORGANIZATION} accessCheck={true}>
              <Th className={widthOrganization}>Организация</Th>
            </AccessControl>
            <Th className={widthDateCreate}>{dateBegin}</Th>
            <Th>{dateEnd}</Th>
          </tr>
          </Thead>

          <Tbody>
          {(listData && !isLoading) && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
      </Clay>
    );
  }
}

DemandsTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null,
    isLoading: store ? store.storeIsLoading : null
  };
};

export default connect(mapStateToProps)(withRouter(DemandsTable));