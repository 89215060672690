import React from 'react';
import {Field} from 'redux-form';
import {getCookie} from '../../../utils/Cookie';
import {Alert, Clay, Col, FieldLabel, Row} from '@n3/kit';

import StaffInput from '../../form/StaffInput';
import StaffSelector from '../../form/StaffSelector';
import StaffDatepicker from '../../form/StaffDatepicker';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as form from '../../specialists/update/formConstructor';
import * as permission from '../../../constants/permissionsList';
import {checkPermission} from '../../../services/checkPermission';
import * as dictionaries from '../../../constants/dictionariesSource';
import {EDUCATION_TYPE_VALUES, STUDY_MODES_VALUES} from '../../../constants/selectorsValues';
import AccessControl from '../../accessControl/AccessControl';
import * as rolesList from '../../../constants/rolesList';

class EducationForm extends React.Component {

  componentDidUpdate(prevProps, prevState) {
    const formName = this.props.form;
    const {invalid, saveCounter} = this.props.formValues;
    const prevSaveCounter = prevProps.formValues && prevProps.formValues.saveCounter;

    if (saveCounter && saveCounter !== prevSaveCounter && formName && invalid) {
      Object.keys(form.SPECIALIST_EDUCATION_FORM).forEach(field => this.props.touch(form, field));
    }
  }

  componentWillUnmount() {
    const {formValues, invalid} = this.props;
    this.props.saveForm({...formValues, invalid});
  }

  canUpdate = (permission) => {
    const {createForm, updateForm} = this.props;
    const userRole = getCookie('role');

    if (userRole === rolesList.EDU) {
      return checkPermission(userRole, permission, updateForm);
    }

    return createForm ? true : checkPermission(userRole, permission, updateForm);
  };

  handleChange = (name, value) => {
    const {form} = this.props;
    this.props.touch(form, name);
    this.props.change(name, value);
  };

  render() {
    const {
      error, organization, education_type, study_mode, degree, qualification, speciality, education_program, course,
      educationCreate, createForm
    } = this.props;

    return (
      <Clay margin={educationCreate && [3, 0, 0]} padding={[2, 3, 5, 4]}>
        {error && <Clay padding={[5]}><Alert color="danger" title="Ошибка:"> {error} </Alert></Clay>}

        <Row>
          <Col xs={6}>
            <FieldLabel>
              Период обучения
            </FieldLabel>
            <Row>
              <Col xs={6}>
                <Field label="Дата начала"
                       name={form.DATE_START}
                       component={StaffDatepicker}
                       handleChange={this.handleChange}
                       isRequired={this.canUpdate(permission.EDUCATION_UPDATE)}
                       isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
              </Col>
              <Col xs={6}>
                <AccessControl permission={permission.EDUCATION_CREATE_DATE_END_REQ}>
                  <Field label="Дата окончания"
                         name={form.DATE_END}
                         isRequired={createForm}
                         component={StaffDatepicker}
                         handleChange={this.handleChange}
                         isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
                </AccessControl>
                <AccessControl permission={permission.EDUCATION_UPDATE}>
                  <Field label="Дата окончания"
                         name={form.DATE_END}
                         component={StaffDatepicker}
                         handleChange={this.handleChange}
                         isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
                </AccessControl>
              </Col>
            </Row>
          </Col>
        </Row>

        <Clay padding={[4, 0]}>
          <Row>
            <Col xs={3}>
              <Field label="Образование"
                     component={StaffSelector}
                     name={form.EDUCATION_TYPE}
                     selectorValue={education_type}
                     options={EDUCATION_TYPE_VALUES}
                     handleChange={this.handleChange}
                     isRequired={this.canUpdate(permission.EDUCATION_UPDATE)}
                     isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
            </Col>

            <Col xs={3}>
              <Field label="Организация"
                     name={form.ORGANIZATION}
                     selectorValue={organization}
                     component={StaffSelectorAsync}
                     handleChange={this.handleChange}
                     defaultValue={form.SELECTOR_DEFAULT_VALUE}
                     dataSource={dictionaries.ORGANIZATIONS_EDUCATIONAL}
                     isRequired={this.canUpdate(permission.EDUCATION_UPDATE_ORGANISATION)}
                     isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE_ORGANISATION)} />
            </Col>

            <Col xs={3}>
              <Field label="Плановая дата окончания"
                     name={form.PLANNED_DATE_END}
                     component={StaffDatepicker}
                     handleChange={this.handleChange}
                     isRequired={this.canUpdate(permission.EDUCATION_UPDATE)}
                     isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
            </Col>

            <Col xs={3}>
              <Field type="number"
                     label="Год обучения"
                     component={StaffInput}
                     name={form.YEAR_EDUCATION}
                     handleChange={this.handleChange}
                     isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
            </Col>
          </Row>
        </Clay>

        <Row>
          <Col xs={3}>
            <Field label="Форма обучения"
                   name={form.STUDY_MODE}
                   component={StaffSelector}
                   selectorValue={study_mode}
                   options={STUDY_MODES_VALUES}
                   handleChange={this.handleChange}
                   isRequired={this.canUpdate(permission.EDUCATION_UPDATE)}
                   isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
          </Col>

          <Col xs={3}>
            <Field label="Степень"
                   name={form.DEGREE}
                   selectorValue={degree}
                   component={StaffSelectorAsync}
                   handleChange={this.handleChange}
                   dataSource={dictionaries.DEGREES}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE}
                   isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
          </Col>

          <Col xs={3}>
            <Field label="Квалификация"
                   name={form.QUALIFICATION}
                   selectorValue={qualification}
                   component={StaffSelectorAsync}
                   handleChange={this.handleChange}
                   dataSource={dictionaries.PROFESSIONS}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE}
                   isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
          </Col>

          <Col xs={3}>
            <Field label="Специальность"
                   name={form.SPECIALITY}
                   selectorValue={speciality}
                   component={StaffSelectorAsync}
                   handleChange={this.handleChange}
                   dataSource={dictionaries.SPECIALITIES}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE}
                   isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
          </Col>
        </Row>

        <Clay padding={[4, 0, 0]} />

        <Row>
          <Col xs={4}>
            <Field label="Специализация"
                   component={StaffInput}
                   name={form.SPECIALIZATION}
                   handleChange={this.handleChange}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE}
                   isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
          </Col>

          <Col xs={4}>
            <Field label="Образовательная программа"
                   name={form.EDUCATION_PROGRAM}
                   component={StaffSelectorAsync}
                   handleChange={this.handleChange}
                   selectorValue={education_program}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE}
                   dataSource={dictionaries.EDUCATION_PROGRAMS}
                   isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
          </Col>

          <Col xs={4}>
            <Field label="Курс"
                   name={form.COURSE}
                   selectorValue={course}
                   component={StaffSelectorAsync}
                   handleChange={this.handleChange}
                   dataSource={dictionaries.COURSES}
                   defaultValue={form.SELECTOR_DEFAULT_VALUE}
                   isRequired={this.canUpdate(permission.EDUCATION_UPDATE)}
                   isDisabled={!this.canUpdate(permission.EDUCATION_UPDATE)} />
          </Col>
        </Row>
      </Clay>
    );
  }
}

export default EducationForm;