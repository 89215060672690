import api from '../../API';

export const APP_STORE_CREATE_STORE = 'APP_STORE_CREATE_STORE';
export const APP_STORE_DELETE_STORE = 'APP_STORE_DELETE_STORE';
export const APP_STORE_SAVE_FILTERS = 'APP_STORE_SAVE_FILTERS';
export const APP_STORE_UPDATE_FILTER = 'APP_STORE_UPDATE_FILTER';
export const APP_STORE_UPDATE_LIST_DATA = 'APP_STORE_UPDATE_LIST_DATA';
export const APP_STORE_UPDATE_CURRENT_PAGE = 'APP_STORE_UPDATE_CURRENT_PAGE';
export const APP_STORE_UPDATE_DISPLAY_DATA = 'APP_STORE_UPDATE_DISPLAY_DATA';

export const appStoreCreate = (storeName, dataSource) => {
  return {
    type: APP_STORE_CREATE_STORE,
    payload: {storeName, dataSource}
  };
};

export const appStoreDelete = (storeName) => {
  return {
    type: APP_STORE_DELETE_STORE,
    payload: {storeName}
  };
};

export const appStoreSaveFilters = (storeName, filters) => {
  return {
    type: APP_STORE_SAVE_FILTERS,
    payload: {storeName, filters}
  };
};

export const appStoreUpdateFilter = (storeName, filter) => {
  return {
    type: APP_STORE_UPDATE_FILTER,
    payload: {storeName, filter}
  };
};

export const appStoreUpdateListData = (storeName, listData, totalPages, totalValues) => {
  return {
    type: APP_STORE_UPDATE_LIST_DATA,
    payload: {storeName, listData, totalPages, totalValues}
  };
};

export const appStoreUpdateCurrentPage = (storeName, currentPage) => {
  return {
    type: APP_STORE_UPDATE_CURRENT_PAGE,
    payload: {storeName, currentPage}
  };
};

export const appStoreUpdateDisplayData = (storeName, displayData) => {
  return {
    type: APP_STORE_UPDATE_DISPLAY_DATA,
    payload: {storeName, displayData}
  };
};

export const appStoreGetListData = (storeName, request) => {
  console.log('get list data', request);

  return dispatch => {
    api.request(request, {method: 'get'})
      .then(response => {
        const listData = response.results;
        const totalValues = response.totals;
        const totalPages = Math.ceil(response.count / 10);
        dispatch(appStoreUpdateListData(storeName, listData, totalPages, totalValues));
      })
      .catch(() => {
        const path = window.location.origin;
        window.location.replace(`${path}/hasNoAccess`);
      });
  };
};

export const appStoreGetFilteredData = (storeName, request, page = 1) => {
  console.log('get filtered data');

  return dispatch => {
    dispatch(appStoreGetListData(storeName, request));
    dispatch(appStoreUpdateCurrentPage(storeName, page));
  };
};

export const appStoreGetDisplayData = (storeName, request) => {
  console.log('get display data', request);

  return dispatch => {
    api.request(request, {method: 'get'})
      .then(response => {
        dispatch(appStoreUpdateDisplayData(storeName, response));
      })
      .catch(() => {
        const path = window.location.origin;
        window.location.replace(`${path}/hasNoAccess`);
      });
  };
};