import * as roles from './rolesList';

export const REPORTS = 'Отчетность';
export const REPORTING = 'Отчетность';
export const VACANCY_READ = 'Вакансия';
export const VACANCIES_LIST = 'Вакансии';
export const VACANCY_CREATE = 'Создание вакансии';
export const DEMANDS_LIST = 'Потребности';
export const DEMANDS_READ = 'Потребность';
export const ORGANISATIONS_LIST = 'Организации';
export const USER_PROFILE = 'Профиль пользователя';
export const DEMAND_CREATE = 'Создание потребности';
export const DEMANDS_REPORT = 'Необходимые специалисты';
export const VACANCY_UPDATE = 'Редактирование вакансии';
export const ORGANIZATION_READ = 'Информация об организации';
export const DEMAND_UPDATE = 'Редактирование потребности';
export const ORGANIZATION_CREATE = 'Создание организации';
export const SPECIALISTS_REPORT = 'Потенциальные сотрудники';
export const ORGANIZATION_UPDATE = 'Редактирование организации';
export const ORIENTATION_REPORT = 'Профессиональная ориентация';
export const ORGANIZATION_READ_INFO = 'Информация об организации';
export const USER_PROFILE_EDIT = 'Редактирование профиля пользователя';
export const RECRUITMENT = 'Подбор специалиста по параметрам вакансии';
export const VACANCY_MODAL_CREATE = 'Выберите потребность для создания вакансии';
export const SPECIALIST_MODAL_CREATE = 'Выберите имеющегося в системе специалиста или добавьте нового';

export const LOGO_TITLE = 'Автоматизированная информационная система';
export const LOGO_SUBTITLE = 'Комитета по строительству';

export const SPECIALIST_READ = {
  [roles.EDU]: 'Карточка учащегося',
  [roles.KS]: 'Карточка специалиста',
  [roles.BUILDER]: 'Карточка сотрудника',
  [roles.STAFFING]: 'Карточка соискателя'
};

export const SPECIALIST_UPDATE = {
  [roles.EDU]: 'Редактирование карточки учащегося',
  [roles.KS]: 'Редактирование карточки специалиста',
  [roles.BUILDER]: 'Редактирование карточки сотрудника',
  [roles.STAFFING]: 'Редактирование карточки соискателя'
};

export const SPECIALISTS_LIST = {
  [roles.EDU]: 'Учащиеся',
  [roles.KS]: 'Специалисты',
  [roles.BUILDER]: 'Сотрудники',
  [roles.STAFFING]: 'Соискатели'
};

export const SPECIALIST_CREATE = {
  [roles.EDU]: 'Добавление карточки учащегося',
  [roles.BUILDER]: 'Добавление карточки сотрудника',
  [roles.STAFFING]: 'Добавление карточки соискателя'
};

