import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Clay} from '@n3/kit';

import StaffBreadCrumbs from '../layouts/StaffBreadCrumbs';

class PageHeader extends Component {
  render() {
    const {breadCrumbs, title, controlButton, bgColor} = this.props;

    return (
      <Clay padding={[4, 5]} margin={[0]}
            bgColor={bgColor ? bgColor : 'bg'} className="app-container__border_bottom">
        {breadCrumbs && <StaffBreadCrumbs breadcrumbs={breadCrumbs} />}
        <Clay className="app-container__row">
          <Clay isBreakWord font='h1'>
            {title}
          </Clay>
          <Clay>
            {controlButton}
          </Clay>
        </Clay>
      </Clay>
    );
  }
}

PageHeader.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string,
  breadCrumbs: PropTypes.array,
  controlButton: PropTypes.object
};

export default PageHeader;