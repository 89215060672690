import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Clay, Table, Thead, Tbody, Th, Td, LoadingArea, Tag} from '@n3/kit';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';

import {checkPermission} from '../../../services/checkPermission';
import {VACANCY_READ} from '../../../constants/permissionsList';
import {EMPTY_VALUE_SYMBOL, NON_BREAKING_SPACE} from '../../../constants/symbolsList';
import StaffOrdering from '../../layouts/StaffOrdering';
import {getCookie} from '../../../utils/Cookie';
import * as filter from './filterConstructor';
import CheckPage from '../../layouts/CheckPage';

class VacanciesTable extends Component {

  redirectToRead = (demand) => {
    const page = window.location.href;
    const path = `/vacancy/read/${demand.id}`;
    const userRole = getCookie('role');

    if (checkPermission(userRole, VACANCY_READ)) {
      page.includes('demands')
        ? window.open(path)
        : this.props.history.push(path);
    }
  };

  renderTableBody = () => {
    const userRole = getCookie('role');
    const vacanciesList = this.props.listData;
    const rowClassName = checkPermission(userRole, VACANCY_READ) ? 'app-container__cursor_pointer' : '';

    return vacanciesList.map(vacancy => {
      return (
        <tr key={vacancy.id}
            className={rowClassName}
            onClick={() => this.redirectToRead(vacancy)}>

          <Td>
            <Tag color={vacancy.status_display === 'Открыта' ? 'green' : 'red'}>
              {vacancy.status_display}
            </Tag>
          </Td>
          <Td hasWordBreak>{vacancy.name}</Td>
          <CheckPage condition="vacancy">
            <AccessControl permission={permission.VACANCY_VIEW_ORGANIZATION} accessCheck={true}>
              <Td hasWordBreak>{vacancy.organization_name}</Td>
            </AccessControl>
          </CheckPage>
          <CheckPage condition="vacancy">
            <Td hasWordBreak>{vacancy.profession_name}</Td>
          </CheckPage>
          <Td>{vacancy.date_created}</Td>
          <Td>{vacancy.date_close ? vacancy.date_close : EMPTY_VALUE_SYMBOL}</Td>
        </tr>
      );
    });
  };

  render() {
    const {listData, storeName, isLoading} = this.props;
    const dateBegin = `Дата${NON_BREAKING_SPACE}создания`;
    const dateEnd = `Дата${NON_BREAKING_SPACE}закрытия`;

    return (
      <Clay>
        <Table className='table'>
          <Thead>
          <tr>
            <Th className='table__column_width--10'>Статус</Th>

            <CheckPage condition="vacancy">
              <Th className='table__column_width--20'>Название</Th>
            </CheckPage>

            <CheckPage condition="demands">
              <Th className='table__column_width--50'>Название</Th>
            </CheckPage>

            <CheckPage condition="vacancy">
              <AccessControl permission={permission.VACANCY_VIEW_ORGANIZATION} accessCheck={true}>
                <Th className='table__column_width--20'>Организация</Th>
              </AccessControl>
            </CheckPage>


            <CheckPage condition="vacancy">
              <Th className='table__column_width--25'>Профессия</Th>
            </CheckPage>

            <Th className='table__column_width--15'>
              <StaffOrdering storeName={storeName}
                             filteredParam={'date_created'}
                             filterName={filter.ORDERING}>
                  {dateBegin}
              </StaffOrdering>
            </Th>
            <Th>
              <StaffOrdering storeName={storeName}
                             filteredParam={'date_close'}
                             filterName={filter.ORDERING}>
                {dateEnd}
              </StaffOrdering>
            </Th>
          </tr>
          </Thead>

          <Tbody>
          {listData && !isLoading && this.renderTableBody()}
          </Tbody>
        </Table>
        {isLoading && <Clay margin={[5, 0]} padding={[5, 0]}><LoadingArea /></Clay>}
      </Clay>
    );
  }
}

VacanciesTable.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    listData: store ? store.storeListData : null,
    isLoading: store ? store.storeIsLoading : null
  };
};

export default connect(mapStateToProps)(withRouter(VacanciesTable));