import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Clay, Button, Row, Col, ListPlaceholder} from '@n3/kit';

import WorkUpdate from './WorkUpdate';
import WorkCreate from '../create/WorkCreate';
import DisplayData from '../../layouts/DisplayData';
import StaffCollapse from '../../layouts/StaffCollapse';
import AccessControl from '../../accessControl/AccessControl';
import StaffDisplayCard from '../../layouts/StaffDisplayCard';
import * as form from '../../specialists/update/formConstructor';
import * as permission from '../../../constants/permissionsList';
import {appStoreUpdateListData} from '../../../store/appStore/actions';
import {getCookie} from '../../../utils/Cookie';
import * as rolesList from '../../../constants/rolesList';

export const WORKS_CREATE_LIST_STORE = 'worksCreateListStore';
export const WORKS_UPDATE_LIST_STORE = 'worksUpdateListStore';
export const WORKS_DELETE_LIST_STORE = 'worksDeleteListStore';

class WorksListForUpdate extends React.Component {

  updateListData = (storeName, values) => {
    const {worksList, worksCreateList} = this.props;

    switch (storeName) {
      case WORKS_UPDATE_LIST_STORE:
        worksList[worksList.findIndex(data => data.id === values.id)] = {...values};
        this.props.appStoreUpdateListData(storeName, worksList);
        break;
      case WORKS_CREATE_LIST_STORE:
        worksCreateList[worksCreateList.findIndex(data => data.id === values.id)] = {...values};
        this.props.appStoreUpdateListData(storeName, worksCreateList);
        break;
      default:
        break;
    }
  };

  handleClick = (event) => {
    event.preventDefault();

    const {worksCreateList, user} = this.props;
    const id = `${new Date().getUTCMilliseconds()}`;
    const organization = user.role === rolesList.BUILDER
      ? {value: user.organization.id, label: user.organization.name}
      : form.SELECTOR_DEFAULT_VALUE;

    this.props.appStoreUpdateListData(WORKS_CREATE_LIST_STORE, [
      ...worksCreateList,
      {...form.SPECIALIST_WORK_FORM, id, organization}
    ]);
  };

  handleDelete = (event, workId) => {
    event.preventDefault();
    const {worksList, worksDeleteList} = this.props;

    this.props.appStoreUpdateListData(WORKS_DELETE_LIST_STORE, [...worksDeleteList, workId]);
    this.props.appStoreUpdateListData(WORKS_UPDATE_LIST_STORE, worksList.filter(work => work.id !== workId));
  };

  renderDeleteButton = (workId) => {
    return (
      <Col xs={1}>
        <Clay padding={[3, 0]} textAlign="right">
          <Button onlyIcon onClick={(event) => this.handleDelete(event, workId)}>
            <i className="fas fa-trash-alt" />
          </Button>
        </Clay>
      </Col>
    );
  };

  canDelete = (orgId) => {
    const userRole = getCookie('role');
    const {organization} = this.props.user;
    const userOrgId = organization ? organization.id : null;

    return userRole === rolesList.BUILDER && userOrgId ? userOrgId === orgId : true;
  };

  renderCollapseHeader = (work) => {
    const {id, rank, date_start, date_end, organization} = work;
    const experience = date_start
      ? date_end
        ? `${date_start} - ${date_end}`
        : `${date_start} - по настоящее время`
      : null;

    return (
      <Clay className="app-container">
        <Row>
          <Col xs={4}>
            <DisplayData data={organization.value && organization.label} label="Организация" />
          </Col>
          <Col xs={3}>
            <DisplayData data={experience} label="Опыт работы" />
          </Col>
          <Col xs={4}>
            <DisplayData data={rank.value && rank.label} label="Должность" />
          </Col>
          <AccessControl permission={permission.WORK_DELETE} accessCheck={this.canDelete(organization.value)}>
            {this.renderDeleteButton(id)}
          </AccessControl>
        </Row>
      </Clay>
    );
  };

  renderWorksList = () => {
    const {worksList, worksCreateList} = this.props;

    if (worksList.length) {
      return worksList.map((work, index) => (
        <StaffCollapse key={index}
                       isOpen={index === 0}
                       collapseHeader={this.renderCollapseHeader(work)}
                       collapseBody={<WorkUpdate work={work}
                                                 key={work.id}
                                                 saveForm={this.updateListData}
                                                 storeName={WORKS_UPDATE_LIST_STORE} />} />
      ));
    }
    else if (!worksCreateList.length) {
      return (
        <StaffDisplayCard>
          Отсутствует информация об опыте работы
        </StaffDisplayCard>
      );
    }
  };

  render() {
    const {worksList, renderCreateForm} = this.props;

    if (!worksList) {
      return (
        <ListPlaceholder>
          Отсутствует информация об опыте работы
        </ListPlaceholder>
      );
    }

    return (
      <Clay padding={[6, 5, 0]}>
        {this.renderWorksList()}

        {renderCreateForm && <WorkCreate storeName={WORKS_CREATE_LIST_STORE} saveForm={this.updateListData} />}

        <AccessControl permission={permission.WORK_CREATE}>
          <Clay padding={[3, 0]}>
            <Button color="primary" onClick={(event) => {this.handleClick(event);}}>
              Добавить работу
            </Button>
          </Clay>
        </AccessControl>
      </Clay>
    );
  }
}

WorksListForUpdate.propTypes = {
  renderCreateForm: PropTypes.bool
};

const mapStateToProps = (state) => {
  const worksList = state.appStore[WORKS_UPDATE_LIST_STORE];
  const worksCreateList = state.appStore[WORKS_CREATE_LIST_STORE];
  const worksDeleteList = state.appStore[WORKS_DELETE_LIST_STORE];

  return {
    user: state.user,
    worksList: worksList ? worksList.storeListData : null,
    worksCreateList: worksCreateList ? worksCreateList.storeListData : null,
    worksDeleteList: worksDeleteList ? worksDeleteList.storeListData : null
  };
};

const mapDispatchToProps = {
  appStoreUpdateListData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter((WorksListForUpdate)));