import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from '@n3/kit';

class StaffSimpleCheckbox extends Component {
  handleChange = (value) => {
    const name = this.props.input.name;
    this.props.handleChange(name, value);
  };

  render() {
    const {input, checkboxValue, label, isDisabled} = this.props;
    const {name} = input;

    return (
      <Checkbox name={name}
                label={label}
                disabled={isDisabled}
                onChange={this.handleChange}
                checked={checkboxValue ? checkboxValue : false} />
    );
  };
}

StaffSimpleCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
  checkboxValue: PropTypes.bool,
};

export default StaffSimpleCheckbox;