import React from 'react';
import {Clay, log} from '@n3/kit';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import API from '../../../API';
import * as form from './formConstructor';
import {getCookie} from '../../../utils/Cookie';
import PageHeader from '../../layouts/PageHeader';
import * as messages from '../../../constants/messages';
import VacancyCreateMapData from './VacancyCreateMapData';
import AccessControl from '../../accessControl/AccessControl';
import * as permission from '../../../constants/permissionsList';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import VacancyCreateFormContainer from './VacancyCreateFormContainer';
import {DEMANDS, dataSourceList} from '../../../constants/dataSourcesList';
import {VACANCY_CREATE, VACANCIES_LIST} from '../../../constants/pagesName';
import {appStoreCreate, appStoreDelete, appStoreGetDisplayData} from '../../../store/appStore/actions';

const DEMAND_FOR_VACANCY_STORE = 'demandForVacancyStore';

class VacancyCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 1,
      breadCrumbs: [
        {
          url: `/vacancy`,
          title: VACANCIES_LIST
        },
        {
          url: null,
          title: VACANCY_CREATE
        }
      ]
    };
  }

  componentDidMount() {
    this.demandId = this.props.match.params.id;
      const useRole = getCookie('role');
      if (useRole) {
        const dataSource = `${dataSourceList[useRole][DEMANDS]}${this.demandId}`;
        this.props.appStoreCreate(DEMAND_FOR_VACANCY_STORE, dataSource);
      }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user.role !== nextProps.user.role) {
      this.demandId = this.props.match.params.id;
      if (nextProps.user.role) {
        const dataSource = `${dataSourceList[nextProps.user.role][DEMANDS]}${this.demandId}`;
        this.props.appStoreCreate(DEMAND_FOR_VACANCY_STORE, dataSource);
      }
    }
  }

  componentWillUnmount() {
    this.props.appStoreDelete(DEMAND_FOR_VACANCY_STORE);
  }

  componentDidUpdate() {
    const {dataSource} = this.props;
    dataSource && this.props.appStoreGetDisplayData(DEMAND_FOR_VACANCY_STORE, dataSource);
  }

  setCurrentTab = (tabId) => {
    this.setState({
      currentTab: tabId
    });
  };

  handleCancelClick = (event) => {
    event.preventDefault();
    const path = '/vacancy';
    this.props.history.push(path);
  };

  renderSuccessLog = () => {
    log({
      title: 'Успешно',
      message: messages.SAVE_SUCCESS,
      color: 'success'
    });
  };

  renderErrorLog = () => {
    log({
      title: 'Ошибка',
      message: messages.SAVE_ERROR,
      color: 'danger',
      timeout: '5000'
    });
  };

  submit = (values) => {
    const data = this.prepareValuesToSend({...values});

    return API.request(form.API, {method: 'post', data})
      .then((resp) => {
        this.renderSuccessLog();
        this.props.history.push(`/vacancy/read/${resp.id}`);
      })
      .catch(() => {
        this.renderErrorLog();
      });
  };

  submitAndContinue = (values) => {
    const data = this.prepareValuesToSend({...values});

    return API.request(form.API, {method: 'post', data})
      .then((resp) => {
        this.renderSuccessLog();
        this.props.history.push(`/vacancy/update/${resp.id}`);
      })
      .catch(() => {
        this.renderErrorLog();
      });
  };

  prepareValuesToSend = (values) => {
    if (!values[form.SALARY_TO]) {
      values[form.SALARY_TO] = 0;
    }
    if (!values[form.SALARY_FROM]) {
      values[form.SALARY_FROM] = 0;
    }
    if (!values[form.EXPERIENCE_TO]) {
      values[form.EXPERIENCE_TO] = 0;
    }
    if (!values[form.EXPERIENCE_FROM]) {
      values[form.EXPERIENCE_FROM] = 0;
    }

    return mapValuesToSend(values);
  };

  render() {
    const {breadCrumbs} = this.state;

    return (
      <AccessControl permission={permission.VACANCY_CREATE} redirect={'/hasNoAccess'}>
        <Clay className="app-container">
          <PageHeader title={VACANCY_CREATE}
                      breadCrumbs={breadCrumbs} />

          <Clay padding={[0]}>
            <VacancyCreateMapData storeName={DEMAND_FOR_VACANCY_STORE}>
              <VacancyCreateFormContainer onSubmit={this.submit}
                                          submitAndContinue={this.submitAndContinue}
                                          handleCancelClick={this.handleCancelClick} />
            </VacancyCreateMapData>
          </Clay>
        </Clay>
      </AccessControl>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.appStore[DEMAND_FOR_VACANCY_STORE];

  return {
    user: state.user,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VacancyCreate));