import React from 'react';
import {Field} from 'redux-form';
import {Button, Clay, Col, Row} from '@n3/kit';

import * as form from './formConstructor';
import StaffInput from '../../form/StaffInput';
import StaffDatepicker from '../../form/StaffDatepicker';
import StaffFileUploader from '../../form/StaffFileUploader';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import * as dictionaries from '../../../constants/dictionariesSource';

class DocumentCreateForm extends React.Component {

  componentDidMount() {
    const id = this.props.match.params.id;
    const currentPage = this.props.match.path;

    if (currentPage.includes('specialist')) {
      this.props.change(form.SPECIALIST, id);
    }
    else if (currentPage.includes('organization')) {
      this.props.change(form.ORGANIZATION, id);
    }
  }

  handleChange = (name, value) => {
    this.props.change(name, value);
  };

  handleClick = (event) => {
    event.preventDefault();
    this.props.handleSubmit();
  };

  render() {
    const {handleSubmit, onHide, type} = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">

        <Row>
          <Col xs={12}>
            <Field label="Название"
                   name={form.NAME}
                   isRequired={true}
                   component={StaffInput} />
          </Col>
        </Row>

        <Clay padding={[5, 0, 0]} />

        <Row>
          <Col xs={6}>
            <Field name={form.TYPE}
                   isRequired={true}
                   selectorValue={type}
                   label="Тип документа"
                   component={StaffSelectorAsync}
                   dataSource={dictionaries.DOC_TYPES}
                   handleChange={this.handleChange} />
          </Col>

          <Col xs={6}>
            <Field label="Номер документа"
                   isRequired={true}
                   name={form.NUMBER}
                   component={StaffInput} />
          </Col>
        </Row>

        <Clay padding={[5, 0, 0]} />

        <Row>
          <Col xs={6}>
            <Field label="Дата документа"
                   isRequired={true}
                   name={form.DOC_DATE}
                   component={StaffDatepicker}
                   handleChange={this.handleChange} />
          </Col>

          <Col xs={6}>
            <Field label="Действителен до"
                   name={form.VALID_UNTIL}
                   component={StaffDatepicker}
                   handleChange={this.handleChange} />
          </Col>
        </Row>

        <StaffFileUploader handleChange={this.handleChange} name={form.ATTACHMENTS} />

        <Clay margin={[4, 0, 0]} className="app-container__row">
          <Button onClick={event => onHide(event)}>
            Отмена
          </Button>

          <Button type="submit" color="primary" onClick={event => this.handleClick(event)}>
            Сохранить
          </Button>
        </Clay>
      </form>
    );
  }
}

export default DocumentCreateForm;