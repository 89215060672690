import {connect} from 'react-redux';

import {appStoreCreate, appStoreDelete, appStoreGetDisplayData, appStoreUpdateDisplayData} from '../../../store/appStore/actions';
import VacancyRead, {VACANCY_READ_STORE} from './VacancyRead';

const mapStateToProps = (state) => {
  const store = state.appStore[VACANCY_READ_STORE];

  return {
    dataSource: store ? store.storeDataSource : null,
    displayData: store ? store.storeDisplayData : null
  };
};

const mapDispatchToProps = {
  appStoreCreate,
  appStoreDelete,
  appStoreGetDisplayData,
  appStoreUpdateDisplayData
};

export default connect(mapStateToProps, mapDispatchToProps)(VacancyRead);