import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Clay} from '@n3/kit';

import {LOGO_TITLE, LOGO_SUBTITLE} from '../../constants/pagesName';
import logo from '../../assets/images/logo.png';

class HeaderLogo extends Component {
  handleClick = () => {
    this.props.userAuthorised ?
      this.props.history.push('/') :
      this.props.history.push('/login');
  };

  render() {
    return (
      <div>
        <div className="app-container__row_flex-start">
          <img src={logo} alt="img"
               style={{width: '25px'}}
               onClick={this.handleClick}
               className="app-container__cursor_pointer" />

          <Clay margin={[0, 1]}>
            <div className="app-container__font-size_13 app-container__font-weight_500">
              {LOGO_TITLE}
            </div>
            <div className="app-container__font-size_12 app-container__color_gray-3">
              {LOGO_SUBTITLE}
            </div>
          </Clay>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuthorised: state.app.userAuthorised
  };
};

export default connect(mapStateToProps)(withRouter(HeaderLogo));