import {connect} from 'react-redux'

import UserPic from './UserPic'
import {appUpdateSidebar, setUserLogout, setUserToken} from '../../store/app/actions';
import {getRequestUserProfile} from '../../store/user/actions';
import {appStoreUpdateListData} from '../../store/appStore/actions';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userToken: state.app.userToken,
    userAuthorised: state.app.userAuthorised
  };
};

const mapDispatchToProps = {
  setUserToken,
  setUserLogout,
  appUpdateSidebar,
  getRequestUserProfile,
  appStoreUpdateListData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPic)