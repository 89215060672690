export const API = '/demands/contractor/';

export const FORM_NAME = 'demandUpdateForm';

export const RANK = 'rank';
export const GENDER = 'gender';
export const STATUS = 'status';
export const AUTHOR = 'author';
export const DATE_CLOSE = 'date_close';
export const PROFESSION = 'profession';
export const TECHNOLOGY = 'technology';
export const DATE_CREATED = 'date_created';
export const STATUS_DISPLAY = 'status_display';
export const ACCESS_WORK_TYPE = 'access_work_type';

export const SELECTOR_DEFAULT_VALUE = {value: null, label: 'Выберите...'};

export const DEMAND_UPDATE_FORM = {
  [GENDER]: {},
  [AUTHOR]: {},
  [STATUS]: null,
  [TECHNOLOGY]: [],
  [DATE_CLOSE]: null,
  [DATE_CREATED]: null,
  [STATUS_DISPLAY]: null,
  [ACCESS_WORK_TYPE]: [],
  [RANK]: SELECTOR_DEFAULT_VALUE,
  [PROFESSION]: SELECTOR_DEFAULT_VALUE
};