import React, {Component} from 'react';
import {Field, reduxForm, change, formValueSelector, SubmissionError, reset} from 'redux-form';
import {Modal, Clay, Row, Col, Button, log} from '@n3/kit';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import * as dictionaries from '../../../constants/dictionariesSource';
import {appStoreGetListData} from '../../../store/appStore/actions';
import StaffSelectorAsync from '../../form/StaffSelectorAsync';
import {mapValuesToSend} from '../../../services/mapValuesToSend';
import StaffDatepicker from '../../form/StaffDatepicker';
import {mapErrorMessage} from '../../../services/mapper';
import * as messages from '../../../constants/messages';
import validate from './formValidateUpdateStatus';
import * as form from './formConstructor';
import api from '../../../API';
import {withRouter} from 'react-router-dom';

const FORM_NAME = 'specialistUpdateStatusForm';

class SpecialistUpdateStatus extends Component {

  handleChange = (name, value) => {
    this.props.change(name, value);
  };

  submit = (values) => {
    const {specId} = this.props;
    const request = `/specialists/employees/create/${specId}/`;
    const data = this.prepareDataToSubmit(mapValuesToSend(values));

    return api.request(request, {method: 'patch', data})
      .then((resp) => {
        const path = `/specialists/update/${resp.employment_status}/${resp.id}/1`;
        this.props.history.push(path);
      })
      .catch((error) => {
        const errorMessages = error.response.status === 400 && mapErrorMessage(error);
        this.renderErrorLog(errorMessages);
        throw new SubmissionError({
          _error: errorMessages ? errorMessages : messages.SERVER_ERROR
        });
      });
  };

  renderErrorLog = (msg) => {
    log({
      title: 'Ошибка',
      message: msg,
      color: 'danger'
    });
  };

  prepareDataToSubmit = (data) => {
    return {
      'work': data
    };
  };

  render() {
    const {isOpened, toggleOpened, handleSubmit, rank} = this.props;

    return (
      <Modal show={isOpened}
             onHide={toggleOpened}>
        <Modal.Body>
          <Clay font="h2" padding={[0, 0, 3]}>
            Необходимо указать данные о работе в Вашей организации

            <form onSubmit={handleSubmit(this.submit)} autoComplete="off">

              <Row>
                <Col xs={4}>
                  <Field label="Дата начала работы"
                         isRequired={true}
                         name={form.DATE_START_WORK}
                         component={StaffDatepicker}
                         handleChange={this.handleChange} />
                </Col>
                <Col xs={4}>
                  <Field label="Должность"
                         name={form.RANK}
                         isRequired={true}
                         selectorValue={rank}
                         component={StaffSelectorAsync}
                         dataSource={dictionaries.RANKS}
                         handleChange={this.handleChange} />
                </Col>

                <Col xs={4}>
                  <Clay padding={[6, 0, 0]} textAlign="right">
                    <Button type="submit" color="primary">
                      Сохранить
                    </Button>
                  </Clay>
                </Col>
              </Row>

            </form>
          </Clay>
        </Modal.Body>
      </Modal>
    );
  }
}

SpecialistUpdateStatus.propTypes = {
  specId: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  storeName: PropTypes.string.isRequired,
  toggleOpened: PropTypes.func.isRequired
};

SpecialistUpdateStatus = reduxForm({validate, form: FORM_NAME}, {change, reset})(SpecialistUpdateStatus);

const selector = formValueSelector(FORM_NAME);

const mapStetToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    [form.RANK]: selector(state, form.RANK),

    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreGetListData
};

export default connect(mapStetToProps, mapDispatchToProps)(withRouter(SpecialistUpdateStatus));