import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {appStoreSaveFilters, appStoreUpdateFilter, appStoreGetFilteredData} from '../../../store/appStore/actions';
import {EMPLOYMENT_STATUS_VALUES} from '../../../constants/selectorsValues';
import StaffSelector from '../../form/StaffSelector';
import {getFilterValue, mapFiltersToStr} from '../../../services/filters';
import {Col, Row, Clay} from '@n3/kit';
import * as filter from './filterConstructor';

class RecruitmentListFilter extends Component {

  componentDidMount() {
    this.initFilters();
  }

  componentDidUpdate() {
    this.initFilters();
  }

  initFilters = () => {
    const storeFilters = this.props.storeFilters;
    if (storeFilters) {
      !storeFilters.length ? this.setFilters() : this.getFilteredData();
    } else {
      this.setFilters()
    }
  }

  setFilters = () => {
    const {storeName, appFilters} = this.props;
    const filters = [...filter.SPECIALISTS_LIST_FILTER];

    if (appFilters.length) {
      appFilters.forEach(appFilter => {
        const index = filters.findIndex(filter => filter.name === appFilter.name);

        if (index > -1) {
          filters[index] = appFilter;
        }
      });
    }

    this.props.appStoreSaveFilters(storeName, filters);
  };

  getFilteredData = () => {
    const {storeName, dataSource, storeFilters} = this.props;
    const request = `${dataSource}${mapFiltersToStr(storeFilters)}`;
    this.props.appStoreGetFilteredData(storeName, request);
  };

  handleSelectorChange = (name, selectorValue) => {
    const storeName = this.props.storeName;
    const label = selectorValue.label;
    const value = selectorValue.value;

    this.props.appStoreUpdateFilter(storeName, {name, label, value});
  };

  render() {
    if (!this.props.storeFilters) {
      return null;
    }

    const { storeFilters } = this.props;

    return (
      <Clay>
        <Row>
          <Col xs={2}>
            <StaffSelector  label="Статус занятости"
                            options={EMPLOYMENT_STATUS_VALUES.filter(status => status.value !== 'employee')}
                            handleChange={this.handleSelectorChange}
                            input={{name: filter.EMPLOYMENT_STATUS}}
                            selectorValue={getFilterValue(storeFilters, filter.EMPLOYMENT_STATUS)} />
          </Col>
        </Row>
      </Clay>
    );
  }
}

RecruitmentListFilter.propTypes = {
  storeName: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName;
  const store = state.appStore[storeName];

  return {
    appFilters: state.app.appFilters,
    storeFilters: store ? store.storeFilters : null,
    dataSource: store ? store.storeDataSource : null
  };
};

const mapDispatchToProps = {
  appStoreSaveFilters,
  appStoreUpdateFilter,
  appStoreGetFilteredData
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentListFilter);
